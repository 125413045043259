<template>
  <div class="page-content">
    <div
      class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
    >
      <div class="my-2">
        <h2 class="mb-2 mb-md-0 page-heading">Test</h2>
        <div class="d-flex">
          <button type="button" onclick="history.back()" class="btn">
            <i class="fas fa-chevron-left"></i>
          </button>
          <h4 style="padding-top: 4px">View candidates</h4>
        </div>
      </div>
      <div
        class="testHeader-button-section d-flex align-items-center flex-wrap text-nowrap"
      >
        <a href="#" class="test-SelectTeam-Button btn me-2"> Select Team </a>
        <button
          type="button"
          class="test-addDate-button btn"
          data-bs-toggle="modal"
          data-bs-target="#ViewCandidatesAddDateModal"
        >
          Add Dates
        </button>
      </div>
    </div>

    <div class="row">
      <div
        class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
      >
        <div class="search-bar">
          <form class="search-form" style="width: 300px">
            <input
              type="text"
              placeholder="Search By Name"
              name=""
              class="form-control"
            />
            <button class="btn"><i class="fas fa-search"></i></button>
          </form>
        </div>
        <div class="d-flex align-items-center flex-wrap text-nowrap my-2">
          <div class="form-group">
            <select class="form-select">
              <option selected disabled>Status</option>
              <option>Pass</option>
              <option>Fail</option>
              <option>To be review</option>
              <option>In progress</option>
              <option>Pending</option>
            </select>
          </div>
          <button
            type="button"
            class="btn btn-outline-secondary btn-icon-text text-dark mx-3"
            data-bs-toggle="modal"
            data-bs-target="#ViewCandidatesDownloadModal"
          >
            Download <i class="fas fa-download mx-2"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row ViewCandidates-Table-section">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card p-0">
          <div class="card-body">
            <div class="table-responsive pt-3">
              <table class="table table-border">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Allan Wong</td>
                    <td>allanw@gmail.com</td>
                    <td><div class="btn btn-success">Pass</div></td>
                    <td>
                      <div class="dropdown">
                        <button
                          class="btn p-0"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li>
                            <a
                              class="dropdown-item d-flex align-items-center disabled"
                            >
                              <span class=""
                                ><i class="fas fa-envelope"></i> Resend
                                invite</span
                              >
                            </a>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="btn bg-white dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#ViewCandidatesReportModal"
                            >
                              <i class="far fa-file-alt"></i> Report
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>John Mark</td>
                    <td>john.mark@gmail.comy</td>
                    <td><div class="btn btn-success">Pass</div></td>
                    <td>
                      <div class="dropdown">
                        <button
                          class="btn p-0"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li>
                            <a
                              class="dropdown-item d-flex align-items-center disabled"
                            >
                              <span class=""
                                ><i class="fas fa-envelope"></i> Resend
                                invite</span
                              >
                            </a>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="btn bg-white dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#ViewCandidatesReportModal"
                            >
                              <i class="far fa-file-alt"></i> Report
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Carol Koo</td>
                    <td>carolkoo@gmail.com</td>
                    <td><div class="btn btn-danger">Fail</div></td>
                    <td>
                      <div class="dropdown">
                        <button
                          class="btn p-0"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li>
                            <a
                              class="dropdown-item d-flex align-items-center disabled"
                            >
                              <span class=""
                                ><i class="fas fa-envelope"></i> Resend
                                invite</span
                              >
                            </a>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="btn bg-white dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#ViewCandidatesReportModal"
                            >
                              <i class="far fa-file-alt"></i> Report
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Joshua Ann</td>
                    <td>joshuaann@gmail.com</td>
                    <td><div class="btn btn-secondary">To be review</div></td>
                    <td>
                      <div class="dropdown">
                        <button
                          class="btn p-0"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li>
                            <a
                              class="dropdown-item d-flex align-items-center disabled"
                            >
                              <span class=""
                                ><i class="fas fa-envelope"></i> Resend
                                invite</span
                              >
                            </a>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="btn bg-white dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#ViewCandidatesReportModal"
                            >
                              <i class="far fa-file-alt"></i> Report
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Huang Lian</td>
                    <td>huanglian@gmail.com</td>
                    <td><div class="btn btn-warning">Pending</div></td>
                    <td>
                      <div class="dropdown">
                        <button
                          class="btn p-0"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li>
                            <a
                              class="dropdown-item d-flex align-items-center disabled"
                            >
                              <span class=""
                                ><i class="fas fa-envelope"></i> Resend
                                invite</span
                              >
                            </a>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="btn bg-white dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#ViewCandidatesReportModal"
                            >
                              <i class="far fa-file-alt"></i> Report
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ViewCandidates",
};
</script>
<style></style>
