<template>
  <div>
    <Loader />
    <Popup />
    <router-view />
  </div>
</template>

<script>
import Loader from "./components/loader";
import Popup from "./components/popup";
import { mapState ,mapActions} from "vuex";
import router from './router';
export default {
  name: "App",
  computed: {
    ...mapState(["loading"]),
  },
  data() {
    return {
      isPageLoaded: false,
    };
  },
  async created(){
  await this.RefreshBillingDetails();
  },
  mounted() {
    window.addEventListener('online', () => {
      this.UpdateOnlineStatus();
    });
    window.addEventListener('offline', () => {
      this.UpdateOnlineStatus();
      router.push("/internet");
    });
    window.addEventListener("load", () => {
      this.isPageLoaded = true;
      if (!this.loading) {
        if (document.body) {
          document.body.classList.remove("loading1");
        }
      }
    });
  },
  watch: {
    "$store.state.loader.isLoading": function (newValue) {
      setTimeout(() => {
        if (newValue) {
          document.body.classList.add("loading1");
        } else {
          document.body.classList.remove("loading1");
        }
      }, 0);
    },
  },
  methods: {
    ...mapActions(["UpdateOnlineStatus","RefreshBillingDetails"]),
    // ...mapActions(["UpdateOnlineStatus"]),

  },
  components: {
    Loader,
    Popup,
  },
};
</script>

<style>
.loading1 {
  overflow: hidden;
}
</style>
