<template>
  <div class="page-content">
    <div
      class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
    >
      <div class="my-2">
        <h2 class="mb-2 mb-md-0 page-heading">Users</h2>
        <h4>Roles and Permissions</h4>
      </div>
    </div>

    <section class="container-fluid faq_page_section">
      <div class="row">
        <div class="tab-content" id="nav-tabContent">
          <div
            class="tab-pane fade"
            id="CreateAdditionalAccount"
            role="tabpanel"
            aria-labelledby="CreateAdditionalAccount-list"
          >
            Create additional account
          </div>
          <div
            class="tab-pane fade"
            id="Multi-accountPlans"
            role="tabpanel"
            aria-labelledby="Multi-accountPlans-list"
          >
            Multi - account plans
          </div>
          <div
            class="tab-pane fade"
            id="JoinAnotherAccount"
            role="tabpanel"
            aria-labelledby="JoinAnotherAccount-list"
          >
            Join Another Account
          </div>
          <div
            class="tab-pane fade"
            id="SwitchBetweenAccounts"
            role="tabpanel"
            aria-labelledby="SwitchBetweenAccounts-list"
          >
            Switch Between Accounts
          </div>
          <div
            class="tab-pane fade"
            id="FrozenAccounts"
            role="tabpanel"
            aria-labelledby="FrozenAccounts-list"
          >
            Frozen Accounts
          </div>
          <div
            class="tab-pane fade"
            id="FullListOfLimits"
            role="tabpanel"
            aria-labelledby="FullListOfLimits-list"
          >
            Full List Of Limits
          </div>

          <!-- Management -->

          <div
            class="tab-pane fade"
            id="HowToAddOrRemovePartners"
            role="tabpanel"
            aria-labelledby="HowToAddOrRemovePartners-list"
          >
            How To Add Or Remove Partners
          </div>
          <div
            class="tab-pane fade"
            id="HowToAddOrRemoveTeam"
            role="tabpanel"
            aria-labelledby="HowToAddOrRemoveTeam-list"
          >
            How to add or remove Team
          </div>
          <div
            class="tab-pane fade show active"
            id="HowToAddOrRemoveUser"
            role="tabpanel"
            aria-labelledby="HowToAddOrRemoveUser-list"
          >
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="w-50">Plan</th>
                    <th>Default roles</th>
                    <th>Customized roles</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Enterprise plan (super admin)</td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                  </tr>
                  <tr>
                    <td>Premium</td>
                    <td><i class="fas fa-check"></i></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="w-25">Plan</th>
                    <th>Master Admin</th>
                    <th>Admin (Client)</th>
                    <th>Admin (Partner)</th>
                    <th>Recruiter</th>
                    <th>Operations</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Dashboard</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>View overview</td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                  </tr>
                  <tr>
                    <td>View analytics</td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td></td>
                    <td><i class="fas fa-check"></i></td>
                  </tr>
                  <tr>
                    <td>View Predictive</td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td></td>
                    <td><i class="fas fa-check"></i></td>
                  </tr>
                  <tr>
                    <th>Partners</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>View partners</td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Add partners</td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Edit partners</td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Delete partners</td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Test</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>View test</td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Edit test</td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Create test</td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Send test</td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Archive test</td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Delete test</td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td><i class="fas fa-check"></i></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "faq_page",
};
</script>
<style scoped>
.my-2 {
  padding-left: 40px;
}
</style>
