<template> 
        <!-- Screenshots -->
        <div style="margin-top: 25px;page-break-inside: avoid;">
            <span style="
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                letter-spacing: 0.008em;
                text-transform: uppercase;
                color: #666666;
              ">DISCLAIMER</span>
            <p style="
                font-style: italic;
                font-weight: 400;
                font-size: 16px;
                color: #666666;
                margin-bottom: 10px;
              ">
              From the test results it seems that, the candidate may be considered
              for the further evaluation in the recruitment process.
            </p>
  
            <p style="font-weight: 400; font-size: 16px; color: #666666">
              PMaps is involved in psychometric assessments and is capable of, on
              a best effort basis designing a test as per its client’s
              requirements and making a suggestion or giving an opinion on the
              suitability of a candidate for a particular job role based on the
              evaluation and interpretation of the candidate’s test results. The
              same applies to the report on the candidate’s psychometric profile,
              the report is an opinion on the candidate’s personality. PMaps makes
              no warranty or representation with respect to the accuracy of its
              opinion, suggestion or the profile report. Client will use PMaps
              opinion and suggestion as an input in its recruitment process, but
              PMaps will not be held liable for any decision that client takes
              based on the suggestions, opinions or the profile report given by
              PMaps. Client indemnifies PMaps from any liability that can arise on
              account of utilizing PMaps services and holds PMaps harmless without
              limitation, against any losses, claims, costs, damages or
              liabilities suffered by PMaps arising out of or in connection with
              providing its services to client. This indemnification extends to
              PMaps, its affiliates, respective directors, officers and employees.
            </p>
          </div>

</template>       