<template>
  <div class="page-content" id="status">
    <div id="status">
      <div class="text-center" id="status">
        <img src="../assets/Images/No-internet-connection.png" />
      </div>
    </div>
  </div>
</template>

<script>
import router from '../router';

export default {
  name: "Internet",
  mounted() {
    if (navigator.onLine) {
      router.push("/");
    } else {
      // console.log("Not-Connected");
    }
  }

};

window.addEventListener('online', () => {
  router.push("/");
});
</script>
