<template>
  <section class="container-fluid login_page_section">
    <div class="row">
      <div class="col-md-6 login_left_section">
        <img
          src="../assets/Images/forgot_password_top.png"
          alt=""
          class="forgot_password_top_img"
        />
        <div class="text-center">
          <img src="../assets/Images/forgot_password.png" alt="" width="55%" />
        </div>
        <img
          src="../assets/Images/forgot_password_bottom.png"
          alt=""
          class="forgot_password_bottom_img"
        />
      </div>
      <div class="col-md-6 login_right_section reset-password-kj-mv">
        <div class="logo_section">
          <img src="../assets/Images/logo.png" alt="" width="120" />
        </div>
        <div style="clear: both"></div>

        <div class="row justify-content-center">
          <div class="col-xl-8 col-lg-10 col-md-11">
            <div class="login_page_heading">
              <h3 class="font-weight-800">Forgot your password?</h3>
              <p class="text-muted font-size-13">
                Fear not. We will email you the instructions to<br />
                reset your password. Enter your email address.
              </p>
            </div>
            <form action="">
              <div class="form-group mt-5">
                <label class="input-hero" for="forgot_password_email">
                  <input
                    class="input_area"
                    type="email"
                    required
                    v-model="Email"
                    v-on:keyup.enter="reset"
                  />
                  <span class="input-name"> Email </span>
                </label>
              </div>

              <div class="my-5 login_button_area">
                <router-link
                  :to="{ name: 'Login' }"
                  class="btn btn-outline-primary me-3 mb-3"
                  type="submit"
                >
                  Back to Login
                </router-link>
                <button
                  class="btn btn-primary mb-3"
                  @click="reset"
                  type="button"
                >
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { validateEmail } from "@/utils";

export default {
  name: "ResetPassword",
  data() {
    return {
      Email: "",
    };
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    ...mapActions(["ResetPassword", "forgetPassword", "ShowWarningMessage"]),

    async reset() {
      if (!validateEmail(this.Email)) {
        return this.ShowWarningMessage("Please enter correct email.");
      } else {
        localStorage.setItem("Email", this.Email);
        await this.forgetPassword({ email: this.Email });
      }
    },
  },
};
</script>
<style></style>
