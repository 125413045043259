<template>
  <div class="page-content">
    <div
      class="d-flex justify-content-between align-items-start flex-wrap mb-2"
    >
      <div class="my-2">
        <h3 class="mb-2 mb-md-0">Descriptive Analytics</h3>
        <p class="font-size-12">30 Nov, 2022 - 15 Dec, 2022</p>
      </div>
      <div
        class="d-flex justify-content-between Overview_dashborad_header_md_flex"
      >
        <div class="d-flex mb-2">
          <div class="top_heading_CreatePartner_btn d-block me-2">
            <div class="Group_top_three_dot">
              <div class="dropdown">
                <button
                  class="btn p-0 Group_top_three_dot_dropdown"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style="line-height: 0.3"
                >
                  <i class="fa-regular fa-calendar"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="top_heading_CreatePartner_btn d-block d-md-none me-2">
            <div class="Group_top_three_dot">
              <div class="dropdown">
                <button
                  class="btn p-0 Group_top_three_dot_dropdown"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style="line-height: 0.3"
                >
                  <i class="fa-solid fa-download"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="partner_item_action">
          <div class="dropdown group_partenr_dropdown_button">
            <button
              class="btn p-0 Group_top_three_dot_dropdown"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Partner
              <i class="fa-solid fa-chevron-down ms-3"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <router-link
                  :to="{ name: 'DashboardGroup' }"
                  class="w-100 btn active text-start"
                >
                  Group
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="Overview_dashborad_map_section my-4">
      <div class="card p-4">
        <div class="card-body p-0">
          <div class="d-md-flex justify-content-between align-items-center">
            <div class="mb-3">
              <h3 class="mb-2 mb-md-0">
                Test Distribution Location
                <img
                  src="../../public/dashboard-assets/images/M2/Actions.png"
                  alt=""
                />
              </h3>
              <p class="font-size-12 mt-1">Total test used: 15,610</p>
            </div>

            <div class="Descriptive_Analytics_tabs_section mb-3">
              <router-link
                :to="{ name: 'DashboardDescriptiveAnalystics' }"
                class="btn Descriptive_Analytics_tabs_link"
              >
                World Map
              </router-link>

              <router-link
                :to="{ name: 'DescriptiveAnalysticsCountry' }"
                class="
                  btn
                  Descriptive_Analytics_tabs_link
                  active
                  btn-outline-primary btn-icon-text
                "
              >
                Country
              </router-link>

              <router-link
                :to="{ name: '' }"
                class="btn Descriptive_Analytics_tabs_link"
              >
                State
              </router-link>
            </div>
          </div>

          <div class="d-flex justify-content-end">
            <div class="partner_item_action">
              <div class="dropdown group_partenr_dropdown_button DescriptiveAnalysticsCountry_dropdown">
                <button
                  class="btn p-0 Group_top_three_dot_dropdown "
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src="../../public/dashboard-assets/images/M2/Country1.png"
                    class="me-1"
                    alt=""
                  />
                  Russia
                  <i class="fa-solid fa-chevron-down ms-4"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <button type="button" class="btn w-100 text-start px-0">
                      <img
                        src="../../public/dashboard-assets/images/M2/Country1.png"
                        class="me-1"
                        alt=""
                      />
                      Russia
                    </button>
                  </li>
                  <li>
                    <button type="button" class="btn w-100 text-start px-0">
                      <img
                        src="../../public/dashboard-assets/images/M2/Country2.png"
                        class="me-1"
                        alt=""
                      />
                      United States
                    </button>
                  </li>
                  <li>
                    <button type="button" class="btn w-100 text-start px-0">
                      <img
                        src="../../public/dashboard-assets/images/M2/Country3.png"
                        class="me-1"
                        alt=""
                      />
                      United Kingdom
                    </button>
                  </li>
                  <li>
                    <button type="button" class="btn w-100 text-start px-0">
                      <img
                        src="../../public/dashboard-assets/images/M2/Country4.png"
                        class="me-1"
                        alt=""
                      />
                      India
                    </button>
                  </li>
                  <li>
                    <button type="button" class="btn w-100 text-start px-0">
                      <img
                        src="../../public/dashboard-assets/images/M2/Country5.png"
                        class="me-1"
                        alt=""
                      />
                      Germany
                    </button>
                  </li>
                  <li>
                    <button type="button" class="btn w-100 text-start px-0">
                      <img
                        src="../../public/dashboard-assets/images/M2/Country6.png"
                        class="me-1"
                        alt=""
                      />
                      Canada
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="Overview_dashborad_map_img">
            <img
              src="../../public/dashboard-assets/images/M2/DescriptiveAnalysticsCountry.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DescriptiveAnalysticsCountry",
};
</script>
<style>
</style>