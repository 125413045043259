<template>
    <!-- style="filter: blur(8px);" -->

  <div class="page-content">
    <div class="Searching_input_box_mobile_view mb-3 position-relative">
      <div class="w-100" v-if="this.show">
        <input
          type="text"
          v-model="input"
          :disabled="searchDisable"
          @keyup.enter="searchregionname(input)"
          placeholder="Search..."
        />
        <!--update @keyup="filteredList"-->
        <!-- <div class="search_detail_list" v-if="this.result > 0">
          <div
            v-for="(item, index) in this.result"
            :key="index"
            class="search_detail_list_item"
            @click="searchusername(item)"
            style="cursor: pointer"
          >
            <span :id="index">
              {{ stringTOHtml(item, index) }}
            </span>
          </div>
        </div> -->
        <!--update-->
        <img
          src="../../public/dashboard-assets/images/M2/Close_icon.png"
          @click="closemobilesearch"
          alt=""
          class="close-icon"
        />
        <!-- </span> -->
      </div>
    </div>

    <!-- header -->
    <div
      class="d-flex justify-content-between align-items-center flex-wrap mb-2"
    >
      <div class="my-2">
        <h3 class="mb-2 mb-md-0">Active Test</h3>
        
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <!-- Search -->
        <!-- Search  web view-->
        <div
          class="top_heading_CreatePartner_btn position-relative z-index-999"
        >
          <div class="Group_top_three_dot">
            <div class="Searching_input-box">
              <input
                type="text"
                v-model="input"
                :disabled="searchDisable"
                @keyup.enter="searchregionname(input)"
                placeholder="Search..."
              />

              <!--update  @keyup="filteredList"-->
              <!-- <div class="search_detail_list" v-if="this.result.length > 0">
                    <div v-for="(item, index) in this.result" :key="index" class="search_detail_list_item"
                      @click="searchregionname(item)" style="cursor: pointer">
                      <span :id="index" style="cursor: pointer">
                        {{ stringTOHtml(item, index) }}
                      </span>
                    </div>
                  </div> -->
              <!--update-->

              <span
                class="icon Group_top_three_dot_dropdown"
                @click="opensearch"
              >
                <img
                  src="../../public/dashboard-assets/images/M2/Icon-Set.png"
                  alt=""
                  class="Searching_search_icon"
                />
              </span>
              <img
                src="../../public/dashboard-assets/images/M2/Close_icon.png"
                @click="closesearch"
                alt=""
                class="close-icon"
              />
            </div>
          </div>
        </div>

        <!-- Mobile View Searching Button -->
        <div
          class="top_heading_CreatePartner_btn position-relative Searching_button_mobileView"
     >
          <div class="Group_top_three_dot">
            <span
              class="icon Group_top_three_dot_dropdown"
              :style="{ display: this.show == true ? 'none' : 'block' }"
              @click="openmobilesearch"
            >
              <img
                src="../../public/dashboard-assets/images/M2/Icon-Set.png"
                alt=""
                class="Searching_search_icon"
              />
            </span>
          </div>
        </div>
        <!-- Filter -->
        <div
          class="top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj d-block mx-2"
        >
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <button
                class="btn p-0 Group_top_three_dot_dropdown position-relative"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
                aria-haspopup="true"
                style="line-height: 0.3"
              >
                <img
                  src="../../public/dashboard-assets/images/M2/Filter_icon.png"
                />

                <span
                  class="Noti-alert-kj translate-middle"
                  v-if="this.applyfilter > 0"
                >
                  {{ this.applyfilter }} <span class="visually-hidden"></span>
                </span>
              </button>

              <ul
                class="dropdown-menu dropdown-menu-kj dropdown-menu-kj-w"
                aria-labelledby="dropdownMenuButton1"
              >
                <div class="modal-header">
                  <h4
                    class="modal-title text-centerm-auto"
                    id="CreateGroupModalLabel"
                  >
                    Filter
                  </h4>
                  <button
                    @click="filterclose"
                    type="button"
                    class="btn-close"
                    data-bs-toggle="dropdown"
                  ></button>
                </div>

                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">

                  <li 
                    class="nav-item" 
                    role="presentation" 
                    v-if="getUserPermissionData({
                      parent: 'Partner Management',
                      child: 'Partner Account', permission: 'Can_View_Partner', //update
                    }) /* && this.isParent === 'true' */ ">
                    <button class=" nav-link text-muted text-center active" id="pills-home-tab" @click="
                        this.filterTab = 1;
                        this.searchfilter = '';
                        this.Filterresult();
                      " 
                      data-bs-toggle="pill" data-bs-target="#pills-home" 
                      type="button" role="tab"
                      aria-controls="pills-home" aria-selected="true"
                    
                      >
 
                      Partner
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link text-muted text-center"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      @click="
                        this.filterTab = 2;
                        this.searchfilter = '';
                        this.Filterresult();
                      "
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Language
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link text-muted text-center"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      @click="
                        this.filterTab = 3;
                        this.searchfilter = '';
                        this.Filterresult();
                      "
                      data-bs-target="#pills-profile1"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Invigilation
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active p-0"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div class="search-bar">
                      <form class="search-form">
                        <input
                          type="text"
                          v-model="searchfilter"
                          @keyup="Filterresult"
                          placeholder="Search"
                          class="form-control bg-white"
                        /><button class="btn">
                          <i class="fas fa-search"></i>
                        </button>
                      </form>
                      <div class="ul-li-checkbox-kj">
                        <ul>
                          <li v-for="item in partners" :key="item">
                            <input
                              type="checkbox"
                              :value="item"
                              name=""
                              @change="addFilter(item.clientId, $event)"
                              :checked="
                                this.filters.partners.includes(item.clientId)
                              "
                            />
                            <span>{{ item.company }} </span>
                          </li>
                        </ul>
                        <div class="text-end">
                          <button
                            type="button"
                            class="btn-Apply-kj-color"
                            v-if="this.applyfilter > 0"
                            @click="clearFilters()"
                          >
                            Clear Filter
                          </button>

                          <button
                            type="button"
                            class="modail_submit_button text-end"
                            :class="{
                              active_form_button: this.applyfilter > 0,
                            }"
                            :disabled="applyfilter == 0"
                            @click="applyFilters()"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade p-0"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div class="search-bar">
                      <form class="search-form">
                        <input
                          type="text"
                          v-model="searchfilter"
                          @keyup="Filterresult"
                          placeholder="Search"
                          class="form-control bg-white"
                        /><button class="btn">
                          <i class="fas fa-search"></i>
                        </button>
                      </form>

                      <!-- <form class="search-form">
                          <input type="text" placeholder="Search" name="" class="form-control bg-white" /><button
                            class="btn">
                            <i class="fas fa-search"></i>
                          </button>
                        </form> -->
                    </div>
                    <div class="ul-li-checkbox-kj">
                      <ul>
                        <li v-for="lang in LanguageList" :key="lang">
                          <input
                            type="checkbox"
                            :value="lang.key"
                            name=""
                            @change="addFilter(lang.key, $event)"
                            :checked="this.filters.language.includes(lang.key)"
                          />
                          <span>{{ lang.value }} </span>
                        </li>
                      </ul>
                      <div class="text-end">
                        <button
                          type="button"
                          class="btn-Apply-kj-color"
                          v-if="this.applyfilter > 0"
                          @click="clearFilters()"
                        >
                          Clear Filter
                        </button>

                        <button
                          type="button"
                          class="modail_submit_button text-end"
                          :class="{ active_form_button: this.applyfilter > 0 }"
                          :disabled="applyfilter == 0"
                          @click="applyFilters()"
                        >
                          Apply
                        </button>
                      </div>
                      <!-- <div class="text-end">
                          <button type="button" class="btn-Apply-kj-color">
                            Clear Filter
                          </button>
                          <button type="button" class="btn-primary btn-Apply-kj">
                            Apply
                          </button>
                        </div> -->
                    </div>
                  </div>
                  <div
                    class="tab-pane fade p-0"
                    id="pills-profile1"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div class="search-bar">
                      <!-- <form class="search-form">
                          <input type="text" placeholder="Search" name="" class="form-control bg-white" /><button
                            class="btn">
                            <i class="fas fa-search"></i>
                          </button>
                        </form> -->

                      <form class="search-form">
                        <input
                          type="text"
                          v-model="searchfilter"
                          @keyup="Filterresult"
                          placeholder="Search"
                          class="form-control bg-white"
                        /><button class="btn">
                          <i class="fas fa-search"></i>
                        </button>
                      </form>
                    </div>
                    <div class="ul-li-checkbox-kj">
                      <ul>
                        <li v-for="invi in InvigilationList" :key="invi">
                          <input
                            type="checkbox"
                            :value="invi.key"
                            name=""
                            @change="addFilter(invi.key, $event)"
                            :checked="
                              this.filters.invigilation.includes(invi.key)
                            "
                          />
                          <span>{{ invi.value }}</span>
                        </li>
                        <!-- <li>
                            <input type="checkbox" name="" id="" />
                            <span>Video Invigilation </span>
                          </li> -->
                        <!-- <li>
                            <input type="checkbox" name="" id="" />
                            <span>Web Invigilation </span>
                          </li> -->
                      </ul>
                      <div class="text-end">
                        <button
                          type="button"
                          class="btn-Apply-kj-color"
                          v-if="this.applyfilter > 0"
                          @click="clearFilters()"
                        >
                          Clear Filter
                        </button>

                        <button
                          type="button"
                          class="modail_submit_button text-end"
                          :class="{ active_form_button: this.applyfilter > 0 }"
                          :disabled="applyfilter == 0"
                          @click="applyFilters()"
                        >
                          Apply
                        </button>
                      </div>
                      <!-- <div class="text-end">
                          <button type="button" class="btn-Apply-kj-color">
                            Clear Filter
                          </button>
                          <button type="button" class="btn-primary btn-Apply-kj">
                            Apply
                          </button>
                        </div> -->
                    </div>
                  </div>
                </div>
              </ul>
              <!-- </div> -->
            </div>
          </div>
        </div>

        <!-- Create Group -->
        <div
          class="top_heading_CreatePartner_btn mx-2"
          v-if="
            getUserPermissionData({
              partner: 'Test Management',
              child: 'Test Account',
              permission: 'Can_Create_Test',
            })
          "
        >
          <button
            type="button"
            class="btn btn-primary btn-icon-text"
            data-bs-toggle="modal"
            data-bs-target="#CreateGroupModal"
          >
            <i class="fa-solid fa-plus"></i> Create Test
          </button>
        </div>

        <!-- Moblie View icon Create Group -->
        <div
          class="mobile_Create_Partner_button managementteam-btn-kj"
          v-if="
            getUserPermissionData({
              partner: 'Test Management',
              child: 'Test Account',
              permission: 'Can_Create_Test',
            })
          "
        >
          <button
            type="button"
            class="btn"
            data-bs-toggle="modal"
            data-bs-target="#CreateGroupModal"
          >
            <i class="fa-solid fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- End -->
    <!-- Data Show -->
    <section class="Partner_empty_main_section_kj mt-3">
      <div class="row">
        <div
          class="col-md-6 col-lg-6 col-xl-3 mb-3 stretch-card"
          v-for="Activelist in List"
          :key="Activelist"
        >
          <div class="card p-0">
            <div
              class="card-body p-0"
              :class="
                Activelist.isActive === false && this.admin
                  ? 'card-body-grey'
                  : ''
              "
            >
              <div
                class="d-flex"
                style="position: absolute; right: 15px; top: 15px;"
              >
                <!-- Added by Pmaps Team -->
                <div>
                  <router-link
                    :to="{
                      name: 'ActiveTestInviteCandidates',
                      params: {
                        id: encode(Activelist.testId),
                        clientId: encode(Activelist.clientId),
                      },
                      query: {
                        search: encode(this.input),
                        offset: encode(this.offset),
                        current: encode(this.currentPage),
                      },
                    }"
                  >
                    <i
                      v-if="
                        Activelist.testId == 3882 ||
                        Activelist.testId == 3824 ||
                        Activelist.testId == 3845 ||
                        Activelist.testId == 3846 ||
                        Activelist.testId == 3851 ||
                        Activelist.testId == 3858 ||
                        Activelist.testId == 3879 ||
                        Activelist.testId == 3880 ||
                        Activelist.testId == 3881 ||
                        Activelist.testId == 3883 ||
                        Activelist.testId == 3859 ||
                        Activelist.testId == 3850
                      "
                      class="fa-solid fa-circle-info i-icon-color"
                      style="cursor: pointer"
                    >
                    </i>
                  </router-link>
                </div>
                <!-- Added by Pmaps Team -->
                <!-- <a data-bs-toggle="modal" data-bs-target="#SharelinkModel" class="text-dark mx-2" @click="
                  (this.testIdforurl = Activelist.testId),
                  (this.SharedUrl = ''),
                  this.GetAllgroups(Activelist.clientId)
                  " v-if="shareTest(Activelist.clientId)">
                  <i style="cursor: pointer" class="fa-solid fa-share-nodes"></i>
                </a> -->
                <span v-if="Activelist.protocorSettings && this.Remainingdays>=0"
                  ><i
                    class="fa-solid fa-eye fa-xs"
                    style="color: #1565ef"
                  ></i
                ></span>

                <span v-if="Activelist.isDeployed == false && this.admin && this.Remainingdays>=0">
                  <a
                    class="fa-solid fa-triangle-exclamation px-2"
                    style="color: #ffc801"
                  >
                  </a>
                </span>
                <span
                  style="cursor: pointer"
                  v-else-if="
                    shareTest(Activelist.clientId) &&
                    Activelist.isPublic == true && this.Remainingdays>=0
                  "
                >
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#SharelinkModel"
                    class="text-dark mx-2"
                    @click="
                      (this.testIdforurl = Activelist.testId),
                        (this.SharedUrl = ''),
                        this.GetAllgroups(Activelist.clientId)
                    "
                  >
                    <i
                      style="cursor: pointer"
                      class="fa-solid fa-share-nodes"
                    ></i>
                  </a>
          
                </span>
                <div class="dropdown" v-if="this.Remainingdays>=0">
                  <button
                    class="btn p-0"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fa-solid fa-ellipsis"></i>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li
                      v-if="
                        showList(Activelist.isParent, Activelist.clientId) &&
                        getUserPermissionData({
                          parent: 'Candidate Management',
                          child: 'Candidates',
                          permission: 'Can_Invite_Candidate',
                        })
                      "
                    >
                    <span v-if="Activelist.isActive == true && Activelist.isDeployed == true">
                        <div class="dropdown-item d-flex align-items-center" @click="
                          invite(
                            Activelist.testName,
                            Activelist.testId,
                            Activelist.clientId
                          )
                        " v-if="shareTest(Activelist.clientId)">
                            Invite Candidates
                        </div>
                      </span>
                    </li>
                    <li>
                      <router-link
                        :to="{
                          name: 'ActiveTestInviteCandidates',
                          params: {
                            id: encode(Activelist.testId),
                            clientId: encode(Activelist.clientId),
                          },
                          query: {
                            search: encode(this.input),
                            offset: encode(this.offset),
                            current: encode(this.currentPage),
                          },
                        }"
                      >
                        <div class="dropdown-item d-flex align-items-center">
                          Test Instance
                        </div>
                      </router-link>
                    </li>
                    <!-- <li v-if="getUserPermissionData({parent:'Candidate Management', child:'Candidates', permission:'Can_Access_Candidates'})">
                                                  <router-link :to="{
                                                  name: 'ActiveTestViewCandidates',
                                                  params: { id: Activelist.testId , search:this.input},
                                              }">
                                                      <div class="dropdown-item d-flex align-items-center">
                                                          View Candidates
                                                      </div>
                                                  </router-link>
                                              </li> -->
                    <!-- <li v-if="this.admin">
                                                  <div class="dropdown-item d-flex align-items-center">
                                                      Edit Test
                                                  </div>
                                              </li> -->
                    <li v-if="Activelist.isDeployed == false && this.admin">
                      <div
                        @click="Deployundeploy(Activelist.testId, 'deploy')"
                        class="dropdown-item d-flex align-items-center"
                      >
                        Deploy
                      </div>
                    </li>
                    <li v-if="Activelist.isDeployed == true && this.admin">
                      <div
                        @click="Deployundeploy(Activelist.testId, 'undeploy')"
                        class="dropdown-item d-flex align-items-center"
                      >
                        Undeploy
                      </div>
                    </li>
                    <li v-if="Activelist.isPublic == false && this.admin">
                      <div
                        @click="privatePublic(Activelist.testId, 'public')"
                        class="dropdown-item d-flex align-items-center"
                      >
                        Public
                      </div>
                    </li>
                    <li v-if="Activelist.isPublic == true && this.admin">
                      <div
                        @click="privatePublic(Activelist.testId, 'private')"
                        class="dropdown-item d-flex align-items-center"
                      >
                        Private
                      </div>
                    </li>
                    <li v-if="Activelist.isActive == false && this.admin">
                      <div
                        @click="ActivateDeactivate(Activelist.testId, 'active')"
                        class="dropdown-item d-flex align-items-center"
                      >
                        Activate
                      </div>
                    </li>
                    <li v-if="Activelist.isActive == true && this.admin">
                      <div
                        @click="
                          ActivateDeactivate(Activelist.testId, 'deactivate')
                        "
                        class="dropdown-item d-flex align-items-center"
                      >
                        Deactivate
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <router-link
                :to="{
                  name: 'ActiveTestViewCandidatesNew',
                  params: {
                    testId: encode(Activelist.testId),
                    clientId: encode(Activelist.clientId),
                    isParent: encode(isParent), //update
                  },
                  query: {
                    search: encode(this.input),
                    offset: encode(this.offset),
                    current: encode(this.currentPage),
                  },
                }"
              >
                <div class="p-3">
                  <div class="Partner_management_top-kj mb-2">
                    <div class="d-flex font-size-12">
                      <span class="text-muted me-1">Test Id: </span>
                      <p class="test-dark fw-bold">{{ Activelist.testId }}</p>
                    </div>


                  </div>
                  <!-- <h6 class="text-dark" v-if="Activelist.testName.length > 15">{{
                                  Activelist.testName.substring(0,
                                      Activelist.testName.lastIndexOf(Activelist.testName.substring(40))) + "..."
                          }}</h6> -->
                  <h6 class="text-dark mt-4">{{ Activelist.testName }}</h6>


                  <div class="my-3">
                    <h6 class="text-muted">Partner</h6>
                    <!-- <h4 class="text-dark" v-if="Activelist.company.length > 15">{{
          Activelist.company.substring(0,
              Activelist.company.lastIndexOf(Activelist.csompany.substring(26))) + "..."
  }}</h4> -->
                    <h6 class="text-dark">{{ Activelist.company }}</h6>
                  </div>
                  <div class="px-2">
                    <!-- <img src="../../public/dashboard-assets/images/M2/ActiveTestchat.png" alt="" class="w-100" /> -->
                    <div class="row">
                      <div
                        class="col-6 p-0 d-flex justify-content-between align-items-center"
                      >
                        <div
                          class="m-auto"
                          style="position: relative"
                          v-if="
                            this.showgraphornot(
                              Activelist.completed,
                              Activelist.progress,
                              Activelist.scheduled
                            )
                          "
                        >
                          <DoughnutChart
                            :chartData="this.setup(Activelist)"
                            :width="500"
                            :height="300"
                            max-width="100%"
                            max-height="100%"
                            style="
                              max-width: 100%;
                              max-height: 100%;
                              display: block;
                              height: 105.6px;
                              width: 107.2px;
                            "
                          />
                          <div class="active_test_cart_number">
                            {{ Activelist.scheduled }} <br />
                            Candidates
                          </div>
                        </div>
                        <div class="m-auto" style="position: relative" v-else>
                          <DoughnutChart
                            :chartData="this.setup()"
                            :options="this.options"
                            :width="500"
                            :height="300"
                            max-width="100%"
                            max-height="100%"
                            style="
                              max-width: 100%;
                              max-height: 100%;
                              display: block;
                              height: 105.6px;
                              width: 107.2px;
                            "
                          />
                          <div class="active_test_cart_number">
                            0 <br />
                            Candidates
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-1 p-0">
                            <i class="fa-solid fa-circle circle-icon"></i>
                          </div>
                          <div class="col-11 p-0">
                            <div
                              class="d-flex justify-content-between align-items-center mt-1"
                            >
                              <div class="d-flex align-items-center">
                                <span class="text-active-kj"> Completed</span>
                              </div>
                              <span class="text-active-kj">{{
                                Activelist.completed
                              }}</span>
                            </div>
                            <div class="progress mt-1">
                              <div
                                class="bg-success-kj"
                                role="progressbar"
                                style="width: 50%"
                                aria-valuenow="50"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {{ Activelist.pass }}
                              </div>
                              <div
                                class="bg-danger-kj"
                                role="progressbar"
                                style="width: 50%"
                                aria-valuenow="50"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {{ Activelist.fail }}
                              </div>
                            </div>
                            <div class="d-flex m-0">
                              <span class="progress-label-vales w-50"
                                >Pass</span
                              >
                              <span class="progress-label-vales w-50"
                                >Fail</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-1 p-0">
                            <i
                              class="fa-solid fa-circle circle-icon-progress"
                            ></i>
                          </div>
                          <div class="col-11 p-0">
                            <div
                              class="d-flex justify-content-between align-items-center mt-1"
                            >
                              <div class="d-flex align-items-center">
                                <span class="text-active-kj"> In-progress</span>
                              </div>
                              <span class="text-active-kj">{{
                                Activelist.progress
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-1 p-0">
                            <i
                              class="fa-solid fa-circle circle-icon-Pending"
                            ></i>
                          </div>
                          <div class="col-11 p-0">
                            <div
                              class="d-flex justify-content-between align-items-center mt-1"
                            >
                              <div class="d-flex align-items-center">
                                <span class="text-active-kj"> Pending</span>
                              </div>
                              <span class="text-active-kj">{{
                                Activelist.notAttempted
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </router-link>
            </div>
          </div>
        </div>
      </div>

      <paginate
        v-model="currentPage"
        :page-count="Page_count"
        :margin-pages="1"
        :page-range="1"
        :click-handler="clickCallback"
        :prev-text="'< Prev'"
        :next-text="'Next >'"
        :container-class="'pagination'"
        :page-class="'page-item'"
      >
      </paginate>

      <!-- share Via model -->
      <div
        class="modal fade"
        id="SharelinkModel"
        tabindex="-1"
        aria-labelledby="IncreaseTokenModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm"
        >
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header token-model-header-color">
              <h5
                class="modal-title text-centerm-auto"
                id="IncreaseTokenModalLabel"
              >
                <span class="font-size-20-kj"> Share Link</span>
                <p class="Active-model-p-kj">
                  Please choose a group you would like to share the test.
                </p>
              </h5>

              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                @click="resetShareLink"
                aria-label="btn-close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="form-group my-4">
                <label class="input-hero" for="Group">
                  <select
                    name=""
                    class="input_area select_ca"
                    v-model="SelectGroup"
                    @change="onChange()"
                    id=""
                  >
                    <option value="" selected="" disabled="">
                      Choose Group<span class="text-danger">*</span>
                    </option>
                    <option
                      v-for="item in groupshowList"
                      :key="item"
                      :value="item.regionId"
                    >
                      {{ item.regionName }}
                    </option></select
                  ><span class="input-name"
                    >Group <span class="text-danger">*</span></span
                  ></label
                >
              </div>

              <div class="Share-via-kj" v-if="SharedUrl != ''">
                <h6>Share via</h6>
                <div class="social-media-kj">
                  <a
                    :href="
                      'https://www.facebook.com/sharer/sharer.php?u=' +
                      this.SharedUrl
                    "
                    target="_blank"
                    class="social-icon-kj"
                  >
                    <img
                      src="../../public/dashboard-assets/images/M2/facebook.png"
                      alt=""
                      class="w-60"
                    />
                    <!-- <i class="fab fa-facebook-f"></i> -->
                  </a>
                  <a
                    :href="
                      'https://twitter.com/intent/tweet?url=' + this.SharedUrl
                    "
                    target="_blank"
                    class="social-icon-kj"
                  >
                    <!-- <i class="fab fa-twitter"></i> -->
                    <img
                      src="../../public/dashboard-assets/images/M2/twitter.png"
                      alt=""
                      class="60"
                    />
                  </a>
                  <a
                    :href="
                      'https://api.whatsapp.com/send?text=' + this.SharedUrl
                    "
                    target="_blank"
                    class="social-icon-kj"
                  >
                    <img
                      src="../../public/dashboard-assets/images/M2/whtasup.png"
                      alt=""
                      class="60"
                    />
                    <!-- <i class="fa-brands fa-whatsapp"></i> -->
                  </a>
                  <a
                    :href="
                      'https://www.linkedin.com/sharing/share-offsite/?url=' +
                      this.SharedUrl
                    "
                    target="_blank"
                    class="social-icon-kj"
                  >
                    <img
                      src="../../public/dashboard-assets/images/M2/link.png"
                      alt=""
                      class="60"
                    />
                    <!-- <i class="fab fa-linkedin-in"></i> -->
                  </a>
                  <button
                    :disabled="this.SelectGroup == ''"
                    @click="copytoClipboard(this.SharedUrl)"
                    data-bs-dismiss="modal"
                    class="social-icon-kj social-icomn-active"
                    :class="{
                      active_form_button2: this.SelectGroup == null,
                    }"
                  >
                    <img
                      src="../../public/dashboard-assets/images/M2/linkshare.png"
                      alt=""
                      class="60"
                    />
                    <!-- <i class="fa-solid fa-paperclip"></i> -->
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  Invite Candidates model -->
      <!-- <inviteCandidate
        v-if="this.openInvite == true"
        :testname="this.testName"
        :testid="this.testId"
        :testclientid="this.testclientid"
        @clicked="closeinvite"
      /> -->


      <InviteCandidatesNew
        v-if="this.openInvite == true"
        :testname="this.testName"
        :testid="this.testId"
        :testclientid="this.testclientid"
        @clicked="closeinvite"
      />
      

      <!-- Archive Test? -->
      <div
        class="modal fade"
        id="DeactivatePartnerModal"
        tabindex="-1"
        aria-labelledby="DeactivatePartnerModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
          v-for="Activelist in List"
          :key="Activelist"
        >
          <div class="modal-content">
            <div class="modal-body p-4">
              <div class="">
                <h3 class="mb-3">Archive Test?</h3>
                <p class="text-muted">
                  Are you sure you want to Archive
                  <strong> {{ Activelist.company }}</strong>
                </p>
              </div>
              <div class="Deactivate_buttons row mt-5 justify-content-center">
                <button
                  class="col-5 Deactivate_Cancel_buttons"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="col-5 btn Deactivate_Deactivate_buttons"
                >
                  Archive
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- empty section -->
    <section class="Partner_empty_main_section_hp" v-if="List.length == 0">
      <div class="row">
        <div class="Partner_empty_img_hp m-auto text-center">
          <img
            src="../../public/dashboard-assets/images/M2/Partner_blank.png"
            alt=""
          />
        </div>
        <div class="Partner_empty_content_hp m-auto text-center">
          <div class="mb-4">
            <h2>No Record Found</h2>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { superadmin } from "../utils/helper";
import Paginate from "vuejs-paginate-next";
import { getUserPermissionData } from "../utils/helper";
// import { SFacebook, STwitter ,SWhatsApp,SLinkedIn } from 'vue-socials'
import { mapState, mapActions, mapMutations } from "vuex";
// import inviteCandidate from "./inviteCandidate.vue";
import InviteCandidatesNew from "./InviteCandidatesNew.vue";
import { DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import { calculateRemainingDays } from '../platformFeatures/dateUtils';
Chart.register(...registerables);

export default {
  name: "Active-Tests",
  components: {
    paginate: Paginate,
    // inviteCandidate,
    InviteCandidatesNew,
    DoughnutChart,
    // SFacebook,STwitter,SWhatsApp,SLinkedIn
  },

  data() {
    return {
      // facebookShareOptions: {
      //               url: this.SharedUrl,
      //               // quote: this.SharedUrl,
      //               // hashtag: '#Github',
      //           },
      //           twitterShareOptions: {
      //               url: this.SharedUrl,
      //               title: 'Say hi to Vite! A brand new, extremely fast development setup for Vue.',

      //               // text: this.SharedUrl,
      //               // hashtags: ['hash', 'tag'],
      //               via: 'Pmaps',
      //           },
      //           shareOptions: {
      //     // number: '1(999)999-99-99',
      //     text: this.SharedUrl,
      //   },
      //   shareOptions1: {

      //     url: this.SharedUrl
      //   },
      //   useNativeBehavior: false,

      isShowing: false,
      openInvite: false,

      testIdforurl: "",
      SharedUrl: "",
      filterTab: 1,
      results: [],
      groupshowList: [],
      listing: [],
      SelectGroup: "",
      page_count: 1,
      limit: 8,
      offset: 1,
      result: [],
      List: [],
      input: "",
      show: false,
      currentPage: 1,
      testId: [],
      partners: [],
      partnersFilter: [],
      applyfilter: 0,
      searchDisable: false,
      searchActivationList: [],
      testclientid: "",
      filters: {
        partners: [],
        language: [],
        invigilation: [],
        testsearchname: "",
      },
      isPublic: true,
      isParent: localStorage.getItem("isParent"), //update
      LanguageList: [
        {
          key: 1,
          value: "English",
        },
        {
          key: 2,
          value: "Gujarati",
        },
        {
          key: 3,
          value: "Marathi",
        },
        {
          key: 4,
          value: "Tamil",
        },
        {
          key: 5,
          value: "Kannada",
        },
        {
          key: 6,
          value: "French",
        },
      ],
      LanguageListFilter: [
        {
          key: 1,
          value: "English",
        },
        {
          key: 2,
          value: "Gujarati",
        },
        {
          key: 3,
          value: "Marathi",
        },
        {
          key: 4,
          value: "Tamil",
        },
        {
          key: 5,
          value: "Kannada",
        },
        {
          key: 6,
          value: "French",
        },
      ],

      InvigilationList: [
        {
          key: 1,
          value: "Desktop Invigilation",
        },
        {
          key: 2,
          value: "Web Invigilation",
        },
      ],

      InvigilationListFilter: [
        {
          key: 1,
          value: "Desktop Invigilation",
        },
        {
          key: 2,
          value: "Web Invigilation",
        },
      ],
      options: {
        plugins: {
          tooltip: {
            enabled: false,
          },
        },
      },
      admin: false,
      routesearch: this.$route.query.id
        ? window.atob(this.$route.query.id)
        : null,
      routeoffset: this.$route.query.offset
        ? window.atob(this.$route.query.offset)
        : null,
      routecurrent: this.$route.query.current
        ? window.atob(this.$route.query.current)
        : null,
        Remainingdays: 0,
    };
  },
  async created(){
    if(this.billing !=null){
      this.Remainingdays = calculateRemainingDays(this.billing.object.data.subscription.expiryDate);
    }
  },
  async mounted() {
    if (getUserPermissionData({
      parent: 'Partner Management',
      child: 'Partner Account', permission: 'Can_View_Partner'
    }) /* && this.isParent === 'true' */ ) {
      this.filterTab = 1
    } else {
      this.filterTab = 2  
    }
    if (this.$route.query.filter) {
      if (localStorage.getItem('ActiveTestfilters') != null) {
        this.filters = JSON.parse(localStorage.getItem('ActiveTestfilters'));
        this.applyfilter =
          this.filters.partners.length +
          this.filters.language.length +
          this.filters.invigilation.length;
      }
    }
    // else{
    //   this.clearFilters();
    // }



    if (this.routeoffset && this.routecurrent) {
      this.offset = parseInt(this.routeoffset);
      this.currentPage = parseInt(this.routecurrent);
    }
    if (this.routesearch) {
      this.input = this.routesearch;
      this.opensearch();
      // await this.ActiivetestList();
    }
    this.admin = superadmin();
    await this.ActiivetestList();

  
    var TeamList = await this.GetTeams({
      clientId: this.user.clientId,
    });
    {
      this.listing = TeamList.object.data.regions;
    }
    if(this.isParent=="true"){
      await this.PartnerList();
    }
  
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      clientId: (state) => state.auth.user.clientId,
    }),
  },

  methods: {
    getUserPermissionData,
    ...mapActions([
      "AllActiveTest",
      "getrole",
      "deleteroles",
      "Deploy",
      "Undeploy",
      "Private",
      "Public",
      "GetTeams",
      "Activate",
      "ShowSucessMessage",
      "Deactivate",
      "AllPartnerList",
      "InviteCandidates",
      "UserRegionData",
      "GetUrlforshare",
      "adminregionList",
    ]),
    ...mapMutations(["setLoading"]),
    superadmin,
    
 
    clickCallback: function (pageNum) {
        this.offset = (pageNum - 1) * this.limit + 1;
        this.ActiivetestList();
      },
    encode(item) {
      return window.btoa(item);
    },
    showgraphornot(complete, progress, scheduled) {
      var x = 0;
      x = complete + progress + scheduled;
      if (x > 0) {
        return true;
      } else {
        return false;
      }
    },
    setup(item) {
      if (item != null) {
        var testData = {
          labels: [],
          datasets: [
            {
              data: [item.completed, item.progress, item.notAttempted],
              backgroundColor: ["#F8B200", "#FEDE69", "#FFECA6"],
            },
          ],
        };
        return testData;
      } else {
        var Datatest = {
          labels: [],
          datasets: [
            {
              data: [100],
              backgroundColor: ["#FFAFD2"],
            },
          ],
        };
        return Datatest;
      }
    },
    invite(name, id, clientId) {
      this.testName = name;
      this.testId = id;
      this.testclientid = clientId;
      this.openInvite = true;
    },
    closeinvite(val) {
      this.openInvite = val;
    },
    showList(val, client) {
      if (!superadmin()) {
        if (val) {
          if (this.clientId != client) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    shareTest(Testclient) {
      var parent = localStorage.getItem("isParent");
      /*update*/
      if (!superadmin() && parent == "true" && this.clientId != Testclient) {
        return false;
      } else {
        return true;
      }

      // else {
      //   if (this.clientId != Testclient) {
      //     return false
      //   }
      //   else {
      //     return true;
      //   }
      // }
    },
    async copytoClipboard(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        this.SelectGroup = "";
        window.$("#SharelinkModel").modal("hide");
        return this.ShowSucessMessage("Link copied");
      } catch ($e) {
        // console.log($e);
      }
    },

    async ActiivetestList() {
      var model = {
        clientId: this.clientId,
        limit: this.limit,
        sortCol: "testid",
        offset: this.offset,
        sortdir: "desc",
      };
      if (this.filters.partners.length > 0) {
  model.partner = this.filters.partners.toString();
  //model.offset = (this.currentPage ) * this.limit;

}
if (this.filters.language.length > 0) {
  model.language = this.filters.language.toString();
  //model.offset = (this.currentPage ) * this.limit;
}
if (this.filters.invigilation.length > 0) {
  model.invigilation = this.filters.invigilation.toString();
  //model.offset = (this.currentPage ) * this.limit;
}

      if (this.input.trim() != "") {
        model.Search = this.input;
        this.searchDisable = true;
      }

      var list = await this.AllActiveTest(model);
      if (list.status) {
        this.searchDisable = false;
        this.List = list.object.data.testSummary;
        this.Page_count = list
          ? Math.ceil(list.object.data.filterCount / this.limit)
          : 0;
      }

    },

    async applyFilters() {
      this.currentPage = 1;
      this.offset = 1;
      localStorage.setItem("ActiveTestfilters", JSON.stringify(this.filters));
      // var ActiveTestfilters = localStorage.getItem("ActiveTestfilters");
      document.getElementById("dropdownMenuButton1").click();
      await this.ActiivetestList();
    },

    async clearFilters() {
      this.currentPage = 1;
      this.offset = 1;
      this.filters.language = [];
      this.filters.invigilation = [];
      this.filters.partners = [];
      this.applyfilter = 0;
      this.searchfilter = "";
      this.Filterresult();
      this.ActiivetestList();
      document.getElementById("dropdownMenuButton1").click();
      localStorage.removeItem('ActiveTestfilters')
    },

    async addFilter(value, e) {
      if (e.target.checked) {
        if (this.filterTab == 1) {
          this.filters.partners.push(value);
        }
        if (this.filterTab == 2) {
          this.filters.language.push(value);

        }

        if (this.filterTab == 3) {
          this.filters.invigilation.push(value);
        }
        this.applyfilter =
          this.filters.partners.length +
          this.filters.language.length +
          this.filters.invigilation.length;
      } else {
        if (this.filterTab == 1) {
          this.filters.partners.splice(
            Object.freeze(
              JSON.parse(JSON.stringify(this.filters.partners))
            ).indexOf(value),
            1
          );
        }
        if (this.filterTab == 2) {
          this.filters.language.splice(
            Object.freeze(
              JSON.parse(JSON.stringify(this.filters.language))
            ).indexOf(value),
            1
          );
        }

        if (this.filterTab == 3) {
          this.filters.invigilation.splice(
            Object.freeze(
              JSON.parse(JSON.stringify(this.filters.invigilation))
            ).indexOf(value),
            1
          );
        }
        if (this.applyfilter == 1) {
          this.applyFilters();
        }
        this.applyfilter =
          this.filters.partners.length +
          this.filters.language.length +
          this.filters.invigilation.length;
      }
    },

    Filterresult() {
      if (this.searchfilter != "") {
        if (this.filterTab == 1) {
          this.partners = this.partnersFilter.filter((element) =>
            element.company
              .toLowerCase()
              .includes(this.searchfilter.toLowerCase())
          );
        }

        if (this.filterTab == 2) {
          this.LanguageList = this.LanguageListFilter.filter((element) =>
            element.value
              .toLowerCase()
              .includes(this.searchfilter.toLowerCase())
          );
        }
        if (this.filterTab == 3) {
          this.InvigilationList = this.InvigilationListFilter.filter(
            (element) =>
              element.value
                .toLowerCase()
                .includes(this.searchfilter.toLowerCase())
          );
        }
      } else {
        this.partners = this.partnersFilter;
        this.LanguageList = this.LanguageListFilter;
        this.InvigilationList = this.InvigilationListFilter;
      }
    },

    async Deployundeploy(val, type) {
      if (type == "deploy") {
        await this.Deploy({
          testId: val,
        });
        await this.ActiivetestList();
      }
      if (type == "undeploy") {
        await this.Undeploy({
          testId: val,
        });
        await this.ActiivetestList();
      }
    },
    async privatePublic(val, type) {
      if (type == "public") {
        await this.Public({
          testId: val,
        });
        await this.ActiivetestList();
      }
      if (type == "private") {
        await this.Private({
          testId: val,
        });
        await this.ActiivetestList();
      }
    },

    async ActivateDeactivate(val, type) {
      if (type == "active") {
        await this.Activate({
          testId: val,
        });
        await this.ActiivetestList();
      }
      if (type == "deactivate") {
        await this.Deactivate({
          testId: val,
        });
        await this.ActiivetestList();
      }
    },

    filterclose() {
      document.getElementById("dropdownMenuButton1").click();
    },

    async PartnerList() {
      var partners = await this.AllPartnerList({
        clientId: this.clientId,
        offset: 0,
        limit: -1,
        Search: "",
      });
      this.partners = partners?.object?.data?.partnerData;
      this.partnersFilter = partners?.object?.data?.partnerData;
    },

    async resetShareLink() {
      // this.SelectGroup = [];
      this.SelectGroup = "";
    },

    async searchregionname(val) {
      this.input = val.trim();
      this.offset = 0;
      this.Search = val;
      this.result = [];
      this.currentPage = 1;
      await this.ActiivetestList();
    },
    stringTOHtml(item, idx) {
      setTimeout(() => {
        if (this.input != "") {
          let re = new RegExp(this.input.toLowerCase(), "g");
          var html = document.getElementById(idx);
          var ex = item
            .toLowerCase()
            .replace(
              re,
              `<span style="color:#7b68ee !important">${this.input}</span>`
            );
          if (html) html.innerHTML = ex;
        }
      }, 100);
    },
    // Searching box
    opensearch() {
      document.querySelector(".Searching_input-box").classList.add("open");
    },
    async closesearch() {
      document.querySelector(".Searching_input-box").classList.remove("open");
      this.result = [];
      this.Search = "";
      if(this.input !== "") {
        this.input = "";
        await this.ActiivetestList();
      }
    },
    async closemobilesearch() {
      this.show = false;
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.remove("open");
      this.result = [];
      this.Search = "";
      if(this.input !== "") {
        this.input = "";
        await this.ActiivetestList();
      }
    },

    openmobilesearch() {
      this.show = true;
      this.result = [];
      this.input = "";
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.add("open");
    },
    async filteredList() {
      if (this.input != "") {
        this.searchActivationList = [];
        var roles = await this.AllActiveTest({
          Search: this.input,
          clientId: this.clientId,
          limit: 100,
          sortCol: "testid",
          offset: 0,
          sortdir: "desc",
        });
        var x = roles.object.data.testSummary;
        x.forEach((element) => {
          if (this.searchActivationList.indexOf(element.testName) < 0) {
            this.searchActivationList.push(element.testName);
          }
        });
        this.result = this.searchActivationList.filter((element) =>
          element.toLowerCase().startsWith(this.input.toLowerCase())
        );
        this.result = Object.freeze(JSON.parse(JSON.stringify(this.result)));
      } else {
        this.result = [];
        this.ActiivetestList();
      }
    },

    async GetAllgroups(id) {
      this.groupshowList = []; //update
      var result;
      if (this.clientId == 1001) {
        result = await this.adminregionList({
          userId: id,
        });
        var res = result.object.data.regions;
        res.forEach((element) => {
          if (element.isActive) {
            this.groupshowList.push(element);
          }
        });
      } else {
        result = await this.UserRegionData({
          userId: this.user.userId,
        });
        var rs = result.object.data;
        rs.forEach((element) => {
          if (element.isActive) {
            this.groupshowList.push(element);
          }
        });
      }
    },

    async onChange() {
      if (this.SelectGroup != "") {
        var result = await this.GetUrlforshare({
          testId: this.testIdforurl,
          regionId: this.SelectGroup,
        });
        if (result.status) {
          this.SharedUrl = result.object.data.token;
        } else {
          window.$("#SharelinkModel").modal("hide");
          this.SelectGroup = "";
        }
      }
    },
    onClose() {},
    onOpen() {
      this.SelectGroup = "";
    },
    onBlock() {},
    onFocus() {},
  },
};
</script>

<style>
@import "../../node_modules/share-button-links/main.css";

.social-icomn-active {
  color: #64686f !important;
  border-color: #ded8ff !important;
  background: #ded8ff !important;
}

.active_form_button2 {
  background-color: #fff !important;
  color: #7b68ee !important;
}

.text-active-kj {
  font-size: 12px;
  font-weight: 600;
  color: #292e34;
}

.circle-icon {
  font-size: 9px;
  color: #f8b200;
}

.circle-icon-progress {
  font-size: 9px;
  color: #fede69;
}

.circle-icon-Pending {
  font-size: 9px;
  color: #ffeca68a;
}

.bg-danger-kj {
  background-color: #ff549e !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  padding-left: 10px;
  white-space: nowrap;
  background-color: #6571ff;
  transition: width 0.6s ease;
}

.bg-success-kj {
  background-color: #5e45eb !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  padding-left: 10px;
  white-space: nowrap;
  background-color: #6571ff;
  transition: width 0.6s ease;
}

.progress-label-vales {
  color: #666666;
  font-size: 10px;
  font-weight: 600;
  padding-left: 13px;
}

.dropdown-menu-kj-w {
  min-width: 290px !important;
}

.w-60 {
  width: 60%;
}

.page-item.disabled .page-link {
  background-color: #fff !important;
  border-color: #fff !important;
}

.pagination .page-item .page-link {
  width: 30px;
  height: 32px;
  background: transparent;
  display: flex;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  margin: 5px;
  color: #000 !important;
  border: none;
  cursor: pointer;
}

.pagination {
  justify-content: flex-end;
}

.pagination .page-item.active .page-link {
  font-weight: 600;
  background: #ffc801;
}

.pagination .page-item:last-child .page-link {
  background-color: transparent;
  border: none;
  width: 100% !important;
}

.pagination .page-item:first-child .page-link {
  background-color: transparent;
  border: none;
  width: 100% !important;
}

.pagination .page-item.disabled .page-link {
  color: rgb(130, 130, 130) !important;
  cursor: no-drop !important;
  background: #7fffd400 !important;
}

@media (max-width: 480px) {
  .active_test_cart_number {
    font-size: 7px;
  }
}

.font-size-12 {
  font-size: 12px !important;
}

/* Added by Pmaps Team */

.i-icon-color {
  color: black;
}

/* Added by Pmaps Team */
</style>
