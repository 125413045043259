import axios from "axios";
import {
  getAuthUserData,
  setAuthUserData,
  // getAuthToken,
  //removeAuthUserData,
} from "../utils/auth";
import router from "../router";
import Cookies from "js-cookie"; //uncomment when push code to client repo

const api = axios.create({
  baseURL: window.api_base_url,
  withCredentials: true, // send cookies when cross-domain requests
});
const reportApi = axios.create({
  baseURL: window.api_base_url,
  withCredentials: false, // send cookies when cross-domain requests
});

let requestVar = "";
api.interceptors.request.use((request) => {
  const userAuth = getAuthUserData();
  if (navigator.onLine) {
    if (userAuth != null) {
      if (request.url != `v1/accounts/${userAuth.userId}/refresh-token`) {
        requestVar = request;
      }
    }
    if (userAuth && userAuth.token) {
      request.headers.Authorization = `Bearer ${userAuth.token}`;
    }

    return request;
  } else {
    router.push("internet");
  }
});

api.interceptors.response.use(
  function (response) {
    // Any status code that lie within responsethe range of 2xx cause this function to trigger
    return response;
  },
  async function (error) {
    const userAuth = getAuthUserData();
    if (error.response.status == 401) {
      let token = Cookies.get('refreshToken');
      if (token == null || token == undefined) {
        localStorage.clear();
        window.location.replace("/login");
        return;
      } else //uncomment when push code to client repo
      if (userAuth != null) {
        if (error.response.headers["x-token-expired"]) {
          var resp = await api.get(
            `v1/accounts/${userAuth.userId}/refresh-token`
          );
          if(!resp.status) {
            localStorage.clear();
            window.location.replace('/login');
            return;
          }
          userAuth.token = resp.data.object.data.jwtToken;
          setAuthUserData(userAuth);
          requestVar.headers.Authorization = `Bearer ${userAuth.token}`;
          router.go();
          return Promise.reject(error);
        } else {
          return Promise.reject(error.response);
        }
      } else {
        return Promise.reject(error.response);
      }
    }else {
      return Promise.reject(error.response);
    }
    
  }
);
//----------------------Auth------------------------------//
export const login = async (userInfo) =>
  await api
    .post("v1/accounts/login", userInfo)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const platformFeatures = async(data) => await api
  .get(`v1/features/Plans/${data.PlanCode}`)  
  .then((response)=>{
  return response;
  })
  .catch((error)=>{
    return error;
  });

export const signUp = async (userInfo) =>
  await api
    .post("auth/signUp", userInfo)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const getrole = async (getroleInfo) =>
  await api
    .get(`v1/roles/${getroleInfo.roleid}/clients/${getroleInfo.clientId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
export const forgetPassword = async (email) =>
  await api
    .post("v1/accounts/forgot-password", email)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const accountProfileImage = async (data) =>
  await api
    .patch(`v1/accounts/profile/company/${data.params.clientId}`, data.body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const updateProfilePic = async (data) =>
  await api
    .patch(`v1/accounts/${data.userId}/profile-pic`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

export const activateregion = async (data) =>
  await api
    .patch(`v1/regions/${data.regionId}/activate`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const deactivateregion = async (data) =>
  await api
    .patch(`v1/regions/${data.regionId}/deactivate`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

export const RolePermissionUpdate = async (data) =>
  await api
    .put(`v1/roles/${data.roleId}/clients/${data.clientId}`, data.body)
    .then((response) => {
      /*eslint-disable*/

      return response;
    })
    .catch((error) => {
      return error;
    });

export const rejectReq = async (info) =>
  await api
    .patch(
      `v1/tokens/clients/${info.clientId}/requests/${info.requestId}?status=${info.status}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const verifyToken = async (token) =>
  await api
    .post("v1/users/verify-password-token", token)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const SetPassword = async (data) =>
  await api
    .post(`v1/accounts/${data.userId}/password`, data.password)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const SetRestLink = async (data) =>
  await api
    .post(`v1/accounts/set-password-link`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const resetPassword = async (data) =>
  await api
    .patch(`v1/accounts/${data.userId}/reset-password`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const GetTokenAllocation = async (data) =>
  await api
    .get(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const logout = async (data) =>
  await api
    .post(`v1/accounts/${data.userId}/logout`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const SendotpCode = async (data) =>
  await api
    .post("v1/accounts/" + data.userId + "/2FA", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const tnc = async (userId) =>
  await api
    .patch(`v1/accounts/${userId}/tnc`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

// export const GetotpCode = async (data) =>
//   await api
//     .get(`v1/accounts/${data.userId}/2FA?Type=Email&Token=${data.token}`, data)
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       return error;
//     });

export const GetotpCode = async (data) =>
  await api
    .post(`v1/accounts/${data.params.userId}/2FA/verify`, JSON.parse(data.body))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const Accesstoken = async (data) =>
  await api
    .get(`v1/accounts/${data.userId}/access-token`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const Refreshtoken = async (data) =>
  await api
    .get(`v1/accounts/${data.userId}/refresh-token`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const GetUserInfo = async (data) =>
  await api
    .get(`v1/accounts/${data.userId}/profile`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const AllActiveTest = async (data) =>
  await api
    .get(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const AllDeactiveTest = async (data) =>
  await api
    .get(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const GetUserStatus = async (data) =>
  await api
    .get(`v1/accounts/${data.userId}/2FA/status`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
export const GetPartnerList = async (data) =>
  await api
    // .get(`v1/clients/${data.clientId}/partners`, data)
    .get(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const GetAllPartners = async (data) =>
  await api
    .get(`v1/clients/partners/${data.clientId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const PartnerTeamMapping = async (data) =>
  await api
    // .get(`v1/clients/${data.clientId}/partners`, data)
    .get(
      `v1/regions/${data.regionId}/clients/${data.clientId}/partner-team-mapping`,
      data
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const deleteroles = async (data) =>
  await api
    .delete(`v1/roles/${data.roleId}/clients/${data.clientId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const removeuser = async (data) =>
  await api
    .delete(`v1/region-users/${data.userId}/regions/${data.regionId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const DeleteUserById = async (data) =>
  await api
    .delete(`v1/users/${data.userId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const deleteregionmember = async (data) =>
  await api
    .delete(`v1/region-users/${data.userId}/regions/${data.regionId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const requestedTokens = async (data) =>
  await api
    .get(`v1/tokens/clients/${data.clientId}/requests`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const regionRequest = async (data) =>
  await api
    .get(`v1/tokens/regions/${data.regionId}/requests`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const testRequest = async (data) =>
  await api
    .get(`v1/tokens/tests/${data.testId}/requests`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const rejectRequestTest = async (data) =>
  await api
    .patch(
      `v1/tokens/tests/${data.testId}/requests/${data.requestId}?status=${data.status}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const clientlogo = async (data) =>
  await api
    .post(`v1/aws/s3/signed-url`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const TeamsFetche = async (data) =>
  await api
    .get(`v1/regions/clients/${data.clientId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const getGroupsWithPagination = async (data) =>
  await api
    .get(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const Getpartnerteammembers = async (data) =>
  await api
    .get(
      `v1/clients/${data.clientId}/partners/${data.PartnerId}/region-members`,
      data
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const Getteammembers = async (data) =>
  await api
    .get(`v1/regions/clients/${data.clientId}/all-teams?mode=teams-list`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
export const UserRegionData = async (data) =>
  await api
    .get(`v1/regions/users/${data.userId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
export const GetUrlforshare = async (data) =>
  await api
    .get(`v1/tests/${data.testId}/regions/${data.regionId}/token`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const GetTAllocation = async (data) =>
  await api
    .get(`v1/tokens/clients/${data.clientId}/allocation`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
export const UserEditData = async (data) =>
  await api
    .get(`v1/users/${data.userId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const Getregionmembers = async (data) =>
  await api
    .get(`v1/region-members/regions/${data.regionId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const getregionlocation = async (data) =>
  await api
    .get(`v1/region-locations/clients/${data.clientId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const activatepartner = async (data) =>
  await api
    // .patch(`v1/partners/${data.params.PartnerId}/activate`, data)
    .patch(`v1/clients/${data.params.clientId}/activate`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const updatepartner = async (data) =>
  await api
    .patch(`v1/clients/${data.params.clientId}`, data.body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const MultipleUserDeactivate = async (data) =>
  await api
    .patch(`v1/users/deactivate`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const getpartner = async (data) =>
  await api
    .get(`v1/clients/${data.clientId}`, data.body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
// export const TemplateDownload = async (data) =>

//   await api
//     .get(`v1/users/clients/${data.clientId}/template`,data.headers,data.responseType)
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       return error;
//     });

export const TemplateDownload = async (data) =>
  await api
    .get(`v1/users/clients/${data.clientId}/template`, {
      responseType: "arraybuffer",
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const DownloadGroupTemplate = async (data) =>
  await api
    .get(`v1/regions/clients/${data.clientId}/template`, {
      responseType: "arraybuffer",
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const bulkUploadGroups = async (data) =>
  await api
    .post(`v1/regions/clients/${data.clientId}/upload`, data.formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const deactivatepartner = async (data) =>
  await api
    // .patch(`v1/partners/${data.params.PartnerId}/deactivate`, data)
    .patch(`v1/clients/${data.params.clientId}/deactivate`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
export const DeactivateUser = async (data) =>
  await api

    .patch(`v1/users/${data.userId}/deactivate`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
export const ReactivateUser = async (data) =>
  await api

    .patch(`v1/users/${data.userId}/activate`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const UpdateUserInfo = async (data) =>
  await api
    .put(`v1/accounts/${data.params.userId}/profile`, JSON.parse(data.body))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
export const UpdateUserData = async (data) =>
  await api
    .put(`v1/users/${data.userId}`, data.body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const TwoFactorAuth = async (data) =>
  await api
    .patch(`v1/accounts/${data.userId}/settings/2FA/${data.operation}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const ChangeUserPassword = async (data) =>
  await api
    .put(`v1/accounts/${data.params.userId}/password`, JSON.parse(data.body))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const VerifyPassToken = async (data) =>
  await api
    .post("v1/accounts/verify-password-token", JSON.parse(data.body))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const GetAllUsers = async (data) =>
  await api
    .get(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const Getuserlist = async (data) =>
  await api
    .get(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
export const Getusersearchlist = async (data) =>
  await api
    .get(`v1/users/clients/${data.clientId}?status=4&search=${data.search}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
export const GetAllPermission = async (data) => {
  return await api.get(`v1/permissions`, data);
};

export const GetAllRoles = async (data) =>
  await api
    .get(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const CreatePartner = async (data) =>
  await api
    // .post(`v1/clients/${data.params.clientId}/partners`, JSON.parse(data.body))
    .post(`v1/clients/${data.params.clientId}`, JSON.parse(data.body))
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const Getpartners = async (data) =>
  await api
    .get(`v1/parent-child-regions/clients/${data.clientId}`, data)
    // .get(`v1/clients/${data.clientId}/partner-regions`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const CreateClientRole = async (data) =>
  await api
    .post(`v1/roles/clients/${data.clientId}`, data.body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
export const BulkUseruploading = async (data) =>
  await api
    .post(`v1/users/clients/${data.clientId}/upload`, data.formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const GetRole = async (data) =>
  await api
    .get(`v1/clients/${data.clientId}/roles/${data.roleid}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const UpdateRole = async (data) =>
  await api
    .put(
      `v1/clients/${data.params.clientId}/roles/${data.params.roleid}`,
      JSON.parse(data.body)
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const GetAllTeams = async (data) =>
  await api
    .get(`v1/clients/${data.clientId}/all-teams`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const DeleteUser = async (data) =>
  await api
    .patch(`v1/users/${data.userId}/delete`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const DeleteGroup = async (data) =>
  await api
    .delete(`v1/regions/${data.regionId}/clients/${data.clientId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const DeletePartner = async (data) =>
  await api
    .delete(`v1/regions/parent-child-regions/${data.MappingId}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const CreateUser = async (data) =>
  await api
    .post(`v1/users/clients/${data.clientId}`, data.model)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const AssignTeams = async (data) =>
  await api
    .post(
      `v1/regions/clients/${data.clientId}/region/${data.regionId}/assign-child-teams`,
      data
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const adduseringroup = async (data) =>
  await api
    .post(`v1/region-users/${data.userId}/regions/${data.regionId}/attach`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const inviteUser = async (data) =>
  await api.post(
    `v1/users/clients/${data.clientId}/user-invite/${data.userId}`,
    {}
  );

export const verifyUser = async (data) =>
  await api
    .patch("v1/accounts/verify", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
// .then((response) => response)
// .catch((error) => error);

// Regions
export const getRegionByRegionId = async (data) =>
  await api
    .get(`v1/regions/${data.regionId}`)
    .then((response) => response)
    .catch((error) => error);

// Management Partners
// export const getAssignedTeams = async (data) =>
//   await api
//     .get(`v1/clients/${data.clientId}/regions/${data.clientRegionId}`)
//     .then((response) => response)
//     .catch((error) => error);

// Management Teams
export const getClientTeams = async (data) =>
  await api
    .get(`v1/clients/${data.clientId}/region-members`)
    .then((response) => response)
    .catch((error) => error);

export const getRegionCategories = async (data) =>
  await api
    // .get(`v1/region-categories`)
    .get(`v1/region-categories/clients/${data.clientId}`)
    .then((response) => response)
    .catch((error) => error);

export const createTeam = async (data) =>
  await api
    .post(`v1/regions`, data)
    .then((response) => response)
    .catch((error) => error);

export const updateTeam = async (data) =>
  await api
    .put(`v1/regions/${data.regionId}`, {
      name: data.name,
      regionCategoryId: data.regionCategoryId,
      country_code: data.country_code,
    })
    .then((response) => response)
    .catch((error) => error);

export const assignTeam = async (data) =>
  await api
    .post(`v1/regions/assign-users-and-teams`, data)
    .then((response) => response)
    .catch((error) => error);

export const GetAssignedGroupsList = async (data) =>
  await api
    .get(
      `v1/regions/clients/${data.clientId}/partner-teams?iOffSet=${data.offset}&iLimit=${data.limit}`,data
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const GetloggedinPermissions = async (userId) =>
  await api
    .get(`v1/users/${userId}/permissions`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const AssignGroup = async (data) =>
  await api
    .post(`v1/region-users/multiple`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const viewtestList = async (data) =>
  await api
    .get(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const CallFailedFunction = async (payload) => {
  try {
    if (payload.method == "get") {
      const { data } = await api.get(payload.url);
      if (data.status) {
        return data;
      } else {
        return null;
      }
    } else if (payload.method == "post") {
      const { data } = await api.post(payload.url, JSON.parse(payload.data));
      if (data.status) {
        return data;
      } else {
        return null;
      }
    } else if (payload.method == "put") {
      // console.log(JSON.parse(payload.data));
      const { data } = await api.put(payload.url, JSON.parse(payload.data));
      if (data.status) {
        return data;
      } else {
        return null;
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const GetAllocateDataClientPartner = async (data) =>
  await api
    .get(
      `v1/tokens/clients/${data.clientId}/partners/allocation?Page=${data.Page}&Limit=${data.Limit}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const GetAllocatedAmount = async (data) =>
  await api
    .post(`v1/tokens/clients/${data.clientId}/allocate/${data.amount}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const amountAllocate = async (data) =>
  await api
    .post(`v1/tokens/tests/${data.testId}/allocate/${data.amount}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const allocateToken = async (data) =>
  await api
    .post(`v1/tokens/tests/${data.testId}/allocate/${data.amount}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const RequestToken = async (data) =>
  await api
    .post(`v1/tokens/tests/${data.testId}/request-token/${data.amount}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const IncrOrDecTokens = async (data) =>
  await api
    .post(
      `v1/tokens/clients/${data.clientId}/request-token/${data.amount}`,
      data
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const viewGroupsList = async (data) =>
  await api
    .get(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const regionAllocated = async (data) =>
  await api
    .get(`v1/tokens/regions/${data.regionsId}/allocation`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const regionAllocatedToken = async (data) =>
  await api
    .post(`v1/tokens/regions/${data.regionId}/allocate/${data.amount}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const regionRequestedToken = async (data) =>
  await api
    .post(
      `v1/tokens/regions/${data.regionId}/request-token/${data.amount}`,
      data
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const regionRejectRequest = async (data) =>
  await api
    .patch(
      `v1/tokens/regions/${data.regionId}/requests/${data.requestId}?status=${data.status}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

//Milestone-4-API

export const activetestdetail = async (data) =>
  await api
    .get(`v1/tests/${data.testId}/test-details`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
    // to be used in future
    // export const GetTestWeightage = async (data) =>
    // await api
    //   .get(`v1/test-sections/testWeightage/testId/${data.testId}`)
    //   .then((response) => {
    //     return response;
    //   })
    //   .catch((error) => {
    //     return error;
    //   });

//  export const GetCandidateAdditionalDetails = async (data) =>
//     await api
//       .get(`v1/test-sections/candidate-additional-details/test-Id/${data.testId}`)
//       .then((response) => {
//         return response;
//       })
//       .catch((error) => {
//         return error;
//       });

  // export const GetLanguageWeightageDetails = async (data) =>
  // await api
  //   .get(`v1/test-sections/language-weightage/test-id/${data.testId}`)
  //   .then((response) => {
  //     return response;
  //   })
  //   .catch((error) => {
  //     return error;
  //   });
      
      export const GetCandidateDetails = async (data) =>
      await api
        .get(`v1/candidates/details?iSearch=${data.search}&iOffSet=${data.offset}&iLimit=${data.limit}`)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
      
        export const updateCandidateDetails = async (data) =>
        await api
          .post(`v1/candidates/${data.candidateId}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return error;
          });
        
export const GetTestDuration = async(data) => await api
.get(`v1/test-duration/tests/${data.testId}`)  
.then((response)=>{
return response;
})
.catch((error)=>{
  return error;
});

export const CreateTestScheduler = async(data) => await api
.post(`v1/test-setting/tests/${data.testId}`, data)  
.then((response)=>{
return response;
})
.catch((error)=>{
  return error;
});

export const UpdateTestDuration = async(data) => await api
.put(`v1/test-setting/tests/${data.testId}/?`,data)  
.then((response)=>{
return response;
})
.catch((error)=>{
  return error;
});


export const GetCoolingPeriod = async(data) => await api
.get(`v1/cooling-period/get/${data.testId}`)  
.then((response)=>{
return response;
})
.catch((error)=>{
  return error;
});

export const UpdateCoolingPeriod = async(data) => await api
.put(`v1/cooling-period/settings/update?ClientId=${data.clientId}&TestId=${data.testId}`,data)  
.then((response)=>{
return response;
})
.catch((error)=>{
  return error;
});


//.post(`v1/cooling-period/settings?CoolingPeriodType=${data.CoolingPeriodType}&ClientId=${data.clientId}&TestId=${data.testId}`, data)  
export const GetCoolingPeriodSetting = async(data) => await api
.get(`v1/cooling-period/settings/clients?ClientId=${data.clientId}&TestId=${data.testId}`)  
.then((response)=>{
return response;
})
.catch((error)=>{
  return error;
});

export const GetCoolingPeriodDuration = async(data) => await api
.get(`v1/cooling-period/duration/clients/${data.clientId}/all`)  
.then((response)=>{
return response;
})
.catch((error)=>{
  return error;
});


export const CreateCoolingPeriodSetting = async(data) => await api
.post(`v1/cooling-period/settings?CoolingPeriodType=${data.CoolingPeriodType}&ClientId=${data.clientId}&TestId=${data.testId}`, data)  
.then((response)=>{
return response;
})
.catch((error)=>{
  return error;
});

export const activetestsection = async (data) =>
  await api
    .get(`v1/test-sections/tests/${data.testId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const activetestgeneralsetting = async (data) =>
  await api
    .get(`v1/tests/${data.testId}/general-settings`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const activetestproctorsettings = async (data) =>
  await api
    .get(`v1/tests/${data.testId}/proctor-settings`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const invitefromemail = async (data) =>
  await api
    .get(`v1/users/${data.userId}/from-email`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const emailverifystatus = async (data) =>
  await api
    .get(
      `v1/aws/ses/${data.sesRegion}/verification-status?email=${data.value} `
    )

    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const saveemailtemplates = async (data) =>
  await api
    .put(`v1/email-templates/tests/${data.testId}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const selectemailtemplates = async () =>
  await api
    .get(`v1/email-templates/candidates`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
export const sendselecttemplates = async (data) =>
  await api
    .patch(`v1/email-templates/${data.etId}/tests/${data.testId}/select`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const emailverification = async (data) =>
  await api
    .post(
      `v1/aws/ses/${data.sesRegion}/clients/${data.clientId}/verify-email`,
      data
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const viewCandinate = async (data) =>
  await api
    .get(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const viewCandidateV2 = async (data) =>
  await api
    .get(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

//PMAPS Change
export const deleteCandidate = async (data) =>
  await api
    .delete(`v1/candidates/${data}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
//PMAPS Change End
export const previewtemplate = async (data) =>
  await api
    .get(`v1/email-templates/tests/${data.testId}/preview`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
export const getInviteCandidate = async (data) =>
  await api
    .get(`v1/email-templates/tests/${data.testId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
export const resendInvite = async (data) =>
  await api
    .post(`v1/candidates/${data.testId}/resent`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
export const resendInviteEmailCandidate = async (data) =>
  await api
    .post(`v1/candidates/tests/${data.testId}/send-mail`, {
      senderEmail: data.senderEmail,
      candidates: data.candidates,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
//Pmaps team
export const getLastCandidateInviteMode = async (data) =>
  await api
    .get(`v1/email-logs/candidates/${data.candidateId}/last-invite-mode`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
//Pmaps team
export const downloadsheet = async (data) =>
  await api
    .get(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
/*update*/
export const downloadMedusindBulkCandidates = async (data) =>
  await api
    .get(
      `v1/tests/${data.testId}/medusind/bulk-download?FromDate=${data.fromDate}&ToDate=${data.toDate}&UserId=${data.userId}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
/*update*/
export const getviewReport = async (data) =>
  await api
    .get(`/v1/tests/${data.testId}/candidate-details/reports`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

export const viewReport = async (data) =>
  await api
    .get(
      `v1/candidates/tests/${data.testId}/reports?Token=${data.tokenId}&ReportType=${data.type}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

export const viewReportcalendar = async (data) =>
  await api
    .get(
      `v1/candidates/tests/${data.testId}/attempts?candidateEmail=${data.candidateEmail}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const Deploy = async (data) =>
  await api
    .patch(`v1/test-masters/tests/${data.testId}/deploy`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

export const Undeploy = async (data) =>
  await api
    .patch(`v1/test-masters/tests/${data.testId}/undeploy`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

export const Private = async (data) =>
  await api
    .patch(`v1/test-masters/tests/${data.testId}/private`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

export const Public = async (data) =>
  await api
    .patch(`v1/test-masters/tests/${data.testId}/public`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

export const Deactivate = async (data) =>
  await api
    .patch(`v1/test-masters/tests/${data.testId}/deactivate`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
export const Activate = async (data) =>
  await api
    .patch(`v1/test-masters/tests/${data.testId}/activate`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

export const getregionlocationnewData = async (data) =>
  await api
    .get(`v1/region-locations/clients/${data.clientId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const getlocation = async () =>
  await api
    .get(`v1/locations/countries`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

    export const getTimeZone = async (data) =>
    await api
    .get(`v1/locations/timezones/${data.countryCode}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });


export const CreateCandidates = async (data) =>
  await api
    .post(`v1/candidates/${data.testId}/regions/${data.regionId}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

export const getRegionCategoriesNewData = async (data) =>
  await api
    .get(`v1/region-categories/clients/${data.clientId} `)
    .then((response) => response)
    .catch((error) => error);

export const regioncategory = async () =>
  await api
    .get(`v1/region-categories`)
    .then((response) => response)
    .catch((error) => error);

export const UserAccounts = async () =>
  await api
    .get(`v1/accounts`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

export const SwitchAccount = async (data) =>
  await api
    .post(`v1/accounts/${data.userId}/switch`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

export const SetPrimaryAccount = async (data) =>
  await api
    .patch(`v1/accounts/set-primary`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

export const Companyheader = async (data) =>
  await api
    .get(`v1/clients/${data.clientId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const adminregionList = async (data) =>
  await api
    .get(`v1/regions/clients/${data.userId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const Dashover = async (data) =>
  await api
    .get(
      `v1/dashboard/clients/${data.clientId}/overview-stats?mode=${data.mode}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

export const Graph = async (data) =>
  await api
    // .get(`v2/dashboard/clients/${data.clientId}/overview-test-activity`)
    .get(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
export const Dashallocation = async (data) =>
  await api
    .get(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
export const Dashscheduled = async (data) =>
  await api
    .get(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const regionfilter = async (data) =>
  await api
    .get(
      `v1/regions/clients/${data.clientId}?offSet=${data.offset}&limit=${data.limit}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

export const profilePic = async (data) =>
  await api
    .get(`v1/accounts/${data.userId}/profile-pic`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

export const piechartPartner = async (data) =>
  await api
    .get(`v1/clients/partners/${data.clientId}?iOffSet=1&iLimit=-1`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const partnerLogo = async (data) =>
  await api
    .post(`v1/aws/s3/partnerImage`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

//PMAPS Change
export const getAllNotifications = async (data) =>
  await api
    .get(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const SendSmsInvitation = async (data) =>
  await api
    .post(`v1/candidates/tests/${data.testId}/send-sms`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
//PMAPS Change End

//***********************************************Milestone 7 APIS START ************************************************************
export const getReportData = async (testId) =>
  await reportApi
    .get(`v1/reports/tests/${testId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const getBasicInfo = async (candidateId) =>
  await reportApi
    .get(`v1/reports/candidates/${candidateId}/basic-info`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
export const getTestResultSummary = async (candidateId) =>
  await reportApi
    .get(`v1/reports/candidates/${candidateId}/test-result-summary`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const getRecommendations = async (candidateId, type) =>
  await reportApi
    .get(`v1/reports/candidates/${candidateId}/recommendations?type=${type}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const HardwareHistory = async (id) =>
  await reportApi
    .get(`v1/reports/proctor/candidates/${id}/hardware-history`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const ImageandVideos = async (payload) =>
  await reportApi
    .get(
      `v1/reports/proctor/candidates/${payload.id}/media?type=${payload.type}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
export const ImageandVideosPdf = async (payload) =>
  await reportApi
    .get(
      `v1/reports/proctor/candidates/${payload.id}/media?type=${payload.type}&mode=pdf`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const ProctoredLogsModel = async (id) =>
  await reportApi
    .get(`v1/reports/proctor/candidates/${id}/logs`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const GetsAllProctor = async (id) =>
  await reportApi
    .get(`v1/reports/proctor/candidates/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
export const getLanguageSummary = async (candidateId) =>
  await reportApi
    .get(`v1/reports/language/candidates/${candidateId}/summaries`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const getLanguageReport = async (candidateId) =>
  await reportApi
    .get(`v1/reports/language/candidates/${candidateId}/reports`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const cognitiveReport = async (candidateId) =>
  await reportApi
    .get(`v1/reports/cognitive/candidates/${candidateId}/reports`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const responseHeader = async (payload) =>
  await reportApi
    .get(`v1/test-sections/tests/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

// export const responseContent = async (payload) => {
//   await reportApi
//     .get(
//       `v1/reports/answers/test-sections/${payload.testSectionId}/candidates/${payload.candidateId}`
//     )
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       return error;
//     });
// };
export const languageHeader = async (testId) =>
  await reportApi
    .get(`v1/reports/language/tests/${testId}/tabs`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const getBehaviouralData = async (candidateId) =>
  await reportApi
    .get(`v1/reports/behavioural/candidates/${candidateId}/reports`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const GetGenuineModel = async (candidateId) =>
  await reportApi
    .get(
      `v1/reports/behavioural/candidates/${candidateId}/response-genuineness`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const GetAttemptData = async (candidateId) =>
  await reportApi
    .get(`v1/reports/candidates/${candidateId}/attempt-log`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const GetScoreRankData = async (candidateId) =>
  await reportApi
    .get(`v1/reports/candidates/${candidateId}/score-and-rank`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const GetCandidateAns = async (candidateId) =>
  await reportApi
    .get(
      // `v1/reports/answers/test-sections/${payload.testSectionId}/candidates/${payload.candidateId}`
      `v1/reports/answers/candidates/${candidateId}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
//Pmaps team
export const GetIsSmsInvitePreference = async (data) =>
  await api
    .get(`v1/client-preferences/clients/${data.clientId}?name=NewSmsInvite&value=True&isActive=true`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
//Pmaps team

export const getTyping = async (candidateId) =>
  await reportApi
    .get(`v1/reports/skills/typing/candidates/${candidateId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

export const getCoding = async (candidateId) =>
  await reportApi
    .get(`v1/reports/skills/coding/candidates/${candidateId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });

//***********************************************Milestone 7 APIS END **************************************************************

export const getRMWithPagination = async (data) =>
  await api
    .get(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
  });

export const CreateMapClientsWithUsers = async(data) => 
  await api
    .post(`v1/clientRM`,data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
  });

  export const GetMapClientsWithUsers = async(data) => 
  await api
    .get(`v1/clientRM/Clients/${data.clientId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
  });

export const UpdateMappedClientsWithUsers = async(data) =>
  await api
  .patch(`v1/clientRM/clients/${data.clientId}`, data)
  .then((response) => {
    return response;
  })
  .catch((error) => {
    return error;
});


export const billingSignup = async(data) =>
  await api
  .post(`v1/clients/v2/signup`, data)
  .then((response) => {
    return response;
  })
  .catch((error) => {
    return error;
});

export const GetPlanCardDetails = async(data) => 
await api
.get(`v1/features/plans`,data)
.then((response) => {
  return response;
})
.catch((error) => {
  return error;
});

export const GetInvoiceData = async(data) =>
  await api
  .get(`v1/invoice/customer/${data.customerId}?startDate=${data.startDate}&EndDate=${data.EndDate}`, data)
  .then((response) => {
    return response;
  })
  .catch((error) => {
    return error;
});


export const DownloadInvoice = async(data) => {
  return await api.get(`v1/invoice/${data.invoiceId}/Pdf`, { ...data, responseType: 'blob' })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const GetMappedPrimaryUser = async(data) =>  
  await api
  .get(data)
  .then((response) => {
    return response;
  })
  .catch((error) => {
    return error;
});

export const GetTestLibraryCardDetails = async(data) => await api
.get(data)  
.then((response)=>{
return response;
})
.catch((error)=>{
  return error;
});

export const GetTestLibraryOffcanvasDetails = async(data) => await api
.get(`v1/test-libraries/tests/${data.testId}`)  
.then((response)=>{
return response;
})
.catch((error)=>{
  return error;
});
export const GetTestLibraryCardCategories = async(data) => await api
.get(`v1/test-library-categories/types/${data}/details`)  
.then((response)=>{
return response;
})
.catch((error)=>{
  return error;
});

export const CreateTestLibraryBookmark = async(data) => await api
.post(`v1/test-library-bookmarks`, data)  
.then((response)=>{
return response;
})
.catch((error)=>{
  return error;
});

export const DeleteTestLibraryBookmark = async(data) => await api
.delete(`v1/test-library-bookmarks/${data.bookmarkId}`, data)  
.then((response)=>{
return response;
})
.catch((error)=>{
  return error;
});

export const GetAllJobLevels =  async() => 
  await api.get(`v1/job-levels`)
  .then((response) => {
    return response;
  })
  .catch((error) => {
    return error;
  })

export const submitTestRating = async(data) =>
  await api.post(`v1/test-library-reviews/ratings`, data)
  .then((response) => {
    return response;
  })
  .catch((error) => {
    return error;
  })

export const submitTestReview = async(data) =>
  await api.post(`v1/test-library-reviews`, data)
  .then((response) => {
    return response;
  })
  .catch((error) => {
    return error;
  })

export const sendTestLibraryEmailToRm = async(data) => 
  await api.post(`v1/test-libraries/tests/${data.testId}`)
  .then((response) => {
    return response;
  })
  .catch((error) => {
    return error
  })

  export const testLibraryTrailTest = async(data) => await api
  .get(`v1/test-libraries/tests/${data.testId}/trial?userClientId=${data.userClientId}&amount=${data.amount}`)
  .then((response) => {
    return response;
  })
  .catch((error) => {
    return error;
  })

  export const GetBillingDetails = async(data) => await api
  .get(`v1/billing/clients/${data.clientId}/details`)
  .then((response) => {
    return response;
  })
  .catch((error) => {
    return error;
  })


  export const getPaymentPage = async(data) => 
  await api.post(`v1/payment/GetPayment`,data)
  .then((response) => {
    return response;
  })
  .catch((error) => {
    return error
  })



  export const putSubscriptionCancel = async(data) => 
  await api.put(`v1/billing/subscription-cancel/${data.subscriptionId}`,data)
  .then((response) => {
    return response;
  })
  .catch((error) => {
    return error
  })

  export const postDeployTest = async(data) => 
  await api.post(`v1/test-libraries/deploy?testid=${data.testid}`,data)
  .then((response) => {
    return response;
  })
  .catch((error) => {
    return error
  })

