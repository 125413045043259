/*update*/
import Cookies from "js-cookie";
/*update*/
import * as Rx from 'rxjs/Rx';
const messageObservable = new Rx.Subject();
import { Subject } from 'rxjs';

const userPrmsn = new Subject();

export const permissionService = {
    setPrmsn: value =>{
        userPrmsn.next(value);
    } ,
    clearPrmsn: () => userPrmsn.next(),
    getPrmsn: () => userPrmsn.asObservable()
};
export default {
    name: "Directive",
    subscriptions: {
        userPermission: messageObservable
    },
    data() {
        return {
            userPermission: messageObservable
        };
    },
}
export const getAuthUserData = () => {
    const data = localStorage.getItem("authUserData");
    let refresh_token = Cookies.get('refreshToken');
    if (refresh_token == null || refresh_token == undefined) {
        // user doesn't have refresh token, redirect to login
        // removeAuthUserData();
        // localStorage.clear();  //uncomment this when push to client repo
    }
    return data ? JSON.parse(data) : null;
};

export const setAuthUserData = (data) => {
    localStorage.setItem("authUserData", JSON.stringify(data));
};



// export const SetPlatformUserData = (data) => {
//     try {
       
//         localStorage.setItem("platformUserData", JSON.stringify(data));
//         console.log('Platform user data set successfully:', data);
//     } catch (error) {
//         console.error('Error setting platform user data:', error);
//     }
// };

// export const GetPlatformUserData = () => {
//     try {
//         const data = localStorage.getItem("platformUserData");
//         let refresh_token = Cookies.get('refreshToken');
//         if (refresh_token == null || refresh_token == undefined) {
//                 }
//         return data ? JSON.parse(data) : null;
//     } catch (error) {
//         console.error('Error retrieving platform user data:', error);
//         return null;
//     }
// };


export const removeAuthUserData = () => {
    localStorage.removeItem("authUserData");
};

export const removePlanFeature=()=>{
    localStorage.removeItem("featuresData");
};

export const removeBillingData=()=>{
    localStorage.removeItem("billingData");
};

export const removeUserPermission = () => {
    localStorage.removeItem("Userpermission");
};

export const removeIsParent = () => {
    localStorage.removeItem("isParent");
    localStorage.removeItem("activeAccount");
};
export const setUserPermissionData = (data) => {
    localStorage.setItem("Userpermission", JSON.stringify(data));
    permissionService.setPrmsn(data);
};

export const hasPermissions = (permissions) => {
    return this.user.value.SysAdm || (permissions && this.checkPermission(permissions))
}

export const checkPermission = (permissions) => {
    let hasPermission = false;

    if (permissions && permissions.length > 0 && permissions[0] == undefined)
        return true;

    if (this.userPermission.value) {

        var TempObject = this.userPermission.value;
        for (const checkPermission of permissions) {
            TempObject = TempObject[checkPermission]
        }
        if (TempObject != undefined)
            hasPermission = TempObject.CanDo;
    }

    return hasPermission;
}

// export const userInfo = (token) => {
//     try {
//         const userInfo = decode(token);
//         return userInfo;
//     } catch (error) {
//         return {};
//     }
// };

// export const checkTokenExpire = (token) => {
//     try {
//         const { exp } = decode(token);
//         const currentTime = new Date().getTime() / 1000;

//         if (currentTime > exp) {
//             return false;
//         }

//         return true;
//     } catch (error) {
//         return false;
//     }
// };

// export const decodeId = (token) => {
//     try {
//         const { id } = decode(token);
//         return id;
//     } catch (error) {
//         return false;
//     }
// };

export const setOtpAttempts = (attempt) => {
    const data = localStorage.getItem("OtpAttempts");
    if (data > 0) {
        localStorage.setItem("OtpAttempts", (parseInt(data) + attempt));
    }
    else {
        localStorage.setItem("OtpAttempts", 0 + attempt);
    }
};

export const getOtpAttempts = () => {
    const data = localStorage.getItem("OtpAttempts");
    return data ? JSON.parse(data) : null;
};

export const removeOtpAttempts = () => {
    localStorage.removeItem("OtpAttempts");
};


