
<template>
<img style="  display: block;margin-top: 10%;  margin-left: auto; margin-right: auto;" src="../assets/Images/pmaps-loading.gif" hidden/>
</template>

<script>

import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "logout",
  data() {
    return {
      errorMsg: "",
    };
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      userId: (state) => state.auth.user.userId,
    }),
  },
  methods: {
    ...mapActions(["logout"]),
    ...mapMutations(["setLoading"]),
    async isLoggedout() {
      this.isLoading = true;
      var data = await this.logout({ userId: this.userId });
      if (data.statusCode === 1012) {
        this.$router.push("/login");
      } else {
        // this.$toast.show(data.msg.message, {
        //   type: "warning",
        //   position: "top-right",
        //   duration: 3000,
        // });
      }
    },
  },
   beforeMount(){
    this.isLoggedout();
 }
};

</script>