<template>
  <div class="page-content">
    <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
      <div class="my-2 d-flex align-items-center">
        <div class="top_heading_CreatePartner_btn d-block me-2">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <router-link :to="{ name: 'ManagementRoles' }" class="
                  btn
                  p-0
                  Group_top_three_dot_dropdown
                  back_button_role_page_line
                " type="button">
                <i class="fa-solid fa-chevron-left"></i>
              </router-link>
            </div>
          </div>
        </div>
        <h4 class="mb-2 mb-md-0">Create Role</h4>
      </div>
      <div class="d-flex">
        <div class="ms-2 top_heading_CreatePartner_btn">
          <button type="button" class="btn rola_reset_form_button  mb-md-0 me-2" data-bs-toggle="modal"
            data-bs-target="#ResetFormRoleModal">
            Reset Form
          </button>

          <button type="button" class="btn btn-primary btn-icon-text mb-2 mb-md-0" @click="saveChanges()">
            Save
            <router-link :to="{ name: '' }"></router-link>
          </button>
        </div>
      </div>

      <div class="mobile_Create_Partner_button">
        <div class="">

          <button type="button" class="
            btn btn-primary btn-icon-text
            mb-2 mb-md-0
            mobile_form_save_button
          " @click="saveChanges">
            Save
            <router-link :to="{ name: 'ManagementCreateRoles' }"></router-link>
          </button>
          <button type="button" class="btn rola_reset_form_button me-2 mb-2 mb-md-0 reset-btn-m-kj"
            data-bs-toggle="modal" data-bs-target="#ResetFormRoleModal">
            Reset Form
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card p-4">
          <div class="card-body p-0">
            <form class="row rola_name_des_area align-content-start">
              <div class="col-xl-4 col-lg-5 col-md-6">
                <div class="form-group mb-4">
                  <label class="input-hero" for="PartnerCompany">
                    <input class="input_area form-control" type="text" v-model="rolename" required="" /><span
                      class="input-name">Role Name</span>
                  </label>
                </div>
              </div>
              <div class="col-xl-8 col-lg-7 col-md-6">
                <div class="form-group mb-4 position_relative">
                  <label class="input-hero" for="PartnerCompany">
                    <textarea class="input_area form-control-lg" v-on:keyup="countdown" v-on:blur="trimspace"
                      v-model="roledescription"></textarea>
                    <span class="input-name">Role Description</span>
                  </label>
                  <p class="role_testarea_info">
                    {{ remainingCount }}/500 Characters
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-12 grid-margin stretch-card" v-for="item in this.allpermission" :key="item">
        <div class="card p-0">
          <div class="card-head border-bottom p-4">
            <form class="align-content-start">
              <div class="form-group d-flex">
                <input type="checkbox" class="form-check-input me-3 checkout" name=""
                  :id="item.name.replace(/\s+/g, '')" @click="selectAll(item.id, $event)" />
                <h4 for="AccountControl">{{ item.name }}</h4>
              </div>
            </form>
          </div>
          <div class="card-body p-4" v-for="sub in item.groups" :key="sub">
            <form class="row align-content-start">
              <div class="col-lg-4 col-md-6 col-12 mb-3 form-group" v-for="subb in sub.permissions" :key="subb">
                <input type="checkbox" class="form-check-input me-2" name="" :checked="subb.isSelected"
                  @change="checkboxevent(item.id, subb.id, $event, item.name.replace(/\s+/g, ''))" />

                <label for="CreateClientAccount">{{ subb.name }}</label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- ResetFormRoleModal -->
    <div class="modal fade" id="ResetFormRoleModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="ResetFormRoleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-body p-4">
            <div class="">
              <h3 class="mb-3">Reset form?</h3>
              <p class="text-muted">
                Please note all data recorded will be deleted if you choose to
                reset.
              </p>
            </div>
            <div class="Deactivate_buttons row mt-5 justify-content-center">
              <button id="close"  data-bs-dismiss="modal" aria-label="Close" class="col-5 Deactivate_Cancel_buttons" @click="CancelPopup">
                Cancel
              </button>
              <button type="button" class="col-5 btn Deactivate_Deactivate_buttons" @click="ResetForm">
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- updateFormRoleModal -->
    <div class="modal fade" id="updateFormRoleModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="updateFormRoleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-body p-4">
            <div class="">
              <h3 class="mb-3">Update Changes?</h3>
              <p class="text-muted">
                You have made some changes on this form want to save it.
              </p>
            </div>
            <div class="Deactivate_buttons row mt-5 justify-content-center">
              <button class="col-5 Deactivate_Cancel_buttons">Cancel</button>
              <button type="button" class="col-5 btn Deactivate_Deactivate_buttons">
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";



export default {
  name: "ManagementCreateRoles",
  data() {
    return {
      arr: [],
      roleid: this.$route.params.id,
      rolepermissions: {},
      maxCharacters: 500,
      rolename: "",
      roledescription: "",
      permissions: [],
      maxCount: 500,
      remainingCount: 0,
      allpermission: [],
      permissionsList: [],
      selectedid: [],
    };
  },
  async mounted() {
    await this.Allpermission();

  },

  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      clientId: (state) => state.auth.user.clientId,
    }),
  },

  methods: {
    ...mapActions([
      "GetRolebyRoleId",
      "GetAllPermission",
      "CreateClientRole",
      "UpdateRole",
      "ShowWarningMessage",
      "getrole",
    ]),
    ...mapMutations(["setLoading"]),
    countdown: function () {
      this.roledescription = this.roledescription.trimStart();
      var lroledescription = this.roledescription;
      this.remainingCount = lroledescription.length;
      this.hasError = this.remainingCount < 0;
    },
    trimspace: function () {
      this.roledescription = this.roledescription.trim();
      var lroledescription = this.roledescription;
      this.remainingCount = lroledescription.length;
      this.hasError = this.remainingCount < 0;
    },

    async Allpermission() {
      var a = await this.GetAllPermission();
      this.allpermission = a.object.data;
    },
    ResetForm() {
      this.rolename = "";
      this.roledescription = "";
      this.remainingCount = 0;
      window.$('.AccountControl').prop('checked', false);
      window.$('.checkout').prop('checked', false);

      this.allpermission.forEach(async (element) => {
        element.groups.forEach(async (elm1) => {
          elm1.permissions.forEach(async (elm2) => {
            elm2.isSelected = false;
          });
        });
      });
      document.getElementById("close").click()
      window.$("#ResetFormRoleModal").modal("hide");
    },
    CancelPopup() {
      window.$("#ResetFormRoleModal").modal("hide");
    },

    async saveChanges() {
      if (this.rolename.trim() == null || this.rolename.trim() === "") {
        return this.ShowWarningMessage("Please enter valid name.");
      }
      if (this.rolename.length > 25) {
        return this.ShowWarningMessage(
          "Please enter Name upto 25 character."
        );
      }

      // this.roledescription = this.roledescription.trim();
      if (!this.roledescription) {
        return this.ShowWarningMessage("Please enter Role Description.");
      }

      if (this.roledescription.length < 20) {
        return this.ShowWarningMessage("Please enter a role description of minimum 20 characters.");
      }
      if (this.roledescription.length > 500) {
        return this.ShowWarningMessage(
          "Please enter Role Description upto 500 character."
        );
      }

      this.arr.forEach(async (element) => {
        element.groups.forEach(async (elm1) => {
          elm1.permissions.forEach(async (elm2) => {
            if (elm2.isSelected) {
              this.selectedid.push({
                id: elm2.id,
              });
            }
          });
        });
      });

      await this.CreateClientRole({
        clientId: this.clientId,

        body: {
          Name: this.rolename,
          DisplayName: this.rolename,
          Description: this.roledescription,
          IsDefault: true,
          Permissions: this.selectedid,
        },
      });
      await this.$router.push({
        name: "ManagementRoles",
      });
    },

    async checkboxevent(id, elmId, event, inputId) {
      var check = event.target.checked;
      let selectAllTick = 0;
      var groups = this.allpermission.find(x => x.id == id);
      var activeGroup = groups.groups.find(gr => gr.permissions.find(g => g.id == elmId));
      let element = activeGroup.permissions.find(g => g.id == elmId);
      element.isSelected = check
      groups.groups.forEach(async elm => {
        if (elm.permissions.length == elm.permissions.filter(i => i.isSelected == true).length) {
          selectAllTick++;
        }
      });

      if (selectAllTick == groups.groups.length) {
        window.$(`#${inputId}`).prop('checked', true)
      } else {
        window.$(`#${inputId}`).prop('checked', false)
      }
      this.arr = this.allpermission;


      // var check = event.target.checked;
      // var b = 0;
      // this.allpermission.forEach(async (element) => {
      //   element.groups.forEach(async (elm1) => {
      //     elm1.permissions.forEach(async (elm2) => {
      //       if (elm2.id == id) {
      //         elm2.isSelected = check;
      //       }
      //       if (elm2.isSelected == false) {
      //         b++;
      //         window.$(`#${inputId}`).prop('checked', false);
      //       }
      //       if (b == 0) {
      //         window.$(`#${inputId}`).prop('checked', true);
      //       }
      //       if (b > elm1.permissions.length ) {
      //         window.$(`#${inputId}`).prop('checked', false);
      //       }
      //     });
      //   });
      // });

      // this.arr = this.allpermission;
    },
    async selectAll(id, event) {
      var c = event.target.checked;
      this.allpermission.forEach(async (element) => {
        if (element.id == id) {
          element.groups.forEach(async (elm1) => {
            elm1.permissions.forEach((elm2) => {
              elm2.isSelected = c;
            });
          });
        }
      });
      this.arr = this.allpermission;
    },
  },
};
</script>

<style>
.reset-btn-m-kj {
  display: block !important;
  width: 130px !important;
  height: 42px !important;
  font-size: 17px !important;
  margin-right: 10px;
  padding: 7px 17px !important;
}
</style>
