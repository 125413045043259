
<template>
  <div class="container fluid">
    <div class="row">


  <div class="page-content" id="overviewpdf">
    <div style="background-color: #fff">
      <!-- <section class="container-fluid topPDF" style="padding: 20px 25px;">
      <div style="display: flex; justify-content: space-between">
        <span>
          <img :src="this.clientCompany" alt="ClientCompany logo" style="width: 40px;height: 40px; " />
        </span>
        <span>
         
           <img :src="this.Companylogo" alt="Companylogo logo"  style="width: 40px;height: 40px; "/>
      
        </span>
      </div>
      </section>
      
      <section class="container-fluid" style="padding: 15px 20px; background: linear-gradient(132deg, #F2F5F0 37.25%, #E8F7FF 85.4%); margin-botton:10px">
      <div style="display: flex; justify-content: space-between">
        <span>
           <img :src="this.clientCompany" alt="ClientCompany logo" style="width: 40px;height: 40px; " /> </span>
        <span style="font-size: 18px;
        font-weight: 500; padding: 10px 15px;">
         {{ this.testNamePDF }}
        </span>
        <span>
          <img :src="this.Companylogo" alt="Companylogo logo"  style="width: 40px;height: 40px; "/>
          
        </span>
      </div>
          </section> -->
      <section class="container-fluid topPDF" style="padding: 20px 25px">
        <div style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
          ">
          <span style="margin: 10px 0 0px">
            <!-- <img src="../../public/dashboard-assets/images/M7/PLA_top.png" width="350" alt="" /> -->
            <h1 class="pmaps-color" style="
                  font-style: normal;
                  font-weight: 700;
                  font-size: 60px;
                  color:#7c68ee;
                  word-break: break-word;
                  background: none;
                ">
              {{
                  this.OverviewbasicInfo?.testName
                  ? this.OverviewbasicInfo?.testName
                  : "NA"
                }}
            </h1>
            <span style="
                border: 2px solid #7b68ee;
                width: 100%;
                display: block;
                margin: 15px 0;
              "></span>
            <h2 style="font-style: normal; font-weight: 500; font-size: 30.4979px">
              {{
                this.OverviewbasicInfo?.companyName
                ? this.OverviewbasicInfo?.companyName
                : "NA"
              }}
            </h2>
          </span>
          <span>
            <img src="../../public/dashboard-assets/images/M7/Proctor-header-img.png" width="400" alt="" />
          </span>
        </div>
      </section>

      <section style="padding: 20px 25px">
        <h4 style="
            text-transform: uppercase;
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 25px;
            font-style: normal;
            color: #7c68ee;
          ">
          Overview Report
        </h4>
        <div class="Recomended_icon_btn mb-2" v-if="this.recommendationValue">
          <div class="Assessment_Recommended_new" :class="getRecommendationClass()"   style="display: flex;align-items: center;flex-wrap: wrap;flex-direction: row;">
            <img :src="this.recommendation_img"   style="width:25px;height:25px;" alt="" />
            <p style="font-size: 20px;margin-left: 2px;  line-height: 1; padding-bottom: 0px; margin-bottom: 0;">
              {{ this.recommendationValue }}</p>
          </div>
        </div>
        <!-- basic info -->
        <div style="margin-top: 25px">
          <span style="
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              letter-spacing: 0.008em;
              text-transform: uppercase;
              color: #666666;
            ">basic info</span>
          <p style="
              font-style: italic;
              font-weight: 400;
              font-size: 14px;
              padding-top: 15px;
              color: #666666;
            ">
            Refers to the essential details of the assessment.
          </p>
          <div class="row" style="
              border: 1px solid rgba(0, 0, 0, 0.17);
              border-radius: 8px;
              margin: 25px 0;
              padding: 15px;
            ">
            <div class="col-md-4" style="padding-bottom: 20px; flex: 0 0 auto; width: 33.33333333%">
              <label style="font-weight: 400; font-size: 14px; color: #7b818d">Candidate Name</label>
              <h6 style="font-weight: 800; font-size: 14px; color: #292e34">
                {{
                  this.OverviewbasicInfo?.name
                  ? this.OverviewbasicInfo?.name
                  : "NA"
                }}
              </h6>
            </div>
            <div class="col-md-4" style="padding-bottom: 20px; flex: 0 0 auto; width: 33.33333333%">
              <label style="font-weight: 400; font-size: 14px; color: #7b818d">Candidate ID</label>
              <h6 style="font-weight: 800; font-size: 14px; color: #292e34">
                {{ this.candidateId }}
              </h6>
            </div>
            <div class="col-md-4" style="padding-bottom: 20px; flex: 0 0 auto; width: 33.33333333%">
              <label style="font-weight: 400; font-size: 14px; color: #7b818d">Email</label>
              <h6 style="font-weight: 800; font-size: 14px; color: #292e34">
                {{
                  this.OverviewbasicInfo?.emailAddress
                  ? this.OverviewbasicInfo?.emailAddress
                  : "NA"
                }}
              </h6>
            </div>
            <div class="col-md-4" style="padding-bottom: 20px; flex: 0 0 auto; width: 33.33333333%">
              <label style="font-weight: 400; font-size: 14px; color: #7b818d">Exam Started</label>
              <h6 style="font-weight: 800; font-size: 14px; color: #292e34">
                {{
                  moment(this.OverviewbasicInfo?.examStarted).format(
                    "DD/MM/YYYY, HH:mm:ss a"
                  )
                }}
              </h6>
            </div>
            <div class="col-md-4" style="padding-bottom: 20px; flex: 0 0 auto; width: 33.33333333%">
              <label style="font-weight: 400; font-size: 14px; color: #7b818d">Invited By</label>
              <h6 style="font-weight: 800; font-size: 14px; color: #292e34">
                {{
                  this.OverviewbasicInfo?.invitedBy
                  ? this.OverviewbasicInfo?.invitedBy
                  : "NA"
                }}
              </h6>
            </div>
            <div class="col-md-4" style="padding-bottom: 20px; flex: 0 0 auto; width: 33.33333333%">
              <label style="font-weight: 400; font-size: 14px; color: #7b818d">Test Name</label>
              <h6 style="font-weight: 800; font-size: 14px; color: #292e34">
                {{
                  this.OverviewbasicInfo?.testName
                  ? this.OverviewbasicInfo?.testName
                  : "NA"
                }}
              </h6>
            </div>
            <div class="col-md-4" style="padding-bottom: 20px; flex: 0 0 auto; width: 33.33333333%">
              <label style="font-weight: 400; font-size: 14px; color: #7b818d">Language</label>
              <h6 style="font-weight: 800; font-size: 14px; color: #292e34">
                {{
                  this.OverviewbasicInfo?.language
                  ? this.OverviewbasicInfo?.language
                  : "NA"
                }}
              </h6>
            </div>
            <div class="col-md-4" style="padding-bottom: 20px; flex: 0 0 auto; width: 33.33333333%">
              <label style="font-weight: 400; font-size: 14px; color: #7b818d">Time Taken (in min)</label>
              <h6 style="font-weight: 800; font-size: 14px; color: #292e34">
                {{
                  this.OverviewbasicInfo?.timeTakenInMins
                  ? this.OverviewbasicInfo?.timeTakenInMins
                  : "NA"
                }}
              </h6>
            </div>
            <div class="col-md-4" style="padding-bottom: 20px; flex: 0 0 auto; width: 33.33333333%">
              <label style="font-weight: 400; font-size: 14px; color: #7b818d">Total Question Attempted</label>
              <h6 style="font-weight: 800; font-size: 14px; color: #292e34">
                {{
                  this.OverviewbasicInfo?.totalQuestionAttempted
                  ? this.OverviewbasicInfo?.totalQuestionAttempted
                  : "NA"
                }}
              </h6>
            </div>
          </div>
        </div>

        <div style="page-break-inside: avoid;">
          <!-- Hardware history -->
          <div style="margin-top: 25px">
            <span style="
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                letter-spacing: 0.008em;
                text-transform: uppercase;
                color: #666666;
              ">score & rank</span>
            <p style="
                font-style: italic;
                font-weight: 400;
                font-size: 14px;
                color: #666666;
                font-style: italic;
              ">
              Refers to the candidates scores and position across various parameters.

            </p>
          </div>

          <div style="
              border: 1px solid rgba(0, 0, 0, 0.17);
              padding: 25px;
              border-radius: 10px;
              margin: 30px 0;
            " class="row justify-content-center">
            <div class="row" style="
                flex-wrap: wrap;
                justify-content: space-between;
                padding-bottom: 25px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.17);
              ">

              <div class="text-center col-md-4" style="
                
                " v-for="(item, index) in RankPDF" :key="index" :style="[
                  item.ranges == null ? 'opacity: 0.3;' : '',
                  index != RankPDF.length - 1
                    ? 'border-right: 1px solid #e9ecef !important'
                    : '',
                ]">

                <h6 style="margin-bottom: 15px; font-weight: 600;">{{ item?.name }}</h6>
                <CustomChart v-if="item.name == 'Overall Score'" :score="this.overallScore[index]" :main="'scorepdf'"
                  :iconcolor="'transform-328deg'"></CustomChart>

                <div class="Genuinity_chart" style="
                    width: 120px;
                    height: 120px;
                    background: radial-gradient(#f4f2ff 60%, #fff 20%);
                    border: 3px solid #7c68ee;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 15px auto;
                  " v-if="item.name == 'Proctoring Rank' && item.ranges == null">
                  <div class="Genuinity_chart_content" :style="item.ranges == null
                    ? 'color: rgba(124, 104, 238, 0.3);'
                    : ''
                    " style="font-size: 16px; font-weight: 500; color: #7c68ee">
                    <img src="../../public/dashboard-assets/images/M7/Genuinity_chart_icon.png" width="30" alt="" />
                    <p>NA</p>
                  </div>
                </div>

                <div               
                   v-if="item.name == 'Proctoring Rank' && item.ranges != null">
                  <div>
                    <!-- <CustomChart :score="this.overallScore[index]" :main="'proctorSectionpdf'"
                      :iconcolor="'transform-328deg'" /> -->
                      <ProctorChart :score="this.proctorScore" :main="'proctorSection'" :isPdf="true" />

                  </div>
                </div>

                <div class="Genuinity_chart" style="
                    width: 120px;
                    height: 120px;
                    background: radial-gradient(#f4f2ff 60%, #fff 20%);
                    border: 3px solid #7c68ee;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 15px auto;
                  " v-if="item.name == 'Genuinity'">
                  <div class="Genuinity_chart_content" style="font-size: 16px; font-weight: 500; color: #7c68ee">
                    <img src="../../public/dashboard-assets/images/M7/Genuinity_chart_icon.png" width="30" alt="" />
                    <p>NA</p>
                  </div>
                </div>

                <div class="Company_Rank_chart" style="
                    width: 120px;
                    height: 120px;
                    background: radial-gradient(#f4f2ff 60%, #fff 20%);
                    border: 3px solid #7c68ee;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 15px auto;
                  " v-if="item.name == 'Company Rank'">
                  <div class="Company_Rank_chart_content" style="font-size: 16px; font-weight: 500; color: #7c68ee">
                    <img src="../../public/dashboard-assets/images/M7/Company_Rank_chart_icon.png" width="30" alt="" />
                    <p>NA</p>
                  </div>
                </div>

                <div class="Industry_Rank_chart" style="
                    width: 120px;
                    height: 120px;
                    background: radial-gradient(#fffbeb 60%, #fff 20%);
                    border: 3px solid #ffc801;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 15px auto;
                  " v-if="item.name == 'Industry Rank'">
                  <div class="Industry_Rank_chart_content" style="font-size: 16px; font-weight: 500; color: #ffc800">
                    <img src="../../public/dashboard-assets/images/M7/Industry_Rank_chart_icon.png" width="30" alt="" />
                    <p>NA</p>
                  </div>
                </div>
              </div>
            </div>

            <div style="margin-top: 30px">
              <span style="
                  padding: 6px 20px;
                  background-color: #f1f0ff;
                  border-radius: 8px;
                  margin: 30px 0 15px;
                  font-weight: 500;
                ">
                Definitions
              </span>
              <div class="row my-4" v-for="(DefRank, index) in RankPDF" :key="index">
                <div class="col-md-4" style="
                    flex: 0 0 auto;
                    width: 20.66666667%;
                    color: #7b818d;
                    font-weight: 500;
                  ">
                  {{ DefRank.name }}
                </div>
                <div class="col-md-1 text-end" style="flex: 0 0 auto; width: 8.33333333%">
                  -
                </div>
                <div class="col-md-7" style="flex: 0 0 auto; width: 66.66666667%">
                  <div class="d-flex">
                    <div style="display: flex; align-items: center" v-for="item in this.overallScore[index]?.ranges"
                      :key="item">
                      <div style="
                          width: 15px;
                          height: 15px;
                          border-radius: 50%;
                          content: ;
                          padding-right: 10px;
                        " :style="{ background: item.colorCode }"></div>
                      <div class="mx-2">
                        {{ item.rangeName }}({{ item.minVal }}-{{
                          item.maxVal
                        }})
                      </div>
                    </div>
                  </div>
                  <p style="color: #666666; font-weight: 400">
                    {{ DefRank.definition ? DefRank.definition : "NA" }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <!-- Hardware history -->
          <div style="margin-top: 25px">
            <span style="
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                letter-spacing: 0.008em;
                text-transform: uppercase;
                color: #666666;
              ">Overall Test Result Summary</span>
            <p style="
                font-style: italic;
                font-weight: 400;
                font-size: 14px;
                color: #666666;
                font-style: italic;
              ">
              Summary candidate's score on all sections.

            </p>
          </div>

          <div style="
              border: 1px solid rgba(0, 0, 0, 0.17);
              padding: 25px;
              border-radius: 10px;
              margin: 30px 0;
            ">
            <div class="row justify-content-center d-flex" style="
                padding-bottom: 25px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.17);
              ">

              <div class="col-xl-4 col-lg-4 col-md-6 col-12 my-3 text-center" :class="[(index < testSummary.length - 1 && index % 3 != 2) ? 'border-end' : '']" v-for="(item, index) in this.testSummary" :key="index">
                <h4 style="margin-bottom: 15px; font-weight: 700; font-size: 16px;"> Overall {{ item.sectionName }}</h4>
                <CustomChart :score="this.score[index]" :main="item.sectionName + 'pdf'" :iconcolor="'transform-328deg'">
                </CustomChart>
              </div>
              <!-- <div class="text-center">
                <h4 style="margin-bottom: 15px">Email Writing Test</h4>
                <img src="../../public/dashboard-assets/images/M7/OverallTestPieChart.png" alt="" />
              </div>
              <div class="text-center">
                <h4 style="margin-bottom: 15px">Accent Result</h4>
                <img src="../../public/dashboard-assets/images/M7/OverallTestPieChart.png" alt="" />
              </div>
              <div class="text-center">
                <h4 style="margin-bottom: 15px">Solve to Resolve</h4>
                <img src="../../public/dashboard-assets/images/M7/OverallTestPieChart.png" alt="" />
              </div> -->
            </div>

            <div style="margin-top: 40px">
              <span style="
                  padding: 6px 20px;
                  background-color: #f1f0ff;
                  border-radius: 8px;
                  margin: 30px 0 15px;
                  font-weight: 500;
                ">
                Definitions
              </span>
              <div class="row my-4" v-for="(DefSun, index) in testSummary" :key="index">
                <div class="col-md-2" style="
                    flex: 0 0 auto;
                    width: 16.66666667%;
                    color: #7b818d;
                    font-weight: 500;
                  ">
                  {{ DefSun.sectionName }}
                </div>
                <div class="col-md-1 text-end" style="flex: 0 0 auto; width: 8.33333333%">
                  -
                </div>
                <div class="col-md-8" style="flex: 0 0 auto; width: 66.66666667%">
                  <div class="d-flex">
                    <div style="display: flex; align-items: center" v-for="item in this.score[index]?.ranges" :key="item">
                      <div style="
                          width: 15px;
                          height: 15px;
                          border-radius: 50%;
                          content: ;
                          padding-right: 10px;
                        " :style="{ background: item.colorCode }"></div>
                      <div class="mx-2">
                        {{ item.rangeName }} ({{ item.minVal }}-{{
                          item.maxVal
                        }})
                      </div>
                    </div>
                  </div>
                  <p style="color: #666666; font-weight: 400">
                    {{ DefSun.definition ? DefSun.definition : "NA" }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="(Behaviouralrecommend?.aoiRecommendations?.length != 0 || Behaviouralrecommend?.strengthRecommendations.length != 0) && (Behaviouralrecommend?.aoiRecommendations != null || Behaviouralrecommend?.strengthRecommendations != null)">
          <!-- Hardware history -->
          <div style="margin-top: 25px"  >
            <span style="
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                letter-spacing: 0.008em;
                text-transform: uppercase;
                color: #666666;
              ">Refers to the combined score obtained by the candidate on all behavioral competencies.

            <span :class="[ Behaviouralrecommend.fitment === 'Recommended' ? 'Recommended_heading_text ms-3' : Behaviouralrecommend.fitment === 'Not Recommended' ? 'Not_recommended_heading_text ms-3' : 'Cautiously_recommended_heading_text ms-3' ]"> {{ Behaviouralrecommend.fitment }} </span>
            </span>
            <p style="
                font-style: italic;
                font-weight: 400;
                font-size: 14px;
                color: #666666;
                font-style: italic;
              ">
              Suggestions given to the candidate on the basis of their scores on the behavioral competencies.

            </p>
          </div>
          <div style="
              border: 1px solid rgba(0, 0, 0, 0.17);
              padding: 25px;
              border-radius: 10px;
              margin: 30px 0;
            ">
            <div style="
                background: #f8f7ff;
                padding: 20px;
                border-radius: 8px;
                margin-bottom: 20px;
              ">
              <h4>Strengths:</h4>
              <div v-if="Behaviouralrecommend?.strengthRecommendations?.length == 0">

                <img src="../../public/dashboard-assets/images/M7/Improvement_none_icon.png" width="150" alt=""
                  class="mt-3 me-4" />

                <span style="font-weight: bold;"> XXXX</span>
              </div>
              <div class="d-flex align-items-flex-start my-3" v-for="trength of Behaviouralrecommend?.strengthRecommendations"
                :key="trength">
                <img src="../../public/dashboard-assets/images/M7/Strengths_icon.png" style="margin-top: 5px;" width="20" height="20" class="me-1"
                  alt="" />
                <p>
                  <strong> {{ trength.title }} </strong>
                  {{ trength.description }}
                </p>
              </div>
            </div>

            <div style="background: #fff7fb; padding: 20px; border-radius: 8px">
              <h4>Area of Improvements:</h4>
              <div v-if="Behaviouralrecommend?.aoiRecommendations?.length == 0">
                <img src="../../public/dashboard-assets/images/M7/Improvement_none_icon.png" width="150" alt=""
                  class="mt-3 me-4" />

                <span style="font-weight: bold;"> XXXX</span>
              </div>
              <div class="d-flex align-items-flex-start my-3" v-for="AOI of this.Behaviouralrecommend?.aoiRecommendations"
                :key="AOI">
                <img src="../../public/dashboard-assets/images/M7/Improvements_icon.png" style="margin-top: 5px;" width="20" height="20"
                  class="me-1" alt="" />
                <p>
                  <strong> {{ AOI.title }}:</strong> {{ AOI.description }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="(recommendations?.strengthRecommendations?.length != 0 || recommendations?.aoiRecommendations?.length != 0) && (recommendations?.strengthRecommendations?.length != null || recommendations?.aoiRecommendations?.length != null)">
          <!-- Hardware history -->
          <div style="margin-top: 25px" >
            <span style="
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                letter-spacing: 0.008em;
                text-transform: uppercase;
                color: #666666;
              ">Refers to the combined score obtained by the candidate on all cognitive competencies

               <span :class="[ recommendations.fitment === 'Recommended' ? 'Recommended_heading_text ms-3' : recommendations.fitment === 'Not Recommended' ? 'Not_recommended_heading_text ms-3' : 'Cautiously_recommended_heading_text ms-3' ]"> {{ recommendations.fitment }} </span></span>
            <p style="
                font-style: italic;
                font-weight: 400;
                font-size: 14px;
                color: #666666;
                font-style: italic;
              ">
              Suggestions given to the candidate on the basis of their scores on the cognitive competencies.

            </p>
          </div>
          <div style="
              border: 1px solid rgba(0, 0, 0, 0.17);
              padding: 25px;
              border-radius: 10px;
              margin: 30px 0;
            ">
            <div style="
                background: #f8f7ff;
                padding: 20px;
                border-radius: 8px;
                margin-bottom: 20px;
              ">
              <h4>Strengths:</h4>
              <div v-if="recommendations?.strengthRecommendations?.length == 0">

                <img src="../../public/dashboard-assets/images/M7/Improvement_none_icon.png" width="150" alt=""
                  class="mt-3 me-4" />

                <span style="font-weight: bold;"> XXXX</span>
              </div>
              <div class="d-flex align-items-flex-start my-3" v-for="trength of recommendations?.strengthRecommendations"
                :key="trength">
                <img src="../../public/dashboard-assets/images/M7/Strengths_icon.png" style="margin-top: 5px;" width="20" height="20" class="me-1"
                  alt="" />
                <p>
                  <strong> {{ trength.title }} </strong>
                  {{ trength.description }}
                </p>
              </div>
            </div>

            <div style="background: #fff7fb; padding: 20px; border-radius: 8px">
              <h4>Area of Improvements:</h4>
              <div v-if="recommendations?.aoiRecommendations?.length == 0">
                <img src="../../public/dashboard-assets/images/M7/Improvement_none_icon.png" width="150" alt=""
                  class="mt-3 me-4" />

                <span style="font-weight: bold;"> XXXX</span>
              </div>
              <div class="d-flex align-items-flex-start my-3" v-for="AOI of this.recommendations?.aoiRecommendations"
                :key="AOI">
                <img src="../../public/dashboard-assets/images/M7/Improvements_icon.png" style="margin-top: 5px;" width="20" height="20"
                  class="me-1" alt="" />
                <p>
                  <strong> {{ AOI.title }}:</strong> {{ AOI.description }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- logs -->
        <div style="margin-top: 25px">
          <span style="
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              letter-spacing: 0.008em;
              text-transform: uppercase;
              color: #666666;
            ">attempt details</span>
          <p style="
              font-style: italic;
              font-weight: 400;
              font-size: 14px;
              color: #666666;
              font-style: italic;
              margin-bottom: 20px;
            ">
            Includes information about the candidate's name; number of questions attempted out of the given questions, the
            amount of time spent on each section, pauses and resume time of the candidate.

          </p>
          <div class="table-responsive" style="
              min-height: auto;
              border: 1px solid rgba(0, 0, 0, 0.13);
              border-radius: 8px;
            ">
            <table class="table table-bordered mb-0" style="
                width: 100%;
                border-color: rgba(0, 0, 0, 0.17);
                border-color: #0000002b !important;
                border-radius: 8px 8px 0;
              ">
              <thead style="background-color: #f1f0ff; border-radius: 8px 8px 0 0">
                <tr style="
                    border-color: #0000002b !important;
                    border-width: 1px 0;
                    font-weight: 400;
                    border-radius: 8px 8px 0;
                  ">
                  <th style="
                      border-color: #0000002b !important;
                      color: #292e34;
                      text-transform: capitalize;
                      font-weight: 500;
                      font-size: 16px;
                    ">
                    Section
                  </th>
                  <th style="
                      border-color: #0000002b !important;
                      color: #292e34;
                      text-transform: capitalize;
                      font-weight: 500;
                      font-size: 16px;
                    ">
                    Attempt log
                  </th>
                  <th style="
                      border-color: #0000002b !important;
                      color: #292e34;
                      text-transform: capitalize;
                      font-weight: 500;
                      font-size: 16px;
                    ">
                    Time Spent
                  </th>
                  <!-- <th
                    style="
                      border-color: #0000002b !important;
                      color: #292e34;
                      text-transform: capitalize;
                      font-weight: 500;
                      font-size: 16px;
                    "
                  >
                    Pause Time
                  </th>
                  <th
                    style="
                      border-color: #0000002b !important;
                      color: #292e34;
                      text-transform: capitalize;
                      font-weight: 500;
                      font-size: 16px;
                    "
                  >
                    Resume Time
                  </th> -->
                </tr>
              </thead>
              <tbody style="border-color: #0000002b !important; border-width: 1px 0">
                <tr style="
                    border-color: #0000002b !important;
                    border-width: 1px 0;
                    font-size: 15px;
                  " v-for="item in AttpDataPDF" :key="item">
                  <td style="border-color: #0000002b !important">
                    {{ item.sectionName }}
                  </td>
                  <td style="border-color: #0000002b !important">
                    {{ item.totalQuestionCount }} / {{ item.totalQuestionCount }}
                  </td>
                  <td style="border-color: #0000002b !important">
                    {{ item.timeSpent }}
                  </td>
                  <!-- <td style="border-color: #0000002b !important">{{ item.pauseTime }}</td>
                  <td style="border-color: #0000002b !important">{{ item.resumeTime }}</td> -->
                </tr>
              </tbody>
            </table>
          </div>

        </div>

         <Disclaimer></Disclaimer>
      </section>
      <!-- <section class="container-fluid footerPDF" style="padding: 20px 25px"><span style="font-weight: 700;">Overview</span></section> -->
    </div>
  </div>
</div>
</div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import CustomChart from "./CustomChart.vue";
import ProctorChart from "./ProctorChart.vue";
import Disclaimer from "./report/partial-view/Disclaimer.vue";
 import store from "../store";
 import Base64Images from "../utils/base64Image.js";
 import * as html2pdf from "html2pdf.js";
export default {
  components: {
    CustomChart,
    ProctorChart,
    Disclaimer
  },
  name: "ActiveTestReportOverviewPDF",
  data() {
    return {
      OverviewbasicInfo: [],
      recommendations: [],
      Behaviouralrecommend: [],
      testSummary: [],
      candidateId: window.atob(this.$route.query.candidateId), //10098,
      name:window.atob(this.$route.query.name),
      testId:window.atob(this.$route.query.testId),
      score: [], 
      proctorScore: [],
      recommendationValue: '',
      recommendation_img: '',
      overallScore: [],
      AttpDataPDF: [],
      RankPDF: "",
      clientCompany: "",
      base64: "",
      namePdf: "",
      testNamePDF: "",
      Companylogo: "",
    };
  },
  // props: ['testId', 'candidateId', 'name', 'pdfData'],

  async created() {
    this.moment = moment;
    // this.OverviewbasicInfo = this.pdfData.OverviewbasicInfo;
    // this.AttpDataPDF = this.pdfData.AttpDataPDF;
    // this.testSummary = this.pdfData.testSummary;
    // this.RankPDF = this.pdfData.RankPDF;
    // console.log(this.RankPDF)
    // this.recommendations = this.pdfData.recommendations;
    await this.getInfo();
    await this.getTestSummary();
    await this.getCognitiveRecommendations();
    await this.getBehaviouralrecommend();
    // var reportsList = await this.getReportData(this.testId);
    // var isCognitiveReport = reportsList?.object?.data?.details.filter(x => x.name === 'Cognitive');
    // if(isCognitiveReport.length === 1) {
    //   await this.getCognitiveRecommendations();
    // }
    // var isBehaviouralReport = reportsList?.object?.data?.details.filter(x => x.name === 'Behavioural');
    // if(isBehaviouralReport.length === 1) {
    //   await this.getBehaviouralRecommendations(); 
    // }
    await this.getAttpdataPDF();
    await this.GetScoreRankPDF();
    await this.callPDfFunction(); 
  },

  async mounted() {
    // store.state.loader.isLoading = true;
    // console.log(this.pdfData)


    // await this.getInfo();
    // await this.getTestSummary();
    // await this.getCognitiveRecommendations();
    // await this.getAttpdataPDF();
    // await this.GetScoreRankPDF();
    
     
    // await this.callPDfFunction(); 
     

  },
  methods: {
    ...mapActions([
      "getBasicInfo",
      "getTestResultSummary",
      "getReportData",
      "getRecommendations",
      "GetAttemptData",
      "GetScoreRankData",
      "callPDfFunction",
    ]),

    async getInfo() {
      var info = await this.getBasicInfo(this.candidateId);
      if (info?.status) {
        this.OverviewbasicInfo = info.object.data;
        this.clientCompany = this.OverviewbasicInfo.clientCompanyLogo;
          this.Companylogo = this.OverviewbasicInfo.companyLogo;
          this.namePdf = this.OverviewbasicInfo.name;
          this.testNamePDF = this.OverviewbasicInfo.testName;
      } else {
        this.OverviewbasicInfo = null;
      }
    },

    async getAttpdataPDF() {
      var AttpDataPDF = await this.GetAttemptData(this.candidateId);
      if (AttpDataPDF?.status) {
        this.AttpDataPDF = AttpDataPDF.object.data;
      } else {
        this.AttpDataPDF = null;
      }
    },
    async GetScoreRankPDF() {
      var RankPDF = await this.GetScoreRankData(this.candidateId);
      
      if (RankPDF?.status) {
        this.RankPDF = RankPDF?.object?.data?.scoreAndRanks;
        this.recommendationValue = RankPDF?.object?.data?.testRecommendation;
        this.RankPDF.forEach((element, index) => {
          this.overallScore[index] = {
            // marksScored: element.score ? element.score : null,
            marksScored: element.score != null ? element.score : null,
            outOfMarks: element.ranges
              ? element.ranges[element.ranges.length - 1].maxVal
              : null,
            result: element.fitment ? element.fitment : null,
            ranges: element.ranges ? element.ranges : null,
          };
          if (element.name == 'Proctoring Rank') {
            this.proctorScore = {
              marksScored: element.score != null ? +element.score : null,
              outOfMarks: element.ranges
                ? element.ranges[element.ranges.length - 1].maxVal
                : null,
              result: element.fitment ? element.fitment : null,
              ranges: element.colorCode ? element.colorCode : null,
            };
          }
        });
      } else {
        this.RankPDF = null;
      }
    },
    getRecommendationClass() {
      if (this.recommendationValue === "This Assessment is Recommended.") {
        this.recommendation_img = Base64Images.Recommended;
        return "recommended";
      } else if (this.recommendationValue === "This Assessment is not Recommended.") {
        this.recommendation_img = Base64Images.Recommended_Not;
        return "recommended_not";
      } else if (this.recommendationValue === "") {
        this.recommendation_img = Base64Images.Recommended_Null;
        return "recommended_null";
      } else {
        this.recommendation_img = Base64Images.Recommended_Default;
        return "recommended_default";
      }

    },

    async getTestSummary() {
      var testSum = await this.getTestResultSummary(this.candidateId);
      if (testSum?.status) {
        this.testSummary = testSum?.object?.data?.summary;
        this.testSummary.forEach((element, index) => {
          this.score[index] = {
            marksScored: +element.score,
            outOfMarks: element?.ranges[element?.ranges.length - 1].maxVal,
            result: element.fitment,
            ranges: element.ranges,
          };
        });
      } else {
        this.testSummary = null;
      }
    },

    async getCognitiveRecommendations() {
      var model = {
        candidateId: this.candidateId,
        type: "Cognitive",
      };
      var Commend = await this.getRecommendations(model);
      if (Commend?.status) {
        this.recommendations = Commend?.object?.data;
      } else {
        this.recommendations = null;
      }
    },
    async getBehaviouralrecommend() {
      var model = {
        candidateId: this.candidateId,
        type: "Behavioural",
      };
      var Commend = await this.getRecommendations(model);
      if (Commend?.status) {
        this.Behaviouralrecommend = Commend?.object?.data;
      } else {
        this.recommendations = null;
      }
    },

    async callPDfFunction(){
      store.state.loader.isLoading = true;

      const namePdf = this.namePdf;
      const testName = this.testNamePDF;
      // const Companylogo = this.Companylogo;
      // const clientCompany = this.clientCompany;
    var opt = {
        margin: [23, 1, 20, 1],
        filename: `Overview_${namePdf}`,
        html2canvas: { scale: 3,dpi: 192,letterRendering: true,useCORS: true },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };
      /*header*/
      const headerContent = `
      <section class="container-fluid" style="padding: 15px 20px; background: linear-gradient(132deg, #F2F5F0 37.25%, #E8F7FF 85.4%); margin-botton:10px">
      <div style="display: flex; justify-content: space-between">
        <span>
          <img src="`+Base64Images.PMapsLogo+`" alt="" />
        </span>
        <span style="font-size: 18px;
        font-weight: 500; padding: 10px 15px;">
        `+testName+`
        </span>
        <span>
          <img src="`+Base64Images.PMapsLogo2+`" alt="" />
        </span>
      </div>
    </section>`;

      const headerContent1 = `
      <section class="container-fluid topPDF" style="padding: 20px 25px;">
      <div style="display: flex; justify-content: space-between">
        <span>
          <img src="`+Base64Images.PMapsLogo+`" alt="" />
        </span>
        <span>
          <img src="`+Base64Images.PMapsLogo2+`" alt="" />
        </span>
      </div>
      </section>`;

      /*footer*/
      const footerContent = `<section class="container-fluid footerPDF" style="padding: 20px 25px"></span></section>`;


      html2pdf()
        .from(headerContent1)
        .toImg()
        .get("img")
        .then((image) => {
          html2pdf()
            .from(headerContent)
            .toImg()
            .get("img")
            .then((img) => {
              html2pdf()
                .from(footerContent)
                .toImg()
                .get("img")
                .then((ig) => {
                  html2pdf()
                    .from(document.getElementById("overviewpdf"))
                    .set(opt)
                    .toPdf()
                    .get("pdf")
                    .then((pdf) => {
                      var totalPages = pdf.internal.getNumberOfPages();
                      for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        if (i != 1) {
                          pdf.addImage(
                            img,
                            "JPEG",
                            0,
                            0,
                            pdf.internal.pageSize.getWidth(),
                            0
                          );
                        } else {
                          pdf.addImage(
                            image,
                            "JPEG",
                            0,
                            0,
                            pdf.internal.pageSize.getWidth(),
                            0
                          );
                        }
                        pdf.addImage(
                          ig,
                          "JPEG",
                          0,
                          pdf.internal.pageSize.getHeight() - 23,
                          pdf.internal.pageSize.getWidth(),
                          0
                        );
                        pdf.setFontSize(15);
                        pdf.setTextColor(100);
                        pdf.text(
                          "Overview " + "|" + i,
                          pdf.internal.pageSize.getWidth() - 35,
                          pdf.internal.pageSize.getHeight() - 3
                        );
                        this.$router.push({
                          name: "ActiveTestReportOverview",
                          params: {
                            candidateId: this.$route.query.candidateId,
                            testId: this.$route.query.testId,
                            
                          },
                          query: {
                            name: this.$route.query.name,
                            clientId: this.$route.query.clientId,
                            isParent: this.$route.query.isParent,
                            id: this.$route.query.id,
                            offset: this.$route.query.offset,
                            current: this.$route.query.current,
                          },
                        });
                
                        store.state.loader.isLoading = false;
                        
                      }
                    })
                    .save();
                    
                    //window.open('', '_self', ''); window.close();
                });
            });
            
        });

    },
  },

};
</script>
<style>


.Assessment_Recommended_new {
  display: flex;
  align-items: center;
  font-size: 20px;
  padding: 10px 15px;
  border-radius: 50px;
  font-weight: 600;
  width: fit-content;
}

.recommended {

  background: #F4F2FF;
  border-color: #F4F2FF;
  color: #7C68EE;

}

.recommended_not {
  background: #ffeafa;
  border-color: #ffeafa;
  color: #F86AD6;
}

.recommended_null {
  background: #ffe6e6;
  border-color: #ffe6e6;
  color: #FB6B6B;
}

.recommended_default {
  background: #fff9d5;
  border-color: #fff9d5;
  color: #FFC801;
}

.Recommended_heading_text {
  border-radius: 28px;
  padding: 6px 20px;
  background: #F4F2FF;
  color: #7C68EE;
  font-size: 16px;
  text-transform: capitalize;
}

.Not_recommended_heading_text {
  border-radius: 28px;
  padding: 6px 20px;
  background: #ffeafa;
  color: #F86AD6;
  font-size: 16px;
  text-transform: capitalize;
}

.Cautiously_recommended_heading_text {
  border-radius: 28px;
  padding: 6px 20px;
  background: #fff9d5;
  color: #FFC801;
  font-size: 16px;
  text-transform: capitalize;
}


</style>

