<template>
    <div class="page-content">
      <div class="card p-0 "  v-if="this.show">
                        <div class="table-responsive">
                            <table class="table text-center">
                                <thead>
                                    <tr>
                                      <th>
                                        
    <span style="display: flex; align-items: center; justify-content: center;">
      <div style="color: #7c68ee; font-size:17px; display: block; margin-right: 10%;">Monthly</div>
  
        <div class="form-check form-switch">
    <input class="form-check-input custom-toggle" type="checkbox" id="flexSwitchCheckChecked" checked v-model="planType">
  </div>
  
        <div style="color: #7c68ee; font-size:17px; display: block;margin-left: 4%; ">Yearly</div>    
    </span>
    <span style="display: block; margin-top: 5%;">Per Organization/Month</span>
    <span style="display: block; margin-top: 12%; color: #7c68ee;font-size:20px; font-weight: 600;">
      Features
    </span>
  </th>
  
                                        <th  v-for="plan in filteredPlans" :key="plan">
                                        <span class="planheading">{{ plan.displayName.split(" ")[0] }}</span>
                                        <span style="display: block; margin-top: 10%;">  
                                          
                                          <button type="button" class="UpgradeButton"  >Upgrade Now</button></span>
                                          <span style="display: block; margin-top: 5%; color: #7c68ee; font-size:20px; font-weight: 600;">
                                            {{ this.fetchCurrency(plan.currencyCode) }} {{ plan.price }}</span>
                                        </th>
                                        <th>
  
                                          <span class="planheading">Enterprise</span>
                                        <span style="display: block; margin-top: 7%;">  
                                          
                                          <button type="button" class="UpgradeButton" >Connect Now</button></span>
                                          <span style="display: block; margin-top: 5%; color: #a898ff; font-size:17.5px; font-weight: 600;">
                                            Custom Plan</span>
  
                                        </th>
  
                                       
                                    </tr>
                                </thead> 
                                <tbody v-for="item in PlanFeatures" :key="item">
                                  <tr>
                                    <td style="font-weight: 500; font-size:15px; text-align: left !important;">{{ item.name }}</td>
                                    <td v-for="plan in item.plans" :key="plan">
                                               <span>
                                          <i :class="plan.hasFeature ? 'fa-solid fa-circle-check fa-lg' : 'fa-regular fa-circle-xmark fa-lg'" style="color: #7c68ee;"></i>
                                           </span>
  
                                       </td>
                                    <td><span><i class="fa-solid fa-circle-check fa-lg" style="color: #7c68ee;"></i></span></td>
  
                             
                                    </tr>
                                </tbody>
                            </table>
  
                 
                        </div>
                    </div>
                
                 
      </div>
             
  
  </template>
  
  <script>
  import moment from "moment";
  import { mapActions, mapMutations, mapState } from "vuex";
  
  export default {
    name: "BillingPlanGlobal",
    data() {
      return {
        PlanFeatures: [],
        allFeatures:[] ,
        PlanDetails:[],
        planData:[],
        planType:false,
        show:false,
        data:[]
  
      };
    },
    async created() {
    await this.planContent();
    },
  
    computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapState('features', {
      allFeatures: (state) => state.features.features,
    }),
    filteredPlans() {
      let type = this.planType ? 'Yearly' : 'Monthly';
      return this.PlanDetails.filter((plan) => plan.type === type);
    },
  },
  
   
    methods: {
      ...mapActions(['GetPlanCardDetails']),
  
      ...mapMutations(["setLoading"]),
  
      dateConvert: function (value) {
        return moment(String(value)).format("DD/MM/YYYY HH:mm");
      },
  
      async planContent(){
      this.planData = await this.GetPlanCardDetails({
        currency:"INR"
      });
      this.PlanFeatures = this.planData.object.data.features;
      this.PlanDetails=this.planData.object.data.allPlans;
      this.show=true;
      },

      fetchCurrency(data) {
        if (data == 'INR') {
          return '₹';
        } else {
          return '$';
        }
      },
  
    },
  };
  </script>
  <style scoped>
  
  .table-responsive {
    height: 710px; 
    overflow-y: auto;
    display: block;  
  }
  .table-responsive thead th {
    position: sticky;
    top: 0;
    background-color: #f2f0ff; 
    z-index: 2;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }
  
  .UpgradeButton{
  border-radius: 5px !important;
  border: 1px solid #7c68ee !important;
  background: #fff !important;
  color: #7c68ee !important;
  margin: 0px 6px 0 0;
  width: 100%;
  height: 35px;
  font-size: 14px;
  }
  .UpgradeButton:hover{
  background: #7c68ee !important;
  color: #ffffff !important;
  margin: 0px 6px 0 0;
  }
  
  .planheading{
  color: #7c68ee; font-size:25px; font-weight: 600;display: block
  }
  
  .custom-toggle{
  padding: 10px 25px;
    font-size: 20px;
  }
  
  .custom-toggle:checked{
  background-color: #7c68ee!important; 
  
  }
  .table thead th {
    border-top: 0;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: #7987a1;
}
  
  </style>
  

   