<template>
  <section class="container-fluid login_page_section login-img">
    <div class="row align-items-center">
      <div class="col-md-6 login_left_section">
        <div class="text-center">
          <img src="../assets/Images/login_left_img.png" alt="" />
        </div>
      </div>
      <div class="col-md-6 login_right_section">
        <div class="logo_section mx-3">
          <img src="../assets/Images/logo.png" alt="" width="" />
        </div>
        <div style="clear: both"></div>

        <div class="row justify-content-center"  style=" padding-right: var(--bs-gutter-x, 0.75rem); padding-left: var(--bs-gutter-x, 0.75rem);">
          <div class="col-xl-8 col-lg-10 col-md-11">
            <div class="login_page_heading">
              <h3 class="font-weight-800">Glad to see you again!</h3>
              <p>Hire the right personality and skilled candidate</p>
            </div>
            <form action="">
              <div class="form-group mt-5">
                <div class="form-group mb-3">
                  <label class="input-hero" for="email">
                    <input
                      class="input_area"
                      type="email"
                      required
                      v-model="email"
                      v-on:keyup.enter="submit"
                    />
                    <span class="input-name">Email</span>
                  </label>
                </div>
              </div>

              <div class="form-group mt-4">
                <label class="input-hero" for="password">
                  <input
                    v-bind:type="[toggle ? 'text' : 'password']"
                    class="input_area"
                    id="password"
                    required
                    v-model="password"
                    v-on:keyup.enter="submit"
                  />
                  <span class="input-name"> Password </span>
                  <span class="toggle-password" @click="toggle = !toggle">
                    <i
                      class="far fa-eye"
                      :class="[toggle ? 'fa-eye' : 'fa-eye-slash']"
                      aria-hidden="true"
                    ></i>
                  </span>
                </label>
              </div>
             <!-- <div class="mt-4" >
              <div style="clear: both"></div>
              <div class="form-group ">
                <input
                  type="checkbox"
                  name=""
                  id="Remember_checkbox"
                  class="input_area form-check-input me-1 border-dark"
                  value="lsRememberMe"
                  @change="isRemember($event)"
                  :checked="booleanValue"
                />
                <label for="Remember_checkbox">Remember me</label>
              </div>
              <div class="float-end forgot_password_link" style="color: #7b68ee;">
                <router-link :to="{ name: 'ResetPassword' }">Forgot Password?</router-link>
              </div>
             </div> -->
             <div class="mt-4 d-flex justify-content-between align-items-center">
  <div class="form-group d-flex align-items-center">
    <input
      type="checkbox"
      name=""
      id="Remember_checkbox"
      class="input_area form-check-input me-1 border-dark"
      value="lsRememberMe"
      @change="isRemember($event)"
      :checked="booleanValue"
    />
    <label for="Remember_checkbox">Remember me</label>
  </div>
  <div class="forgot_password_link" style="color: #7b68ee;">
    <router-link :to="{ name: 'ResetPassword' }">Forgot Password?</router-link>
  </div>
</div>

              <!-- <div class="my-5 login_button_area">
               
                <button class="btn btn-primary" type="button" @click="submit">
                  Login
                </button>
              </div> -->
              <div class="mt-5 login_button_area mb-6">
                <div class="row">
                 <div class="col-12  ">
                  <button class="btn btn-primary" @click="submit" type="button" style="width: 100%;">
                  Login
                </button>
              </div> 
                </div>
                <span class="float-end mt-4">Don’t have an account? <router-link :to="{ name: 'Signup' }">Sign up</router-link></span>

              </div>

            </form>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { validateEmail } from "@/utils";
import { getAuthUserData } from "@/utils/auth";
import { superadmin } from "@/utils/helper.js";
import { encryptData} from '@/platformFeatures/security'
// import { calculateRemainingDays } from '../platformFeatures/dateUtils';


export default {
  name: "login",
  data() {
    return {
      toggle: false,
      planCode:''
    };
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
    }),
  },
  created() {
    if (localStorage.rmCheck && localStorage.rmCheck !== "") {
      this.booleanValue = true;
      this.email = localStorage.username;
      this.password = localStorage.password;
    } else {
      this.booleanValue = false;
      this.email = "";
      this.password = "";
    }
  },
  methods: {
    ...mapActions([
      "login",
      "GetAccessToken",
      "verifyUser",
      "ShowWarningMessage",
      "GetBillingDetails",
    ]),
    superadmin,
    ...mapMutations(["setLoading"]),

    //     mounted() {
    //     window.$cookie.set('cookie_name', userName, expiringTime);
    // },

    async submit() {
      if (!validateEmail(this.email)) {
        return this.ShowWarningMessage(
          "Please enter valid email address." // and Password."
        );
      }
      if (!this.password) {
        return this.ShowWarningMessage("Please enter password");
      }
      var userAuth = getAuthUserData();

      if (userAuth != null) {
        // this.ShowWarningMessage("The user is already Logged in.");
        setTimeout(() => {
          location.reload();
        }, 1000);
      } else {
          await this.login({ email: this.email, password: this.password });
        if (this.user != null && Object.keys(this.user).length !== 0) {
          if (!this.user.termsAndConditionsAccepted) {
            await this.GetAccessToken({ userId: this.user.userId });
            this.$router.push({
              name: "TermsPop",
            });
          } else if (!this.user.passwordSet) {
            await this.GetAccessToken({ userId: this.user.userId });
            this.$router.push({
              name: "SetPassword",
            });
          } else if (this.user.twoFactorRequired) {
            this.$router.push({
              name: "OtpCode",
            });
          } else if (!this.user.twoFactorRequired) {
            await this.GetAccessToken({ userId: this.user.userId });
                  
      if(this.user.isEnterprise==false){
      var data= await this.GetBillingDetails({
      clientId: this.user.clientId
      // clientId: 1565   
    })
    localStorage.setItem("billingData",JSON.stringify(data));

    this.planCode=data.object.data.plan.planCode;
    }
    // planCode
    if(this.planCode!=''){
      // const planCode= this.user.plan.planCode;
      const secretKey = '7aB&2kL#d$!Pz%9wQxR5tNvYbEgUfTsX'; 
      // const encryptedData = localStorage.getItem('featuresData');
      // if(!encryptedData){
        const data = await this.$store.dispatch('fetchFeatures', this.planCode);
        const plandata = data.data.object.data;    
        if (data.status === 200) {
          const encryptedData = encryptData(plandata, secretKey);
          localStorage.setItem("featuresData", encryptedData);
        // } 
      }
    }

   
    // if(this.user.subscription !=null){
    //     this.Remainingdays = calculateRemainingDays(this.billing.data.object.data.subscription.expiryDate);
    // }
   
            setTimeout(() => {

      // if(this.Remainingdays<=0 || this.Remainingdays==null )   {
        if(this.Remainingdays<0){
        this.$router.push("/billing-plan");
      }      
      else{
        this.$router.push("/dashboard-group");
      }
              // var x = localStorage.getItem("isParent");
              // if (superadmin()) {
              //     this.$router.push("/dashboard-partner");
              // }
              // else{
                // if (x == "true") {
                //   this.$router.push("/dashboard-partner");
                // } else {
                //   this.$router.push("/dashboard-group");
                // }
                // this.$router.push("/dashboard-group");
              // }
            }, 1000);
          }
        }
      }
    },

    isRemember(event) {
      if (event.target.checked) {
        localStorage.username = this.email;
        localStorage.password = this.password;
        localStorage.rmCheck = event.target.value;
      } else {
        localStorage.username = "";
        localStorage.password = "";
        localStorage.rmCheck = "";
      }
    },

    
  },
};
</script>
<style>
.btn-primary2{
    border-radius: 50px !important;
    border: 2px solid #7c68ee !important;
    background: #fff !important;
    color: #7c68ee !important;
    margin: 0px 6px 0 0;
  }
  .container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right:0rem  !important;
  padding-left: 0rem !important;
  
  margin-right: auto;
  margin-left: auto;
}
</style>

