<template>
  <div class="page-content">
    <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
      <div class="my-2">
        <h3 class="mb-2 mb-md-0">Overview</h3>
        <p class="font-size-12 overview-date-color">
          {{ this.dateData }}
        </p>
      </div>
      <div class="d-flex justify-content-between align-items-end">
        <div class="top_heading_CreatePartner_btn d-block" data-bs-toggle="modal" data-bs-target="#choose-Date">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                <img src="../../public/dashboard-assets/images/M2/date-icon.png" @click="opencal" />
                <input type="text" name="daterange" hidden="true" id="calendar_icon" class="fade" />
              </button>
            </div>
          </div>
        </div>
        <!-- <div class="top_heading_CreatePartner_btn d-block mx-2" data-bs-toggle="modal"
          data-bs-target="#ConsolidatedSheet">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="line-height: 0.3">
                <img src="../../public/dashboard-assets/images/M2/download-icon.png" />
              </button>
            </div>
          </div>
        </div> -->

        <div class="partner_item_action ms-2">
          <div class="dropdown group_partenr_dropdown_button">
            <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              My Group
              <i class="fa-solid fa-chevron-down ms-4"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" v-if="isParent=='true'">
              <li>
                <router-link :to="{ name: 'DashboardPartner' }" class="w-100 btn text-start">
                  Partner
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row flex-grow-1 Overview_dashborad_box_section mt-5">
      <!-- <div
        class="col-md-6 col-6 col-lg-4 col-xl-3 mb-3 m-auto stretch-card Overview_dashborad_box_item"
       >
        <div class="card">
          <div class="card-body p-0">
            <img
              src="../../public/dashboard-assets/images/M2/Partners.png"
              class="Overview_dashborad_box_item_icon"
            />
            <div class="ms-2">
              <p class="mt-3 mb-2 overview-date-color">
                Partners
                <img
                  src="../../public/dashboard-assets/images/M2/Actions.png"
                  alt=""
                />
              </p>
              <h2>{{ cardsdata.partnersCount }}</h2>
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-md-4 col-6 col-lg-4 col-xl-4 mb-3 stretch-card Overview_dashborad_box_item">
        <div class="card">
          <div class="card-body p-0">
            <img src="../../public/dashboard-assets/images/M2/Groups.png" class="Overview_dashborad_box_item_icon" />
            <div class="ms-2">
              <p class="mt-3 mb-2 overview-date-color">
                Groups
                <img src="../../public/dashboard-assets/images/M2/Actions.png" alt="" />
              </p>
              <h2>{{ cardsdata.groupsCount }}</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-6 col-lg-4 col-xl-4 mb-3 stretch-card Overview_dashborad_box_item">
        <div class="card">
          <div class="card-body p-0">
            <img src="../../public/dashboard-assets/images/M2/Users.png" class="Overview_dashborad_box_item_icon" />
            <div class="ms-2">
              <p class="mt-3 mb-2 overview-date-color">
                Users
                <img src="../../public/dashboard-assets/images/M2/Actions.png" alt="" />
              </p>
              <h2>{{ cardsdata.userCount }}</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-6 col-lg-4 col-xl-4 mb-3 stretch-card Overview_dashborad_box_item">
        <div class="card">
          <div class="card-body p-0">
            <img src="../../public/dashboard-assets/images/M2/token1.png" class="Overview_dashborad_box_item_icon" />
            <div class="ms-2">
              <p class="mt-3 mb-2 overview-date-color">
                Tokens
                <img src="../../public/dashboard-assets/images/M2/Actions.png" alt="" />
              </p>
              <h2>{{ cardsdata.availableTestCount }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Overview_dashborad_map_section my-4">
      <div class="card p-4">
        <div class="card-body p-0">
          <div class="d-flex justify-content-between align-items-center">
            <div class="">
              <h3 class="mb-2 mb-md-0">
                Test Activity
                <img src="../../public/dashboard-assets/images/M2/Actions.png" alt="" />
              </h3>
              <p class="font-size-12 mt-1">
                Total test used: {{ totalStackgroup }}
              </p>
            </div>

            <!-- <div class="partner_item_action">
              <div class="dropdown group_partenr_dropdown_button">
                <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  2017
                  <i class="fa-solid fa-chevron-down ms-4"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <button type="button" class="btn w-100 text-start">
                      2015
                    </button>
                  </li>
                  <li>
                    <button type="button" class="btn w-100 text-start">
                      2016
                    </button>
                  </li>
                  <li>
                    <button type="button" class="btn w-100 text-start">
                      2017
                    </button>
                  </li>
                  <li>
                    <button type="button" class="btn w-100 text-start">
                      2018
                    </button>
                  </li>
                  <li>
                    <button type="button" class="btn w-100 text-start">
                      2019
                    </button>
                  </li>
                  <li>
                    <button type="button" class="btn w-100 text-start">
                      2020
                    </button>
                  </li>
                </ul>
              </div>
            </div> -->
          </div>
          <div class="Overview_dashborad_map_img mt-5" v-if="this.month.length > 0">
            <canvas id="myChart"></canvas>
          </div>
          <div class="Overview_dashborad_map_img mt-5" v-else>
            No Record Found
          </div>
        </div>
      </div>
    </div>

    <pieChart :mode="this.mode" :from="this.fromDate" :to="this.toDate" v-if="
      this.fromDate ==
      this.dateformat(
        new Date(new Date().setMonth(new Date().getMonth() - 6))
      ) && this.toDate == this.todateformat(new Date(new Date()))
    " />
    <pieChart :mode="this.mode" :from="this.fromDate" :to="this.toDate" v-else />

    <!-- Welcome Modal -->
    <div class="modal fade" id="WelcomePopupModal" tabindex="-1" aria-labelledby="WelcomePopupModalLabel"
      aria-hidden="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-4 text-center">
            <h3 class="text-center mb-3">
              Welcome to PMaps, {{ this.user?.firstName }}
            </h3>

            <p class="text-muted text-justify" style="font-size: 13px">
              Sends assessment to candidates and access to real-time reports on
              their performance and suitability.
            </p>

            <div class="loadPopupImage my-4">
              <img src="../../public/dashboard-assets/images/Welcome_ill_img.png" class="loadPopupImage_img" alt="" />
            </div>

            <button class="btn btn-primary w-100" @click="hideWelcome" aria-label="btn-close">
              Get started
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
import { mapState, mapActions } from "vuex";
import pieChart from "./pieChart.vue";
import { superadmin } from "../utils/helper";
import moment from "moment";
export default {
  name: "dashboard-Group",
  data() {
    return {
      lastLoggedInDate: "",
      cardsdata: "",
      opencals: false,
      fromDate: this.dateformat(
        new Date(new Date().setMonth(new Date().getMonth() - 6))
      ),
      toDate: this.todateformat(new Date(new Date())),
      mode: "Group",
      month: [],
      graphData: [],
      dateData: "",
      myChart: {},
      stackedchart: [],
      totalStackgroup: 0,
      screenWidth: 0,
      colors: ["#4B2EEE",
        "#FD71AE",
        "#7C68EE"],
        isParent : localStorage.getItem("isParent")       
        /*bgColor:[
    "#7b68ee","#6f5ed6","#6253be","#5649a7","#b0a4f5","#bdb4f7","#cac3f8","#d7d2fa","#e5e1fc","#f2f0fd",
    "#49ccf9","#42b8e0","#3aa3c7","#338fae","#92e0fb","#a4e6fc","#b6ebfd","#c8f0fd","#dbf5fe","#edfafe",
    "#fd71af","#e4669e","#ca5a8c","#b14f7a","#feaacf","#feb8d7","#fec6df","#fed4e7","#ffe3ef","#fff1f7",
    "#ffc800","#e6b400","#cca000","#b38c00","#ffde66","#ffe480","#ffe999","#ffefb3","#fff4cc","#fffae6",
    "#645cbb","#5a53a8","#504a96","#464083","#a29dd6","#b2aedd","#c1bee4","#d1ceeb","#e0def1","#f0eff8",
    "#ebc7e6","#d4b3cf","#bc9fb8","#a58ba1","#f3ddf0","#f5e3f3","#f7e9f5","#f9eef8","#fbf4fa","#fdf9fd",
    "#3f0071","#521a7f","#65338d","#794d9c","#8c66aa","#9f80b8","#b299c6","#c5b3d4","#d9cce3","#ece6f1",
    "#ff597b","#e6506f","#cc4762","#b33e56","#ff9bb0","#ffacbd","#ffbdca","#ffcdd7","#ffdee5","#ffeef2",
    "#0081b4","#0074a2","#6790","#005a7e","#66b3d2","#80c0da","#99cde1","#b3d9e9","#cce6f0","#e6f2f8",
    "#ff8b13","#e67d11","#cc6f0f","#b3610d","#ffb971","#ffc589","#ffd1a1","#ffdcb8","#ffe8d0","#fff3e7",
    "#eb455f","#ed586f","#ef6a7f","#f17d8f","#f38f9f","#f5a2af","#f7b5bf","#f9c7cf","#fbdadf","#fdecef"
  ],
  companyColor:[]*/
    };
  },
  components: {
    pieChart,
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      userId: (state) => state.auth.user.userId,
      clientId: (state) => state.auth.user.clientId,
      Date: (state) => state.auth.user.lastLoggedInDate,
    }),
  },
  created() {
    // window.addEventListener("resize", this.myEventHandler);
    this.screenWidth = window.outerWidth;
  },
  methods: {
    ...mapActions([
      "GetAccessToken",
      "Dashover",
      "Graph",
      "ShowWarningMessage",
    ]),
    superadmin,
    myEventHandler(e) {
      this.screenWidth = window.outerWidth;
      this.myChart.destroy();
      this.Graphical(this.fromDate, this.toDate);
      if (this.screenWidth < 400) {
        this.myChart.config._config.options.scales.y.font.size = 5;
        this.myChart.config._config.options.scales.x.font.size = 9;
      }

      e.preventDefault();
    },
    hideWelcome(){
      window.$("div").removeClass("modal-backdrop");
      window.$("#WelcomePopupModal").modal("hide")
    },
    random() {
      return Math.floor(Math.random() * this.colors.length);
      // return Math.floor(Math.random() * this.bgColor.length);
    },
    // random(idx,name) {
    //   this.companyColor[idx]={
    //     label:name,
    //     color:this.bgColor[idx]
    //   }
    //   return this.bgColor[idx]
    // },
    dateformat: (val) => {
      return moment(String(val)).format("YYYY-MM-DD 00:00:00");
    },
    todateformat: (val) => {
      return moment(String(val)).format("YYYY-MM-DD 23:59:59");
    },
    opencal() {
      document.getElementById("calendar_icon").click();
      this.showw = true;
    },

    async Graphical(from, to) {
      var stackedbar = await this.Graph({
        clientId: this.clientId,
        mode: "Group",
        From: from,
        To: to,
      });
      this.stackedchart = stackedbar.object.data;
      this.stackedchart.forEach((element) => {
        element.testActivity.forEach((elm) => {
          if (this.month != []) {
            var y = this.month.filter((x) => x == elm.monthName);
            if (y.length == 0) {
              this.month.push(elm.monthName);
            }
          } else {
            this.month.push(elm.monthName);
          }

        });
      });
      this.month = Object.freeze(JSON.parse(JSON.stringify(this.month)));
      const graphData = [];
      // this.stackedchart.forEach((element,index) => {
        this.stackedchart.forEach((element) => {
        const eleArr = [];
        element.testActivity.forEach((elm) => {
          var idx = this.month.findIndex(x => x == elm.monthName);
          eleArr[idx] = elm.assessmentCount;
        });
        graphData.push({
          label: element.name,
          data: eleArr,
          barPercentage: 0.5,
          maxBarThickness: 50,
          // backgroundColor: [
          //   "#4B2EEE",
          //   "#FD71AE",
          //   "#7C68EE",
            // "rgb(" +
            // Math.floor(Math.random() * 256) +
            // "," +
            // Math.floor(Math.random() * 256) +
            // "," +
            // Math.floor(Math.random() * 256) +
            // ")",
          // ],
          // backgroundColor: this.colors[this.random()]
          // backgroundColor: this.bgColor[this.random()]
          // backgroundColor:this.random(index,element.name)
          backgroundColor:element.colorCode
        });
        var total = 0;
        graphData.forEach((element) => {
          element.data.forEach((elm) => {
            total = total + elm;
          });
        });
        this.totalStackgroup = total;
        // this.$store.state.auth.color = this.companyColor;
      });
      setTimeout(() => {
        var ctx = document.getElementById("myChart").getContext("2d");
        if (this.screenWidth < 400) {
          ctx.canvas.height = 250;
        } else {
          ctx.canvas.height = 100;
        }
        this.myChart = new Chart(ctx, {
          type: "bar",
          data: {
            labels: this.month,
            datasets: graphData,
          },

          options: {
            responsive: true,
            maintainAspectRatio: true,
            scales: {
              x: {
                ticks: {
                  font: function (context) {
                    var width = context.chart.width;
                    var size;
                    if (width < 400) {
                      size = Math.round(width / 40);
                    } else {
                      size = 12;
                    }
                    return {
                      size: size,
                    };
                  },
                },
                stacked: true,
                title: {
                  display: true,
                  text: "Months",
                  font: function (context) {
                    var width = context.chart.width;
                    var size;
                    if (width < 400) {
                      size = Math.round(width / 40);
                    } else {
                      size = 12;
                    }
                    return {
                      size: size,
                    };
                  },
                },
              },
              y: {
                stacked: true,
                ticks: {
                  min: 0,
                  stepSize: 500,
                },
                title: {
                  display: true,
                  text: "Number of candidate",
                  font: function (context) {
                    var width = context.chart.width;
                    var size;
                    if (width < 400) {
                      size = Math.round(width / 40);
                    } else {
                      size = 12;
                    }
                    return {
                      size: size,
                    };
                  },
                },
              },
            },
            plugins: {
              legend: {
                display: true,
                position: "bottom",
                labels: {
                  padding: 20,
                  font: function (context) {
                    var width = context.chart.width;
                    var size;
                    if (width < 400) {
                      size = Math.round(width / 50);
                    } else {
                      size = 12;
                    }
                    return {
                      size: size,
                    };
                  },
                },
              },
            },
          },
        });
      }, 100);
    },
  },

  async mounted() {
    setTimeout(async () => {
      // if (!superadmin()) {
      //   var isParent = localStorage.getItem("isParent");
      //   if (isParent == "true") {
      //     this.$router.push("/dashboard-partner");
      //   }
      // }
      await this.Graphical(this.fromDate, this.toDate);
    }, 1000);
    this.dateData =
      moment(String(this.fromDate)).format("YYYY MMM DD") +
      " - " +
      moment(String(this.toDate)).format("YYYY MMM DD");
    this.screenWidth = window.outerWidth;

    

    window.$('input[name="daterange"]').daterangepicker(
      {
        maxDate: new Date(),
        locale: { cancelLabel: "Clear" },
        opens: "center",
      },
      async (start, end) => {
        if (start && end) {
          var x = start.format("YYYY MMM DD");
          var y = end.format("YYYY MMM DD");
          this.fromDate = this.dateformat(x);
          this.toDate = this.todateformat(y);
          this.showw = false;
        }
      }
    );
    window
      .$('input[name="daterange"]')
      .on("cancel.daterangepicker",async (ev, picker)=>{
        picker.startDate._d = new Date();
        picker.endDate._d = new Date();
        this.fromDate = this.dateformat(
        new Date(new Date().setMonth(new Date().getMonth() - 6))
      ),
      this.toDate = this.todateformat(new Date(new Date())),
        this.myChart.destroy();
        this.month = [];
        await this.Graphical(this.fromDate, this.toDate);
        window.$('input[name="daterange"]').val("");
      });
    window
      .$('input[name="daterange"]')
      .on("apply.daterangepicker",async(ev, picker)=>{
        var x = moment(String(picker.startDate._d)).format("YYYY MMM DD");
        var y = moment(String(picker.endDate._d)).format("YYYY MMM DD");
        var start = moment(String(picker.startDate._d)).format("YYYY-MM-DD 00:00:00")
        var end = moment(String(picker.endDate._d)).format("YYYY-MM-DD 00:00:00")
        if (
          Math.ceil(
            Math.abs(new Date(start) - new Date(end)) /
            (1000 * 60 * 60 * 24)
          ) <=
          6 * 30
        ) {
          this.dateData = x + " - " + y;
          if (this.opencals == false) {
            this.myChart.destroy();
            this.month = [];
            await this.Graphical(start, end);
          }
        } else {
          return this.ShowWarningMessage("Please select range within 6 months");
        }

      });
    if (this.user != null && Object.keys(this.user).length !== 0) {
      if (!this.user.termsAndConditionsAccepted) {
        await this.GetAccessToken({ userId: this.user.userId });
        this.$router.push({
          name: "TermsPop",
        });
      } else if (!this.user.passwordSet) {
        await this.GetAccessToken({ userId: this.user.userId });
        this.$router.push({
          name: "SetPassword",
        });
      } else {
        window.$("#WelcomePopupModal").modal("hide");
        this.lastLoggedInDate = this.Date;
        if (this.lastLoggedInDate === null)
          window.$("#WelcomePopupModal").modal("show");
      }
      setTimeout(async () => {
        var cards = await this.Dashover({
          clientId: this.clientId,
          mode: "Group",
        });
        this.cardsdata = cards.object.data;
      }, 500);
    }
  },
};
</script>
<style>

</style>
