<template>
  <section class="container-fuild">
    <div class="container">
      <div>
        <div class="termsPop-Logo mt-5">
          <router-link :to="{ name: 'DashboardGroup' }" class="dropdown-item">
            <img src="/assets/images/logo.png" />
          </router-link>
        </div>
        <h1 class="text-center mb-3">Privacy Policy</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro, autem
          adipisci tenetur asperiores ipsa nulla quos impedit quas nihil error
          ullam, quis explicabo ducimus. Nesciunt voluptatum dolores culpa
          aliquid harum. Ut iusto excepturi saepe esse corporis quae
          voluptatibus veritatis adipisci dicta! Saepe, velit. Quia, aliquid
          tenetur voluptas accusantium inventore blanditiis? Accusamus provident
          alias sint, ex quaerat repellendus. Molestias, atque quis. Consectetur
          in deleniti rem aperiam harum, quisquam numquam hic labore dolores
          distinctio provident corrupti maxime, vel dignissimos odio
          consequuntur pariatur eum expedita at! Nihil vitae itaque autem,
          debitis incidunt perspiciatis! Praesentium, dignissimos nihil. At sed
          quo dolorem adipisci nobis! Vero aut quae obcaecati officia! Vel
          officia amet fuga iste consequatur tempore suscipit ea eveniet
          expedita, sint consectetur magni rem. Eligendi. Dicta inventore soluta
          natus voluptatibus cupiditate repellendus earum fuga odio alias
          facilis? Vero iste neque, accusamus corporis possimus quidem harum ad
          sint! Nisi laudantium consequuntur mollitia laborum sint, blanditiis
          quasi. Blanditiis, ut natus a, suscipit, nesciunt harum saepe vel
          numquam nobis maiores repellat excepturi voluptatum eveniet illum.
          Illo eveniet quos aspernatur optio, quibusdam mollitia vero, ipsa
          ratione possimus odit excepturi! Omnis laborum vitae reiciendis. Totam
          consequatur qui nemo reprehenderit. Temporibus nihil quasi recusandae
          ipsum excepturi earum tenetur quo quia illum, possimus minus illo
          ratione alias odio fugiat optio ex voluptatem. Eaque voluptate nihil
          consequuntur facere fugiat? Tempora molestiae est consequatur libero
          distinctio, assumenda omnis accusantium. Ut, eius et deleniti suscipit
          dolore eaque ipsam nam quae at error, corporis, necessitatibus
          dignissimos. Labore blanditiis nisi totam molestias aperiam
          consequuntur, dolore voluptatem sit. Maiores nemo quis ea
          exercitationem harum doloribus consequuntur natus quasi, tempore sequi
          facere explicabo, molestiae expedita soluta unde dolore asperiores.
          Provident autem necessitatibus quae perferendis tempore cupiditate
          asperiores iusto mollitia delectus aliquam eligendi est excepturi
          molestias quos maxime at nihil dolorum placeat suscipit, fuga vitae
          tempora esse assumenda! Cum, iusto. Nihil deserunt sed laborum
          eligendi consequatur enim dolor optio magni itaque ratione repudiandae
          delectus provident deleniti, quas alias pariatur, adipisci ipsam
          veniam eius aspernatur? Est eligendi quidem ipsum illum optio?
          Praesentium nulla cum labore distinctio maiores. Maiores ipsa animi
          quam soluta, libero sequi deserunt sit illo nulla ad cum laborum quasi
          consequatur, obcaecati ab assumenda? Repudiandae quod non quos!
          Aperiam? Autem obcaecati nam, quas libero itaque in corporis
          exercitationem facere aliquid ea maiores numquam repellendus, totam
          minima ducimus soluta tenetur repudiandae reiciendis maxime.
        </p>
        <div class="termsPop-content"></div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "PrivacyPolicy",
};
</script>
<style lang="css">
@import url("/assets/css/style.css");
@import url("/assets/css/responsive.css");
</style>
