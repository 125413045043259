<template>
  <div>
    <slot :current="current" />
  </div>
</template>

<script>
export default {
  props: {
    expected: [String, Number],
    result: [String, Number],
  },
  computed: {
    current() {
      return this.expected === this.result;
    },
  },
};
</script>
