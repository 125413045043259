<template>
  <div class="page-content">
    <!-- mobile view Searching -->
    <!-- <div class="Searching_input_box_mobile_view mb-3 position-relative">
      <div class="w-100" v-if="this.show">
        <input
          type="text"
          v-model="input"
          @keyup="filteredList"
          placeholder="Search..."
        />
          <div class="search_detail_list" v-if="this.result.length > 0">
          <div
            v-for="(item, index) in this.result"
            :key="index"
            class="search_detail_list_item"
          >
            <span :id="index" @click="searchregionname(item)">
              {{ stringTOHtml(item, index) }}
            </span>
          </div>
        </div>
        <img src="../../public/dashboard-assets/images/M2/Close_icon.png" @click="closemobilesearch" alt=""
          class="close-icon" />
      </div>
    </div> -->

    <div class="
        d-flex
        justify-content-between
        align-items-center
        flex-wrap
        mb-2
        header-MV-kj
        d-none d-md-flex
      ">
      <div class="my-2 d-flex">
        <div class="top_heading_CreatePartner_btn d-block me-2">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <router-link :to="{ name: 'ManagementPartnersAssignTeam' ,query:{offset:this.$route.params.offset}}" class="btn p-0 Group_top_three_dot_dropdown"
                type="button" style="line-height: 2.6">
                <i class="fa-solid fa-chevron-left"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div>
          <h4 class="mb-2 mb-md-0 page-heading">{{ this.regionname }}</h4>
          <h6 class="text-muted">Total Partners ({{ this.lst.length }})</h6>
        </div>
      </div>
      <!-- Search  web view-->
      <!-- <div class="top_heading_CreatePartner_btn position-relative">
        <div class="Group_top_three_dot">
          <div class="Searching_input-box">
            <input
              type="text"
              v-model="input"
              @keyup="filteredList"
              placeholder="Search..."
            />
              <div class="search_detail_list" v-if="this.result.length > 0">
              <div
                v-for="(item, index) in this.result"
                :key="index"
                class="search_detail_list_item"
              >
                <span :id="index" @click="searchregionname(item)">
                  {{ stringTOHtml(item, index) }}
                </span>
              </div>
            </div>
            <span class="icon Group_top_three_dot_dropdown" @click="opensearch">
              <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" class="Searching_search_icon" />
            </span>
            <img src="../../public/dashboard-assets/images/M2/Close_icon.png" @click="closesearch" alt=""
              class="close-icon" />
          </div>
        </div>
      </div> -->
    </div>
    <!-- </div>
        </div> -->
    <div class="
        header-2-MV-kj
        justify-content-between
        align-items-center
        flex-wrap
        mb-2
      "
    >
      <div class="d-flex justify-content-between my-2">
        <div class="d-flex">
          <div class="top_heading_CreatePartner_btn d-block me-2">
            <div class="Group_top_three_dot">
              <div class="dropdown">
                <router-link :to="{ name: 'ManagementPartnersAssignTeam' }" class="btn p-0 Group_top_three_dot_dropdown"
                  type="button">
                  <i class="fa-solid fa-chevron-left"></i>
                </router-link>
              </div>
            </div>
          </div>
          <div>
            <h4 class="mb-2 mb-md-0">Assigned Groups</h4>
            <nav style="--bs-breadcrumb-divider: '>' " aria-label="breadcrumb :">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'ManagementPartners' }" style="color: #7987a1 !important">{{ this.regionname
                  }}</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Total Members ({{ this.lst.length }})
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <!-- Mobile View Searching Button -->
        <div
          class="
            top_heading_CreatePartner_btn
            position-relative
            Searching_button_mobileView
          "
        >
          <div class="Group_top_three_dot">
             <!-- Search  web view-->
      <div class="top_heading_CreatePartner_btn position-relative">
        <div class="Group_top_three_dot">
          <div class="Searching_input-box">
            <input type="text" v-model="input" @keyup="filteredList" placeholder="Search..." />
            <div v-for="(item, index) in this.result" :key="index">
              <span :id="index">{{ stringTOHtml(item, index) }}</span>
            </div>
            <span class="icon Group_top_three_dot_dropdown" @click="opensearch">
              <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" class="Searching_search_icon" />
            </span>
            <img src="../../public/dashboard-assets/images/M2/Close_icon.png" @click="closesearch" alt=""
              class="close-icon" />
          </div>
        </div>
      </div>

            <!-- <div class="
          top_heading_CreatePartner_btn
          position-relative
          Searching_button_mobileView
        ">
              <div class="Group_top_three_dot">
                <span class="icon Group_top_three_dot_dropdown"
                  :style="{ display: this.show == true ? 'none' : 'block' }" @click="openmobilesearch">
                  <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt=""
                    class="Searching_search_icon" />
                </span>
              </div>
            </div> -->
            <!-- <div class="dropdown">
              <button
                class="btn p-0 Group_top_three_dot_dropdown"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style="line-height: 0.3"
              >
                <img
                  src="../../public/dashboard-assets/images/M2/Icon-Set.png"
                  alt=""
                  width="50%"
                />
              </button>
            </div> -->
          </div>
        </div>
      </div>

      <!-- </div> -->
    </div>

    <div class="row">
      <div class="col-md-12 teble_heading_section_hp">
        <div class="card p-0 mb-3" style="background: #f1f0ff; box-shadow: none !important">
          <div class="card-body card-body-MV-kj">
            <div class="row">
              <div class="col-4 col-md-3 col-lg-3 teble_heading_item_hp">
                Partner
              </div>
              <div class="
                  col-4 col-md-3 col-lg-3
                  teble_heading_item_hp
                  AssignTeam-text-none
                ">
                Partner <span class="AssignTeam-text-none">Team</span>
              </div>
              <div class="col-4 col-md-3 col-lg-3 teble_heading_item_hp">
                Date <span class="AssignTeam-text-none">of Assigned</span>
              </div>
              <div class="col-4 col-md-3 col-lg-3 teble_heading_item_hp">
                Action
              </div>
            </div>
          </div>
        </div>

        <div class="card p-0 mt-1" v-for="item in this.lst" :key="item">
          <div class="card-body card-body-MV-kj p-3">
            <div class="row">
              <div class="col-12 col-md-3 col-lg-3">
                <span class="text-demo" style="font-weight: 700">
                  {{ item.partnerCompany }}
                </span>
              </div>
              <div class="col-4 col-md-3 col-lg-3">
                <span class="text-demo1 text-muted">
                  {{ item.partnerRegionName }}</span>
              </div>
              <div class="col-4 col-md-3 col-lg-3">
                <span class="text-demo1 text-muted">{{ dateConvert(item.createdDate) }}
                </span>
              </div>
              <div class="col-4 col-md-3 col-lg-3">
                <span class="text-demo1 text-muted">
                  <a href="" data-bs-toggle="modal" data-bs-target="#DeactivatePartnerModal" style="color: #666666"
                    @click="RemoveFunction(item.id, item.partnerCompany)"><i class="fa-solid fa-xmark"></i>
                    Remove</a></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-end mt-3">
      <!-- DeletePartnerModal -->
      <div class="modal fade" id="DeactivatePartnerModal"  tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-body p-4">
              <div class="">
                <h3 class="mb-3">Remove Partner?</h3>
                <p class="text-muted">
                  Are you sure you want to Remove
                  <strong style="color: rgb(0, 0, 0);">{{ partnerName }} </strong> Partner.
                </p>
              </div>
              <div class="Deactivate_buttons row mt-5 justify-content-center">
                <button data-bs-dismiss="modal" id="close" class="col-5 Deactivate_Cancel_buttons" @click="CancelDeletePopup">
                  Cancel
                </button>
                <button type="button" class="col-5 btn Deactivate_Deactivate_buttons" @click="confirmdeletePartner()"
                  data-bs-target="#DeactivatePartnerModal">
                  Remove Partner
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
export default {
  name: "AssignedTeamDetails",
  data() {
    return {
      lst: [],
      grpid: "",
      assignedgrpsList: [],
      grpsList: [],
      groups: [],
      partners: [],
      offset: 1,
      limit: 5,
      regionname: "",
      partnerName: '',
      show:false,
      result:[],
    };
  },
  async mounted() {
    this.grpid = window.atob(this.$route.params.grpid);
    await this.list();
    await this.getAssignedgroupList();
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      clientId: (state) => state.auth.user.clientId,
    }),
  },
  methods: {
    ...mapActions([
      "MappingTeamPartner",
      "login",
      "AllAssignedGroups",
      "DeletePartner",
    ]),

    // Searching box
    opensearch() {
      document.querySelector(".Searching_input-box").classList.add("open");
    },
    closesearch() {
      document.querySelector(".Searching_input-box").classList.remove("open");
      this.result = [];
      this.input = "";
    },
    closemobilesearch() {
      this.show = false;
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.remove("open");
    },
    openmobilesearch() {
      this.show = true;
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.add("open");
      this.result = [];
      this.input = "";
    },
    // Searching box

    dateConvert: (value) => {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    CancelDeletePopup() {
      window.$("#DeactivatePartnerModal").modal("hide");
    },
    async RemoveFunction(id, name) {
      this.partnerName = name;
      this.MappingId = id;
    },
    async confirmdeletePartner() {
      await this.DeletePartner({
        MappingId: this.MappingId,
      });
      document.getElementById("close").click();
      window.$("#DeactivatePartnerModal").modal("hide");
      await this.list();

      // await this.getAssignedgroupList();
    },
    clickCallback(pageNum) {
      this.offset = (pageNum - 1) * this.limit + 1;
      this.list();
    },
    async list() {
      var partnerlist = await this.MappingTeamPartner({
        regionId: this.grpid,
        clientId: this.clientId,
      });

      if (partnerlist.status == false) this.lst = [];
      else this.lst = partnerlist.object.data;
    },
    async getAssignedgroupList() {
      var assignedgrps = await this.AllAssignedGroups({
        clientId: this.clientId,
        offset: this.offset,
        limit: this.limit,
      });
      if (assignedgrps != undefined) {
        this.assignedgrpsList = assignedgrps.object.data.partnerRegion;
        this.grpsList = Object.freeze(
          JSON.parse(JSON.stringify(this.assignedgrpsList))
        );
        var region = this.grpsList.filter((d) => {
          return d.regionId == this.grpid;
        });
        this.regionname = region[0].regionName;
      }
    },
  },
};
</script>
<style>
.search-field {
  background-color: transparent;
  background-image: url(https://wp-themes.com/wp-content/themes/twentythirteen/images/search-icon.png);
  background-position: 5px center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  border: none;
  cursor: pointer;
  height: 40px;
  margin: 3px 0;
  padding: 0 0 0 34px;
  position: relative;
  right: 0;
  -webkit-transition: width 400ms ease, background 400ms ease;
  transition: width 400ms ease, background 400ms ease;
  width: 0px;
  cursor: pointer;
}

.search-field:focus {
  border: 2px solid #c3c0ab;
  cursor: text;
  outline: 0;
  width: 250px;
  color: #fff;
}

.search-form .search-submit {
  display: none;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: #f7f8fa !important;
}
</style>