<template>
    <!--  Invite Candidates model -->
    <div class="modal fade" id="InviteCandidates" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel" aria-hidden="true"
      data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable model-sm">
        <div class="modal-content border-radius-kj-20">
          <div class="modal-header token-model-header-color">
            <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
              Invite Candidates
            </h5>
            <button type="button" @click="closeinvite" class="btn-close" aria-label="btn-close"></button>
          </div>
          <div class="modal-body">
            <div class="row mt-3">
              <div class="col-md-6 mb-2">
                <div class="form-group Multiselect-kj position-relative">
                  <label class="input-hero" for="Group">
                    <Multiselect v-model="this.invite.from" :options="this.fromuser" mode="single" :searchable="true"
                      @select="fromemailverificationstatus" @deselect="invitedisable" ref="mul">
                    </Multiselect>
                    <span class="input-name input-group-kj">From <span class="text-danger">*</span></span>
                  </label>
                  <!-- <div class="close-btn-position" @click="clearFrom">
                    <i class="fa-solid fa-xmark"></i>
                  </div> -->
                  <div class="text-end">
                    <a class="verify-email" @click="fromemailverification" v-if="this.verify">Verify</a>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-2">
                <div class="form-group Multiselect-kj">
                  <label class="input-hero" for="Group">
                    <Multiselect v-model="this.invite.fromgroupname" :options="this.groupname" mode="single"
                      :searchable="true" @select="invitedisable" @deselect="invitedisable">
                    </Multiselect>
                    <!-- <div class="close-btn-position"
                      @click="clearGroup"
                    >
                      <i class="fa-solid fa-xmark"></i>
                    </div> -->
                    <span class="input-name input-group-kj">
                      From Group <span class="text-danger">*</span></span>
                  </label>
                </div>
              </div>
              <div class="col-md-12 mb-2">
                <div v-if="isSmsSettingEnabled !== undefined">
                  <div class="form-check my-2">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="email-radio" @click="selectInviteMode('email')" />
                    <label class="form-check-label" for="email-radio">
                      Send invitation using email address
                    </label>
                  </div>
                  <div class="form-check my-3">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="mobileno-radio" @click="selectInviteMode('sms')" />
                    <label class="form-check-label" for="mobileno-radio">
                      Send invitation using mobile number
                    </label>
                  </div>
                </div>
                <div class="form-group position-relative">
                  <div class="row">
                    <div class="col-10">
                      <label class="input-hero" for="Group">
                        <Multiselect class="multiselect__placeholder" :placeholder="isSmsSettingEnabled === undefined || isEmailSelected === 'true' ? 'Enter candidate email address' : 'Enter candidate mobile number'"
                          v-model="invite.customObj" @deselect="spliceemail" mode="tags" @select="getdata"
                          @search-change="searchmulti" @close="searchTag" :createOption="true" :searchable="true"
                          :showOptions="false" @option="checkInvite" @paste="pasteemail"
                          :addOptionOn="['enter', 'space', ';', ',', 'tab']" :clearOnSearch="true" :object="true"
                          @open="openmulti" v-on:keyup.delete="spliceCandidateData" ref="multiselect">
                          <slot name="placeholder">
                            {{ placeholder }}
                          </slot>
                        </Multiselect>
                        <span class="input-name input-group-kj">
                          To <span class="text-danger">*</span>
                        </span>
                      </label>
                      <div class="totaltoinvite" v-if="showIconsInCandidateData" @click="showallinvite">
                        <div>
                          +{{ this.totalToInviteLength }}
                        </div>
                      </div>
                      <div class="remove-candidate-invite" v-if="showIconsInCandidateData" @click="removeCandidateData()">
                        <button type="button" class="btn-close" aria-label="btn-close"></button>
                      </div>
                    </div>
                    <div class="col-1">
                      <input id="fileInput" class="file-upload-input" type="file" v-on:click="$event.target.value=''" v-on:change="handleFileUpload($event)"
                        accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                      <button type="button" class="btn btn-hover" @click="openFileInput()">
                        <i class="fa-solid fa-upload fa-lg"></i>
                      </button>
                    </div>
                    <div class="col-1">
                      <button type="button" class="btn" @click="DownloadCandidateUploadSheet()">
                        <i class="fa-solid fa-download fa-lg"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="col-md-12 mb-2">
                <div class="form-group">
                  <label class="input-hero" for="PartnerCompany">
                    <input class="input_area" type="text" required="" v-model="this.invite.subject"
                      @blur="saveemailtemplate" id="subject" @input="invitedisable" />
                    <span class="input-name">Subject <span class="text-danger">*</span></span></label>
                </div>
              </div>
  
              <div class="col-md-12 mb-2 QuillEditor-inside-dot-relative">
                <!-- three_dot -->
  
                <div class="top_heading_CreatePartner_btn d-block QuillEditor-inside-dot-fixed">
                  <div class="Group_top_three_dot">
                    <div class="dropdown">
                      <button class="btn p-0 Group_top_three_dot_dropdown inviteicon" type="button" id="dropdownMenuButton"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa-solid fa-ellipsis-vertical"></i>
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li>
                          <button type="button" class="btn w-100 text-start" @click="previewTemp">
                            Preview Template
                          </button>
                        </li>
                        <!--update-->
                        <!-- <li>
                          <button
                            type="button"
                            class="btn w-100 text-start"
                            data-bs-toggle="modal"
                            data-bs-target="#SelectTemplate"
                            @click="selectemailtemplate"
                          >
                            Select Template
                          </button>
                        </li> -->
                        <!--update-->
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="form-group mb-4 QuillEditor-kj">
                  <QuillEditor theme="snow" v-model:content="texteditor" contentType="html" :toolbar="toolbarOptions"
                    @blur="saveemailtemplate" @textChange="invitedisable" />
                </div>
              </div>
            </div>
            <div class="text-end">
              <button type="button" class="modail_submit_button text-end" :disabled="this.isDisabled"
                :class="{ active_form_button: !this.isDisabled }" @click="createcandidate">
                Send Invite
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  Invite or Remove candidates model -->
    <div class="modal fade" id="InviteCandidatesRemove" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel"
      aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm">
        <div class="modal-content border-radius-kj-20">
          <div class="modal-header token-model-header-color">
            <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
              Invite or Remove candidates
            </h5>
            <button type="button" class="btn-close" id="closeinvite" @click="closeinvite" aria-label="btn-close"></button>
          </div>
          <div class="modal-body">
            <div class="row mt-3">
              <div class="col-md-11">
                <h5>
                  Are you sure you want to send an invitation to
                  {{
                    this.sendinvitelist.withoutDuplicate.length +
                    this.sendinvitelist.Duplicate.length
                  }}
                  candidates for
                  <strong>{{ this.testname }}</strong>
                </h5>
                <!-- Added by Pmaps Team -->
                <div>
                  <input type="hidden" id="duplicate-candidates" name="duplicate-candidates" :value="checkDuplicate">
                </div>
                <!-- Added by Pmaps Team -->
                <p class="my-3 Active-model-p-kj" v-if="this.sendinvitelist.Duplicate.length > 0">
                  Note: There are
                  {{ this.sendinvitelist.Duplicate.length }} duplicate candidates.
                </p>
                <div class="form-check my-2">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="1" @click="selectcans(1)" />
                  <label class="form-check-label" for="1">
                    Send invitation without duplicate candidates
                  </label>
                </div>
                <div class="form-check my-2">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="2" @click="selectcans(2)" />
                  <label class="form-check-label" for="2">
                    Send invitation with duplicate candidates
                  </label>
                </div>
              </div>
              <!-- <p class="text-show-btn my-3" v-if="showlist" @click="(showlist = false)">
                Hide Candidates({{ this.invitesend.length }})
              </p> -->
              <p class="text-show-btn my-3" @click="(this.invitesend.length >0) ?(showlist = !showlist) : ''">
                {{ (showlist) ? 'Hide' : 'Show' }} Candidates({{ this.invitesend.length }})
              </p>
              <div v-if="showlist">
                <div class="show-Candidates">
                  <div class="d-flex my-2 border-bottom pb-2" v-for="item in this.invitesend" :key="item">
                    <div v-if="this.isEmailSelected === 'true'" class="PartnerTeamMembersProfile-area-kj">
                      <div class="PartnerTeamMembersProfile-kj name-logo-1">
                        {{ item?.emailAddress.charAt(0) }}
                      </div>
                    </div>
                    <div class="mx-2">
                      <p>{{ this.isEmailSelected === 'true' ? item?.emailAddress : item.mobileNo }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-3">
                <!-- <p class="text-show-btn" v-if="showExcludedCandidatesList" @click="displayExcludeOptions(false)">
                  Hide Excluded Candidates({{ this.sendinvitelist.excludedList.length }})
                </p> -->
                <p class="text-show-btn"  @click="this.sendinvitelist.excludedList.length > 0 ? (showlist = !showlist) : ''">
                  {{ (showlist) ? 'Hide' : 'Show' }} Excluded Candidates({{ this.sendinvitelist.excludedList.length }})
                </p>
              </div>
              <div v-if="showlist">
                <div class="show-Candidates" >
                  <div class="d-flex my-2 border-bottom pb-2" v-for="item in this.sendinvitelist.excludedList" :key="item">
                    <div v-if="this.isEmailSelected === 'true'" class="PartnerTeamMembersProfile-area-kj">
                      <div class="PartnerTeamMembersProfile-kj name-logo-1">
                        {{ item.emailAddress.charAt(0) }}
                      </div>
                    </div>
                    <div class="mx-2">
                      <p>{{ this.isEmailSelected === 'true' ? item?.emailAddress : item.mobileNo }}</p>
                    </div>
                  </div>
                </div>
               <!-- Added by Pmaps Team -->
              </div>
              <!-- Added by Pmaps Team -->
            </div>
            <div class="text-end">
              <button type="button" class="btn btn-primary btn-icon-text btn-Send-invite"
                :disabled="this.invitesend.length == 0" @click="sendinvite">
                Invite
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Select Template  -->
    <div class="modal fade" id="SelectTemplate" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
      aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm">
        <div class="modal-content border-radius-kj-20">
          <div class="modal-header token-model-header-color">
            <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
              Select Template
            </h5>
            <button type="button" class="btn-close" @click="closeinvite"></button>
          </div>
          <div class="modal-body">
            <div class="select-list-Template" v-for="(item, index) in this.selectTemplate" :key="index">
              <div class="form-check select-Template-radio">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                  @change="selectEtemplate(item, $event)" />
                <label class="form-check-label" for="flexRadioDefault1">
                  <div class="select-Template">
                    <!-- <span class="select-list-name">
                                      {{item.subjectPart}}
                                  </span> -->
                    <span class="select-list-name"> Template {{ ++index }} </span>
                  </div>
                </label>
              </div>
              <!-- <img src="../../public/dashboard-assets/images/M2/Delete.png" data-bs-toggle="modal" data-bs-target="#DeleteTemplate" class="w-24" alt=""> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Template  -->
    <div class="modal fade" id="DeleteTemplate" tabindex="-1" aria-labelledby="DeleteTemplate" aria-hidden="true"
      data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-body p-4">
            <div class="">
              <h3 class="mb-3">Delete Template</h3>
              <p class="text-muted">
                Are you sure you want to delete
                <strong class="text-dark"> Template 1 </strong>
              </p>
            </div>
            <div class="Deactivate_buttons row mt-5 justify-content-center">
              <button class="col-5 Deactivate_Cancel_buttons" @click="closeinvite">
                Cancel
              </button>
              <button type="button" class="col-5 btn Deactivate_Deactivate_buttons">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!--preview template-->
    <div class="modal fade" id="PreviewTemplate" tabindex="-1" aria-labelledby="PreviewTemplate" aria-hidden="true"
      data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-body p-4">
            <div class="modal-header token-model-header-color">
              <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
                Preview Template
              </h5>
              <button type="button" @click="closepreviewinvite" class="btn-close" aria-label="btn-close"></button>
            </div>
            <div class="preview-content"></div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Multiselect from "@vueform/multiselect";
  import { QuillEditor } from "@vueup/vue-quill";
  import "@vueup/vue-quill/dist/vue-quill.snow.css";
  import { mapState, mapActions } from "vuex";
  import { validateEmail, validatePhoneNumber } from "@/utils";
  import readXlsxFile from 'read-excel-file';
  
  export default{
    name: "InviteCandidatesNew",
    props: ["testname", "testid", "testclientid"],
    components: {
      QuillEditor,
      Multiselect
    },
    computed: {
      ...mapState({
        user: (state) => state.auth.user,
        clientId: (state) => state.auth.user.clientId,
      }),
      //Added by Pmaps team
      checkDuplicate() {
        return this.sendinvitelist.Duplicate.length > 0 ? true : false;
      },
      // showExcludedCandidatesList() {
      //   return this.hideExcludedCandidates;
      // },
      showIconsInCandidateData() {
        return this.totalToInviteLength > 0 ? true : false;
      }
      //Added by Pmaps team
    },
    async created() {
      this.isSmsSettingEnabled = await this.IsSmsInvitePreference({
        clientId: this.testclientid
      });
      if(this.isSmsSettingEnabled === undefined) {
        this.isEmailSelected = 'true'
      }
    },
    data() {
      return {
        isDisabled: true,
        invitesend: [],
        excludedCandidateList: [], //Added by Pmaps team
        filter: 0,
        groupList: [],
        groupname: [],
        selectTemplate: [],
        showlist: false,
        // hideExcludedCandidates: false, //Added by Pmaps team
        totalToInvite: [],
        totalToInviteLength: 0,
        example5: {
          mode: "tags",
          closeOnSelect: false,
          searchable: true,
          createOption: true,
          object: true,
        },
        isPaste: false,
        pasteErrorCount: 0,
        total: [],
        invite: {
          content: "",
          subject: "",
          to: [],
          from: "",
          id: "",
          fromgroupname: "",
          customObj: [],
        },
        sub: "",
        texteditor: "",
        fromuser: [],
        isSmsSettingEnabled: {},
        isEmailSelected: '',
        verify: false,
        sendinvitelist: {
          withoutDuplicate: [],
          Duplicate: [],
          excludedList: [] //Added by Pmaps team
        },
        val: "",
        toolbarOptions: [
          ["bold", "italic", "underline", "strike"],
          [
            {
              list: "ordered",
            },
            {
              list: "bullet",
            },
          ],
          [
            {
              script: "sub",
            },
            {
              script: "super",
            },
          ],
          [
            {
              indent: "-1",
            },
            {
              indent: "+1",
            },
          ],
  
          [
            {
              color: [],
            },
            {
              background: [],
            },
          ],
          ["image", "link"],
        ],
        pstmail: "",
        x: "",
        cpyPst: "",
      };
    },
    async mounted() {
      window.$("#InviteCandidates").modal("show");
      const candidatedata = await this.getInviteCandidate({
        testId: this.testid,
      });
      this.texteditor = candidatedata.object.data.contentData;
      var p = document.querySelector(".ql-editor");
      p.innerHTML = this.texteditor;
      this.invite.subject = candidatedata.object.data.subjectline
        ? candidatedata.object.data.subjectline
        : "";
      const fromemail = await this.invitefromemail({
        userId: this.user.userId,
      });
      this.fromuser = fromemail.object.data;
      var group;
      if (this.clientId == 1001) {
        group = await this.adminregionList({
          userId: this.testclientid,
        });
        this.groupList = group.object.data.regions;
      } else {
        group = await this.UserRegionData({
          userId: this.user.userId,
        });
        this.groupList = group.object.data;
      }
  
      this.groupList.forEach((element) => {
        if (element.isActive) this.groupname.push(element.regionName);
      });
    },
  
    methods: {
      ...mapActions([
        "UserRegionData",
        "invitefromemail",
        "ShowWarningMessage",
        "ShowErrorMessage",
        "emailverifystatus",
        "emailverification",
        "previewtemplate",
        "emailtemplates",
        "saveemailtemplates",
        "selectemailtemplates",
        "resendInviteEmailCandidate",
        "CreateCandidates",
        "adminregionList",
        "getInviteCandidate",
        "sendselecttemplates",
        "IsSmsInvitePreference",
        "sendSmsInvitation"
      ]),
      clearFrom() {
        this.invite.from = "";
        this.invitedisable();
      },
      clearGroup() {
        this.invite.fromgroupname = "";
        this.invitedisable();
      },
      searchTag() {
        this.savetodata();
      },
      searchmulti(e) {
  
        this.x = e;
      },
      checkInvite(e) {
        if (e == "" || e == null || e.match(/^\s+$/)?.input.trim().length == 0) {
          this.invite.customObj.splice(
            this.invite.customObj((elm) => elm.label == e),
            1
          );
        }
        if (this.invite.customObj.filter((elm) => elm.value == e.trim)) {
          this.cpyPst = e;
        }
      },
      openmulti() {
        if (
          document.getElementsByClassName("multiselect-tags-search-copy")[0] ==
          undefined
        ) {
          var x = document.createElement("span");
          x.classList.add("multiselect-tags-search-copy");
          document
            .getElementsByClassName("multiselect-tags-search-wrapper")[0]
            .appendChild(x);
        }
      },
      pasteemail(evt) {
        // console.log("ok1")
        this.isPaste = true;
        this.sub = evt;
        document
          .getElementsByClassName("multiselect-tags-search-copy")[0]
          .parentNode.removeChild(
            document.getElementsByClassName("multiselect-tags-search-copy")[0]
          );
        this.$refs.multiselect.closeOnSelect;
        var data = evt.clipboardData.getData("text").trim();
        if (data.length > 0) {
          var array = data.split(/[,; \r\n]+/);
          var inviteArray = [];
          inviteArray = JSON.parse(JSON.stringify(this.invite.customObj));
          array.forEach((element) => {
            if (element != "") {
              if (inviteArray.length > 0) {
                var filteredRes = inviteArray.filter((x) => x.label == element);
                if (filteredRes.length == 0) {
                  this.invite.customObj.push({
                    value: element,
                    label: element,
                  });
                }
              } else {
                this.invite.customObj.push({
                  value: element,
                  label: element,
                });
              }
            } else {
              this.invite.customObj.push({
                value: element,
                label: element,
              });
            }
          });
        }
        this.getdata();
        this.savetodata();
        document.getElementsByClassName("multiselect-tags-search")[0].blur();
        this.showWarningToastMessage();
      },
      //done
      getdata() {
        var count = 0;
        this.invite.customObj
        .forEach((element) => {
          if (element.value.trim() == this.cpyPst.trim()) {
            count++;
          }
        });
        if (count > 1) {
          this.invite.customObj.splice(
            this.invite.customObj.findIndex((elm) => elm.label == this.cpyPst),
            1
          );
        }
        this.cpyPst = "";
        if (this.invite.customObj.length < this.totalToInvite.length) {
          this.totalToInvite = this.total;
          this.invite.customObj.forEach((element) => {
            var x = this.totalToInvite.filter((elm) => elm == element.label);
            if (x.length == 0) {
              this.totalToInvite.push(element.label);
            }
          });
        } else {
          if (this.totalToInvite.length == 0) {
            this.invite.customObj.forEach((element) => {
              this.totalToInvite.push(element.label);
            });
          } else {
            this.invite.customObj.forEach((element) => {
              var x = this.totalToInvite.filter((elm) => elm == element.label);
              if (x.length == 0) {
                this.totalToInvite.push(element.label);
              }
            });
          }
        }
      },
       savetodata() {
        
        if (this.x) {
          var array = this.x.split(/[,; \r\n \t]+/);
          var inviteArray = [];
          inviteArray = JSON.parse(JSON.stringify(this.invite.customObj));
          array.forEach((element) => {
            if (inviteArray.length > 0) {
              var filteredRes = inviteArray.filter((x) => x.label == element);
              if (filteredRes.length == 0) {
                this.invite.customObj.push(
                  JSON.parse(
                    JSON.stringify({
                      value: element,
                      label: element,
                    })
                  )
                );
              }
            } else {
              this.invite.customObj.push({
                value: this.x,
                label: this.x,
              });
            }
          });
  
        }
        this.x = "";
        this.invitedisable();
        if (this.total.length == 0) {
          this.invite.customObj.forEach((element) => {
            this.total.push(element.label);
          });
        } else {
          this.invite.customObj.forEach((element) => {
            var x = this.total.filter((elm) => elm == element.label);
            if (x.length == 0) {
              this.total.push(element.label);
            }
          });
        }
        if (this.total.length > 5) {
          this.totalToInviteLength = this.total.length - 5;
        } else {
          this.totalToInviteLength = 0;
        }
        if (this.invite.customObj.length > 5) {
          this.invite.customObj = JSON.parse(
            JSON.stringify(this.invite.customObj.filter((index, i) => i < 5))
          );
        }
      },
      showallinvite() {
        this.totalToInvite.forEach((element) => {
          var x = this.invite.customObj.filter((x) => x.label == element);
          if (x.length == 0) {
            this.invite.customObj.push({
              value: element,
              label: element,
            });
          }
        });
        // this.invite.customObj = this.totalToInvite
        this.totalToInviteLength = 0;
      },
       spliceemail(val) {
        
        this.totalToInvite.splice(
          this.totalToInvite.findIndex((elm) => val.label == elm),
          1
        );
         this.invitedisable();
        if (this.invite.customObj.length < 5) {
          var y = this.totalToInvite.filter((index, i) => i < 5);
          y.forEach((element) => {
            var m = this.invite.customObj.filter((x) => x.label == element);
            if (m == 0) {
              this.invite.customObj.push({
                value: element,
                label: element,
              });
            }
          });
        }
        if (this.totalToInvite.length > 5) {
          this.totalToInviteLength = this.totalToInvite.length - 5;
        } else {
          this.totalToInviteLength = 0;
        }
        this.total = this.totalToInvite;
      },
      spliceCandidateData(val) {
        var lastElement = this.totalToInvite[this.totalToInvite.length - 1];
        
        const elementData = {
          value: lastElement,
          label: lastElement
        };
  
        if(val?.key === 'Delete') {
          // since keyboard typing is allowed 
          // and delete keyboard key removes the previous character
          // Thus, splice method has been used below for delete key press event
          this.invite.customObj.splice(-1, 1);
          this.spliceemail(elementData);
        }
        else {
          this.spliceemail(elementData);
        }
      },
      selectcans(val) {
        this.invitesend = [];
        this.excludedCandidateList = this.sendinvitelist.excludedList; //Added by Pmaps team
        if (val == 1) {
          if (this.sendinvitelist.withoutDuplicate.length > 0) {
            this.filter = 1;
            this.sendinvitelist.withoutDuplicate.forEach((element) => {
              this.invitesend.push(element);
            });
          } else {
            this.showlist = false;
            this.invitesend = [];
          }
        }
        if (val == 2) {
          if (this.sendinvitelist.Duplicate.length > 0) {
            this.filter = 2;
            this.sendinvitelist.Duplicate.forEach((element) => {
              this.invitesend.push(element);
            });
            this.sendinvitelist.withoutDuplicate.forEach((element) => {
              this.invitesend.push(element);
            }); //Added by Pmaps team
          } else {
            this.showlist = false;
            this.invitesend = [];
          }
        }
      },
      async selectEtemplate(val, e) {
        if (e.target.value == "on") {
          this.texteditor = val.htmlPart;
          await this.sendselecttemplates({
            etId: val.id,
            testId: this.testid,
          });
          window.$("#InviteCandidates").modal("show");
          window.$("#SelectTemplate").modal("hide");
          // var p = document.querySelector(".ql-editor");
          // p.innerHTML = this.texteditor;
        }
      },
      DownloadCandidateUploadSheet() {
        if(this.isEmailSelected.length === 0) {
            this.ShowWarningMessage('Please select bulk candidate upload mode');
            return;
          }
  
        var downloadUrl = this.isEmailSelected === 'false' ? 'https://testdelivery.s3.ap-northeast-1.amazonaws.com/BulkUploadTemplate/Candidate/CandidateBulkMobileUploadSheet.xlsx' 
          : 'https://testdelivery.s3.ap-northeast-1.amazonaws.com/BulkUploadTemplate/Candidate/CandidateBulkEmailUploadSheet.xlsx';
  
        window.open(downloadUrl);
      },
      openFileInput() {
        this.$refs.fileInput.click();
      },
      async handleFileUpload(event) {
        if (event) {
          if(this.isEmailSelected.length === 0) {
            this.ShowWarningMessage('Please select bulk candidate upload mode');
            return;
          }
          if(event.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            this.ShowWarningMessage("Please select valid file format");
            return;
          }
          
          var rowCount = await readXlsxFile(event.target.files[0]);
          
          if(Object.keys(rowCount).length <= 1) {
            this.ShowWarningMessage('Empty excel sheet uploaded');
            return;
          }
  
          rowCount.shift();
          this.invite.customObj = [];
          this.totalToInvite = [];
  
          let validInviteData = [];
          let invalidInviteData = [];
  
          if(this.isEmailSelected === 'true') {
            // processing for email invite
            rowCount.forEach((excelRow) => {
              if(excelRow[1].toString().length > 10) {
                var extraNumbers = excelRow[1].toString().length - 10
                var substr = excelRow[1].toString().substring(extraNumbers, excelRow[1].toString().length);
                if(this.checkPhoneNumber(substr) === true) {
                  invalidInviteData.push(excelRow[1].toString());
                } else {
                  validInviteData.push(excelRow[1].toString());
                }
              }
            })
          } else {
            // processing for sms invite
            rowCount.forEach((excelRow) => {
              if(excelRow[1].toString().length > 10) {
                var extraNumbers = excelRow[1].toString().length - 10
                excelRow[1] = excelRow[1].toString().substring(extraNumbers, excelRow[1].toString().length);
                if(this.checkPhoneNumber(excelRow[1]) === true) {
                  validInviteData.push(excelRow[1].toString());
                } else {
                  invalidInviteData.push(excelRow[1]);
                }
              } else if(this.checkPhoneNumber(excelRow[1]) === true) {
                validInviteData.push(excelRow[1].toString());
              } else {
                invalidInviteData.push(excelRow[1]);
              }
            });
          }
  
          if(validInviteData.length === 0 && invalidInviteData.length > 0) {
            this.ShowErrorMessage('Empty file encountered after removing invalid invite data');
            return;
          } else if (validInviteData.length > 0 && invalidInviteData.length > 0) {
            this.ShowErrorMessage('Removed invalid candidate invite data');
          }
  
            validInviteData.forEach((element) => {
                this.invite.customObj.push({
                  value: element,
                  label: element
                })
                this.totalToInvite.push(element);
              });
  
          this.getdata();
          this.savetodata();
        }
      },
      async selectemailtemplate() {
        var selecttemp = await this.selectemailtemplates();
        this.selectTemplate = selecttemp.object.data;
      },
      async saveemailtemplate() {
        // this.invitedisable();
        if (this.invite.subject != "" && this.texteditor != "") {
          await this.saveemailtemplates({
            testId: this.testid,
            subjectline: this.invite.subject,
            contentData: this.texteditor,
          });
        }
      },
      async createcandidate() {
        this.groupList.forEach((element) => {
          if (element.regionName == this.invite.fromgroupname) {
            this.invite.id = element.regionId;
          }
        });
        var x = [];
        if (this.invite.customObj.length != this.totalToInvite.length) {
          this.totalToInvite.forEach((element) => {
            var x = this.invite.customObj.filter((x) => x.label == element);
            if (x.length == 0) {
              this.invite.customObj.push({
                value: element,
                label: element,
              });
            }
          });
        }
        this.invite.customObj.forEach((element) => {
          x.push(element.label);
        });
        this.invite.to = x;
        var List = await this.CreateCandidates({
          testId: this.testid,
          regionId: this.invite.id,
          fromEmail: this.invite.from,
          // fromEmail: 'ptrivedi@pmaps.in',
          //email: this.invite.to,
          contactDetails: this.invite.to,
          inviteMode: this.isEmailSelected === 'true' ? 1 : 2,
          candidateType: "Invite",
        });
        this.sendinvitelist = {
          withoutDuplicate: List.object.data.newList,
          Duplicate: List.object.data.dupicateList,
          excludedList: List.object.data.excludedList //Added by Pmaps team
        };
        // if (this.sendinvitelist.Duplicate.length > 0) {
        window.$("#InviteCandidatesRemove").modal("show");
        window.$("#InviteCandidates").modal("hide");
        // }
        // else{
        //   this.nonDuplicate();
        // }
      },
      selectInviteMode(val) {
        this.invite.customObj = [];
        this.totalToInvite = [];
        this.total = [];
        this.totalToInviteLength = this.totalToInvite.length;
        this.isEmailSelected = val === 'email' ? 'true' : 'false';
      },
      removeCandidateData() {
        this.invite.customObj = [];
        this.totalToInvite.splice(0, this.totalToInvite.length);
        this.totalToInviteLength = this.totalToInvite.length;
      },
      // async nonDuplicate(){
      //   var ids = [];
      //     this.sendinvitelist.withoutDuplicate.forEach((element) => {
      //       ids.push(element.candidateId);
      //     });
      //     await this.resendInviteEmailCandidate({
      //       testId: this.testid,
      //       // senderEmail: "ptrivedi@pmaps.in",
      //       senderEmail: this.invite.from,
      //       candidates: ids,
      //     });
      //     document.getElementById("closeinvite").click();
      // },
      async sendinvite() {
        var ids = [];
        if (this.filter == 1) {
          // when user selects invite candidates without duplicate option
          this.sendinvitelist.withoutDuplicate.forEach((element) => {
            ids.push(element.candidateId);
          });
        }
        if (this.filter == 2) {
          // when user selects invite candidates with duplicate option
          this.sendinvitelist.Duplicate.forEach((element) => {
            ids.push(element.candidateId);
          });
          this.sendinvitelist.withoutDuplicate.forEach((element) => {
            ids.push(element.candidateId);
          });
        }
        
        if(this.isEmailSelected === 'true') {
            await this.resendInviteEmailCandidate({
              testId: this.testid,
              senderEmail: this.invite.from,
              candidates: ids,
            });
          }
          else if(this.isEmailSelected === 'false') {
            await this.sendSmsInvitation({
              testId: this.testid,
              senderEmail: this.invite.from,
              candidates: ids
            });
          }
          document.getElementById("closeinvite").click();
      },
      closeinvite() {
        this.$emit("clicked", "false");
        window.$("#InviteCandidates").modal("hide");
        window.$("#InviteCandidatesRemove").modal("hide");
        window.$("#SelectTemplate").modal("hide");
        window.$("#DeleteTemplate").modal("hide");
      },
      closepreviewinvite() {
        window.$("#PreviewTemplate").modal("hide");
        window.$("#InviteCandidates").modal("show");
      },
      checkemail(value) {
        this.val = value;
        if (!validateEmail(value)) {
          return false;
        } else {
          return true;
        }
      },
      checkPhoneNumber(value) {
        // regex to be changed as required
        if(!validatePhoneNumber(value)) {
          return false;
        } else {
          return true;
        }
      },
      invitedisable() {
        if (
          this.invite.from &&
          this.invite.fromgroupname &&
          this.invite.subject.trim() &&
          this.invite.subject.trim().length < 255 &&
          this.invite.customObj.length > 0
        ) {
          var p = document.querySelectorAll(".ql-editor p");
          var count = 0;
          p.forEach((element) => {
            if (element != null) {
              if (
                element.innerHTML.trim() != "" &&
                element.innerHTML != null &&
                element.innerHTML.toString() != "<br>"
              ) {
                this.invite.content = this.texteditor;
                count++;
              }
            }
          });
  
          if (count > 0) {
            var counter = 0;
            var x = [];
            if (this.invite.customObj.length != this.totalToInvite.length) {
              this.totalToInvite.forEach((element) => {
                var x = this.invite.customObj.filter((x) => x.label == element);
                if (x.length == 0) {
                  this.invite.customObj.push({
                    value: element,
                    label: element,
                  });
                }
              });
            }
            this.invite.customObj.forEach((element) => {
              x.push(element.label);
            });
            this.invite.to = x;
  
  
            // if email mode is selected
            if(this.isEmailSelected === 'true') {
              this.invite.to.forEach((element) => {
                if (this.checkemail(element)) {
                  counter = counter + 1;
                }
              });
            }
  
  
            // if sms mode is selected
            if(this.isEmailSelected === 'false') {
              this.invite.to.forEach((element) => {
                if (this.checkPhoneNumber(element)) {
                  counter = counter + 1;
                }
              });
            }
            if (this.invite.customObj.length == counter) {
              if (!this.verify) {
                this.isDisabled = false;
                this.pasteErrorCount = 0;
              } else {
                this.isDisabled = true;
               
              }
            } else {
              if(this.isPaste === false) {
                if(this.isEmailSelected === 'true') {
                  this.ShowWarningMessage("Please enter correct e-mail/s");
                  this.isDisabled = true;
                } else {
                  this.ShowWarningMessage("Please enter correct mobile number/s");
                  this.isDisabled = true;
                }
              } else {
                this.pasteErrorCount = this.pasteErrorCount + 1;
              }
            }
          } else {
            this.isDisabled = true;
           
          }
  
          /*if (p != null) {
            if (
              p.innerHTML.trim() != "" &&
              p.innerHTML != null &&
              p.innerHTML.toString() != "<br>"
            ) {
              console.log("ok3");
              this.invite.content = this.texteditor;
              var counter = 0;
              this.invite.customObj.forEach((element) => {
                if (this.checkemail(element.label)) {
                  console.log("ok4");
                  counter = counter + 1;
                }
              });
              if (this.invite.customObj.length == counter) {
                console.log("ok5");
                if (!this.verify) {
                  console.log("ok6");
                  return false;
                } else {
                  return true;
                }
              } else {
                this.ShowWarningMessage("Please enter correct E-Mail");
                return true;
              }
            } else {
              return true;
            }
          }*/
        } else {
          this.isDisabled = true;
        }
      },
      showWarningToastMessage() {
        if(this.pasteErrorCount > 0) {
          if(this.isEmailSelected === 'true') {
            this.ShowWarningMessage("Please enter correct e-mail/s");
            this.isDisabled = true;
          } else {
            this.ShowWarningMessage("Please enter correct mobile number/s");
            this.isDisabled = true;
          }
        }
      },
      async fromemailverificationstatus() {
        this.invitedisable();
        const emailvstatus = await this.emailverifystatus({
          sesRegion: "m",
          value: this.invite.from,
        });
        if (emailvstatus.object.data[0].status == "Success") {
          this.verify = false;
        } else {
          this.verify = true;
        }
      },
      async fromemailverification() {
        await this.emailverification({
          sesRegion: "m",
          clientId: this.user.clientId,
          emails: [this.invite.from],
        });
      },
      async previewTemp() {
        window.$("#InviteCandidates").modal("hide");
        window.$("#PreviewTemplate").modal("show");
        var temp = await this.previewtemplate({
          testId: this.testid,
        });
        var x = temp.object.data.htmlPart;
  
        var p = document.querySelector(".preview-content");
        p.innerHTML = x;
      },
      // displayExcludeOptions(val) {
      //   this.hideExcludedCandidates = val;
      // }// Added by pmaps team
    },
  };
  </script>
  
  <style src="@vueform/multiselect/themes/default.css"></style>
  <style scoped>
  .multiselect-dropdown {
    display: none !important;
  }
  
  .multiselect-options {
    display: none !important;
  }
  
  .multiselect-no-results {
    display: none !important;
  }
  
  .select-Template {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem !important;
  }
  
  .select-list-name {
    color: #292e34;
    font-weight: 500;
  }
  
  .select-list-time {
    color: #7b818d;
    font-size: 13px;
  }
  
  .select-Template-radio {
    align-items: center;
    display: flex !important;
  }
  
  .w-24 {
    width: 24px;
    cursor: pointer;
  }
  
  .select-list-Template {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  #DeleteTemplate .Deactivate_buttons .Deactivate_Cancel_buttons {
    border-radius: 50px;
    border: 2px solid #7c68ee;
    background: #fff;
    color: #7c68ee;
    margin: 0px 6px 0 0;
  }
  
  #DeleteTemplate .Deactivate_buttons .Deactivate_Deactivate_buttons {
    border-radius: 50px;
    border: 2px solid #7c68ee;
    background: #7b68ee;
    color: #fff;
    margin: 0 0 0px 6px;
  }
  
  .QuillEditor-kj {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column-reverse;
    /* margin-top: 45px; */
    border: 2px solid #d1d5db;
    border-radius: 10px;
  }
  
  .QuillEditor-inside-dot-relative {
    position: relative;
    right: 0;
  }
  
  .QuillEditor-inside-dot-fixed {
    position: absolute;
    right: 20px;
    top: 10px;
  }
  
  .verify-email {
    color: #7b68ee;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .multiselect-options {
    overflow: hidden;
  }
  
  .totaltoinvite {
    position: absolute;
    right: 28%;
    z-index: 1;
    top: 22%;
    color: #7b68ee;
    border: 1px solid #7b68ee;
    background: #ffffff00;
    padding: 1px 10px;
    border-radius: 34px;
  }
  
  .remove-candidate-invite {
    position: absolute;
    right: 18%;
    z-index: 1;
    top: 22%;
    color: #fff;
    padding: 1px 10px;
    border-radius: 34px;
  }
  
  .close-btn-position {
    position: absolute;
    z-index: 1;
    right: 25px;
    top: 9px;
  }
  
  .file-upload-input {
    margin-top: -23px;
    height: 100%;
    width: 6%;
    margin-left: 8px;
  }
  </style>
  