import * as api from "@/api/api";
// import { message } from "@/utils/message";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import router from "@/router";
import { encryptData} from '@/platformFeatures/security'

// import Cookies from "js-cookie";
import {
  setAuthUserData,
  // SetPlatformUserData,
  getAuthUserData,
  removeAuthUserData,
  setUserPermissionData,
  removeUserPermission,
  removeIsParent,
  removePlanFeature,
  removeBillingData
} from "@/utils/auth";
import { superadmin } from "@/utils/helper.js";


export const login = async ({ commit, dispatch }, userInfo) => {
  commit("setLoading", true);
  try {
    const { data } = await api.login(userInfo);
    if (data.status) {
      commit("setAuthenticated", data.object.data);
      setAuthUserData(data.object.data); 
      dispatch("GetloggedinPermissions", data.object.data.userId);

      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error);
    router.push("/Error_page");
  }
  commit("setLoading", false);
};



export const getrole = async ({ commit }, getroleInfo) => {
  commit("setLoading", true);
  try {
    const { data } = await api.getrole(getroleInfo);
    if (data.status) {
      commit("setLoading", false);
      return data;
    } else {
      commit("setLoading", false);
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error);
  }
  commit("setLoding", false);
};

export const rejectReq = async ({ commit }, rejectReq) => {
  commit("setLoading", true);
  try {
    const { data } = await api.rejectReq(rejectReq);
    if (rejectReq.status == "Approved") {
      if (data.status) {
        toast.success(
          rejectReq.tokenAmount +
          " token(s) allocated to " +
          rejectReq.company +
          " successfully.");
        return data;
      } else {
        toast.error(data.msg.message);
      }
    } else {
      if (data.status) {
        toast.success(data.msg.message);
      }
    }
  } catch (error) {
    toast.error(error);
  }
  commit("setLoading", false);
};

export const viewtestList = async ({ commit }, payload) => {
  var query = `v1/tokens/clients/${payload.clientId}/tests/allocation?Page=${payload.Page}&Limit=${payload.Limit}`;
  if (payload.Search) {
    query = query + `&Search=${payload.Search}`;
  } else commit("setLoading", true);
  if (payload.Partners) {
    query = query + `&Partners=${payload.Partners}`;
  }
  try {
    const { data } = await api.viewtestList(query);
    if (data.status) {
      commit("setLoading", false);
      return data;
    } else {
      commit("setLoading", false);
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    commit("setLoading", false);
    toast.error(error);
  }
};

export const UpdateOnlineStatus = ({ commit }) => {
  commit("OnlineStatus", { online: navigator.onLine });
  commit("setLoading", false);
};

export const ShowErrorMessage = ({ commit }, message) => {
  toast.error(message, commit);
};

export const ShowWarningMessage = ({ commit }, message) => {
  toast.warning(message, commit);
};
export const ShowSucessMessage = ({ commit }, message) => {
  toast.success(message, commit);
};

export const signUp = async ({ commit }, userInfo) => {
  commit("setLoading", true);
  try {
    const { data } = await api.signUp(userInfo);
    if (data.statusCode === 409) {
      toast.error(data.msg.message, commit);
    }
    if (data.statusCode === 201) {
      router.push("/login");
    }
    if (data.statusCode == 400) {
      toast.error(data.msg.message, commit);
    }
  } catch (error) {

    toast.error(error);
    router.push("/Error_page");
  }
  commit("setLoading", false);
};

export const forgetPassword = async ({ commit }, email) => {
  commit("setLoading", true);
  try {
    const { data } = await api.forgetPassword(email);
    if (data.status) {
      toast.success(data.msg.message);
      router.push("/reset-password-link");
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  }
  commit("setLoading", false);
};


export const managePassword = async ({ commit }, email) => {
  commit("setLoading", true);
  try {
    const { data } = await api.forgetPassword(email);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  }
  commit("setLoading", false);
};
export const verifyToken = async ({ commit }, token) => {
  commit("setLoading", true);
  try {
    const { data } = await api.verifyToken(token);
    if (!data.status) {
      router.push("/reset-password");
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  }
  commit("setLoading", false);
};

export const resetPassword = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.resetPassword(payload);
    if (data.status) {
      router.push("/login");
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  }
  commit("setLoading", false);
};

export const accountProfileImage = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.accountProfileImage(payload);
    if (data.status) {
      // router.push("/login");
      toast.success(data.msg.message);
      commit("setLoading", false);
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  }
  commit("setLoading", false);
};

export const updateProfilePic = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.updateProfilePic(payload);
    if (data.status) {
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
  }
  commit("setLoading", false);
};

export const RolePermissionUpdate = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.RolePermissionUpdate(payload);
    if (data.status) {
      toast.success(data.msg.message);
      commit("setLoading", false);
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  }
  commit("setLoading", false);
};

export const SetPassword = async ({ commit }, password) => {
  commit("setLoading", true);
  try {
    const { data } = await api.SetPassword(password);
    if (data.status) {
      const userAuth = getAuthUserData();
      userAuth.passwordSet = true;
      setAuthUserData(userAuth);

      toast.success(data.msg.message);
      commit("setLoading", false);
      return data;
      // router.push("/login");
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    return error;
  }
  commit("setLoading", false);
};

export const logout = async ({ commit }, userId) => {
  commit("setLoading", true);
  try {
    const { data } = await api.logout(userId);
    if (data.statusCode === 1012) {
      const userAuth = getAuthUserData();
      commit("logout", userAuth);
      removeAuthUserData();
      removeUserPermission();
      removeIsParent();
      removePlanFeature();
      removeBillingData();
      router.push("/login");
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};
export const OtpCode = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.SendotpCode(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};
export const tnc = async ({ commit }, userId) => {
  commit("setLoading", true);
  try {
    const { data } = await api.tnc(userId);
    if (data.status) {
      const userAuth = getAuthUserData();
      userAuth.termsAndConditionsAccepted = true;
      setAuthUserData(userAuth);
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  }
  commit("setLoading", false);
};
export const GetotpCode = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.GetotpCode(payload);
    if (data.status) {
      toast.success(data.msg.message);
      commit("setLoading", false);
      return data;
    } else {
      toast.error(data.msg.message);
      commit("setLoading", false);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};
// export const GetAccessToken = async ({ commit, dispatch }, payload) => {
//   commit("setLoading", true);
//   try {
//     const { data } = await api.Accesstoken(payload);
//     if (data.status) {
//       const userAuth = getAuthUserData();
//       userAuth.token = data.object.data.jwtToken;
//       commit("setAuthenticated", userAuth);
//       setAuthUserData(userAuth);
//       return data;
//     } else {
//       toast.error(data.msg.message);
//       const userAuth = getAuthUserData();
//       await dispatch("logout", { userId: userAuth.userId });
//     }
//   } catch (error) {
//     toast.error(error, commit);
//     // router.push("/Error_page");
//   }
//   commit("setLoading", false);
// };

export const GetAccessToken = async ({ commit, dispatch }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.Accesstoken(payload);
    if (data.status) {
      const userAuth = getAuthUserData();
      userAuth.token = data.object.data.jwtToken;
      commit("setAuthenticated", userAuth);
      setAuthUserData(userAuth);
      return data;
    } else {
      toast.error(data.msg.message);
      const userAuth = getAuthUserData();
      await dispatch("logout", { userId: userAuth.userId });
    }
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  }
  commit("setLoading", false);
};

export const GetRefreshToken = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.Refreshtoken(payload);
    if (data.status) {
      setTimeout(() => {
        var x = localStorage.getItem("isParent");
        if (superadmin()) {
          router.push("/dashboard-partner");
        } else {
          if (x == "true") {
            router.push("/dashboard-partner");
          } else {
            router.push("/dashboard-group");
          }
        }
      }, 1000);
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
  }
  commit("setLoading", false);
};

export const GetUserInfo = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.GetUserInfo(payload);
    if (data.status) {
      // message("sucess", data.msg.message, commit);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    // return e;
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};
export const GetCandidateDetails = async ({ commit }, payload) => {
  commit("setLoading", true);


  try {
    const { data } = await api.GetCandidateDetails(payload);
    if (data.status) {
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    // return e;
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const AllActiveTest = async ({ commit }, data) => {
  commit("setLoading", true);

  var query = `/v1/tests/clients/${data.clientId}?iLimit=${data.limit}&iSortCol=${data.sortCol}&iOffSet=${data.offset}&iSortDir=${data.sortdir}`;

  if (data.partner) {
    query = query + `&PartnerId=${data.partner}`;
  }
  if (data.language) {
    query = query + `&LanguageId=${data.language}`;
  }
  if (data.invigilation) {
    query = query + `&ProctorTypeId=${data.invigilation}`;
  }
  if (data.Search) {
    query = query + `&iSearch=${data.Search}`;
  }
  try {
    const { data } = await api.AllActiveTest(query);
    if (data.status) {
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    // return e;
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const AllDeactiveTest = async ({ commit }, data) => {
  commit("setLoading", true);

  var query = `/v1/tests/clients/${data.clientId}?iLimit=${data.limit}&iSortCol=${data.sortCol}&iOffSet=${data.offset}&iSortDir=${data.sortdir}&isActive=inactive`;

  if (data.partner) {
    query = query + `&PartnerId=${data.partner}`;
  }
  if (data.language) {
    query = query + `&LanguageId=${data.language}`;
  }
  if (data.invigilation) {
    query = query + `&ProctorTypeId=${data.invigilation}`;
  }
  if (data.Search) {
    query = query + `&iSearch=${data.Search}`;
  }
  try {
    const { data } = await api.AllDeactiveTest(query);
    if (data.status) {
      return data;
    } else {
      toast.error(data.msg.message, commit);
      return data;
    }
  } catch (error) {
    // return e;
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const GetUserStatus = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.GetUserStatus(payload);
    if (data.status) {
      // message("sucess", data.msg.message, commit);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    // return e;
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const UpdateUserInfo = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.UpdateUserInfo(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    // return e;
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const TwoFactorAuth = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.TwoFactorAuth(payload);
    if (data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const ChangeUserPassword = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.ChangeUserPassword(payload);
    if (data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const CreateClientRole = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.CreateClientRole(payload);
    if (data.status) {
      toast.success(data.object.data.displayName + "  " + data.msg.message,
        commit
      );
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};
export const UpdateRole = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.UpdateRole(payload);
    if (data.status) {
      toast.success(
        data.object.data.displayName + "  " + data.msg.message,
        commit
      );
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};
export const CreatePartner = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.CreatePartner(payload);
    if (data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const Getpartners = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.Getpartners(payload);
    if (data.status) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const VerifyPassToken = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.VerifyPassToken(payload);
    if (data.status) {
      commit("setUserIdValue", data.object.data.id);
      return data;
    } else {
      router.push("/reset-password");
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};
export const Getpartnerteammembers = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.Getpartnerteammembers(payload);
    if (data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const Getregionmembers = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.Getregionmembers(payload);
    if (data.status) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const AssignTeams = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.AssignTeams(payload);
    if (data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};
export const MappingTeamPartner = async ({ commit }, datas) => {
  commit("setLoading", true);
  try {
    const { data } = await api.PartnerTeamMapping(datas);
    if (data.status) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const AllRolesList = async ({ commit }, payload) => {
  var query = `v1/roles/clients/${payload.clientId}?Page=${payload.PageNo}&Limit=${payload.Limit}`;
  if (payload.Search != "") {
    query = query + `&Search=${payload.Search}`;
  } else //commit("setLoading", true);
  try {
    const { data } = await api.GetAllRoles(query);
    return data;
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const activateregion = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.activateregion(payload);
    return data;
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const AllPartnerList = async ({ commit }, payload) => {
  //commit("setLoading", true);
  var query = `v1/clients/partners/${payload.clientId}?iOffSet=${payload.offset}&iLimit=${payload.limit}`;
  if (payload.Search) {
    query = query + `&iSearch=${payload.Search}`;
  }
  try {
    const { data } = await api.GetPartnerList(query);
    return data;
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const GetAllPartners = async ({ commit }, payload) => {
  try {
    const { data } = await api.GetAllPartners(payload);
    return data;
  } catch (error) {
    toast.error(error, commit);
    return error;
  } finally {
    commit("setLoading", false);
  }
};

export const companylogo = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.clientlogo(payload);
    if (data.status) {
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    // toast.error(error,commit);
  } finally {
    commit("setLoading", false);
  }
};

export const GetTeams = async ({ commit }, payload) => {
  //commit("setLoading", true);
  try {
    const { data } = await api.TeamsFetche(payload);
    return data;
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    //commit("setLoading", false);
  }
};

export const GetRolebyRoleId = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.GetRole(payload);
    return data;
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};
export const GetAllPermission = async ({ commit }, data1) => {
  commit("setLoading", true);
  try {
    const { data } = await api.GetAllPermission(data1);
    return data;
  } finally {
    commit("setLoading", false);
  }
};

export const AllTeamList = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.GetAllTeams(payload);
    return data;
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const GetAllUsers = async ({ commit }, payload) => {
  var query = `v1/users/clients/${payload.clientId}?page=${payload.page}&Limit=${payload.limit}&status=${payload.status}`;
  if (payload.searchinput) {
    query = query + `&search=${payload.searchinput}`;
  } else //commit("setLoading", true);
  if (payload.searchRole) {
    query = query + `&roles=${payload.searchRole}`;
  }
  if (payload.searchDept) {
    query = query + `&depts=${payload.searchDept}`;
  }
  try {
    const { data } = await api.GetAllUsers(query);
    return data;
  } catch (error) {
    // return e;
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

//   modified function

// export const GetAllUsers = async({ commit }, payload) => {
//     commit("setLoading", true);
//     try {
//         const { data } = await api.GetAllUsers(payload);
//         return data;
//     } catch (error) {
//         // return e;
//         toast.error(message,commit);
//         router.push("/Error_page");
//     } finally {
//         commit("setLoading", false);
//     }
// };

export const getpartner = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.getpartner(payload);
    if(data.status) {
      return data;
    } else {
      toast.error(data.msg.message, commit);
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const DeleteUser = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.DeleteUser(payload);
    return data;
  } catch (error) {
    // return e;
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const DeleteGroup = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.DeleteGroup(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  }
  commit("setLoading", false);
};

export const DeletePartner = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.DeletePartner(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  }
  commit("setLoading", false);
};

export const activatepartner = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.activatepartner(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  }
  commit("setLoading", false);
};

export const deactivatepartner = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.deactivatepartner(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  }
  commit("setLoading", false);
};

export const deleteroles = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.deleteroles(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  }
  commit("setLoading", false);
};

export const updatepartner = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.updatepartner(payload);
    if (data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const AddUser = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.CreateUser(payload);
    if (!data.status) {
      toast.error(data.msg.message);
    } else {
      toast.success(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const inviteUser = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.inviteUser(payload);
    if (!data.status) {
      toast.error(data.msg.message);
    } else {
      toast.success(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const verifyUser = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.verifyUser(payload);
    if (!data.status) {
      //  toast.error(data.msg.message);
    } else {
      toast.success(data.msg.message);
    }
    commit("setLoading", false);
    commit("setIsNewAccountValue", true);
    return data;
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  }
  commit("setLoading", false);
};

// export const AllAssignedGroups = async ({ commit }, payload) => {
//   commit("setLoading", true);
//   try {
//     const { data } = await api.GetAssignedGroupsList(payload);
//     return data;
//   } catch (error) {
//     // console.log(error)
//   } finally {
//     commit("setLoading", false);
//   }
// };

export const AllAssignedGroups = async ({ commit }, payload) => {
  //commit("setLoading", true);
  var query = `v1/regions/clients/${payload.clientId}/partner-teams?iOffSet=${payload.offset}&iLimit=${payload.limit}`;
  if (payload.Search) {
    query = query + `&iSearch=${payload.Search}`;
  }
  try {
    const { data } = await api.GetPartnerList(query);
    return data;
  } catch (error) {
    toast.error(error, commit);
  } finally {
    //commit("setLoading", false);
  }
};













export const GetAllocateDataClientPartner = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.GetAllocateDataClientPartner(payload);
    if (data.status) {
      // toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const GetAllocateAmount = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.GetAllocatedAmount(payload);
    if (data.status) {
      toast.success(payload.amount +
        " token(s) allocated to " +
        payload.companyName +
        " successfully.",
        commit
      );
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const AmountAllocation = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.amountAllocate(payload);
    if (data.status) {
      toast.success(payload.amount +
        " token(s) allocated to " +
        payload.companyName +
        " successfully.",
        commit
      );
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const allocateToken = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.allocateToken(payload);
    if (data.status) {
      toast.success(payload.amount +
        " token(s) allocated to " +
        payload.testName +
        " successfully.",
        commit
      );

      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const GetTokenAllocation = async ({ commit }, payload) => {
  var query = `v1/tokens/clients/${payload.clientId}/partners/allocation?Page=${payload.Page}&Limit=${payload.Limit}`;
  if (payload.Search) {
    query = query + `&Search=${payload.Search}`;
  } else commit("setLoading", true);
  try {
    const { data } = await api.GetTokenAllocation(query);
    if (data.status) {
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    //router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const GetTAllocation = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.GetTAllocation(payload);
    return data;
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};
export const requestedTokens = async ({ commit }, payload) => {
  commit("setLoading", false);
  try {
    const { data } = await api.requestedTokens(payload);
    return data;
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const IncrOrDecTokens = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.IncrOrDecTokens(payload);
    if (data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const requestedToken = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.IncrOrDecTokens(payload);
    if (data.status) {
      toast.success(payload.amount + " token(s) requested successfully.",
        commit
      );
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const RequestToken = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.RequestToken(payload);
    if (data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const viewGroupsList = async ({ commit }, payload) => {
  var query = `v1/tokens/clients/${payload.clientId}/regions/allocation?page=${payload.page}&limit=${payload.limit}`;
  if (payload.search) {
    query = query + `&search=${payload.search}`;
  } else commit("setLoading", true);
  if (payload.partners) {
    query = query + `&partners=${payload.partners}`;
  }
  try {
    const { data } = await api.viewGroupsList(query);
    if (data.status) {
      // toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const regionAllocated = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.regionAllocated(payload);
    if (data.status) {
      // toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const regionAllocatedToken = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.regionAllocatedToken(payload);
    if (data.status) {
      toast.success(payload.amount +
        " token(s) allocated to " +
        payload.regionName +
        " successfully.",
        commit
      );

      // toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const regionRequestedToken = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.regionRequestedToken(payload);
    if (data.status) {
      toast.success(
        payload.amount + " token(s) requested successfully.",
        commit
      );

      // toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const testRequest = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.testRequest(payload);
    if (data.status) {
      // toast.success(data.msg.message);
      // toast.success(data.msg.message);
      // toast.error(data.msg.message);
      return data;
    } else {
      //  toast.error(data.msg.message);
      // toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const regionRequest = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.regionRequest(payload);
    if (data.status) {
      // toast.success(data.msg.message);
      return data;
    } else {
      // toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const rejectRequestTest = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.rejectRequestTest(payload);
    if (data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const regionRejectRequest = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.regionRejectRequest(payload);
    if (data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const getregionlocation = async ({ commit }, payload) => {
  //commit("setLoading", true);
  try {
    const { data } = await api.getregionlocation(payload);
    if (data.status) {
      //toast.success(data.msg.message);
      return data;
    } else {
      //  toast.error(data.msg.message);
      //return data;
    }
  } catch (error) {
    // return error;
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

//   Merge Api by ankit and ekta rathi
export const AssignGroup = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.AssignGroup(payload);
    if (data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const GetloggedinPermissions = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.GetloggedinPermissions(payload);
    setUserPermissionData(data.object.data);
    commit("setParent", data.object.data);
    return data;
  } catch (error) {
    // console.log(error)
  } finally {
    commit("setLoading", false);
  }
};

export const DeleteUserById = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.DeleteUserById(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  }
  commit("setLoading", false);
};

export const ReactivateUser = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.ReactivateUser(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  }
  commit("setLoading", false);
};

export const DeactivateUser = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.DeactivateUser(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  }
  commit("setLoading", false);
};

export const MultipleUserDeactivate = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.MultipleUserDeactivate(payload);
    if (data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  }
  commit("setLoading", false);
};

export const removeuser = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.removeuser(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    // return e;
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const bulkUploadGroups = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.bulkUploadGroups(payload);
    if (data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const DownloadGroupTemplate = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.DownloadGroupTemplate(payload);
    return data;
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const TemplateDownload = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.TemplateDownload(payload);
    return data;
  } catch (error) {
    // return e;
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const getGroupsWithPaginationwithoutreload = async (
  { commit },
  payload
) => {
  var query = `v1/regions/clients/${payload.clientId}?offSet=${payload.offset}&limit=${payload.limit}&status=${payload.status}`;
  if (payload.regionName) query = query + `&regionName=${payload.regionName}`;
  if (payload.regionCategoryId)
    query = query + `&regionCategoryId=${payload.regionCategoryId}`;
  if (payload.countrycode)
    query = query + `&countrycode=${payload.countrycode}`;
  //commit("setLoading", false);
  try {
    const { data } = await api.getGroupsWithPagination(query);
    return data;
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    //commit("setLoading", false);
  }
};

export const getGroupsWithPagination = async ({ commit }, payload) => {
  var query = `v1/regions/clients/${payload.clientId}?offSet=${payload.offset}&limit=${payload.limit}&status=${payload.status}`;
  if (payload.regionName) query = query + `&regionName=${payload.regionName}`;
  if (payload.regionCategoryId)
    query = query + `&regionCategoryId=${payload.regionCategoryId}`;
  if (payload.countrycode)
    query = query + `&countrycode=${payload.countrycode}`;
  //commit("setLoading", true);
  try {
    const { data } = await api.getGroupsWithPagination(query);
    return data;
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
   //commit("setLoading", false);
  }
};

export const adduseringroup = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.adduseringroup(payload);
    if (data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};
export const Getusersearchlist = async ({ commit }, payload) => {
  //commit("setLoading", true);
  try {
    const { data } = await api.Getusersearchlist(payload);
    return data;
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const Getuserlist = async ({ commit }, payload) => {
  var query = `v1/users/clients/${payload.clientId}?status=4`;
  if (payload.limit) {
    query = query + `&limit=${payload.limit}`;
  }
  //commit("setLoading", true);
  try {
    const { data } = await api.Getuserlist(query);
    return data;
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const getClientTeams = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.getClientTeams(payload);
    if (data.status) {
      commit("setTeams", data.object.data.team);
    }
    return data;
  } catch (error) {
    return error;
  } finally {
    commit("setLoading", false);
  }
};

export const getRegionCategories = async ({ commit }, payload) => {
  //commit("setLoading", true);
  try {
    const { data } = await api.getRegionCategories(payload);
    return data;
  } catch (error) {
   toast.error(error.data.msg.message,commit);
    return error;
  } finally {
    commit("setLoading", false);
  }
};

export const assignTeam = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.assignTeam(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
   toast.error(error.data.msg.message,commit);
    return error;
  } finally {
    commit("setLoading", false);
  }
};



export const getTimeZone = async ({ commit }, payload) => {
  //commit("setLoading", true);
  try {
    const { data } = await api.getTimeZone(payload);
    return data;
  } catch (error) {
    toast.error(error.data.msg.message, commit);
    return error;
  } finally {
    commit("setLoading", false);
  }
};

export const getRegionByRegionId = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.getRegionByRegionId(payload);
    return data;
  } catch (error) {
   toast.error(error.data.msg.message,commit);
    return error;
  } finally {
    commit("setLoading", false);
  }
};

export const updateTeam = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.updateTeam(payload);
    toast.success(data.msg.message);

    return data;
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const createTeam = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.createTeam(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const deleteregionmember = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.deleteregionmember(payload);
    if (data.status) {
      toast.success(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const deactivateregion = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.deactivateregion(payload);
    if (data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const UserEditData = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.UserEditData(payload);
    if (data.status) {
      // toast.success(data.msg.message);
      return data;
    } else {
      //  toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    // return error;
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const Getteammembers = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.Getteammembers(payload);
    if (data.status) {
      //toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    // return error;
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};
export const UserRegionData = async ({ commit }, payload) => {
  //commit("setLoading", true);
  try {
    const { data } = await api.UserRegionData(payload);
    if (data.status) {
      // toast.success(data.msg.message);
      return data;
    } else {
      //  toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    // return error;
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};
export const GetUrlforshare = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.GetUrlforshare(payload);
    if (data.status) {
      // toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    // return error;
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const BulkUseruploading = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.BulkUseruploading(payload);
    if (data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const UpdateUserData = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.UpdateUserData(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    // return e;
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

//Mileston-4-API
export const activetestdetail = async ({ commit }, payload) => {
  //commit("setLoading", true);
  try {
    const { data } = await api.activetestdetail(payload);
    if (data.status) {
      return data;
    } else {
       toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
  } finally {
    //commit("setLoading", false);
  }
};

// to be used in future
// export const GetCandidateAdditionalDetails = async ({ commit }, payload) => {
//   commit("setLoading", true);
//   try {
//     const { data } = await api.GetCandidateAdditionalDetails(payload);
//     if (data.status) {
//       return data;
//     }
//   } catch (error) {
//     toast.error(error, commit);
//   } finally {
//     commit("setLoading", false);
//   }
// };

// export const GetTestWeightage = async ({ commit }, payload) => {
//   commit("setLoading", true);
//   try {
//     const { data } = await api.GetTestWeightage(payload);
//     if (data.status) {
//     return data;
//     } 
//   } catch (error) {
//     toast.error(error, commit);
//   } finally {
//     commit("setLoading", false);
//   }
// };

// export const GetLanguageWeightageDetails = async ({ commit }, payload) => {
//   commit("setLoading", true);
//   try {
//     const { data } = await api.GetLanguageWeightageDetails(payload);
//     if (data.status) {
//       return data;
//     } 
//   } catch (error) {
//     toast.error(error, commit);
//   } finally {
//     commit("setLoading", false);
//   }
// };






export const CreateCoolingPeriodSetting = async ({commit},payload)=>{
  console.log(payload);
  commit("setLoading",true);
  try{
    const { data } = await api.CreateCoolingPeriodSetting(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message, commit);
    }
  }catch(error){
    toast.error(error, commit);
  }finally{
    commit("setLoading", false);
  }
};

export const SetRestLink = async ({commit},payload)=>{
  commit("setLoading",true);
  try{
    const { data } = await api.SetRestLink(payload);
    if (data.status) {
      toast.success('Link copied successfully');

      return data;
    } else {
      toast.error(data.msg.message, commit);
    }
  }catch(error){
    toast.error(error, commit);
  }finally{
    commit("setLoading", false);
  }
};


export const updateCandidateDetails = async ({commit},payload)=>{
  console.log(payload);
  commit("setLoading",true);
  try{
    const { data } = await api.updateCandidateDetails(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message, commit);
    }
  }catch(error){
    toast.error(error, commit);
  }finally{
    commit("setLoading", false);
  }
};




export const CreateTestScheduler = async ({commit},payload)=>{
  console.log(payload);
  commit("setLoading",true);
  try{
    const { data } = await api.CreateTestScheduler(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message, commit);
    }
  }catch(error){
    toast.error(error, commit);
  }finally{
    commit("setLoading", false);
  }
};


export const GetCoolingPeriodSetting = async ({commit},payload)=>{
  commit("setLoading",true);
  try{
    const { data } = await api.GetCoolingPeriodSetting(payload);
    if (data.status) {
            return data;

    } 
  }catch(error){
    toast.error(error, commit);
  }finally{
    commit("setLoading", false);
  }
};








export const GetCoolingPeriodDuration = async ({commit},payload)=>{
  console.log(payload);
  commit("setLoading",true);
  try{
    const { data } = await api.GetCoolingPeriodDuration(payload);
    if (data.status) {
            return data;

    } else {
      // toast.error(data.msg.message, commit);
    }
    return data;
  }catch(error){
    toast.error(error, commit);
  }finally{
    commit("setLoading", false);
  }
};


export const UpdateTestDuration = async({commit},payload) =>{
  console.log(payload);
  commit("setLoading",true);
  try{
    const { data } = await api.UpdateTestDuration(payload);
    if (data.status) {
      toast.success(data.msg.message, commit);
    } else {
      toast.error(data.msg.message, commit);
    }
  }catch(error){
    toast.error(error, commit);
  }finally{
    commit("setLoading", false);
  }
};

export const UpdateCoolingPeriod = async({commit},payload) =>{
  console.log(payload);
  commit("setLoading",true);
  try{
    const { data } = await api.UpdateCoolingPeriod(payload);
    if (data.status) {
      toast.success(data.msg.message, commit);
    } else {
      toast.error(data.msg.message, commit);
    }
  }catch(error){
    toast.error(error, commit);
  }finally{
    commit("setLoading", false);
  }
};

export const GetCoolingPeriod =async ({commit},payload) =>{
  try {
    const{data}=await api.GetCoolingPeriod(payload);
    if(data.status){
      return data;
  
    }else{
      toast.error(data.msg.message, commit);
  
    }
  } catch (error) {
    toast.error(error, commit);
  
  }finally {
    commit("setLoading", false);
  }
  };

export const GetTestDuration =async ({commit},payload) =>{
try {
  const{data}=await api.GetTestDuration(payload);
  if(data.status){
    return data;

  }else{
    toast.error(data.msg.message, commit);

  }
} catch (error) {
  toast.error(error, commit);

}finally {
  commit("setLoading", false);
}
};


export const activetestsection = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.activetestsection(payload);
    if (data.status) {
      return data;
    } else {
       toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const activetestgeneralsetting = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.activetestgeneralsetting(payload);
    if (data.status) {
      // toast.success(data.msg.message);
    } else {
      //  toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const activetestproctorsettings = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.activetestproctorsettings(payload);
    if (data.status) {
      // toast.success(data.msg.message);
    } else {
      //  toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const saveemailtemplates = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.saveemailtemplates(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const selectemailtemplates = async ({ commit }) => {
  commit("setLoading", true);
  try {
    const { data } = await api.selectemailtemplates();
    if (data.status) {
      // toast.success(data.msg.message);
    } else {
      //  toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const viewCandinate = async ({ commit }, payload) => {
  var query = `v1/candidates/tests/${payload.testId}?iOffSet=${payload.OffSet}&iLimit=${payload.Limit}`;
  if (payload.regionId) {
    query = query + `&regionId=${payload.regionId}`;
  }
  if (payload.status) {
    query = query + `&status=${payload.status}`;
  }
  if (payload.Search) {
    query = query + `&iSearch=${payload.Search}`;
  }
  if (payload.fromDate) {
    query = query + `&fromDate=${payload.fromDate}`;
  }
  if (payload.toDate) {
    query = query + `&toDate=${payload.toDate}`;
  }
  //update
  if (payload.isParent) {
    query = query + `&isParent=${payload.isParent}`;
  }
  //update
  try {
    commit("setLoading", true);    
    const { data } = await api.viewCandinate(query);
    if (data.status) {
      return data;
    } else {
      //  toast.error(data.msg.message);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const viewCandidateV2 = async({ commit }, payload) => {
  var query = `v2/candidates/tests/${payload.testId}?iOffSet=${payload.OffSet}&iLimit=${payload.Limit}`;
  if (payload.regionId) {
    query = query + `&regionId=${payload.regionId}`;
  }
  if (payload.status) {
    query = query + `&status=${payload.status}`;
  }
  if (payload.Search) {
    query = query + `&iSearch=${payload.Search}`;
  }
  if (payload.fromDate) {
    query = query + `&fromDate=${payload.fromDate}`;
  }
  if (payload.toDate) {
    query = query + `&toDate=${payload.toDate}`;
  }
  //update
  if (payload.isParent) {
    query = query + `&isParent=${payload.isParent}`;
  }
  //update
  try {
    commit("setLoading", true);    
    const { data } = await api.viewCandinate(query);
    if (data.status) {
      return data;
    } else {
      return data;
    }
  } catch(error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
}

export const deleteCandidate = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.deleteCandidate(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const resendInvite = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.resendInvite(payload);
    if (data.status) {
      // toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const resendInviteEmailCandidate = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.resendInviteEmailCandidate(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    // return e;
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const getLastCandidateInviteMode = async ({ commit }, payload) => {
  try {
    const { data } = await api.getLastCandidateInviteMode(payload);
    if (data.status) {
      //toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch(error) {
    toast.error(error, commit);
  }
};

export const getviewReport = async ({ commit }, payload) => {
  try {
    const { data } = await api.getviewReport(payload);
    if (!data.status) {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    // return e;
    toast.error(error, commit);
    router.push("/Error_page");
  }
};

export const viewReport = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.viewReport(payload);
    if (data.status) {
      // toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    // return e;
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const viewReportcalendar = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.viewReportcalendar(payload);
    if (data.status) {
      // toast.success(data.msg.message);
    } else {
      //  toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    // return e;
    toast.error(error, commit);
    //router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const downloadsheet = async ({ commit }, payload) => {
  var query = `v1/tests/${payload.testId}/consolidated-sheets/download?&FromDate=${payload.formDate}&ToDate=${payload.toDate}`;
  if (payload.RegionId) {
    query = query + `&RegionId=${payload.RegionId}`;
  }
  if (payload.RegionId) {
    query = query + `&UserId=${payload.UserId}`;
  }
  if (payload.RegionId) {
    query = query + `&Status=${payload.Status}`;
  }
  commit("setLoading", true);
  try {
    const { data } = await api.downloadsheet(query);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    // return e;
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

/*update*/
export const MedusindBulkData = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.downloadMedusindBulkCandidates(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

/*update*/

export const Deploy = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.Deploy(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};
export const getInviteCandidate = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.getInviteCandidate(payload);
    return data;
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};
export const sendselecttemplates = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.sendselecttemplates(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const Undeploy = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.Undeploy(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const Private = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.Private(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};
export const Public = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.Public(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};
export const Activate = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.Activate(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};
export const Deactivate = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.Deactivate(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const invitefromemail = async ({ commit }, payload) => {
  //commit("setLoading", true);
  try {
    const { data } = await api.invitefromemail(payload);
    if (data.status) {
      // toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    //commit("setLoading", false);
  }
};

export const getregionlocationnewData = async ({ commit }, payload) => {
  //commit("setLoading", true);
  try {
    const { data } = await api.getregionlocationnewData(payload);
    if (data.status) {
      // toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const getlocation = async ({ commit }) => {
  //commit("setLoading", true);
  try {
    const { data } = await api.getlocation();
    if (data.status) {
      // toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const CreateCandidates = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.CreateCandidates(payload);
    if (data.status) {
      // toast.success(data.msg.message);
    } else {
      //  toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const emailverifystatus = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.emailverifystatus(payload);
    if (data.status) {
      // toast.success(data.msg.message);
      return data;
    } else {
      //  toast.error(data.msg.message);
      //return data;
    }
  } catch (error) {
    // return error;
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const getRegionCategoriesNewData = async ({ commit }, payload) => {
  //commit("setLoading", true);
  try {
    const { data } = await api.getRegionCategoriesNewData(payload);
    return data;
  } catch (error) {
    toast.error(error, commit);
    return error;
  } finally {
    commit("setLoading", false);
  }
};
export const regioncategory = async ({ commit }) => {
  //commit("setLoading", true);
  try {
    const { data } = await api.regioncategory();
    return data;
  } catch (error) {
    toast.error(error, commit);
    return error;
  } finally {
    commit("setLoading", false);
  }
};

// export const InviteCandidates = async ({ commit }, payload) => {
//   commit("setLoading", true);
//   try {
//     const { data } = await api.InviteCandidates(payload);
//     if (data.status) {
//       toast.success(data.msg.message);
//     } else {
//        toast.error(data.msg.message);
//     }
//     return data;
//   } catch (error) {
//     toast.error(error,commit);
//     // router.push("/Error_page");
//   } finally {
//     commit("setLoading", false);
//   }
// };

export const emailverification = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.emailverification(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const previewtemplate = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.previewtemplate(payload);
    if (data.status) {
      // toast.success(data.msg.message);
    } else {
      //  toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const UserAccounts = async ({ commit }, payload) => {
  //commit("setLoading", true);
  try {
    const { data } = await api.UserAccounts(payload);
    if (data.status) {
      // toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};
export const SwitchAccount = async ({ commit, dispatch }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.SwitchAccount(payload);
    if (data.status) {
      toast.success("Accounts switched successfully", commit);
      const userAuth = getAuthUserData();
      userAuth.token = data.object.data.jwtToken;
      userAuth.clientId = payload.clientId;
      userAuth.userId = payload.accountId;
      userAuth.plan = data.object.data.plan;
      userAuth.subscription = data.object.data.subscription;
      dispatch("GetloggedinPermissions", userAuth.userId);
      commit("setAuthenticated", userAuth);
      setAuthUserData(userAuth);
      dispatch("Companyheader", { clientId: userAuth.clientId });
      setTimeout(() => {
                  router.push("/dashboard-group");

        // if (!superadmin()) {
        //   var x = localStorage.getItem("isParent");
        //   if (x == "false") {
        //     router.push("/dashboard-group");
        //   } else {
        //     router.push("/dashboard-partner");
        //   }
        // } else {
        //   router.push("/dashboard-partner");
        // }
      }, 500);

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      //  toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const SetPrimaryAccount = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.SetPrimaryAccount(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const adminregionList = async ({ commit }, payload) => {
  //commit("setLoading", true);
  try {
    const { data } = await api.adminregionList(payload);
    return data;
  } catch (error) {
    toast.error(error, commit);
  } finally {
    //commit("setLoading", false);
  }
};

export const Companyheader = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.Companyheader(payload);
    if (data.status) {
      localStorage.setItem("isParent", data.object.data.isParent);
      // toast.success(data.msg.message);
    } else {
      //  toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    // toast.error(error,commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const Dashover = async ({ commit }, payload) => {
  // commit("setLoading", true);
  try {
    const { data } = await api.Dashover(payload);
    if (data.status) {
      // toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const Dashallocation = async ({ commit }, payload) => {
  var query = `v1/dashboard/clients/${payload.clientId}/overview-test-allocation?mode=${payload.mode}`;
  if (payload.From) {
    query = query + `&From=${payload.From}`;
  }
  if (payload.To) {
    query = query + `&To=${payload.To}`;
  }
  //commit("setLoading", true);
  try {
    const { data } = await api.Dashallocation(query);
    return data;
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};
export const Dashscheduled = async ({ commit }, payload) => {
  var query = `v1/dashboard/clients/${payload.clientId}/overview-test-scheduled?mode=${payload.mode}`;
  if (payload.From) {
    query = query + `&From=${payload.From}`;
  }
  if (payload.To) {
    query = query + `&To=${payload.To}`;
  }
  //commit("setLoading", true);
  try {
    const { data } = await api.Dashscheduled(query);
    return data;
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const Graph = async ({ commit }, payload) => {
  var query = `v1/dashboard/clients/${payload.clientId}/overview-test-activity?mode=${payload.mode}`;
  if (payload.From) {
    query = query + `&From=${payload.From}`;
  }
  if (payload.To) {
    query = query + `&To=${payload.To}`;
  }
  try {
    const { data } = await api.Graph(query);
    if (data.status) {
      // toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const regionfilter = async ({ commit }, payload) => {
  // commit("setLoading", true);
  try {
    const { data } = await api.regionfilter(payload);
    if (data.status) {
      // toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const profilePic = async ({ commit }, payload) => {
  // commit("setLoading", true);
  try {
    const { data } = await api.profilePic(payload);
    if (data.status) {
      // toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const piechartPartner = async ({ commit }, payload) => {
  // commit("setLoading", true);
  try {
    const { data } = await api.piechartPartner(payload);
    if (data.status) {
      // toast.success(data.msg.message);
    } else {
      //  toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const partnerLogo = async ({ commit }, payload) => {
  // commit("setLoading", true);
  try {
    const { data } = await api.partnerLogo(payload);
    if (data.status) {
      // toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const getAllNotifications = async ({ commit }, payload) => {
  try {
    const { data } = await api.getAllNotifications(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
  }
};

//***********************************************Milestone 7 APIS START ************************************************************
export const getReportData = async ({ commit }, payload) => {
  
  try {
    const { data } = await api.getReportData(payload);
    if (data.status) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  }
  
};

export const HardwareHistory = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.HardwareHistory(payload);
    if (data.status) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  }
  finally {
    commit("setLoading", false);
  }
};

export const ImageandVideos = async ({ commit }, payload) => {
//  commit("setLoading", true);
  try {
    const { data } = await api.ImageandVideos(payload);
    if (data.status) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  }
  finally {
    // commit("setLoading", false);
  }
};
export const ImageandVideosPdf = async ({ commit }, payload) => {
 commit("setLoading", true);
  try {
    const { data } = await api.ImageandVideosPdf(payload);
    if (data.status) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  }
  finally {
    commit("setLoading", false);
  }
};

export const ProctoredLogsModel = async ({ commit }, payload) => {
 
  try {
    const { data } = await api.ProctoredLogsModel(payload);
    if (data.status) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  }

};

export const getBasicInfo = async ({ commit }, payload) => {
 
  try {
    const { data } = await api.getBasicInfo(payload);
    if (data.status) {
      return data;
    } else {
      toast.error(data.msg.message, commit);
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  }
  
};
export const getTestResultSummary = async ({ commit }, payload) => {
  try {
    const { data } = await api.getTestResultSummary(payload);
    if (data.status) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  }
};
export const getRecommendations = async ({ commit }, payload) => {
  try {
    const { data } = await api.getRecommendations(
      payload.candidateId,
      payload.type
    );
    if (data.status) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  }
};

export const GetsAllProctor = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.GetsAllProctor(payload);
    if (data.status) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  }
  finally {
    commit("setLoading", false);
  }
};
export const getLanguageSummary = async ({ commit }, payload) => {
  try {
    const { data } = await api.getLanguageSummary(payload);
    if (data.status) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  }
};

export const getLanguageReport = async ({ commit }, payload) => {
  try {
    const { data } = await api.getLanguageReport(payload);
    if (data.status) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    toast.error(error,commit);
  }
};

export const cognitiveReport = async ({ commit }, payload) => {
  try {
    const { data } = await api.cognitiveReport(payload);
    if (data.status) {
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
  }
};

export const responseHeader = async ({ commit }, payload) => {
  try {
    const { data } = await api.responseHeader(payload);
    if (data.status) {
      return data;
    } else {
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  }
};

export const languageHeader = async ({ commit }, payload) => {
  try {
    const { data } = await api.languageHeader(payload);
    if (data.status) {
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
  }
};

export const getBehaviouralData = async ({ commit }, payload) => {
  try {
    const { data } = await api.getBehaviouralData(payload);
    if (data.status) {
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
  }
};

export const GetGenuineModel = async ({ commit }, payload) => {
  try {
    const { data } = await api.GetGenuineModel(payload);
    if (data.status) {
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
  }
};

export const GetAttemptData = async ({ commit }, payload) => {
  try {
    const { data } = await api.GetAttemptData(payload);
    if (data.status) {
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
  }
};

export const GetScoreRankData = async ({ commit }, payload) => {
  try {
    const { data } = await api.GetScoreRankData(payload);
    if (data.status) {
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
  }
};

export const GetCandidateAns = async ({ commit }, payload) => {
  try {
    const { data } = await api.GetCandidateAns(payload);
    if (data.status) {
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
  }
};
export const getTyping = async ({ commit }, payload) => {
  try {
    const { data } = await api.getTyping(payload);
    if (data.status) {
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
  }
};
export const getCoding = async ({ commit }, payload) => {
  try {
    const { data } = await api.getCoding(payload);
    if (data.status) {
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
  }
};
//***********************************************Milestone 7 APIS END **************************************************************
export const sendSmsInvitation = async ({ commit }, payload) => {
  try {
    commit("setLoading", true);
    const { data } = await api.SendSmsInvitation(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
}

export const IsSmsInvitePreference = async ({ commit }, payload) => {
  try {
    const { data } = await api.GetIsSmsInvitePreference(payload);
    if (data.status) {
      return data;
    }
  } catch (error) {
    toast.error(error, commit);
  }
};

export const getRMWithPagination = async ({ commit }, payload) => {
  var query = `v1/clients/partners/${payload.clientId}?iOffSet=${payload.offset}&iLimit=${payload.limit}`;
  if (payload.clientId) query = query + `&clientId=${payload.clientId}`;
  if (payload.company)
    query = query + `&company=${payload.company}`;
  // if (payload.countrycode)
  //   query = query + `&countrycode=${payload.countrycode}`;
  //commit("setLoading", true);
  try {
    const { data } = await api.getRMWithPagination(query);
    if(data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};
  
export const CreateMapClientsWithUsers = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.CreateMapClientsWithUsers(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};  


 

export const GetPlanCardDetails = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.GetPlanCardDetails(payload);
    return data;
  } catch (error) {
    toast.error(error, commit);
    return error;
  } finally {
    commit("setLoading", false);
  }
};


export const GetInvoiceData = async ({ commit }, payload) => {
  try {
    const { data } = await api.GetInvoiceData(payload);
    if (data.status) {
      toast.success(data.msg.message);   
      return data;

    } 
    // else {
    //   toast.error(data.msg.message);
    // }
  } catch (error) {
    // toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const DownloadInvoice = async ({ commit }, payload) => {
  try {
    const { data } = await api.DownloadInvoice(payload, { responseType: 'blob' });
    const blob = new Blob([data]);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'invoice.pdf';
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit('setLoading', false);
  }
};

export const GetMapClientsWithUsers = async ({ commit }, payload) => {
  //commit("setLoading", true);
  try {
    const { data } = await api.GetMapClientsWithUsers(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
};

export const UpdateMappedClientsWithUsers= async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.UpdateMappedClientsWithUsers(payload);
    if (data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
  }finally{
  commit("setLoading", false);
  }
};

export const GetMappedPrimaryUser = async ({ commit }, payload) => {
  var query = `v1/clientRM/GetPrimaryRM/Clients/${payload.clientId}`;
  if (payload.searchinput) {
    query = query + `&search=${payload.searchinput}`;
  }
  if (payload.searchRole) {
    query = query + `&roles=${payload.searchRole}`;
  }
  if (payload.searchDept) {
    query = query + `&depts=${payload.searchDept}`;
  }
  try {
    commit("setLoading", true);
    const { data } = await api.GetMappedPrimaryUser(query);
    return data;
  } catch (error) {
    // return e;
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};



//#region Billing API
export const GetBillingDetails = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.GetBillingDetails(payload);
    return data;
  } catch (error) {
    toast.error(error, commit);
    return error;
  } finally {
    commit("setLoading", false);
  }
};

export const RefreshBillingDetails = async ({ commit, dispatch }) => {
  commit("setLoading", true);
  try {
    const userAuth = getAuthUserData();    
    if(userAuth.isEnterprise==false){
      const billingInfo = await api.GetBillingDetails({clientId: userAuth.clientId});
    // const billingInfo = await api.GetBillingDetails({clientId: 1565});
    localStorage.setItem("billingData", JSON.stringify(billingInfo.data));

    const planCode = billingInfo.data.object.data.plan.planCode;
    const secretKey = '7aB&2kL#d$!Pz%9wQxR5tNvYbEgUfTsX';
    // const encryptedData = localStorage.getItem('featuresData');
    // if (!encryptedData) {
      const data = await dispatch('fetchFeatures', planCode);
      const plandata = data.data.object.data;

      if (data.status === 200) {
        const encryptedData = encryptData(plandata, secretKey);
        localStorage.setItem("featuresData", encryptedData);
      // }
    }
    }
    // return userAuth;
  } catch (error) {
    // toast.error(error, commit);
    return error;
  } finally {
    commit("setLoading", false);
  }
};
//      commit("setAuthenticated", data.object.data);

export const billingSignup = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.billingSignup(payload);
    if (data.status) {
      toast.success(data.msg.message);
      router.push("/login");
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
    // router.push("/Error_page");
  } finally {
    commit("setLoading", false);
  }
}; 

export const getPaymentPage = async({ commit }, payload) => {
  try {
    commit("setLoading", true);
    const { data } = await api.getPaymentPage(payload);
    if(data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch(error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const postDeployTest = async({ commit }, payload) => {
  try {
    commit("setLoading", true);
    const { data } = await api.postDeployTest(payload);
    if(data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch(error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const putSubscriptionCancel = async({ commit }, payload) => {
  try {
    commit("setLoading", true);
    const { data } = await api.putSubscriptionCancel(payload);
    if(data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch(error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

//#endregion

//#region Test Library API
export const fetchAllJobLevels = async({ commit }) => {
  try {
    const { data } = await api.GetAllJobLevels();
    if (data.status) {
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch (error) {
    toast.error(error, commit);
  }
};

export const rateTest = async({ commit }, payload) => {
  try {
    const { data } = await api.submitTestRating(payload);
    if(data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch(error) {
    toast.error(error, commit);
  }
};

export const submitTestReview =  async({ commit }, payload) => {
  try {
    const { data } = await api.submitTestReview(payload);
    if(data.status) {
      toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch(error) {
    toast.error(error, commit);
  }
};

export const DeleteTestLibraryBookmark = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    const { data } = await api.DeleteTestLibraryBookmark(payload);
    if (data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
    return data;
  } catch (error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
};

export const CreateTestLibraryBookmark = async ({commit},payload)=>{
  // console.log(payload);
  commit("setLoading",true);
  try{
    const { data } = await api.CreateTestLibraryBookmark(payload);
    if (data.status) {
      toast.success(data.msg.message);
      
    } else {
      toast.error(data.msg.message, commit);
    }
    return data;
  }catch(error){
    toast.error(error, commit);
  }finally{
    commit("setLoading", false);
  }
};

export const GetTestLibraryCardCategories = async ({ commit }, payload) => {
  try {
    const { data } = await api.GetTestLibraryCardCategories(payload);
    return data;
  } catch (error) {
    toast.error(error, commit);
    return error;
  } finally {
    commit("setLoading", false);
  }
};


export const GetTestLibraryOffcanvasDetails = async ({commit},payload)=>{
  commit("setLoading",true);
  try{
    const { data } = await api.GetTestLibraryOffcanvasDetails(payload);
    if (data.status) {
            return data;
    } 
  }catch(error){
    toast.error(error, commit);
  }finally{
    commit("setLoading", false);
  }
};

export const GetTestLibraryCardDetails = async ({commit},payload)=>{
  commit("setLoading",true);
  try{
    var query = `v1/test-libraries?Page=${payload.Page}&Limit=${payload.Limit}`;
    
    if(payload.isBookmarked) {
      query += `&isBookmarked=${payload.isBookmarked}`
    }
    if(payload.Search) {
      query += `&Search=${payload.Search}`
    }
    if(payload.JobFamily) {
      query += `&JobFamily=${payload.JobFamily}`
    }
    if(payload.JobLevel) {
      query += `&JobLevel=${payload.JobLevel}`
    }
    if(payload.CompetencyTags) {
      query += `&CompetencyList=${payload.CompetencyTags}`
    }

    const { data } = await api.GetTestLibraryCardDetails(query);
    if (data.status) {
      return data;
    } else {
      toast.error(data.msg.message, commit);
    }
  }catch(error){
    toast.error(error, commit);
  }finally{
    commit("setLoading", false);
  }
};

export const sendTestLibraryEmailToRm = async({ commit }, payload) => {
  try {
    commit("setLoading", true);
    const { data } = await api.sendTestLibraryEmailToRm(payload);
    if(data.status) {
      toast.success(data.msg.message);
    } else {
      toast.error(data.msg.message);
    }
  } catch(error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
}

export const testLibraryTrailTest = async({ commit }, payload) => {
  try {
    commit("setLoading", true);
    const { data } = await api.testLibraryTrailTest(payload);
    if(data.status) {
      // toast.success(data.msg.message);
      return data;
    } else {
      toast.error(data.msg.message);
    }
  } catch(error) {
    toast.error(error, commit);
  } finally {
    commit("setLoading", false);
  }
}
//#endregion
