<template>
  <div class="page-content">
    <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
      <div class="my-2">
        <h3 class="mb-2 mb-md-0">Descriptive Analytics</h3>
        <p class="font-size-12">30 Nov, 2022 - 15 Dec, 2022</p>
      </div>
      <div class="d-flex justify-content-between Overview_dashborad_header_md_flex">
        <div class="d-flex mb-2">
          <div class="top_heading_CreatePartner_btn d-block" data-bs-toggle="modal" data-bs-target="#choose-Date">
            <div class="Group_top_three_dot">
              <div class="dropdown">
                <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="line-height: 0.3">
                  <img src="../../public/dashboard-assets/images/M2/date-icon.png" />
                </button>
              </div>
            </div>
          </div>
          <!-- Filter -->
          <div class="
          top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj
          d-block
          mx-2
        ">
            <div class="Group_top_three_dot mx-2">
              <div class="dropdown">
                <button class="btn p-0 Group_top_three_dot_dropdown position-relative" type="button"
                  id="dropdownMenuButton1" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false"
                  aria-haspopup="true" style="line-height: 0.3">
                  <img src="../../public/dashboard-assets/images/M2/Filter_icon.png" />
                  <span class="Noti-alert-kj translate-middle">
                    5 <span class="visually-hidden"></span>
                  </span>
                </button>

                <ul class="dropdown-menu dropdown-menu-kj" aria-labelledby="dropdownMenuButton1">
                  <div class="modal-header">
                    <h4 class="modal-title text-centerm-auto" id="CreateGroupModalLabel">
                      Filter
                    </h4>
                    <button type="button" class="btn-close" data-bs-toggle="dropdown"
                      data-bs-auto-close="inside"></button>
                  </div>

                  <ul class="nav nav-pills justify-content-between mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                        aria-selected="true">
                        Partner
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                        aria-selected="false">
                        Test
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-profile1" type="button" role="tab" aria-controls="pills-profile"
                        aria-selected="false">
                        Language
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active p-0" id="pills-home" role="tabpanel"
                      aria-labelledby="pills-home-tab">
                      <div class="search-bar">
                        <form class="search-form">
                          <input type="text" placeholder="Search" name="" class="form-control bg-white" /><button
                            class="btn">
                            <i class="fas fa-search"></i>
                          </button>
                        </form>
                        <div class="ul-li-checkbox-kj">
                          <ul>
                            <li>
                              <input type="checkbox" name="" id="" />
                              <span>Cogent </span>
                            </li>
                            <li>
                              <input type="checkbox" name="" id="" />
                              <span>Infosys</span>
                            </li>
                            <li>
                              <input type="checkbox" name="" id="" />
                              <span>Mindtree</span>
                            </li>
                            <li>
                              <input type="checkbox" name="" id="" />
                              <span>Startech </span>
                            </li>
                            <li>
                              <input type="checkbox" name="" id="" />
                              <span>Techmahindra </span>
                            </li>
                            <li>
                              <input type="checkbox" name="" id="" />
                              <span>Vodafone </span>
                            </li>
                          </ul>
                          <div class="text-end">
                            <button type="button" class="btn-Apply-kj-color">
                              Clear Filter
                            </button>
                            <button type="button" class="btn-primary btn-Apply-kj">
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade p-0" id="pills-profile" role="tabpanel"
                      aria-labelledby="pills-profile-tab">
                      <div class="search-bar">
                        <form class="search-form">
                          <input type="text" placeholder="Search" name="" class="form-control bg-white" /><button
                            class="btn">
                            <i class="fas fa-search"></i>
                          </button>
                        </form>
                      </div>
                      <div class="ul-li-checkbox-kj">
                        <ul>
                          <li>
                            <input type="checkbox" name="" id="" />
                            <span>CSO Test </span>
                          </li>
                          <li>
                            <input type="checkbox" name="" id="" />
                            <span>HR Test </span>
                          </li>
                          <li>
                            <input type="checkbox" name="" id="" />
                            <span>Language Test </span>
                          </li>
                          <li>
                            <input type="checkbox" name="" id="" />
                            <span>PDX Recruiter Test 2020 </span>
                          </li>
                          <li>
                            <input type="checkbox" name="" id="" />
                            <span>Startech Test </span>
                          </li>
                          <li>
                            <input type="checkbox" name="" id="" />
                            <span>Test Try </span>
                          </li>
                        </ul>

                        <div class="text-end">
                          <button type="button" class="btn-Apply-kj-color">
                            Clear Filter
                          </button>
                          <button type="button" class="btn-primary btn-Apply-kj">
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade p-0" id="pills-profile1" role="tabpanel"
                      aria-labelledby="pills-profile-tab">
                      <div class="search-bar">
                        <form class="search-form">
                          <input type="text" placeholder="Search" name="" class="form-control bg-white" /><button
                            class="btn">
                            <i class="fas fa-search"></i>
                          </button>
                        </form>
                      </div>
                      <div class="ul-li-checkbox-kj">
                        <ul>
                          <li>
                            <input type="checkbox" name="" id="" />
                            <span>English </span>
                          </li>
                          <li>
                            <input type="checkbox" name="" id="" />
                            <span>Gujarati </span>
                          </li>
                          <li>
                            <input type="checkbox" name="" id="" />
                            <span>Marathi </span>
                          </li>
                          <li>
                            <input type="checkbox" name="" id="" />
                            <span>Tamil </span>
                          </li>
                          <li>
                            <input type="checkbox" name="" id="" />
                            <span>Kannada </span>
                          </li>
                          <li>
                            <input type="checkbox" name="" id="" />
                            <span>French </span>
                          </li>
                        </ul>
                        <div class="text-end">
                          <button type="button" class="btn-Apply-kj-color">
                            Clear Filter
                          </button>
                          <button type="button" class="btn-primary btn-Apply-kj">
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
                <!-- </div> -->
              </div>
            </div>
          </div>
          <div class="top_heading_CreatePartner_btn d-block" data-bs-toggle="modal" data-bs-target="#ConsolidatedSheet">
            <div class="Group_top_three_dot">
              <div class="dropdown">
                <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="line-height: 0.3">
                  <img src="../../public/dashboard-assets/images/M2/download-icon.png" />
                </button>
              </div>
            </div>
          </div>
        </div>


        <div class="partner_item_action">
          <div class="dropdown group_partenr_dropdown_button">
            <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Partner
              <i class="fa-solid fa-chevron-down ms-3"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <router-link :to="{ name: 'DashboardGroup' }" class="w-100 btn active text-start">
                  Group
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="Overview_dashborad_map_section my-4">
      <div class="card p-4">
        <div class="card-body p-0">
          <div class="d-md-flex justify-content-between align-items-center">
            <div class="mb-3">
              <h3 class="mb-2 mb-md-0">
                Test Distribution Location
                <img src="../../public/dashboard-assets/images/M2/Actions.png" alt="" />
              </h3>
              <p class="font-size-12 mt-1">Total test used: 15,610</p>
            </div>

            <div class="Descriptive_Analytics_tabs_section mb-3">
              <router-link :to="{ name: 'DashboardDescriptiveAnalystics' }" class="
                btn
                Descriptive_Analytics_tabs_link
                active
                btn-outline-primary btn-icon-text
              ">
                World Map
              </router-link>

              <router-link :to="{ name: 'DescriptiveAnalysticsCountry' }" class="btn Descriptive_Analytics_tabs_link">
                Country
              </router-link>

              <router-link :to="{ name: '' }" class="btn Descriptive_Analytics_tabs_link">
                State
              </router-link>
            </div>
          </div>
          <div class="Overview_dashborad_map_img mt-5">
            <img src="../../public/dashboard-assets/images/M2/Descriptive_Map.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="Overview_dashborad_map_section my-4">
      <div class="card p-4">
        <div class="card-body p-0">
          <div class="d-flex justify-content-between align-items-center">
            <div class="">
              <h3 class="mb-2 mb-md-0">
                Test Activity
                <img src="../../public/dashboard-assets/images/M2/Actions.png" alt="" />
              </h3>
              <p class="font-size-12 mt-1">Total test used: 15,610</p>
            </div>

            <div class="partner_item_action">
              <div class="dropdown group_partenr_dropdown_button">
                <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Last 7 Days
                  <i class="fa-solid fa-chevron-down ms-4"></i>
                </button>
                <!-- <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li>
                  <button type="button" class="btn w-100 text-start">
                    2015
                  </button>
                </li>
                <li>
                  <button type="button" class="btn w-100 text-start">
                    2016
                  </button>
                </li>
                <li>
                  <button type="button" class="btn w-100 text-start">
                    2017
                  </button>
                </li>
                <li>
                  <button type="button" class="btn w-100 text-start">
                    2018
                  </button>
                </li>
                <li>
                  <button type="button" class="btn w-100 text-start">
                    2019
                  </button>
                </li>
                <li>
                  <button type="button" class="btn w-100 text-start">
                    2020
                  </button>
                </li>
              </ul> -->
              </div>
            </div>
          </div>
          <div class="Overview_dashborad_map_img mt-5">
            <img src="../../public/dashboard-assets/images/M2/TestAllocation.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="Overview_dashborad_map_section">
      <div class="card p-4">
        <div class="card-body p-0">
          <div class="d-flex justify-content-between align-items-center">
            <div class="">
              <h3 class="mb-2 mb-md-0">
                Test Activity
                <img src="../../public/dashboard-assets/images/M2/Actions.png" alt="" />
              </h3>
            </div>
          </div>
          <div class="Overview_dashborad_map_img mt-5">
            <img src="../../public/dashboard-assets/images/M2/TestUsageOverTime.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="Overview_dashborad_map_section mt-3">
      <div class="card p-4">
        <div class="card-body p-0">
          <div class="d-flex justify-content-between align-items-center">
            <div class="">
              <h3 class="mb-2 mb-md-0">
                Summary of Active Tests
                <img src="../../public/dashboard-assets/images/M2/Actions.png" alt="" />
              </h3>
              <div>
                <router-link :to="{ name: 'DescriptiveAnalysticsdetails' }" class="btn btn-primary">
                  All view
                </router-link>
              </div>

            </div>
          </div>
          <div class="Overview_dashborad_map_img mt-5">
            <div class="row">
              <div class="col-6 col-lg-3  me-0 pe-0">
                <div>
                  <table class="table">
                    <thead class="table-header-bg-active-kj">
                      <tr>
                        <th>Test Name</th>
                      </tr>
                    </thead>
                    <tbody class="bg-white">
                      <tr>
                        <td>Customer Orientation Test </td>
                      </tr>
                      <tr>
                        <td>Sales Orientation Test </td>
                      </tr>
                      <tr>
                        <td>Psychometric Test </td>
                      </tr>
                      <tr>
                        <td>Language Test </td>
                      </tr>
                      <tr>
                        <td>Pmaps Test </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-6 col-lg-9  ms-0 ps-0">
                <div class="table-responsive ">
                  <table class="table bg-white">
                    <thead class="table-header-bg-active-kj">
                      <tr>
                        <th>Partners</th>
                        <th>Test Used</th>
                        <th>Overall Passe</th>
                        <th>Avg Time spent (min)</th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr>

                        <td>Techmahindra</td>
                        <td>800</td>
                        <td>90%</td>
                        <td>04:00</td>


                      </tr>
                      <tr>
                        <td>Mindtree</td>
                        <td>300</td>
                        <td>80%</td>
                        <td>14.00</td>
                      </tr>
                      <tr>
                        <td>Cogent</td>
                        <td>200</td>
                        <td>75%</td>
                        <td>20.00</td>
                      </tr>
                      <tr>
                        <td>Startech</td>
                        <td>502</td>
                        <td>50%</td>
                        <td>20.00</td>
                      </tr>
                      <tr>
                        <td>Mindtree</td>
                        <td>15</td>
                        <td>80%</td>
                        <td>10.20</td>
                      </tr>
                    </tbody>

                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Welcome Modal -->
    <div class="modal fade" id="WelcomePopupModal"  tabindex="-1" aria-labelledby="WelcomePopupModalLabel"
      aria-hidden="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-4 text-center">
            <h3 class="text-center mb-3">Welcome to PMaps, {{ this.user.firstName }}</h3>

            <p class="text-muted text-justify" style="font-size: 13px">
              Sends assessment to candidates and access to real-time reports on
              their performance and suitability.
            </p>

            <div class="loadPopupImage my-4">
              <img src="../../public/dashboard-assets/images/Welcome_ill_img.png" width="60%" class="loadPopupImage_img"
                alt="">
            </div>

            <button class="btn btn-primary w-100" @click="hideWelcome" aria-label="btn-close">
              Get started
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="choose-Date" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20 model-sm">
        <div class="modal-content border-radius-kj-20">
          <div class="modal-header token-model-header-color">
            <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel"><span class="font-size-20-kj">
                Choose Date & Time</span>
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"></button>
          </div>
          <div class="modal-body p-4">
            <img src="../../public/dashboard-assets/images/M2/date-img.png" class="w-100" />

            <div class="d-flex justify-content-between align-items-center mt-3">
              <span class="View-Candidates-date-show">27/08/2021 - 02/09/2021</span>
              <button type="button" class="btn btn-primary btn-download">
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "dashboard-descriptive-analystics",
  data() {
    return {
      lastLoggedInDate: "",
    };
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      Date: (state) => state.auth.user.lastLoggedInDate,
    }),
  },
  methods: {
    ...mapActions(["GetAccessToken"]),
    hideWelcome(){
      window.$("div").removeClass("modal-backdrop");
      window.$("#WelcomePopupModal").modal("hide")
    }
  },
  async mounted() {
    if (this.user != null && Object.keys(this.user).length !== 0) {
      if (!this.user.termsAndConditionsAccepted) {
        await this.GetAccessToken({ userId: this.user.userId });
        this.$router.push({
          name: "TermsPop",
        });
      } else if (!this.user.passwordSet) {
        await this.GetAccessToken({ userId: this.user.userId });
        this.$router.push({
          name: "SetPassword",
        });
      }
      //  else if (this.user.twoFactorRequired) {
      //   this.$router.push({
      //     name: "OtpCode",
      //   });
      // }
      else {
        window.$("#WelcomePopupModal").modal("hide");
        this.lastLoggedInDate = this.Date;
        if (this.lastLoggedInDate === null)
          window.$("#WelcomePopupModal").modal("show");
      }
    }
  },
};
</script>
