<template>
    <section class="container-fluid">
        <div class="logo_section">
            <img src="../assets/Images/logo.png" alt="" width="120" />
        </div>
        <div class="container">
            <div class="row align-content-center justify-content-center text-center">
                <div class="link-expired_content">
                    <h3 class="font-weight-800">Uh-oh! Seems like a dead end.</h3>
                    <p class="text-muted">
                        {{ message }}
                    </p>
                    <div>
                        <img src="../assets/Images/link_expired.png" alt="" width="200" />
                    </div>
                    <div class="login_button_area mt-4">
                        <router-link :to="{ name: 'Login' }" class="btn btn-primary me-2 mb-3">Home</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="">
            <img src="../assets/Images/Vector.png" alt="" class="Vector_one_img" />
            <img src="../assets/Images/Vector2.png" alt="" class="Vector_secand_img" />
            <img src="../assets/Images/Vector4.png" alt="" class="Vector_fore_img" />
            <img src="../assets/Images/Vector3.png" alt="" class="Vector_three_img" />
        </div>
    </section>
</template>
<script>
import { mapState } from "vuex";
export default {
    name: "ErrorPage",
    computed: {
        ...mapState({
            message: (state) => state.popup.message,
        }),
    },
};
</script>