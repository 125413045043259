<template>
  <div class="page-content">
    <div
      class="d-flex justify-content-between align-items-center flex-wrap mb-2 header-MV-kj d-none d-md-flex"
    >
      <div class="my-2 d-flex">
        <!------- COMMENTED AS PER CLIENT INSTRUCTIONS-------->
        <!-- <div class="top_heading_CreatePartner_btn d-block me-2">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <router-link :to="{ name: 'ManagementPartners' }" class="btn p-0 Group_top_three_dot_dropdown"
                type="button" style="line-height: 2.3">
                <i class="fa-solid fa-chevron-left"></i>
              </router-link>
            </div>
          </div>
        </div> -->
        <!------- COMMENTED AS PER CLIENT INSTRUCTIONS-------->
        <div class="top_heading_CreatePartner_btn d-block me-2">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <router-link
                :to="{
                  name: 'ManagementPartners',
                  query: {
                    offset: this.$route.params.offset,
                    current: this.$route.params.current,
                    search: this.$route.params.search
                  },
                }"
                class="btn p-0 Group_top_three_dot_dropdown"
                type="button"
                style="line-height: 2.6"
              >
                <i class="fa-solid fa-chevron-left"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div>
          <h3 class="mb-2 mb-md-0">
            {{ getParnter.companyName }}
          </h3>
          <h5 class="text-muted">
            Total Group Members ({{ Regionmemberlistlength }})
          </h5>
        </div>
      </div>
      <!-- Search  web view-->
      <div class="top_heading_CreatePartner_btn position-relative">
        <div class="Group_top_three_dot">
          <div class="Searching_input-box">
            <input
              type="text"
              v-model="input"
              @keyup="clearSearch(input)"
              @keydown.enter="searchregionname(input)"
              placeholder="Search..."
            />
            <!-- <div class="search_detail_list" v-if="this.result.length > 0">
              <div v-for="(item, index) in this.result" :key="index" class="search_detail_list_item">
                <span :id="index" @click="searchregionname(item)">
                  {{ stringTOHtml(item, index) }}
                </span>
              </div>
            </div> -->
            <span class="icon Group_top_three_dot_dropdown" @click="opensearch">
              <img
                src="../../public/dashboard-assets/images/M2/Icon-Set.png"
                alt=""
                class="Searching_search_icon"
              />
            </span>
            <img
              src="../../public/dashboard-assets/images/M2/Close_icon.png"
              @click="closesearch"
              alt=""
              class="close-icon"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- </div>
      </div> -->
    <div
      class="header-2-MV-kj justify-content-between align-items-center flex-wrap mb-2"
    >
      <div class="d-flex my-2">
        <div class="top_heading_CreatePartner_btn d-block me-2">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <router-link
                :to="{ name: 'ManagementPartners' }"
                class="btn p-0 Group_top_three_dot_dropdown"
                type="button"
              >
                <i class="fa-solid fa-chevron-left"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div>
          <h4 class="mb-2 mb-md-0">Partner</h4>
          <nav style="--bs-breadcrumb-divider: >" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link
                  :to="{ name: 'ManagementPartners' }"
                  style="color: #7987a1 !important"
                  >{{ getParnter.companyName }}</router-link
                >
                >
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Total Group Members ({{ GetRegionTeam.length }})
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="top_heading_CreatePartner_btn d-md-block d-none">
        <div class="Group_top_three_dot">
          <div class="dropdown">
            <button
              class="btn p-0 Group_top_three_dot_dropdown"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style="line-height: 0.3"
            >
              <img
                src="../../public/dashboard-assets/images/M2/Icon-Set.png"
                alt=""
                width="50%"
              />
            </button>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
    <section
      class="Partner_empty_main_section_hp"
      v-if="GetRegionTeam.length <= 0"
    >
      <div class="row">
        <div class="Partner_empty_img_hp m-auto text-center">
          <img
            src="../../public/dashboard-assets/images/M2/Empty_member.png"
            alt=""
          />
        </div>
        <div class="Partner_empty_content_hp m-auto text-center">
          <div class="mb-4">
            <h2>Uhhh, No Members!</h2>
            <p class="text-muted mt-2">Your list of groups will appear here</p>
          </div>
        </div>
      </div>
    </section>

    <div class="row" v-if="GetRegionTeam.length > 0">
      <div class="col-md-12">
        <div class="accordion" id="FaqAccordion">
          <div class="managementPartner-none">
            <div
              class="card managementPartner-header-kj mb-2 teble_heading_section_hp"
            >
              <table class="table ActiveTestTable">
                <thead>
                  <tr>
                    <th class="w-50 teble_heading_item_hp">Name</th>
                    <th class="w-50 teble_heading_item_hp">Email</th>
                    <th class="w-50 teble_heading_item_hp">Role</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div class="managementPartner-D-M-V">
            <div class="card managementPartner-header-kj mb-2">
              <div class="card-body p-2">
                <div class="row">
                  <div class="col-9"><span class=""> Name </span></div>
                  <div class="col-3 p-0"><span class=""> Details </span></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="accordion-item border-radius-kj my-3"
            v-for="(team, index) in GetRegionTeam"
            :key="index"
          >
            <h2 class="accordion-header" :id="'headingOne' + team.regionId">
              <button
                class="accordion-button text-dark accordion-color-kj"
                :class="{'collapsed':team.show==false}"
                type="button"
                @click="showTeam(team.regionId)"
                data-bs-toggle="collapse"
                aria-expanded="true"
                :aria-controls="'collapseOne' + team.regionId"
              >
                {{ team.regionName }}
                <span class="text-muted"
                  >&nbsp; ({{ team.teamMemberCount }})</span
                >
              </button>
            </h2>
            <div
            :class="{'show':team.show }"
              class="accordion-collapse collapse border-top"
              :aria-labelledby="'headingOne' + team.regionId"
              data-bs-parent="#FaqAccordion"
            >
              <div class="accordion-body p-0 managementPartner-Acc-p-kj">
                <!-- web UI -->
                <div
                  class="managementPartner-none"
                  v-if="team.teamMemberCount != 0"
                >
                  <table class="table ActiveTestTable">
                    <tbody>
                      <tr v-for="teams in team.Regionmemberlist" :key="teams">
                        <td class="w-50">
                          <div
                            class="d-flex align-items-center"
                            style="font-weight: 700"
                          >
                            <div class="PartnerTeamMembersProfile-area-kj">
                              <div
                                class="PartnerTeamMembersProfile-kj name-logo-1"
                              >
                                {{ teams.firstName[0] }}
                              </div>
                            </div>
                            {{ teams.firstName }}
                            {{ teams.lastName ? team.lastName : "" }}
                            &nbsp;&nbsp;
                            <span
                              class="master-Admin-kj"
                              v-if="teams.roleName == 'Owner'"
                            >
                              Master Admin
                            </span>
                          </div>
                        </td>
                        <td class="w-50">
                          <a
                            href="mailto:samlockyisthebest122@gmail.com"
                            mailto:class="text-dark"
                          >
                            <span class="web-a-color-kj">
                              {{ teams.email }}
                            </span>
                          </a>
                        </td>
                        <td class="w-50">
                          <span class="web-a-color-kj">{{
                            teams.roleName
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Moblie Ui -->
                <div
                  class="managementPartner-D-M-V"
                  v-if="team.teamMemberCount != 0"
                >
                  <div
                    class="card mb-2"
                    v-for="teams in team.Regionmemberlist"
                    :key="teams"
                  >
                    <div class="card-body p-2">
                      <div class="row">
                        <div class="col-2">
                          <div class="PartnerTeamMembersProfile-area-kj">
                            <div
                              class="PartnerTeamMembersProfile-kj name-logo-1"
                            >
                              {{ teams.firstName[0] }}
                            </div>
                          </div>
                        </div>
                        <div class="col-7">
                          <div>
                            <span class="font-size-12-MV-kj">
                              {{ teams.firstName + " " + teams.lastName }}</span
                            ><span
                              class="master-Admin-kj"
                              v-if="teams.roleName == 'Owner'"
                              >Master Admin
                            </span>
                          </div>
                          <div>
                            <a
                              href="mailto:samlockyisthebest122@gmail.com"
                              mailto:class="text-dark"
                              ><span class="font-size-10-MV-kj text-muted">
                                {{ teams.email }}</span
                              >
                            </a>
                          </div>
                        </div>
                        <div class="col-3 p-0">
                          <span class="font-size-12-MV-kj text-muted">{{
                            teams.roleName
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "ManagementPartnersDetail",
  data() {
    return {
      GetRegionTeam: [],
      getParnter: "",
      Regionmemberlistlength: 0,
      Regionmember: [],
      result: [],
      regionTeam: [],
      teamRegion: [],
      input: "",
      resultList: [],
      subResultList: [],
    };
  },
  methods: {
    ...mapActions(["getpartner", "Getregionmembers", "Getteammembers"]),

    // Searching box
    opensearch() {
      document.querySelector(".Searching_input-box").classList.add("open");
    },
    closesearch() {
      document.querySelector(".Searching_input-box").classList.remove("open");
      this.input = "";
      this.GetRegionTeam = JSON.parse(JSON.stringify([...this.regionTeam]));
    },
    clearSearch(input) {
      if (input == "" || input == null) {
        this.GetRegionTeam = [];
        this.GetRegionTeam = JSON.parse(JSON.stringify([...this.regionTeam]));
      }
    },
    searchregionname(input) {
      this.GetRegionTeam = JSON.parse(JSON.stringify([...this.regionTeam]));
      this.input = this.input.trim();
      if (input != null || input != "") {
        this.resultList = [];
        var str1, str2;
        if (input.trim().includes(" ")) {
          var array = input.split(" ");
          str1 = array[0];
          str2 = array[1];
        } else {
          str1 = input;
        }

        this.GetRegionTeam.forEach((element) => {
          element.Regionmemberlist?.forEach((elm) => {
            if (str1 && str2) {
              if (
                (elm.firstName + " " + elm.lastName)
                  ?.toLowerCase()
                  .includes((str1 + " " + str2).toLowerCase())
              ) {
                element.Regionmemberlist = [];
                element.Regionmemberlist.push(JSON.parse(JSON.stringify(elm)));
                element.teamMemberCount = element.Regionmemberlist.length;
                this.resultList.push(JSON.parse(JSON.stringify(element)));
              }
            } else {
              if (str1) {
                if (
                  elm.email
                    ?.toLowerCase()
                    .includes(str1.trim().toLowerCase()) ||
                  elm.firstName
                    ?.toLowerCase()
                    .includes(str1.trim().toLowerCase()) ||
                  elm.lastName
                    ?.toLowerCase()
                    .includes(str1.trim().toLowerCase())
                ) {
                  element.Regionmemberlist = [];
                  element.Regionmemberlist.push(
                    JSON.parse(JSON.stringify(elm))
                  );
                  element.teamMemberCount = element.Regionmemberlist.length;
                  this.resultList.push(JSON.parse(JSON.stringify(element)));
                }
              }
            }
          });
        });
        this.GetRegionTeam = JSON.parse(JSON.stringify([...this.resultList]));
      }
    },

    // closemobilesearch() {
    //   this.show = false;
    //   document
    //     .querySelector(".Searching_input_box_mobile_view")
    //     .classList.remove("open");
    // },
    // openmobilesearch() {
    //   document
    //     .querySelector(".Searching_input_box_mobile_view")
    //     .classList.add("open");
    //   this.input = "";
    //   this.show = true;
    // },

    // Searching box
    showTeam(id){
      this.GetRegionTeam.forEach((elm)=>{
        if(elm.regionId == id && elm.show!=true) elm.show = true;
        else elm.show = false; 
      })
    }
  },

  async mounted() {
    var id = window.atob(this.$route.params.id);
    var data = {
      clientId: id,
    };
    var getpartner = await this.getpartner(data);
    if (getpartner.status) {
      this.getParnter = getpartner.object.data;
    }

    const model = {
      clientId: window.atob(this.$route.params.id),
    };
    var res = await this.Getteammembers(model);
    this.regionTeam = JSON.parse(JSON.stringify(res.object.data) ?? null);
    for (let element of this.regionTeam) {
      if (element.teamMemberCount != 0) {
        var data2 = {
          regionId: element.regionId,
        };
        var getpartner2 = await this.Getregionmembers(data2);

        if (getpartner2) {
          element.Regionmemberlist = await getpartner2.object.data;
          this.Regionmemberlistlength += element.teamMemberCount;
        } else {
          element.Regionmemberlist = [];
        }
      }
    }

    this.Regionmemberlistlength = 0;
    this.GetRegionTeam = JSON.parse(JSON.stringify(res.object.data) ?? null);
    for (let element of this.GetRegionTeam) {
      element.show = false;
      if (element.teamMemberCount != 0) {
        var data1 = {
          regionId: element.regionId,
        };
        var getpartner1 = await this.Getregionmembers(data1);

        if (getpartner1) {
          element.Regionmemberlist = await getpartner1.object.data;
          this.Regionmemberlistlength += element.teamMemberCount;
        } else {
          element.Regionmemberlist = [];
        }
      }
    }
  },

  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      clientId: (state) => state.auth.user.clientId,
      PartnerId: (state) => state.auth.user.PartnerId,
    }),
  },
};
</script>
<style lang=""></style>
