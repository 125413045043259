<template>
    <div style="margin-top: 5px;">
        <div class="mt-0" style="padding: 4px; border-radius: 3px;border: 1px solid #E4E4E4;" v-if="qType == 1003">
            <div style="display: flex; align-items: start;font-weight: 700;">
                <p style="margin-right: 3px;">Q.{{ this.index + 1 }}</p>
                <div style="display: flex;flex-wrap: wrap; align-items: start;">
                    <p v-html="this.question?.question"></p>
                    <p v-if="question.responses == null && qType != 1005" style="
                    color: #FF2A87;
    background-color: #FFAFD233;
    padding: 2px 4px 1.8px;
    font-size:3px;
    border-radius: 20px;
    margin-left: 5px;
    margin-top: 0px;">Unanswered</p>

                </div>
            </div>
            <div :id="'Q' + this.index">
                <div class="accordion-body p-0">
                    <div class="ms-3" v-if="this.question?.imageUrl">
                        <img v-bind:src="this.question?.imageUrl" alt="">
                    </div>
                    <hr style="margin: 5px 0; border-color: #e9ecef; border-width: 1px;">
                    <div class="Report_Responses_Answer_option_list_area">
                        <div v-for="data in this.question?.questionOptions" :key="data"
                            class="Report_Responses_Answer_option_item" :class="[
                                data.isPsychometryOption
                                    ? data.isChecked
                                        ? 'Report_Responses_right_option_section'
                                        : ''
                                    : data.optionWeightage > 0.0
                                        ? 'Report_Responses_right_option_section'
                                        : data.isChecked
                                            ? 'Report_Responses_wrong_option_section'
                                            : '',
                            ]">
                            {{ data.optionName }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-0" style="padding: 4px; border-radius: 3px;border: 1px solid #E4E4E4;" v-if="qType == 1005">
            <div style="display: flex; align-items: start;font-weight: 700;">
                <p style="margin-right: 3px;">Q.{{ this.index + 1 }}</p>
                <div style="display: flex;flex-wrap: wrap; align-items: start;">
                    <p v-html="this.question?.question"></p>
                    <p v-if="question.responses == null && qType != 1005" style="
                    color: #FF2A87;
    background-color: #FFAFD233;
    padding: 2px 4px 1.8px;
    font-size:3px;
    border-radius: 20px;
    margin-left: 5px;
    margin-top: 0px;">Unanswered</p>
                </div>
            </div>
            <div :id="'Q' + this.index">
                <div class="accordion-body p-0">
                    <div class="ms-3" v-if="this.question?.imageUrl">
                        <img v-bind:src="this.question?.imageUrl" class="w-50" alt="">
                    </div>
                    <hr style="margin: 5px 0; border-color: #e9ecef; border-width: 1px;">
                    <SubQuestionTypepdf v-for="(subQ, i) in this.question?.dependentQuestions" :subQuestion="subQ" :key="i"
                        :quesType="subQ?.questionType" :pIndex="this.index" :index="i" :type="'pdf'"></SubQuestionTypepdf>
                    <hr style="margin: 5px 0; border-color: #e9ecef;">
                </div>
            </div>
        </div>
        <div class="mt-0" style="padding: 4px; border-radius: 3px;border: 1px solid #E4E4E4;" v-if="qType == 1037">
            <div cstyle="display: flex; align-items: start;font-weight: 700;">
                <p style="margin-right: 3px;">Q 1:</p>
                <div class="col-md-10">
                    <div class="row">
                        <p class="col-12" v-html="this.question.question"></p>
                        <p>{{ this.question?.dependentQuestions.mediaAudio }}</p>
                        <!-- <audio class="col-4" controls v-if="this.question?.mediaAudio">
                                <source :src="this.question?.mediaAudio" type="audio/mpeg" />
                            </audio> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-0" style="padding: 4px; border-radius: 3px;border: 1px solid #E4E4E4;" v-if="qType == 1036">
            <div class="fw-bolder">
                <div style="display: flex; align-items: start;font-weight: 700;">
                    <p style="margin-right: 3px; width: 20px;">Q {{ this.index + 1 }}:</p>

                    <div style="display: flex;flex-wrap: wrap; align-items: flex-start;">
                        <p class="col-12" v-html="this.question.question"></p>
                        <p v-if="question.responses == null && qType != 1005" style="
                                color: #FF2A87;
                                background-color: #FFAFD233;
                                padding: 2px 4px 1.8px;
                                font-size:3px;
                                border-radius: 20px;
                                margin-left: 5px;
                                margin-top: 0px;">Unanswered</p>
                    </div>
                    <p>{{ this.question?.mediaAudio }}</p>
                    <!-- <audio class="col-4" controls v-if="this.question?.mediaAudio">
                                <source :src="this.question?.mediaAudio" type="audio/mpeg" />
                            </audio> -->
                </div>
            </div>
            <div>
                <div class="accordion-body p-0">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="Report_basic_info_area active-report-repsponses my-4"
                                v-for="resp in this.question?.responses" :key="resp">
                                <p class="" style="margin: 3px 0; border-top: 1px solid #E4E4E4; border-bottom: 1px solid #E4E4E4
                                ; padding: 3px;">
                                    <b>Candidate Inputs:</b> <br>
                                    {{ resp.candidateInputs }}
                                </p>
                                <div class="table-responsive-sm table-border-M7-responsive mt-3"
                                    style="font-size: 8px !important;">
                                    <table class="table table-border-M7-kj">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <h5 style="font-size: 8px !important ;" class="table-title">Response
                                                        Confidence</h5>
                                                    <P>{{ resp?.scoringResults?.outputConfidence }}</P>
                                                </td>
                                                <td>
                                                    <h5 style="font-size: 8px !important;" class="table-title">
                                                        Pronunciation(%)</h5>
                                                    <P>{{ resp?.scoringResults?.pronunciationAbs }}</P>
                                                </td>
                                                <td>
                                                    <h5 style="font-size: 8px !important;" class="table-title">Fluency(%)
                                                    </h5>
                                                    <P>{{ resp?.scoringResults?.fluencyAbs }}</P>
                                                </td>
                                                <td>
                                                    <h5 style="font-size: 8px !important;" class="table-title">Grammar(%)
                                                    </h5>
                                                    <P>{{ resp?.scoringResults?.grammerAbs }}</P>
                                                </td>
                                                <td>
                                                    <h5 style="font-size: 8px !important;" class="table-title">
                                                        Comprehension(%)</h5>
                                                    <P>{{ resp?.scoringResults?.comprehensionAbs }}</P>
                                                </td>
                                                <td>
                                                    <h5 style="font-size: 8px !important;" class="table-title">Vocabulary(%)
                                                    </h5>
                                                    <P>{{ resp?.scoringResults?.vocabularyAbs }}</P>
                                                </td>
                                                <td>
                                                    <h5 style="font-size: 8px !important;" class="table-title">Transcribe(%)
                                                    </h5>
                                                    <P>{{ resp?.scoringResults?.transcribe }}</P>
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="mt-0" style="padding: 4px; border-radius: 3px;border: 1px solid #E4E4E4;" v-if="qType == 1027">
            <div class="fw-bolder d-flex">
                <p style="margin-right: 3px;">Q.{{ this.index + 1 }}</p>
                <div style="display: flex;flex-wrap: wrap; align-items: flex-start;">
                    <p v-html="this.question.question"></p>
                    <p v-if="question.responses == null && qType != 1005" style="
                                color: #FF2A87;
                                background-color: #FFAFD233;
                                padding: 2px 4px 1.8px;
                                font-size:3px;
                                border-radius: 20px;
                                margin-left: 5px;
                                margin-top: 0px;">Unanswered</p>
                </div>
            </div>
            <div>
                <div class="accordion-body">
                    <div class="">
                        <div class="col-xl-6 col-xl-7 col-md-12">
                            <div class="table-border-M7">
                                <div class="row border">
                                    <div class="col-md-6 p-0">
                                        <div class="border p-2 bg-secondary-option">
                                            Option
                                        </div>
                                        <div v-for="data in questionOptions(true)" :key="data">
                                            <div class="border-top border-bottom p-2"
                                                :class="{ 'Report_Responses_right_option_section border': data.isChecked }">
                                                <p>
                                                    {{ data.optionName }}
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-6 p-0">
                                        <div class="border p-2 bg-secondary-option">
                                            Option
                                        </div>
                                        <div class="" v-for="data in questionOptions(false)" :key="data">
                                            <div class="border-top border-bottom p-2"
                                                :class="{ 'Report_Responses_wrong_option_section border': data.isChecked }">
                                                <p>
                                                    {{ data.optionName }}
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>





                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <p class="fw-bolder">Candidate Input</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-0" style="padding: 4px; border-radius: 3px;border: 1px solid #E4E4E4;" v-if="qType == 1039">
            <div class="fw-bolder align-items-sm-start d-flex">
                <p style="margin-right: 3px;">Q.{{ this.index + 1 }}</p>
                <div style="display: flex;flex-wrap: wrap; align-items: flex-start;">
                    <p v-html="this.question?.question"></p>
                    <p v-if="question.responses == null && qType != 1005" style="
                                color: #FF2A87;
                                background-color: #FFAFD233;
                                padding: 2px 4px 1.8px;
                                font-size:3px;
                                border-radius: 20px;
                                margin-left: 5px;
                                margin-top: 0px;">Unanswered</p>

                </div>
            </div>
            <div>
                <div class="accordion-body p-0">
                    <SubQuestionTypepdf v-for="(subQ, i) in this.question?.dependentQuestions" :subQuestion="subQ" :key="i"
                        :quesType="subQ?.questionType" :pIndex="this.index" :index="i" :type="'pdf'"></SubQuestionTypepdf>
                        <hr style="margin: 5px 0; border-color: #e9ecef;">
                </div>
            </div>
        </div>
        <div class="mt-0" style="padding: 4px; border-radius: 3px;border: 1px solid #E4E4E4;" v-if="qType == 1020">
            <div class="fw-bolder d-flex">
                <p style="margin-right: 3px;">Q.{{ this.index + 1 }}</p>
                <div style="display: flex;flex-wrap: wrap; align-items: flex-start;">
                    <p v-html="this.question?.question"></p>
                    <p v-if="question.responses == null && qType != 1005" style="
                                color: #FF2A87;
                                background-color: #FFAFD233;
                                padding: 2px 4px 1.8px;
                                font-size:3px;
                                border-radius: 20px;
                                margin-left: 5px;
                                margin-top: 0px;">Unanswered</p>

                </div>
            </div>
            <div>
                <div class="accordion- p-0">
                    <div class="Report_basic_info_area active-report-repsponses my-4"
                        v-for="resp in this.question?.responses" :key="resp">
                        <p class="my-3 border-top p-3">
                            <b>Candidate Input:</b> <br>
                            {{ resp.candidateInputs }}
                        </p>

                    </div>
                </div>
            </div>
        </div>
        <div class="mt-0" style="padding: 4px; border-radius: 3px;border: 1px solid #E4E4E4;" v-if="qType == 1008">
            <div class="fw-bolder d-flex">
                <p style="margin-right: 3px;">Q.{{ this.index + 1 }}</p>
                <div style="display: flex;flex-wrap: wrap; align-items: flex-start;">
                    <p v-html="this.question?.question"></p>
                    <p v-if="question.responses == null && qType != 1005" style="
                                color: #FF2A87;
                                background-color: #FFAFD233;
                                padding: 2px 4px 1.8px;
                                font-size:3px;
                                border-radius: 20px;
                                margin-left: 5px;
                                margin-top: 0px;">Unanswered</p>

                </div>
            </div>
            <div>
                <p class="p-3">{{ this.question?.passage }}</p>
                <div class="accordion-body" v-for="sectionRes in this.question.responses" :key="sectionRes">
                    <p class="my-3 border-top py-3">
                        <b>Candidate Inputs:</b> <br>
                        {{ sectionRes.candidateInputs }}
                    </p>

                </div>
            </div>
        </div>

    </div>
</template>
<script>
import SubQuestionTypepdf from '../question-type-components/Sub-Question-Type-pdf.vue';
export default {
    name: "QuestionTypePdf",
    props: ["qType", "question", "index"],
    components: {
        SubQuestionTypepdf
    },
    data() {
        return {
            isShow: false,
            questiontype: false,
        };
    },
    methods: {
        questionOptions(type) {
            if (type == true) {
                var x = this.question?.questionOptions.filter((x) => x.optionWeightage > 0)
                return x;
                //    console.log(x);
            } else {
                var y = this.question?.questionOptions.filter((x) => x.optionWeightage <= 0);
                return y;
                // console.log(y);
            }

        }
    }
};
</script>
<style>
.bg-secondary-option {
    background: #d2d2d2;
}
</style>