export const setAuthenticated = (state, user) => {
  state.authenticate = true;
  state.user = user;
};

export const subscription = (state, subscription) => {
  state.subscription = subscription;
};

export const logout = (state) => {
  state.authenticate = false;
  state.user = null;
};

export const setPaginationValue = (state) => {
  state.pagination = localStorage.getItem("pagination") && 5;
};
export const setIsNewAccountValue = (state, IsNewAccount) => {
  state.IsNewAccount = IsNewAccount;
};
export const setUserIdValue = (state, userId) => {
  state.UserId = userId;
};

export const setParent = (state, permission) => {
  state.permission = permission;
};


