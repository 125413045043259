<template>
    <div class="page-content">

        <!--  Activated Groups-Toast -->
        <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
            <div class="my-2">
                <h3 class="mb-2 mb-md-0"> Groups</h3>
            </div>
            <div class="d-flex justify-content-between align-items-center">
                <!-- Search -->

                <div class="top_heading_CreatePartner_btn d-block">
                    <div class="Group_top_three_dot">
                        <div class="dropdown"><button class="btn p-0 Group_top_three_dot_dropdown" type="button"
                                id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false" style="line-height:0.3;"><img
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIXSURBVHgB3VU7TwJBEF6eMaHRzhJ/AMnxbIn/gNLOI+FhhyUdR2eJBYHjEY+STksrodMCWP8BdthRGgjgN+TuvCwHeEQLnWSze7uz883szDfH2F8X165DWZaPfT7fpdvtlpbLZXB9weXimPhisei12+03dihAJpMpwdg1xvFqtZoChAwzAEnGHkal1WqVmRMA8trv9z9hSYY0jE69Xu9ZdbLZrATAAgBkimY2m51rmja1A/CKG2QcF4MwLKuq2rG71Gw2KZp0Pp/niEjRHQrb6XqsH7lcroTpAqMIIyrbI4PB4CUWi02wvAqHw2w4HPZFHfOJ8DRBJHSE0O/heZo5EERyh0hS8/n8THwqt7FAmElKHhRvmUOBU5SrdcVtnFnWElWG/r6OpFar9fVKk7YCwAM6dGzcNIQyNrhiC/BbYgUYY0jsQNEJyLcCQIFTokCiJHMoRDy6y2ye2ARAiXUoUVjKzLkQq6eoop54YBKNc/4B0hxR/4lGo2OQ6JV9Q+B9AXeKALgBfx7E841eBDaPqFVgqaBkd3ICulT3ml7eJ3Y6HnEjFAp1vV5vinpRJBIJxuNxiuZd8DqJKBXoKPgcYz7d2ypsvCvBM7Nds68Emu0ac6XRaJT1HqZQ4xPbt2cbALzuJxKJLgxxkGhCXmIQIZ+xpwUCgXS1Wn00dBERnSliJDv/aE7FiIQiN/L3o0ym5yIA9q/kE1RW/0GByI6AAAAAAElFTkSuQmCC"></button>
                        </div>
                    </div>
                </div>
                <!-- Filter -->
                <div class="top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj d-block mx-2">
                    <div class="Group_top_three_dot">
                        <div class="dropdown">
                            <button class="btn p-0  Group_top_three_dot_dropdown position-relative" type="button"
                                id="dropdownMenuButton1" data-bs-toggle="dropdown" data-bs-auto-close="outside"
                                aria-expanded="false" aria-haspopup="true" style="line-height:0.3;"><img
                                    src="../../public/dashboard-assets/images/M2/Filter_icon.png">

                                <span class="Noti-alert-kj  translate-middle">
                                    5 <span class="visually-hidden"></span>
                                </span>
                            </button>

                            <ul class="dropdown-menu dropdown-menu-kj" aria-labelledby="dropdownMenuButton1">
                                <div class="modal-header">
                                    <h4 class="modal-title text-centerm-auto" id="CreateGroupModalLabel">
                                        Filter
                                    </h4>
                                    <button type="button" class="btn-close" data-bs-toggle="dropdown"
                                        data-bs-auto-close="inside"></button>
                                </div>

                                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li class="nav-item w-50" role="presentation">
                                        <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-home" type="button" role="tab"
                                            aria-controls="pills-home" aria-selected="true">Department</button>
                                    </li>
                                    <li class="nav-item w-50" role="presentation">
                                        <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-profile" type="button" role="tab"
                                            aria-controls="pills-profile" aria-selected="false">Location</button>
                                    </li>

                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active p-0" id="pills-home" role="tabpanel"
                                        aria-labelledby="pills-home-tab">
                                        <div class="search-bar">
                                            <form class="search-form"><input type="text" placeholder="Search" name=""
                                                    class="form-control bg-white"><button class="btn"><i
                                                        class="fas fa-search"></i></button></form>
                                            <div class="ul-li-checkbox-kj">
                                                <ul>
                                                    <li> <input type="checkbox" name="" id=""> <span>JV Department
                                                        </span></li>
                                                    <li> <input type="checkbox" name="" id=""> <span>NYC HR Department
                                                        </span></li>
                                                    <li> <input type="checkbox" name="" id=""> <span>Pune Department
                                                        </span></li>
                                                    <li> <input type="checkbox" name="" id=""> <span>Texas HR Department
                                                        </span></li>
                                                    <li> <input type="checkbox" name="" id=""> <span>Infosys Department
                                                        </span></li>
                                                    <li> <input type="checkbox" name="" id=""> <span>Mindtree HR
                                                            Department
                                                        </span></li>
                                                </ul>
                                                <div class="text-end">
                                                    <button type="button" class="btn-Apply-kj-color">Clear
                                                        Filter</button>
                                                    <button type="button"
                                                        class="btn-primary btn-Apply-kj">Apply</button>

                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                    <div class="tab-pane fade p-0" id="pills-profile" role="tabpanel"
                                        aria-labelledby="pills-profile-tab">
                                        <div class="search-bar">
                                            <form class="search-form"><input type="text" placeholder="Search" name=""
                                                    class="form-control bg-white"><button class="btn"><i
                                                        class="fas fa-search"></i></button></form>
                                        </div>
                                        <div class="ul-li-checkbox-kj">
                                            <ul>
                                                <li> <input type="checkbox" name="" id=""> <span>Africa

                                                    </span></li>
                                                <li> <input type="checkbox" name="" id=""> <span>Bhutan

                                                    </span></li>
                                                <li> <input type="checkbox" name="" id=""> <span>Chennai
                                                    </span></li>
                                                <li> <input type="checkbox" name="" id=""> <span>India

                                                    </span></li>
                                                <li> <input type="checkbox" name="" id=""> <span>Laos
                                                    </span></li>
                                                <li> <input type="checkbox" name="" id=""> <span>Singapore
                                                    </span></li>
                                            </ul>

                                            <div class="text-end">
                                                <button type="button" class="btn-Apply-kj-color">Clear Filter</button>
                                                <button type="button" class="btn-primary btn-Apply-kj">Apply</button>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </ul>
                            <!-- </div> -->

                        </div>
                    </div>
                </div>
                <!-- Assign Group -->
                <div class="top_heading_CreatePartner_btn mx-2">
                    <button type="button" class="btn Assign_Group_buttons" data-bs-toggle="modal"
                        data-bs-target="#AssignGroup">
                        Assign Group
                    </button>
                </div>
                <!-- Create Group -->
                <div class="top_heading_CreatePartner_btn mx-2">
                    <button type="button" class="btn btn-primary btn-icon-text" data-bs-toggle="modal"
                        data-bs-target="#CreateGroupModal">
                        <i class="fa-solid fa-plus"></i> Create Group
                    </button>
                </div>
                <!-- three_dot -->
                <div class="top_heading_CreatePartner_btn d-block">
                    <div class="Group_top_three_dot">
                        <div class="dropdown">
                            <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa-solid fa-ellipsis-vertical"></i>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li>
                                    <button type="button" class="btn w-100 text-start" data-bs-toggle="modal"
                                        data-bs-target="#BulkUploadModal">
                                        <i class="fa-solid fa-arrow-up-from-bracket"></i>&nbsp;&nbsp;
                                        Bulk upload
                                    </button>
                                </li>
                                <li>
                                    <button type="button" class="btn w-100 text-start">
                                        <i class="fa-solid fa-download"></i>&nbsp;&nbsp; Download
                                        Template
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- Moblie View icon Create Group -->
                <div class="mobile_Create_Partner_button managementteam-btn-kj">
                    <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#CreateGroupModal">
                        <i class="fa-solid fa-plus"></i>
                    </button>

                </div>
                <!-- Moblie View icon Create Group / Assign Group-->
                <div class="mobile_Create_Partner_button-kj">
                    <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#GroupsModel">
                        <img src="../../public/dashboard-assets/images/M2/Icon-dashboard.png" alt="">
                    </button>

                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-xl-5 col-lg-8 partners_top_menu_section_hp">
                <div class="row">
                    <div class="col-6">
                        <router-link :to="{ name: 'ManagementTeamsGroupsActivated' }" class="w-100 btn ">
                            Activated <span>(7)</span>
                        </router-link>
                    </div>
                    <div class="col-6">
                        <router-link :to="{ name: 'ManagementTeamsGroupsDeactivated' }"
                            class="w-100 btn active btn-outline-primary btn-icon-text">
                            Deactivated <span>(2)</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>


        <section class="Partner_empty_main_section_kj mt-3">
            <div class="row">
                <div class="col-md-6 col-lg-4 col-xl-3 mb-3 stretch-card">
                    <div class="card p-0">
                        <router-link :to="{ name: '' }">
                            <div class="card-body p-3">
                                <div class="Partner_management_top-kj mb-3">
                                    <h5 class="text-dark">NYC HR Department</h5>
                                    <!-- <div class="dropdown">
                                <button class="btn p-0" type="button" id="dropdownMenuButton"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa-solid fa-ellipsis"></i>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <li>
                                        <div class="dropdown-item d-flex align-items-center">
                                            Remove
                                        </div>
                                       
                                    </li>
                                </ul>
                            </div> -->
                                </div>
                                <div class="Partner_management_top-kj">
                                    <div class="Partner_management_contant-kj">
                                        <div class="mb-3">
                                            <i class="fa-solid fa-users"></i><span class="text-dark"> Members</span>
                                        </div>
                                        <h6>10</h6>
                                    </div>
                                    <div class="Partner_management_contant-2-kj">
                                        <div class="mb-3">
                                            <i class="fa-solid fa-location-dot"></i><span class="text-dark">
                                                Location</span>
                                        </div>
                                        <h6>Singapore</h6>
                                    </div>
                                </div>

                            </div>
                            <div class="card-footer card-footer-bg-kj " data-bs-toggle="modal"
                                data-bs-target="#DeactivatePartnerModal">
                                Reactivate
                            </div>
                        </router-link>
                    </div>
                </div>

            </div>
            <div class="row justify-content-end">
                <div class="col-lg-4">
                    <div class="Partner_management_top-kj pagination-kj">
                        <a href=""><i class="fa-solid fa-chevron-left"></i> <span>Prev</span></a>
                        <a href="" class="pagination-kj-number">1</a>
                        <a href="" class="pagination-kj-number">2</a>
                        <a href="" class="pagination-kj-number">3</a>
                        <a href=""><span>Next</span> <i class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
        </section>
        <!-- DeactivatePartnerModal -->
        <div class="modal fade" id="DeactivatePartnerModal" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
            aria-hidden="true" data-bs-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-body p-4">
                        <div class="">
                            <h3 class="mb-3">Reactivate Group?</h3>
                            <p class="text-muted">
                                Are you sure you want to Reactivate NYC HR Department group.
                            </p>
                        </div>
                        <div class="Deactivate_buttons row mt-5 justify-content-center">
                            <button class="col-5 Deactivate_Cancel_buttons">Cancel</button>
                            <button type="button" class="col-5 btn Deactivate_Deactivate_buttons">
                                Reactivate
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Create Group -->
        <div class="modal fade CreatePartnerModal_hp" id="CreateGroupModal" tabindex="-1"
            aria-labelledby="CreateGroupModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title text-centerm-auto" id="CreateGroupModalLabel">
                            Create Group

                        </h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
                            @click="ResetForm"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row" id="CreateForm">
                            <div class="form-group mb-4">
                                <label class="input-hero" for="PartnerCompany">
                                    <input class="input_area" type="text" v-model="partner_company" required="" /><span
                                        class="input-name">Group Name <span class="text-danger">*</span></span>
                                </label>
                            </div>

                            <div class="form-group mb-4">
                                <label class="input-hero" for="Category">
                                    <select name="" class="input_area select_ca" id="">
                                        <option value="" selected disabled>Choose Category</option>
                                        <option value="">Department</option>
                                        <option value="">Location</option>
                                        <option value="">Region </option>
                                        <option value="">Cluster</option>
                                        <option value="">Region NK11</option>
                                        <option value="">Department</option>
                                        <option value="">Location</option>
                                        <option value="">Region </option>
                                        <option value="">Cluster</option>
                                        <option value="">Region NK11</option>
                                    </select>
                                    <span class="input-name">Category <span class="text-danger">*</span></span>
                                </label>
                            </div>

                            <div class="form-group mb-4">
                                <label class="input-hero" for="ChooseLoaction">
                                    <select name="" class="input_area select_ca" id="">
                                        <option value="" selected disabled>Choose Loaction</option>
                                        <option value="">🏳️‍🌈 Geremany</option>
                                        <option value="">🏳️‍⚧️ Russia</option>
                                    </select>
                                    <span class="input-name">Choose Loaction <span class="text-danger">*</span></span>
                                </label>
                            </div>

                            <div class="">
                                <button @click="savepartner" class="modail_submit_button">
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- AssignGroup -->
        <div class="modal fade CreatePartnerModal_hp" id="AssignGroup" tabindex="-1" data-bs-backdrop="static"
            aria-labelledby="CreateGroupModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title text-centerm-auto" id="CreateGroupModalLabel">
                            Assign Group
                        </h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
                            @click="ResetForm"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mt-4" id="CreateForm">
                            <div class="form-group mb-5">
                                <label class="input-hero" for="PartnerCompany">
                                    <Multiselect class="input_area" v-model="team" mode="tags" :close-on-select="false"
                                        :searchable="true" :create-option="true" :options="options" :classes="{
                                            tag: 'multiselect-tag text-wrap',
                                        }" />
                                    <!-- <input class="input_area" type="text" required=""> -->
                                    <span class="input-name">Group Name <span class="text-danger">*</span>
                                    </span>
                                </label>
                            </div>
                            <div class="form-group mb-5">
                                <label class="input-hero" for="PartnerCompany">
                                    <Multiselect class="input_area" v-model="team" mode="tags" :close-on-select="false"
                                        :searchable="true" :create-option="true" :options="options" :classes="{
                                            tag: 'multiselect-tag text-wrap',
                                        }" />
                                    <!-- <input class="input_area" type="text" required=""> -->
                                    <span class="input-name">Add UserNames <span class="text-danger">*</span>
                                    </span>
                                </label>
                            </div>
                            <div class="">
                                <button @click="savepartner" class="modail_submit_button">
                                    Assign
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- BulkUploadModal -->
        <div class="modal fade" id="BulkUploadModal" tabindex="-1" aria-labelledby="BulkUploadModalLabel" data-bs-backdrop="static"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content border-file-uploading">

                    <div class="modal-body">
                        <div class=" text-end border-bottom-0">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
                                @click="ResetForm"></button>
                        </div>
                        <div class="file-upload-kj">
                            <div class="image-upload-wrap">
                                <input class="file-upload-input" type="file" accept="image/*">
                                <div class="text-center">
                                    <!-- <i class="fas fa-file-alt"></i> -->
                                    <img src="../../public/dashboard-assets/images/M2/Upload-File-icon.png" alt="">
                                    <h4>Upload File</h4>
                                    <h3>Supported formats: PDF, XLSX</h3>
                                </div>
                            </div>
                            <div class="file-upload-content">
                                <img class="file-upload-image" src="#" alt="your image">
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ManagementTeamsGroupsDeactivated",
};
</script>