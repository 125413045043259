<template>
  <div class="page-content">
    <!--  Activated Groups-Toast -->
    <div
      class="d-flex justify-content-between align-items-center flex-wrap mb-2"
    >
      <div class="my-2">
        <h4 class="mb-2 mb-md-0">User</h4>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="top_heading_CreatePartner_btn d-block">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <button
                class="btn p-0 Group_top_three_dot_dropdown"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style="line-height: 0.3"
              >
                <img
                  src="../../public/dashboard-assets/images/M2/Icon-Set.png"
                />
              </button>
            </div>
          </div>
        </div>
        <div
          class="
            top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj
            d-block
            mx-2
          "
        >
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <button
                class="btn p-0 Group_top_three_dot_dropdown"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
                aria-haspopup="true"
                style="line-height: 0.3"
              >
                <img
                  src="../../public/dashboard-assets/images/M2/Filter_icon.png"
                />
              </button>

              <ul
                class="dropdown-menu dropdown-menu-kj"
                aria-labelledby="dropdownMenuButton1"
              >
                <div class="modal-header">
                  <h5
                    class="modal-title text-centerm-auto"
                    id="CreateGroupModalLabel"
                  >
                    Filter
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-auto-close="inside"
                    aria-expanded="false"
                  ></button>
                </div>

                <ul
                  class="nav nav-pills mb-3 border-bottom"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item w-50" role="presentation">
                    <button
                      class="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Role
                    </button>
                  </li>
                  <li class="nav-item w-50" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Department
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active p-0"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div class="search-bar">
                      <form class="search-form">
                        <input
                          type="text"
                          placeholder="Search"
                          name=""
                          class="form-control bg-white"
                        /><button class="btn">
                          <i class="fas fa-search"></i>
                        </button>
                      </form>
                      <div class="ul-li-checkbox-kj">
                        <ul>
                          <li>
                            <input type="checkbox" name="" id="" />
                            <span>Admin </span>
                          </li>
                          <li>
                            <input type="checkbox" name="" id="" />
                            <span>Recruiter</span>
                          </li>
                          <li>
                            <input type="checkbox" name="" id="" />
                            <span>Finance Head</span>
                          </li>
                          <li>
                            <input type="checkbox" name="" id="" />
                            <span>Head </span>
                          </li>
                          <li>
                            <input type="checkbox" name="" id="" />
                            <span>HR</span>
                          </li>
                          <li>
                            <input type="checkbox" name="" id="" />
                            <span>Manager</span>
                          </li>
                        </ul>
                        <div class="">
                          <button type="button" class="btn-Apply-kj-color">
                            Clear Filter
                          </button>
                          <button
                            type="button"
                            class="btn-primary btn-Apply-kj"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade p-0"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div class="search-bar">
                      <form class="search-form">
                        <input
                          type="text"
                          placeholder="Search"
                          name=""
                          class="form-control bg-white"
                        /><button class="btn">
                          <i class="fas fa-search"></i>
                        </button>
                      </form>
                    </div>
                    <div class="ul-li-checkbox-kj">
                      <ul>
                        <li>
                          <input type="checkbox" name="" id="" />
                          <span>NYC HR Department </span>
                        </li>
                        <li>
                          <input type="checkbox" name="" id="" />
                          <span>North Carolina </span>
                        </li>
                        <li>
                          <input type="checkbox" name="" id="" />
                          <span>Seoul City Manager </span>
                        </li>
                        <li>
                          <input type="checkbox" name="" id="" />
                          <span>Texas HR Department </span>
                        </li>
                        <li>
                          <input type="checkbox" name="" id="" />
                          <span>JV Department </span>
                        </li>
                        <li>
                          <input type="checkbox" name="" id="" />
                          <span>Infosys Department </span>
                        </li>
                      </ul>

                      <div class="">
                        <button type="button" class="btn-Apply-kj-color">
                          Clear Filter
                        </button>
                        <button type="button" class="btn-primary btn-Apply-kj">
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
              <!-- </div> -->
            </div>
          </div>
        </div>
        <div class="top_heading_CreatePartner_btn mx-2">
          <button
            type="button"
            class="btn btn-primary btn-icon-text"
            data-bs-toggle="modal"
            data-bs-target="#AddNewUserModal"
          >
            <i class="fa-solid fa-plus"></i> Add User
          </button>
        </div>
        <div class="top_heading_CreatePartner_btn">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <button
                class="btn p-0 Group_top_three_dot_dropdown"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa-solid fa-ellipsis-vertical"></i>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li>
                  <button
                    type="button"
                    class="btn w-100 text-start"
                    data-bs-toggle="modal"
                    data-bs-target="#BulkUploadModal"
                  >
                    <i class="fa-solid fa-arrow-up-from-bracket"></i
                    >&nbsp;&nbsp; Bulk upload
                  </button>
                </li>
                <li>
                  <button type="button" class="btn w-100 text-start">
                    <i class="fa-regular fa-circle-xmark"></i>&nbsp;&nbsp;
                    Deactive users
                  </button>
                </li>
                <li>
                  <button type="button" class="btn w-100 text-start">
                    <i class="fa-solid fa-download"></i>&nbsp;&nbsp; Download
                    Template
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mobile_Create_Partner_button">
          <button
            type="button"
            class="btn"
            data-bs-toggle="modal"
            data-bs-target="#AddNewUserModal"
          >
            <i class="fa-solid fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-5 col-lg-8 partners_top_menu_section_hp">
        <div class="row">
          <div class="col-4">
            <router-link :to="{ name: 'ManagementUsers' }" class="w-100 btn">
              Activated <span>(3)</span>
            </router-link>
          </div>
          <div class="col-4">
            <router-link
              :to="{ name: 'ManagementUsersPanding' }"
              class="w-100 btn"
            >
              Pending <span>(5)</span>
            </router-link>
          </div>
          <div class="col-4">
            <router-link
              :to="{ name: 'ManagementUsersDeactivated' }"
              class="w-100 btn active btn-outline-primary btn-icon-text"
            >
              Deactivated <span>(2)</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- After Data -->
    <div>
      <section class="row role_item_list_section mt-5">
        <div class="col-xl-3 col-lg-4 col-md-6 col-6 mb-4">
          <div class="role_item_box p-0">
            <div
              class="
                p-3
                pb-0
                d-flex
                justify-content-between
                role_item_box_new_item
                align-items-center
              "
            >
              <img
                src="../../public/dashboard-assets/images/M2/UsersDP1.png"
                alt=""
              />
              <div class="partner_item_action">
                <input type="checkbox" class="form-check-input" name="" id="" />
              </div>
            </div>
            <div class="px-3">
              <div class="role_item_box_heading border-0">Lee Min Ho</div>
              <div
                class="role_item_box_email d-flex justify-content-between mb-3"
              >
                <p>wanggaeparkgae21@gm...</p>
                <samp><i class="fa-regular fa-clone"></i></samp>
              </div>
            </div>
            <div class="user_item_Reactivate">
              <button
                type="button"
                class="btn w-100 text-center parnter_item_date border-0 p-0"
                data-bs-toggle="modal"
                data-bs-target="#ReactivateuserModal"
              >
                Reactivate user
              </button>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-6 mb-4">
          <div class="role_item_box p-0">
            <div
              class="
                p-3
                pb-0
                d-flex
                justify-content-between
                role_item_box_new_item
                align-items-center
              "
            >
              <img
                src="../../public/dashboard-assets/images/M2/UsersDP2.png"
                alt=""
              />
              <div class="partner_item_action">
                <div class="dropdown">
                  <button
                    class="btn p-0"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fa-solid fa-ellipsis"></i>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <button
                        type="button"
                        class="btn"
                        data-bs-toggle="modal"
                        data-bs-target="#AddNewUserModal"
                      >
                        Edit user
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        class="btn"
                        data-bs-toggle="modal"
                        data-bs-target="#DeactivateUserModal"
                      >
                        Deactivate user
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        class="btn w-100 text-start"
                        data-bs-toggle="modal"
                        data-bs-target="#DeleteUserModal"
                      >
                        Delete user
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="px-3">
              <div class="role_item_box_heading border-0">Lee Min Ho</div>
              <div
                class="role_item_box_email d-flex justify-content-between mb-3"
              >
                <p>wanggaeparkgae21@gm...</p>
                <samp><i class="fa-regular fa-clone"></i></samp>
              </div>
            </div>
            <div class="user_item_Reactivate">
              <button
                type="button"
                class="btn w-100 text-center parnter_item_date border-0 p-0"
                data-bs-toggle="modal"
                data-bs-target="#ReactivateuserModal"
              >
                Reactivate user
              </button>
            </div>
          </div>
        </div>
      </section>

      <div class="row justify-content-end">
        <div class="col-lg-4">
          <div class="Partner_management_top-kj pagination-kj">
            <a href=""
              ><i class="fa-solid fa-chevron-left"></i> <span>Prev</span></a
            >
            <a href="" class="pagination-kj-number">1</a>
            <a href="" class="pagination-kj-number">2</a>
            <a href="" class="pagination-kj-number">3</a>
            <a href=""
              ><span>Next</span> <i class="fa-solid fa-chevron-right"></i
            ></a>
          </div>
        </div>
      </div>
    </div>

    <!-- Add User -->
    <div
      class="modal fade CreatePartnerModal_hp"
      id="AddNewUserModal"
      tabindex="-1"
      data-bs-backdrop="static"
      aria-labelledby="AddNewUserModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title text-centerm-auto" id="AddNewUserModalLabel">
              Add User
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
              @click="ResetForm"
            ></button>
          </div>
          <div class="modal-body mt-4">
            <div class="row" id="CreateForm">
              <div class="form-group mb-4">
                <label class="input-hero" for="Category">
                  <select name="" class="input_area select_ca" id="">
                    <option value="" selected disabled>Choose Role</option>
                    <option value="">Department</option>
                    <option value="">Location</option>
                    <option value="">Region</option>
                  </select>
                  <span class="input-name"
                    >Role <span class="text-danger">*</span></span
                  >
                </label>
              </div>

              <div class="form-group mb-4">
                <label class="input-hero" for="Category">
                  <select name="" class="input_area select_ca" id="">
                    <option value="" selected disabled>Choose Groups</option>
                    <option value="">Department</option>
                    <option value="">Location</option>
                    <option value="">Region</option>
                  </select>
                  <span class="input-name"
                    >Groups <span class="text-danger">*</span></span
                  >
                </label>
              </div>

              <div class="form-group mb-4">
                <label class="input-hero" for="FirstName">
                  <input class="input_area" type="text" required="" /><span
                    class="input-name"
                    >First Name <span class="text-danger">*</span></span
                  >
                </label>
              </div>

              <div class="form-group mb-4">
                <label class="input-hero" for="LastName">
                  <input class="input_area" type="text" required="" /><span
                    class="input-name"
                    >Last Name <span class="text-danger">*</span></span
                  >
                </label>
              </div>

              <div class="form-group mb-4">
                <label class="input-hero" for="Tokens">
                  <input class="input_area" type="number" required="" /><span
                    class="input-name"
                    >Work Email <span class="text-danger">*</span></span
                  >
                </label>
              </div>

              <div class="">
                <button @click="savepartner" class="modail_submit_button">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ReactivateuserModal -->
    <div
      class="modal fade"
      id="ReactivateuserModal"
      tabindex="-1" data-bs-backdrop="static"
      aria-labelledby="ReactivateuserModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-body p-4">
            <div class="">
              <h3 class="mb-3">Reactivate User?</h3>
              <p class="text-muted">
                Are you sure you want to Reactivate Jackson Wang User.
              </p>
            </div>
            <div class="Deactivate_buttons row mt-5 justify-content-center">
              <button class="col-5 Deactivate_Cancel_buttons">Cancel</button>
              <button
                type="button"
                class="col-5 btn Deactivate_Deactivate_buttons"
              >
                Reactivate
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- DeleteUserModal -->
    <div
      class="modal fade"
      id="DeleteUserModal"
      tabindex="-1" data-bs-backdrop="static"
      aria-labelledby="DeleteUserModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-body p-4">
            <div class="">
              <h3 class="mb-3">Delete User?</h3>
              <p class="text-muted">
                Are you sure you want to delete Jackson Wang.
              </p>
            </div>
            <div class="Deactivate_buttons row mt-5 justify-content-center">
              <button class="col-5 Deactivate_Cancel_buttons">Cancel</button>
              <button
                type="button"
                class="col-5 btn Deactivate_Deactivate_buttons"
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Role Item view section -->
    <div
      class="offcanvas offcanvas-end role_item_view_detail_sidebar"
      tabindex="-1" 
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <h4 id="offcanvasRightLabel">Content Writer</h4>
        <button
          type="button"
          class="btn-close text-reset role_item_view_close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
        <div class="partner_item_action">
          <div class="dropdown">
            <button
              class="btn p-0"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa-solid fa-ellipsis"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <router-link
                  :to="{ name: 'ManagementCreateRoles' }"
                  class="btn w-100 text-start"
                >
                  Edit Role
                </router-link>
              </li>
              <li>
                <button
                  type="button"
                  class="btn w-100 text-start"
                  data-bs-toggle="modal"
                  data-bs-target="#DeleteRoleModal"
                >
                  Delete Role
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="offcanvas-body">
        <div class="card p-0 role_item_view_detail_item mb-3">
          <div class="card-body p-3">
            <h4 class="mb-3">Description</h4>
            <p class="font-size-12">
              This is a special role for the recruitment module. Can view
              candidates, advance stages and view offer details. Has employee
              level access to other modules. Can view candidates, advance stages
              and view offer details. Has employee level access to other
              modules. Has employee level access the to other
            </p>
          </div>
        </div>

        <div class="card p-0 role_item_view_detail_item mb-3">
          <div class="card-body p-3">
            <h4 class="mb-3">Account Control</h4>
            <button class="select_role_item_button btn">
              Create client Account
            </button>
            <button class="select_role_item_button btn">Assign Roles</button>
            <button class="select_role_item_button btn">
              View Access - Client Data</button
            ><button class="select_role_item_button btn">
              Create client Account
            </button>
            <button class="select_role_item_button btn">
              Create client Account
            </button>
            <button class="select_role_item_button btn">
              Create client Account
            </button>
            <button class="select_role_item_button btn">
              Create client Account
            </button>
            <button class="select_role_item_button btn">
              Create client Account
            </button>
          </div>
        </div>

        <div class="card p-0 role_item_view_detail_item mb-3">
          <div class="card-body p-3">
            <h4 class="mb-3">Account Control</h4>
            <button class="select_role_item_button btn">
              Create client Account
            </button>
            <button class="select_role_item_button btn">Assign Roles</button>
            <button class="select_role_item_button btn">
              View Access - Client Data</button
            ><button class="select_role_item_button btn">
              Create client Account
            </button>
            <button class="select_role_item_button btn">
              Create client Account
            </button>
            <button class="select_role_item_button btn">
              Create client Account
            </button>
            <button class="select_role_item_button btn">
              Create client Account
            </button>
            <button class="select_role_item_button btn">
              Create client Account
            </button>
          </div>
        </div>

        <div class="card p-0 role_item_view_detail_item mb-3">
          <div class="card-body p-3">
            <h4 class="mb-3">Account Control</h4>
            <button class="select_role_item_button btn">
              Create client Account
            </button>
            <button class="select_role_item_button btn">Assign Roles</button>
            <button class="select_role_item_button btn">
              View Access - Client Data</button
            ><button class="select_role_item_button btn">
              Create client Account
            </button>
            <button class="select_role_item_button btn">
              Create client Account
            </button>
            <button class="select_role_item_button btn">
              Create client Account
            </button>
            <button class="select_role_item_button btn">
              Create client Account
            </button>
            <button class="select_role_item_button btn">
              Create client Account
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- BulkUploadModal -->
    <div
      class="modal fade"
      id="BulkUploadModal"
      tabindex="-1"
      aria-labelledby="BulkUploadModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content border-file-uploading">
          <div class="modal-body">
            <div class="text-end border-bottom-0">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="btn-close"
                @click="ResetForm"
              ></button>
            </div>
            <div class="file-upload-kj">
              <div class="image-upload-wrap">
                <input class="file-upload-input" type="file" accept="image/*" />
                <div class="text-center">
                  <!-- <i class="fas fa-file-alt"></i> -->
                  <img
                    src="../../public/dashboard-assets/images/M2/Upload-File-icon.png"
                    alt=""
                  />
                  <h4>Upload File</h4>
                  <h3>Supported formats: PDF, XLSX</h3>
                </div>
              </div>
              <div class="file-upload-content">
                <img class="file-upload-image" src="#" alt="your image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Management-Users-Deactivated",
};
</script>
<style>
</style>