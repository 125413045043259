<template>
    <div class="page-content">
        <h3>Current plan</h3>
        <!-- {{ this.PlanFeatures }} -->
        <div class="card mt-3">
            <div class="card-header">
                <div style="display: flex; justify-content: space-between">
                    <div>
                        <h2 style="font-weight: 600;">
                            {{ this.billing.object.data.plan.planName }}</h2>
                    </div>
                    <div class="float-end">
                        <span>
                            <router-link :to="{ name: 'BillingPlan' }" class="btn btn-primary">
                                <span v-if="this.PlanStatus == false && this.FreePlan!='Free Trial'">
                                    Switch Plan
                                </span>
                                <span v-else>Activate Account</span>
                                <!-- {{ this.PlanStatus ? 'Activate Account' : 'Switch Plan' }} -->
                            </router-link>
                        </span>

                        <!-- <a href="#" class="btn btn-primary">Upgrade Plan</a> -->
                    </div>
                </div>

            </div>
            <div class="card-body">
                <!-- <h5 class="card-title">Special title treatment</h5> -->
                <span class="card-text">
                    {{ this.Remainingdays }}
                    Days Left</span>

                    
                <span class="float-end mx-2" style="font-size: small; color: rgb(56, 56, 255); cursor: pointer;"
                    @click="ValidationModal()" v-if="this.PlanStatus == false && this.FreePlan!='Free Trial'">
                    <!--  && this.billing.object.data.plan.planName=='' -->
                    <!-- <router-link :to="{ name: 'BillingPlan' }"> -->
                    Cancel Plan
                    <!-- </router-link> -->
                </span>
            </div>
        </div>

        <div class="modal fade" id="ValidationModal" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable border-radius-kj-20">
          <div class="modal-content border-radius-kj-20">
            <div class="modal-header">  
          <h4 style="font-weight: 600;">Deactivating your {{this.billing.object.data.plan.planName}} </h4>      
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
               ></button>
            </div>
            <div class="modal-body">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic architecto repudiandae ipsam et quam obcaecati ab, qui aliquid, in cumque, rerum aut harum corrupti nesciunt impedit iusto nulla quod ipsum.
              <span class="float-end mt-5">
                <button type="button" class="btn btn-primary secondary " data-bs-dismiss="modal">Cancel</button>
                <button type="button" class=" mx-2 btn btn-primary float-end" @click="CancelSubscription()">Continue to Cancel Plan</button>

        <!-- Exciting News! <i class="fa-solid fa-rocket fa-beat" style="color: #ffc801;"></i></h3> -->
    </span>


  
    
    
</div>


          </div>
        </div>
      </div>
    </div>
</template>
  
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { decryptData } from '@/platformFeatures/security';
import { calculateRemainingDays } from '../platformFeatures/dateUtils';


import moment from "moment";
export default {
    name: "BillingOverview",
    data() {
        return {
            PlanFeatures: [],
            billing: JSON.parse(localStorage.getItem('billingData')),
            PlanStatus: false,
            FreePlan: ''
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),
    },
    async created() {
        const secretKey = '7aB&2kL#d$!Pz%9wQxR5tNvYbEgUfTsX';

        const encryptedData = localStorage.getItem('featuresData');

        if (encryptedData) {
            const decryptedData = decryptData(encryptedData, secretKey);

            if (Array.isArray(decryptedData) && decryptedData.length > 0) {
                this.PlanFeatures = decryptedData;
            }
        }
//         if(window.location.pathname.includes('hostedpage_id')) {
//     location.reload();
// }
        this.Remainingdays = calculateRemainingDays(this.billing.object.data.subscription.expiryDate);
        this.PlanStatus = this.billing.object.data.subscription.isCancelled;
        this.FreePlan= this.billing.object.data.plan.planName;
    },

    methods: {
        ...mapMutations(["setLoading"]),

        ...mapActions(['putSubscriptionCancel','RefreshBillingDetails']),

        dateConvert: function (value) {
            return moment(String(value)).format("DD/MM/YYYY");
        },

        async ValidationModal(){
      window.$('#ValidationModal').modal('show');

    },
        CheckStatus(dueDate) {
            let currentDate = new Date();
            let due_date = new Date(dueDate);
            let diff = due_date.getTime() - currentDate.getTime();
            let days = Math.ceil(diff / (1000 * 60 * 60 * 24));
            this.Remainingdays = Math.abs(days);
            return this.Remainingdays;
        },

        // async CancelSubscription() {
        //     await this.putSubscriptionCancel({
        //         subscriptionId: this.billing.object.data.subscription.subScriptionId
        //     });
        //    location.reload();
        // }

        async CancelSubscription() {
    await this.putSubscriptionCancel({
        subscriptionId: this.billing.object.data.subscription.subScriptionId
    });
    await this.RefreshBillingDetails();
    location.reload();
}


    },
};
</script>
  
<style scoped>

.secondary{
    background-color: rgb(211, 211, 211);
    color: rgb(30, 30, 30);
}
</style>
  