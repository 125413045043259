<template>
  <div class="page-content">
    <div
      class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
    >
      <div class="my-2">
        <h2 class="mb-2 mb-md-0 page-heading">Dashboard</h2>
        <h4>Overview</h4>
      </div>
      <div
        class="dashboard-Partner-button d-flex align-items-center flex-wrap text-nowrap"
      >
        <router-link
          :to="{ name: 'DashboardPartner' }"
          class="dashboard-Partner-otherButton btn me-2"
          >Partner
        </router-link>
        <router-link
          :to="{ name: 'DashboardTeam' }"
          class="dashboard-Partner-ActiveButton btn"
          >My team
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-xl-12 stretch-card">
        <div class="row flex-grow-1">
          <div class="col-md-6 col-lg-4 col-xl-3 mb-3 grid-margin stretch-card">
            <div class="card">
              <div class="card-body p-0 text-center">
                <img src="dashboard-assets/images/others/dashboard-icon2.png" />
                <h6 class="card-title mt-3 mb-2">Created teams</h6>
                <h3>3</h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-3 mb-3 grid-margin stretch-card">
            <div class="card">
              <div class="card-body p-0 text-center">
                <img src="dashboard-assets/images/others/dashboard-icon3.png" />
                <h6 class="card-title mt-3 mb-2">Created users</h6>
                <h3>10</h3>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-3 mb-3 grid-margin stretch-card">
            <div class="card">
              <div class="card-body p-0 text-center">
                <img src="dashboard-assets/images/others/dashboard-icon4.png" />
                <h6 class="card-title mt-3 mb-2">Test balance</h6>
                <h3>150</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- row -->

    <div class="row">
      <div class="col-lg-12 col-xl-12 grid-margin stretch-card">
        <div class="card">
          <img src="dashboard-assets/images/im-1.png" />
        </div>
      </div>
    </div>
    <!-- row -->

    <div class="row">
      <div class="col-lg-12 col-xl-12 grid-margin stretch-card">
        <div class="card">
          <img src="dashboard-assets/images/im-2.png" />
        </div>
      </div>
    </div>
    <!-- row -->

    <div class="row">
      <div class="col-md-12 col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body text-center">
            <h3 style="float: left">Test Scheduled</h3>
            <p style="float: right">THIS WEEK</p>
          </div>

          <div class="row">
            <div class="col-xl-3 col-lg-4 col-md-6">
              <div class="card p-3 rounded-10">
                <div class="row">
                  <div class="col-xl-4 col-lg-5 col-md-6 col-5">
                    <div class="Test-Scheduled-icon">
                      <img
                        src="dashboard-assets/images/Test-Scheduled-icon1.png"
                      />
                    </div>
                  </div>
                  <div class="col-xl-8 col-lg-7 col-md-6 col-7">
                    <div class="p-1">
                      <h6 class="card-title mb-0">Test completed</h6>
                      <h3 style="font-family: Abhaya Libre; font-weight: 800">
                        5
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6">
              <div class="card p-3 rounded-10">
                <div class="row">
                  <div class="col-xl-4 col-lg-5 col-md-6 col-5">
                    <div class="Test-Scheduled-SecandIcon">
                      <img
                        src="dashboard-assets/images/Test-Scheduled-icon2.png"
                      />
                    </div>
                  </div>
                  <div class="col-xl-8 col-lg-7 col-md-6 col-7">
                    <div class="p-1">
                      <h6 class="card-title mb-0">Test in progress</h6>
                      <h3 style="font-family: Abhaya Libre; font-weight: 800">
                        1
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row"></div>
    </div>
    <!-- row -->
  </div>
</template>
<script>
export default {
  name: "dashboard-Team",
};
</script>
<style></style>
