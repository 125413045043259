const state = {
    successPopup: false,
    errorPopup: false,
    warningPopup: false,
    message: "",
    isOnline: navigator.onLine
};

const mutations = {
    setSuccessPopup(state, data) {
        state.successPopup = data.bool
        state.message = data.message
        state.isOnline = data.online
    },
    setErrorPopup(state, data) {
        state.errorPopup = data.bool
        state.message = data.message
        state.isOnline = data.online
    },
    setWarningPopup(state, data) {
        state.warningPopup = data.bool
        state.message = data.message
        state.isOnline = data.online
    },
    OnlineStatus(state, data) {
        state.isOnline = data.online;
    },
    errorPopupStatus(state, data) {
        state.message = data.message.message;
    }
};
export default {
    state,
    mutations,
};

