<template>
    <div class="page-content">
        <div v-if="show"> 
        <div class="d-flex justify-content-between align-items-center flex-wrap mb-2 mt-4">



            <h3 class="mb-2 mb-md-0">Invoices</h3>

            <!-- <div class="d-flex justify-content-between align-items-center">
                <div class="top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj d-block mx-2">
                    <div class="label" style="padding: 20% !important;">
                        <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" />
                    </div>
                </div>

                <div class="top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj d-block mx-2">
                    <div class="label" style="padding: 20% !important;">
                        <img src="../../public/dashboard-assets/images/M2/Filter_icon.png" />
                    </div>
                </div>
                <div class="top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj d-block mx-2">
                    <button class=" my-3 btn btn-primary2 mb-3" type="button">
                        Assign Invoice Receiver
                    </button>

                </div>
            </div> -->
        </div>


        <div class="my-4">
            <div class="row">
                <div class="col-md-12">
                    <div class="card p-0 " style="border-radius: 15px;">
                        <div class="table-responsive">
                            <table class="table text-center">
                                <thead style="background-color: #F1F0FF !important;">
                                    <tr>
                                        <th>Invoice Id</th>
                                        <th>Issued Date</th>
                                        <th>Due Date</th>
                                        <th>Status</th>
                                        <th>Amount</th>
                                        <th>Balance Due</th>
                                        <th>Download</th>
                                    </tr>
                                </thead>
                                <tbody v-for="item in invoiceData" :key="item">
                                    <tr>
                                        <td>{{ item.invoice_number }}</td>
                                        <td>{{ dateConvert(item.invoice_date) }}</td>
                                        <td>{{ dateConvert(item.due_date) }}</td>
                                        <td>
                                            <span class="View-Candidates-xmark" style="color: red;"
                                                v-if="item.status == 'overdue'">
                                                <i class="fa-solid fa-clock-rotate-left me-2"></i>Overdue by
                                                {{ CheckStatus(item.due_date) }} days
                                            </span>
                                            <span class="View-Candidates-clock" style="color:#ff9900"
                                                v-else-if="item.status == 'pending'">
                                                <i class="fa-solid fa-clock-rotate-left me-2"></i>Pending
                                            </span>
                                            <span class="View-Candidates-pass" style="color:#048848"
                                                v-else-if="item.status == 'paid'">
                                                <i class="fa-solid fa-check me-2"></i>Paid</span>
                                        </td>
                                        <td>{{ item.total }}</td>
                                        <td>{{ item.balance }}</td>

                                        <td style=" cursor: pointer;" @click="downloadInvoice(item.invoice_id)">

                                            <!-- <i class="fa-solid fa-circle-down fa-lg"></i> -->
                                            <i class="fa-solid fa-download "></i>
                                            <!-- Download -->

                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <div class="Partner_empty_main_section_hp mt-7" v-if="invoiceData==false">
      <div class="row">
        <div class="Partner_empty_img_hp m-auto text-center">
          <img
            src="../../public/dashboard-assets/images/M2/Partner_blank.png"
            alt=""
          />
        </div>
        <div class="Partner_empty_content_hp m-auto text-center">
          <div class="mb-4">
            <h2>For your account invoice has not been generated yet</h2>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>
  
<script>
import { superadmin } from "../utils/helper";
import { mapState, mapActions, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "BillingInvoice",
    data() {
        return {
            // PlanFeatures: [],
            show:false,
            invoiceData: [],
            billing:JSON.parse(localStorage.getItem('billingData')),

        };
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),
        //         filteredData() {
        //             return this.PlanFeatures.find((item) => {
        //                  return item.moduleName === 'CoreFeature' && item.name === 'Teams';
        //     });
        //   },
    },
    async created() {
        await this.GetInvoice();
    },

    methods: {
        ...mapActions(["GetInvoiceData", "DownloadInvoice"]),
        ...mapMutations(["setLoading"]),
        superadmin,
        dateConvert: function (value) {
            return moment(String(value)).format("DD/MM/YYYY");
        },


        // async GetInvoice() {
        //     var date = new Date();
        //     var endDate = moment(String(date)).format("MM/DD/YYYY");
        //     var startDate = moment(endDate, "MM/DD/YYYY").subtract(6, 'months').format("MM/DD/YYYY");

        //     var invoice = await this.GetInvoiceData({
        //         customerId: this.billing.object.data.subscription.customerId,
        //         startDate: startDate,
        //         EndDate: endDate
        //     });

        //     this.invoiceData = invoice.object.data;
        //     this.show=true;
        // },

        async GetInvoice() {
    var date = new Date();
    // var endDate = moment(String(date)).format("MM/DD/YYYY");
    // endDate = moment(endDate, "MM/DD/YYYY").add(1, 'days').format("MM/DD/YYYY"); 
    var endDate = moment(String(date)).add(1, 'days').format("MM/DD/YYYY");

    var startDate = moment(endDate, "MM/DD/YYYY").subtract(6, 'months').format("MM/DD/YYYY");

    var invoice = await this.GetInvoiceData({
        customerId: this.billing.object.data.subscription.customerId,
        startDate: startDate,
        EndDate: endDate
    });

    this.invoiceData = invoice.object.data;
    this.show=true;
},


        CheckStatus(dueDate) {
            let currentDate = new Date();
            let due_date = new Date(dueDate);
            let diff = due_date.getTime() - currentDate.getTime();
            let days = Math.ceil(diff / (1000 * 60 * 60 * 24));
            return Math.abs(days);
        },

        async downloadInvoice(id) {
            await this.DownloadInvoice({
                invoiceId: id
            })
        },

        // shouldShowDemoButton() {
        // const moduleName = 'CoreFeature';
        // const name = 'Roles and Permissions';

        // return this.PlanFeatures.some((feature) => feature.moduleName === moduleName && feature.name === name);
        // },


    },
};
</script>
  
<style scoped>
/* .datepicker table thead th, */
.table thead th {
    text-transform: capitalize;
    font-size: 18px;
    color: rgb(84, 84, 84);
}

.table tbody td {
    font-size: 15px;
    color: rgb(84, 84, 84);

}

.label {
    position: relative;
    background: whitesmoke;
    width: 45px;
    height: 45px;
    border-radius: 40px;
    text-align: center;
    padding: 25%;
    box-shadow: 0px 0px 25px 5px #ababab4d;
}

.btn-primary2 {
    border-radius: 50px !important;
    border: 2px solid #7c68ee !important;
    background: #fff !important;
    color: #7c68ee !important;
    margin: 0px 6px 0 0;
    min-width: 100px;
}

.btn-primary2:hover {
    background: #7c68ee !important;
    color: #ffffff !important;
    margin: 0px 6px 0 0;
}</style>
  