<template>
  <div class="page-content">
    <div class="row justify-content-between align-items-center flex-wrap grid-margin">
      <div class="my-2 col-lg-5 col-md-5">
        <h2 class="mb-2 page-heading">Management</h2>
        <div class="d-flex">
          <router-link class="btn" :to="{ name: 'ManagementTeams' }">
            <i class="fas fa-chevron-left"></i>
          </router-link>
          <h4 style="padding-top: 4px">Manage Team Members</h4>
        </div>
      </div>
      <div class="col-lg-7 col-md-7">
        <div class="row justify-content-end">
          <div class="col-xl-5 col-lg-7 col-md-7">
            <div class="search-bar mb-2">
              <form class="search-form">
                <input type="text" placeholder="Search by name or email" name=""
                  class="form-control rounded bg-white text-dark" v-model="search.nameOrEmail" />
                <button class="btn"><i class="fas fa-search"></i></button>
              </form>
            </div>
          </div>
          <div class="col-xl-4 col-lg-5 col-md-5">
            <button type="button" class="bg-dark text-white form-control mb-2 px-3" data-bs-toggle="modal"
              data-bs-target="#AssignTeamPopupModal">
              Assign Team
            </button>
          </div>
        </div>
      </div>
    </div>

    <div style="clear: both"></div>

    <div class="row">
      <div class="col-md-12">
        <div class="accordion" id="FaqAccordion">
          <div class="accordion-item" v-for="(team, index) in filteredMembers" :key="index">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button" type="button" data-bs-toggle="collapse"
                :data-bs-target="'#collapse' + index" aria-expanded="true" aria-controls="collapseOne">
                {{ `${team.name} (${team.teamMember.length})` }}
              </button>
            </h2>
            <div :id="'collapse' + index" class="accordion-collapse collapse" :class="[index === 0 ? 'show' : '']"
              aria-labelledby="headingOne" data-bs-parent="#FaqAccordion">
              <div class="accordion-body">
                <div class="table-responsive">
                  <table class="table ActiveTestTable">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>EMAIL</th>
                        <th>ROLE</th>
                        <th style="text-align: right">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(member, index) in team.teamMember" :key="index">
                        <td class="PartnerTeamMembersProfile-area">
                          <div class="PartnerTeamMembersProfile">
                            {{ member.initials }}
                          </div>
                          {{ member.userName }}
                        </td>
                        <td>
                          <a :href="'mailto:' + member.email" class="text-dark">{{ member.email }}</a>
                        </td>
                        <td>
                          <div class="btn btn-outline-secondary form-control disabled">
                            {{ getMemberRole(member.memberRole) }}
                          </div>
                        </td>
                        <td style="text-align: right">
                          <button type="button" class="btn AssignTeamAction">
                            <i class="fas fa-minus-circle"></i>
                          </button>
                        </td>
                      </tr>

                      <tr>
                        <td class="PartnerTeamMembersProfile-area">
                          <div class="PartnerTeamMembersProfile"></div>
                        </td>
                        <td>
                          <a href="mailto:alax@vodafone.com" class="text-dark"></a>
                        </td>
                        <td>
                          <div class="btn btn-outline-secondary form-control disabled"></div>
                        </td>
                        <td style="text-align: right">
                          <button type="button" class="btn AssignTeamAction" data-bs-toggle="modal"
                            data-bs-target="#AddTeamMembersModal">
                            <i class="fas fa-plus"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="AssignTeamPopupModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="AssignTeamPopupModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-centerm-auto" id="AssignTeamPopupModalLabel">
              Assign team
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
              @click="resetAssignForm"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group mb-2 mt-3">
                <label class="pb-1" style="color: #646464">Team Name</label>
                <Multiselect v-model="assignTeamObj.regionId" mode="tags" :searchable="true" :close-on-select="false"
                  :create-option="true" :options="teamList" placeholder="Select Teams" :classes="{
                    tag: 'multiselect-tag text-wrap',
                  }" />
              </div>

              <div class="form-chec mb-2 mt-3">
                <input type="checkbox" class="form-check-input" @change="selectAllTeams" id="AllTeam"
                  v-model="assignTeamObj.allTeam" />
                <label class="form-check-label px-2" for="AllTeam">All Team</label>
              </div>

              <div class="form-group mb-2 mt-5">
                <label class="pb-1" style="color: #646464">User Name </label>
                <Multiselect v-model="assignTeamObj.userId" mode="tags" :searchable="true" :close-on-select="false"
                  :create-option="true" :options="userList" placeholder="Select Users" />
              </div>

              <div class="d-flex mt-2">
                <button class="bg-white text-dark btn-outline-dark form-control mt-3 mx-2" data-bs-dismiss="modal"
                  aria-label="btn-close" @click="resetAssignForm">
                  Cancel
                </button>
                <button class="bg-dark text-white form-control mt-3 mx-2" @click="assignTeamHandler">
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";

import { mapActions, mapState } from "vuex";
export default {
  name: "ManagementTeamMembers",
  components: {
    Multiselect,
  },
  data() {
    return {
      teams: [],
      userList: [],
      teamList: [],
      assignTeamObj: {
        userId: [],
        allTeam: false,
        regionId: [],
      },
      search: {
        nameOrEmail: "",
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      managementTeams: (state) => state.team.teams,
    }),
    filteredMembers() {
      let nameOrEmail = this.search.nameOrEmail.toLowerCase();
      let filteredMembers = JSON.parse(JSON.stringify(this.teams));
      if (nameOrEmail) {
        filteredMembers.forEach((member) => {
          member.teamMember = member.teamMember.filter(
            (teamMem) =>
              teamMem.userName.toLowerCase().includes(nameOrEmail) ||
              teamMem.email.includes(nameOrEmail)
          );
        });
      }
      return filteredMembers;
    },
  },
  watch: {
    "assignTeamObj.regionId"(newValue) {
      if (newValue.length !== this.teamList.length) {
        this.assignTeamObj.allTeam = false;
      } else {
        this.assignTeamObj.allTeam = true;
      }
    },
  },
  methods: {
    ...mapActions(["getClientTeams", "GetAllUsers", "AssignTeams", "ShowWarningMessage"]),
    getMemberRole(roles) {
      const filteredRole = roles.filter((role) => role.selected);
      return filteredRole.length > 0 ? filteredRole[0].name : "--";
    },
    resetAssignForm() {
      this.assignTeamObj = {
        userId: [],
        allTeam: false,
        regionId: [],
      };
    },
    async assignTeamHandler() {
      if (this.assignTeamObj.regionId.length == 0) {
        return this.ShowWarningMessage("Please select team.");
        
      }
      if (this.assignTeamObj.userId.length == 0) {
       
        return this.ShowWarningMessage("Please select user.");
      }

      const data = await this.AssignTeams({
        users: this.assignTeamObj.userId.map((user) => ({ userId: user })),
        regionList: {
          regions: this.assignTeamObj.regionId.map((region) => ({
            regionId: region,
          })),
        },
      });

      window.$("#AssignTeamPopupModal").modal("hide");
      this.resetAssignForm();
      if (data.status) {
        await this.getTeams();
      }
    },
    async getTeams() {
      const teams = await this.getClientTeams({
        clientId: this.user.clientId,
      });

      if (teams.status) {
        this.teams = teams.object.data.team;
        this.teamList = this.teams.map((team) => ({
          label: team.name,
          value: team.teamId,
        }));
      }
    },
    selectAllTeams() {
      if (this.assignTeamObj.allTeam) {
        this.assignTeamObj.regionId = this.teamList.map((team) => team.value);
      } else {
        this.assignTeamObj.regionId = [];
      }
    },
  },
  async mounted() {
    if (this.managementTeams.length == 0) {
      await this.getTeams();
    } else {
      this.teams = this.managementTeams;
      this.teamList = this.teams.map((team) => ({
        label: team.name,
        value: team.teamId,
      }));
    }

    var users = await this.GetAllUsers({ clientId: this.user.clientId });
    if (users.status) {
      this.userList = users.object.data.users.map((user) => ({
        label: user.name,
        value: user.id,
      }));
    }
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css">
</style>
<style>
.multiselect-placeholder {
  color: var(--ms-placeholder-color, #000);
}

.namesp {
  display: block;
}

.nameInp {
  display: none;
}
</style>
