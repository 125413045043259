<template>
  <div class="page-content">
    <div
      class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
    >
      <div class="my-2">
        <h2 class="mb-2">
          <button
            type="button"
            class="btn btn-primary btn-icon-text mb-2 mb-md-0"
            onclick="history.back()"
          >
            <i class="fas fa-chevron-left"></i>
          </button>
          Test
        </h2>
        <h4 style="letter-spacing: 0.15em">Test Detail</h4>
      </div>
      <div class="d-flex align-items-center flex-wrap text-nowrap">
        <button
          type="button"
          class="btn btn-outline-primary btn-icon-text me-2 mb-2 mb-md-0"
        >
          Archive
        </button>
        <button
          type="button"
          class="btn btn-primary btn-icon-text mb-2 mb-md-0"
        >
          Edit
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12 grid-margin stretch-card">
        <div class="card">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div>
              <h3 class="mb-3 mb-md-0">My test 2022</h3>
            </div>
            <div class="d-flex align-items-center flex-wrap text-nowrap">
              <p>09/11/2021 - 05/12/2021</p>
            </div>
          </div>

          <div class="TestDetail-timer-section">
            <div class="d-flex timer-section">
              <i class="fas fa-stopwatch"></i>
              <p>40 mins 30 s</p>
            </div>
            <div class="d-flex timer-section">
              <i class="fas fa-user"></i>
              <p>10/70 candidates</p>
            </div>
            <router-link
              :to="{ name: 'InviteCandidates' }"
              class="dropdown-item d-flex align-items-center"
              >Invite Candidates</router-link
            >
          </div>

          <section class="TestDetail-table-section">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Test sections</th>
                    <th>9</th>
                    <th>4 mins</th>
                  </tr>
                </thead>
                <tbody style="border: 1.2px solid #b2b2c2">
                  <tr>
                    <td>Attention to detail</td>
                    <td>Questions</td>
                    <td>Duration</td>
                  </tr>
                  <tr>
                    <td>Listening</td>
                    <td>5</td>
                    <td>4 mins</td>
                  </tr>

                  <tr>
                    <td>Typing</td>
                    <td>1</td>
                    <td>3 mins</td>
                  </tr>

                  <tr>
                    <td>CSE Domain</td>
                    <td>5</td>
                    <td>4 mins</td>
                  </tr>

                  <tr>
                    <td>Written Communication</td>
                    <td>3</td>
                    <td>6 mins</td>
                  </tr>

                  <tr>
                    <td>Customer Service Orientation</td>
                    <td>25</td>
                    <td>15 mins</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="TestDetail-TotalTime-section">
              <div
                class="d-flex justify-content-between align-items-center flex-wrap"
              >
                <div>
                  <p class="mb-3 mb-md-0">Total instruction time</p>
                </div>
                <div class="d-flex align-items-center flex-wrap text-nowrap">
                  <p>4 mins 30 s</p>
                </div>
              </div>
            </div>

            <div class="col-md-3 col-lg-3 my-2 ViewCriteria-button">
              <button
                type="button"
                class="btn bg-white"
                data-bs-toggle="modal"
                data-bs-target="#ViewCriteriaModal"
              >
                View Criteria
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
    <!-- row -->

    <div class="row">
      <div class="col-lg-12 col-xl-12 grid-margin stretch-card">
        <div class="card">
          <div class="mb-4 pb-3 px-3 border-bottom border-secondary">
            <h3 class="mb-3 mb-md-0">Advenced options</h3>
          </div>

          <section class="AdvencedOption-Section">
            <div class="AdvencedOption-AntiCheating">
              <h4>
                Anti-cheating Settings <i class="far fa-question-circle"></i>
              </h4>
              <div class="my-3">
                <div class="d-flex">
                  <p>Desktop Application</p>
                  <a href="#" class="px-5">Enabled</a>
                </div>
                <p class="text-muted">
                  (snap shots, screen shots, application log, prevent other
                  applications)
                </p>
              </div>

              <div class="my-4">
                <div class="d-flex">
                  <p>Web Proctoring</p>
                  <a href="#" class="px-5 disabled">Disable</a>
                </div>
              </div>
            </div>

            <div class="AdvencedOption-AntiCheating mt-5">
              <h4>General setting <i class="far fa-question-circle"></i></h4>
              <div class="my-3">
                <div class="d-flex">
                  <p>
                    Show Answer sheet
                    <i class="far fa-question-circle px-2"></i>
                  </p>
                  <a href="#" class="px-5 disabled">Disable</a>
                </div>
              </div>

              <div class="my-4">
                <div class="d-flex">
                  <p>
                    Evaluation Enable
                    <i class="far fa-question-circle px-2"></i>
                  </p>
                  <a href="#" class="px-5 disabled">Disable</a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <!-- row -->
  </div>
</template>
<script>
export default {
  name: "TestDetail",
};
</script>
<style></style>
