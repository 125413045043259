<template>
    <div class="donut-chart-block donut-chrt-kj block position-relative"
        style="width: 140px; margin: 15px auto 0; position: relative !important" id="chart">
        <div class="chrt-kj" style="position: relative;height: 125px !important;width: 125px !important;">
            <!-- <canvas :id="this.main" style="
            display: block;
            box-sizing: border-box;
            height: 30px !important;
            width: 30px !important;
            z-index: 5;
            position: absolute !important;
            top: 0;
            left: 0;
          "></canvas> -->
            <circle-progress style="display: block;box-sizing: border-box;height: 125px !important; width: 125px !important; z-index: 5; position: absolute !important; top: 0;left: 0;
          " :percent="this.marksScored" :fill-color="this.resultColor" />
        </div>
        <p class="donut-chart-text" style="
          position: absolute; font-size: 13px;inset: 28px;width: 70px;z-index: 1;
          text-align: center;display: flex;flex-direction: column;align-items: center;
          justify-content: center; font-weight: 500;" :style="{ color: this.resultColor }">
            {{ this.marksScored }}%
            <!-- /{{ this.score?.outOfMarks }} -->
            <span style="font-weight: normal">
                {{ this.score?.result }}
            </span>
        </p>
    </div>
</template>
  
<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
export default {
    name: "ProctorChart",
    props: ["score", "main", "iconcolor", "pdf-chart", "isPdf"],
    components: { CircleProgress },
    data() {
        return {

            chartId: this.main ? this.main : "myChart",
            resultColor: "",
            section: 360 / this.score?.ranges?.length,
            colors: ["#FD71AE", "#FFC801", "#49CCFA "],
            rotateAngle: "",
            marksScored: 0,
            imgData: ''
        };
    },
    async mounted() {
        this.findScore();
      
        this.marksScored = this.score?.marksScored;
        if (this.marksScored === 0.0 || this.marksScored === 10.0) {
            this.marksScored = +this.marksScored;
        }

    },

    methods: {
        findScore() {
            this.resultColor = this.score.ranges;
        },

      






    },
};
</script>
<style lang="css">
.circle_arrow {
    border-radius: 50%;
    z-index: 9;
    position: relative;
    transform: rotate(315deg);
}

.circle_arrow img {
    width: 90px;
    margin-top: -6px;
    fill: #df0000;
}

.circle_arrow img path {
    fill: #df0000;
}

.custom-tooltip-content {
    display: flex;
    align-items: center;
}

.donut-chrt-kj {
    width: 140px;
    margin: 15px auto 0;
}

.donut-chrt-kj .chrt-kj {
    position: relative;
}

.donut-chrt-kj .chrt-kj canvas {
    z-index: 5;
    position: absolute !important;
    top: 0;
    left: 0;
}

.donut-chart-block .donut-chart-border-inside {
    width: 75px;
    height: 75px;
    border: 3px solid;
    border-radius: 50%;
    position: absolute;
    z-index: 0;
    top: 20%;
    display: flex;
    left: 18%;
    align-items: center;
    justify-content: center;
}

.transform-328deg {
    transform: rotate(330deg) !important;
}

.donut-chart-block .donut-chart-border-inside .donut-chart-svg {
    transform: rotate(308deg);

    font-size: 20px;
    position: absolute;
    left: 1px;
    top: 4px;
}

.donut-chart-block .donut-chart-text span {
    font-size: 10px;
}

.donut-chart-block .donut-chart-text {
    position: absolute;
    font-size: 13px;
    inset: 28px;
    width: 70px;
    z-index: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 600px) {
    .donut-chart-block .donut-chart-border-inside {
        left: 25px;
        top: 25px;
    }

    /* .donut-chart-block .donut-chart-text {
   
    width: 30%;} */
}
</style>
  