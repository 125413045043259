<template>
  <div class="page-content">
    <div
      class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
    >
      <div class="my-2">
        <h2 class="mb-2 mb-md-0 page-heading">Test</h2>
        <div class="d-flex">
          <button type="button" onclick="history.back()" class="btn">
            <i class="fas fa-chevron-left"></i>
          </button>
          <h4 style="padding-top: 4px">Invite Candidates</h4>
        </div>
      </div>
    </div>

    <div class="row InviteEmail-section">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card p-0 rounded">
          <div class="card-body">
            <form method="">
              <div class="row">
                <div class="col-lg-4 col-md-5">
                  <div class="form-group my-3">
                    <label class="text-danger"
                      ><b>Sender Email</b>
                      <i class="far fa-question-circle mx-2"></i
                    ></label>
                    <select class="form-select my-3">
                      <option>ed@vodafone.com</option>
                      <option>support@vodafone.com</option>
                      <option>edouarda@vodafone.com</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-4 col-md-5">
                  <div class="form-group my-3">
                    <label class="text-danger"
                      ><b>Sender Team </b
                      ><i class="far fa-question-circle mx-2"></i
                    ></label>
                    <select class="form-select my-3">
                      <option>South Vodafone</option>
                      <option>Techmahindra</option>
                      <option>Startech</option>
                      <option>Cogent</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group my-3">
                    <label>Subject</label>
                    <input
                      type="text"
                      placeholder="Invitation for <test name>"
                      class="form-control my-3"
                      name=""
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12s">
                  <div class="form-group my-3">
                    <label>Email content</label>
                    <textarea class="my-3 form-control">
                        
                        Dear Candidate,

                        This is the link to 
                        <b>name</b> <b> generated test link</b>

                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.

                        regards,
                        <b> name</b>
                        <b>User name</b>
                      </textarea
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="d-flex">
                  <div class="form-group my-3">
                    <button
                      type="button"
                      class="btn btn-outline-secondary text-dark"
                    >
                      Save Template
                    </button>
                  </div>
                  <div class="form-group my-3 mx-4">
                    <button
                      type="button"
                      class="btn btn-outline-secondary text-dark"
                    >
                      Load Template
                    </button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group my-3">
                    <label>Reciever email</label>
                    <input
                      type="text"
                      class="form-control"
                      data-role="tagsinput"
                      value="alaxwong@gmail.com, samentha@gmail.com"
                    />
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="d-flex">
                  <div class="form-group my-3">
                    <button
                      type="button"
                      class="bg-dark text-white form-control px-5"
                      data-bs-toggle="modal"
                      data-bs-target="#InviteCandidatesConfirm"
                    >
                      Send invite (2/200)
                    </button>
                  </div>
                  <div class="form-group my-3 mx-4">
                    <button
                      type="button"
                      class="btn btn-outline-secondary text-dark px-5"
                    >
                      Preview
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InviteCandidatesOld",
};
</script>
<style></style>
