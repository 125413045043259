
<template>
<img style="height: 100px; display: block;margin-top: 10%;  margin-left: auto; margin-right: auto;" src="../assets/Images/pmaps-loading.gif" />
</template>

<!-- <script>

import { mapState,mapActions, mapMutations} from "vuex";
export default {
  name: "Dashboard",
  data() {
    return {}
     
  },

  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      userId: (state) => state.auth.user.userId,
    }),
  },
 async mounted() {

    this.isLoading = true;
    var response = await this.GetUserInfo({ userId: this.user.userId });
    },
  methods: {
    ...mapActions([
      "GetUserInfo",

    ]),
    ...mapMutations(["setLoading"]),

},
};

</script> -->