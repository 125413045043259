import { platformFeatures } from '@/api/api';

const state = {
  features: [],
};

const getters = {
  allFeatures: (state) => state.features,
};

const mutations = {
  setFeatures: (state, features) => {
    state.features = features;
  },
};

const actions = {
  fetchFeatures: async ({ commit }, planCode) => {
    try {
      const response = await platformFeatures({ PlanCode: planCode });
      if (response.status === 200) { 
        commit('setFeatures', response.data);
        return response;
      } 
      // else {
      //   console.log(response, "Error");
      // }
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};