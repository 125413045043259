<template>
  <div class="page-content bg-404">
    <div class="NotFoundPage">
      <div class="text-center">
        <h1 style="font-size: 100px">404</h1>
        <h3>Page Not Found</h3>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageNotFound",
};
</script>
<style scoped>
.NotFoundPage {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg-404 {
  background-image: url("../assets/Images/greg-rosenke-6QnEf_b47eA-unsplash.jpg");
  background-position: center;
  background-size: cover;
  color: white;
  background-repeat: no-repeat;
}
</style>
