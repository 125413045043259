<template>
  <div class="page-content">
    <div
      class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
    >
      <div class="my-2">
        <h2 class="mb-2 page-heading">Management</h2>
        <div class="d-flex">
          <router-link :to="{ name: 'ManagementPartners' }" class="btn">
            <i class="fas fa-chevron-left"></i>
          </router-link>
          <h4 style="padding-top: 4px">Partners</h4>
        </div>
      </div>
      <div class="d-flex align-items-center flex-wrap text-nowrap">
        <div class="search-bar" style="width: 300px">
          <form class="search-form">
            <input
              type="text"
              placeholder="Search by name or email"
              name=""
              class="form-control rounded-0 bg-white text-dark"
            />
            <button class="btn"><i class="fas fa-search"></i></button>
          </form>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="accordion" id="FaqAccordion">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                {{ cunt }}
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#FaqAccordion"
            >
              <div class="accordion-body">
                <div class="table-responsive">
                  <table class="table ActiveTestTable">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>EMAIL</th>
                        <th>ROLE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(partners, index) in PartnerTeamMembers"
                        :key="index"
                      >
                        <td class="PartnerTeamMembersProfile-area">
                          <div class="PartnerTeamMembersProfile">
                            {{ partners.initials }}
                          </div>
                          {{ partners.userName }}
                        </td>
                        <td>
                          <a
                            href="mailto:bob@techmahindra.com"
                            class="text-dark"
                            >{{ partners.email }}</a
                          >
                        </td>
                        <td>
                          <template
                            v-for="(memRole, index) in partners.memberRole"
                            :key="index"
                          >
                            <div
                              v-if="
                                partners.role == memRole.memberRole &&
                                memRole.selected
                              "
                            >
                              {{ memRole.name }}
                            </div>
                          </template>
                        </td>

                        <td></td>
                      </tr>

                      <tr v-if="PartnerTeamMembers.length == 0">
                        <td colspan="5" style="text-align: center !important">
                          <strong> No Records Found </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "ViewPartnerTeamMembers",
  data() {
    return {
      PartnerTeamMembers: "",
      cunt: "",
      partnerid: this.$route.params.id,
    };
  },
  async mounted() {
    var partners = await this.Getpartnerteammembers({
      PartnerId: this.partnerid,
      clientId: this.clientId,
    });
    if (partners != undefined) {
      this.cunt = partners.object.data.team[0].name;
      this.PartnerTeamMembers = partners.object.data.team[0].teamMember;
    }
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      clientId: (state) => state.auth.user.clientId,
      // PartnerId: (state) => state.auth.user.PartnerId,
    }),
  },
  methods: {
    ...mapActions(["Getpartnerteammembers"]),
    ...mapMutations(["setLoading"]),
  },
};
</script>
<style></style>
