<template>
  <div class="page-content">
    <div
      class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
    >
      <div class="my-2">
        <h2 class="mb-2 ">
          <button
            type="button"
            class="btn btn-primary btn-icon-text mb-2 mb-md-0"
            onclick="history.back()"
          >
            <i class="fas fa-chevron-left"></i>
          </button>
          Test
        </h2>
        <h4 style="letter-spacing: 0.1em">Test Management</h4>
      </div>
      <div
        class="viewTestToken-Button-section d-flex align-items-center flex-wrap text-nowrap"
      >
        <div class="viewTestToken">
          <p>Test 2021</p>
          <strong>Test Balance</strong>
        </div>
        <div class="Balance-area">
          <strong>60</strong>
        </div>
      </div>
    </div>

    <div class="row mb-4 justify-content-end">
      <div class="col-md-3 col-lg-3 col-xl-2 my-2 search-bar">
        <form class="search-form">
          <select class="form-select">
            <option>Select Team</option>
          </select>
        </form>
      </div>
      <div class="col-md-3 col-lg-3 col-xl-2 my-2 search-bar">
        <form class="search-form">
          <select class="form-select">
            <option>My Test 2022</option>
          </select>
        </form>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card p-0">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table ActiveTestTable">
                <thead>
                  <tr>
                    <th>
                      Test name
                      <a href=""
                        ><i
                          class="fas fa-sort-alpha-down"
                          style="float: right"
                        ></i
                      ></a>
                    </th>
                    <th>
                      Team
                      <a href=""
                        ><i
                          class="fas fa-sort-alpha-down"
                          style="float: right"
                        ></i
                      ></a>
                    </th>
                    <th>
                      Test Allocated
                      <a href=""
                        ><i
                          class="fas fa-sort-alpha-down"
                          style="float: right"
                        ></i
                      ></a>
                    </th>
                    <!-- <th>Remaining <a href=""><i class="fas fa-sort-alpha-down" style="float: right;"></i></a></th> -->
                    <th style="text-align: right">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Test 2021</td>
                    <td>South Vodafone</td>
                    <td>(0/20)</td>
                    <!-- <td><div class="btn btn-danger">10</td> -->
                    <td style="text-align: right">
                      <div class="dropdown">
                        <button
                          class="btn p-0"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li>
                            <button
                              type="button"
                              class="dropdown-item btn btn-primary btn-icon-text mb-2 mb-md-0"
                              data-bs-toggle="modal"
                              data-bs-target="#AllocateTestTokenModal"
                            >
                              Allocate test token
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="dropdown-item btn btn-primary btn-icon-text mb-2 mb-md-0"
                              data-bs-toggle="modal"
                              data-bs-target="#TransferTestTokenTokenModal"
                            >
                              Transfer test token
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Test 2021</td>
                    <td>North Vodafone</td>
                    <td>(10/50)</td>
                    <!-- <td><div class="btn btn-danger">10</td> -->
                    <td style="text-align: right">
                      <div class="dropdown">
                        <button
                          class="btn p-0"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li>
                            <button
                              type="button"
                              class="dropdown-item btn btn-primary btn-icon-text mb-2 mb-md-0"
                              data-bs-toggle="modal"
                              data-bs-target="#AllocateTestTokenModal"
                            >
                              Allocate test token
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="dropdown-item btn btn-primary btn-icon-text mb-2 mb-md-0"
                              data-bs-toggle="modal"
                              data-bs-target="#TransferTestTokenTokenModal"
                            >
                              Transfer test token
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TestManagementPartnerTeamDetail",
};
</script>
<style></style>
