import CryptoJS from 'crypto-js';

export function encryptData(data, key) {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  return encryptedData;
}

export function decryptData(encryptedData, key) {
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, key);
  const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
}
