<template>
  <div class="page-content">
    <!-- header -->
    <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
      <div class="my-2 d-flex align-items-center">
        <div class="top_heading_CreatePartner_btn d-block me-2">
          <div class="Group_top_three_dot">
            <div class="dropdown">

              <router-link :to="{
                name: 'ActiveTestViewCandidates',
                params: {
                  testId: encode(basicInfo.testId),
                  clientId: encode(this.clientId),
                  isParent: encode(isParent), //update
                },
                query: {
                  search: encode(this.routesearch),
                  offset: encode(this.routeoffset),
                  current: encode(this.routecurrent),
                  activefilter: true,
                },
              }" class="p-0 Group_top_three_dot_dropdown-active btn-back-arrow-kj" type="button"
                style="line-height: 2.6">
                <i class="fa-solid fa-chevron-left"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div>
          <h4 class="mb-2 fw-bolder">Report</h4>
          <h6 class="text-muted">
            {{ this.basicInfo?.testName }}
            <i class="fa-solid fa-angle-right mx-2"></i>
            {{ this.basicInfo?.name }}
          </h6>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="top_heading_CreatePartner_btn d-block mx-3" data-bs-toggle="modal" data-bs-target="#ConsolidatedSheet">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <a :href="reportUrl" target="_blank">
                <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                  <img src="../../public/dashboard-assets/images/M2/download-icon.png" />
                </button>
              </a>
              <!-- <router-link :to="{
                name: 'ActiveTestReportOverviewPDF',
                query: {
                  candidateId: encode(this.candidateId),
                  testId: encode(this.testId),
                  name: encode(this.name),
                  clientId: encode(this.clientId),
                  isParent: encode(this.isParent),
                  id: encode(this.routesearch),
                  offset: encode(this.routecurrent),
                  current: encode(this.routecurrent)
                },
              }">
                <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                  <img src="../../public/dashboard-assets/images/M2/download-icon.png" />
                </button>
              </router-link> -->
              <!-- <button class="btn p-0 Group_top_three_dot_dropdown" type="button" @click="savePdf()">
                <img src="../../public/dashboard-assets/images/M2/download-icon.png" />
              </button> -->
            </div>
          </div>
        </div>
        <div class="top_heading_CreatePartner_btn d-block">
          <div class="Group_top_three_dot">
                        <div class="dropdown">
                          <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="fa-solid fa-ellipsis-vertical fa-lg" style="color: #7c7c7c;"></i>               
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li>
                              <a :href="compeleteReportUrl" target="_blank">
                              <button type="button" class="btn w-100 text-start">
                                Download Consolidated Sheet
                              </button>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
        </div>
      </div>
    </div>

    <div class="row Report_card_section">
      <div class="col-md-12">
        <div class="card active-card">
          <!--Header Component-->
          <div class="card mb-3">
            <ActiveTestHeaderVue :active="this.actived" :candidateId="this.candidateId" :testId="this.testId"
              :isParent="this.isParent" :clientId="this.clientId" :id="this.routesearch" :current="this.routecurrent"
              :offset="this.routeoffset" @search="onSearch" />
          </div>

          <!--Header Component-->
          <div class="card">
            <div class="Report_basic_info_area my-4">
              <div class="Report_basic_info_heading">
                <h4>
                  basic info
                  <img src="../../public/dashboard-assets/images/M7/Actions.png" width="25" class="cursor-pointer ms-2"
                    alt="" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Refers to the essential details of the assessment. " />
                </h4>
              </div>
              <div class="row Report_basic_info_detial_area">
                <div class="col-xl-3 col-lg-4 col-md-6 col-12 my-3">
                  <label class="text-muted font-size-14 fw-normal">Email</label>
                  <h5 class="font-size-14 fw-600">{{ this.basicInfo?.emailAddress }}</h5>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-12 my-3">
                  <label class="text-muted font-size-14 fw-normal">Exam Started</label>
                  <h5 class="font-size-14 fw-600">
                    {{
                      moment(this.basicInfo?.examStarted).format(
                        "DD/MM/YYYY, HH:mm:ss a"
                      )
                    }}
                  </h5>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-12 my-3">
                  <label class="text-muted font-size-14 fw-normal">Exam Ended</label>
                  <h5 class="font-size-14 fw-600">
                    {{
                      moment(this.basicInfo?.examEnded).format(
                        "DD/MM/YYYY, HH:mm:ss a"
                      )
                    }}
                  </h5>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-12 my-3">
                  <label class="text-muted font-size-14 fw-normal">Language</label>
                  <h5 class="font-size-14 fw-600">{{ this.basicInfo?.language }}</h5>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-12 my-3">
                  <label class="text-muted font-size-14 fw-normal">Test Name</label>
                  <h5 class="font-size-14 fw-600">{{ this.basicInfo?.testName }}</h5>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-12 my-3">
                  <label class="text-muted font-size-14 fw-normal">Time Taken (in min)</label>
                  <h5 class="font-size-14 fw-600">{{ this.basicInfo?.timeTakenInMins }}</h5>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-12 my-3">
                  <label class="text-muted font-size-14 fw-normal">Total Question Attempted</label>
                  <h5 class="font-size-14 fw-600">{{ this.basicInfo?.totalQuestionAttempted }}</h5>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-12 my-3">
                  <label class="text-muted font-size-14 fw-normal">Invited By</label>
                  <h5 class="font-size-14 fw-600">
                    {{
                      this.basicInfo.invitedBy == null ||
                      this.basicInfo.invitedBy == ""
                      ? "NA"
                      : this.basicInfo.invitedBy
                    }}
                  </h5>
                </div>
              </div>
            </div>

            <div class="Report_basic_info_area my-4">
              <div class="Report_basic_info_heading">
                <h4>
                  Score & Rank

                  <img src="../../public/dashboard-assets/images/M7/Actions.png" width="25" class="cursor-pointer ms-2"
                    alt="" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Refers to the candidates scores and position across various parameters.  " />
                </h4>
              </div>
              <div class="row Score_Rank_chart_area justify-content-around">
                <div class="col-xl-2 col-lg-4 col-md-6 col-12 my-3 text-center" v-for="(item, idx) in this.Rank"
                  :key="idx" :class="[
                    item.ranges == null ? 'chart_data_NA_css' : '',
                    idx != this.Rank.length - 1 ? ' border-end' : '',
                  ]">
                  <label class="Score_Rank_label_color " style="font-weight: bold;">{{ item?.name }}
                    <img src="../../public/dashboard-assets/images/M7/Actions.png" width="20" class="cursor-pointer ms-0"
                      alt="" data-bs-toggle="tooltip" data-bs-placement="top" :title="item.text" />
                  </label>
                  <br />
                  <CustomChart v-if="item.name == 'Overall Score'" :score="this.score[idx]" :main="'score'"
                    :isPdf="false">
                  </CustomChart>

                  <div class="Genuinity_chart" v-if="item.name == 'Proctoring Rank' && item.ranges == null">
                    <div class="Genuinity_chart_content">
                      <img src="../../public/dashboard-assets/images/M7/Genuinity_chart_icon.png" width="30" alt=""
                        data-bs-toggle="tooltip" data-bs-placement="top" />
                      <p>NA</p>
                    </div>
                  </div>
                  <div v-if="item.name == 'Proctoring Rank' && item.ranges != null">
                    <div>
                      <!-- <CustomChart :score="this.score[idx]" :main="'proctorSection'" :isPdf="true" /> -->

                      <ProctorChart :score="this.proctorScore" :main="'proctorSection'" :isPdf="true" />
                    </div>
                  </div>

                  <div class="Genuinity_chart" v-if="item.name == 'Genuinity'">
                    <div class="Genuinity_chart_content">
                      <img src="../../public/dashboard-assets/images/M7/Genuinity_chart_icon.png" width="30" alt="" />
                      <p>NA</p>
                    </div>
                  </div>

                  <div class="Company_Rank_chart" v-if="item.name == 'Company Rank'">
                    <div class="Company_Rank_chart_content">
                      <img src="../../public/dashboard-assets/images/M7/Company_Rank_chart_icon.png" width="30" alt="" />
                      <p>NA</p>
                    </div>
                  </div>

                  <div class="Industry_Rank_chart" v-if="item.name == 'Industry Rank'">
                    <div class="Industry_Rank_chart_content">
                      <img src="../../public/dashboard-assets/images/M7/Industry_Rank_chart_icon.png" width="30" alt="" />
                      <p>NA</p>
                    </div>
                  </div>
                </div>

                <!-- <div
                class="col-xl-2 col-lg-4 col-md-6 col-12 my-3 text-center border-end">
                <label class="Score_Rank_label_color"
                  >Proctoring Rank
                  <img
                    src="../../public/dashboard-assets/images/M7/Actions.png"
                    width="20"
                    class="cursor-pointer ms-0"
                    alt="" /></label
                ><br/>
                
              </div> -->

                <!-- <div class="col-xl-2 col-lg-4 col-md-6 col-12 my-3 text-center border-end chart_data_NA_css">
                <label class="Score_Rank_label_color">Genuinity
                  <img src="../../public/dashboard-assets/images/M7/Actions.png" width="20" class="cursor-pointer ms-0"
                    alt="" /></label>
                <div class="Genuinity_chart">
                  <div class="Genuinity_chart_content">
                    <img src="../../public/dashboard-assets/images/M7/Genuinity_chart_icon.png" width="30" alt="" />
                    <p>NA</p>
                  </div>
                </div>
              </div> -->

                <!-- <div class="col-xl-2 col-lg-4 col-md-6 col-12 my-3 text-center border-end chart_data_NA_css">
                <label class="Score_Rank_label_color">Company Rank
                  <img src="../../public/dashboard-assets/images/M7/Actions.png" width="20" class="cursor-pointer ms-0"
                    alt="" /></label>
                <div class="Company_Rank_chart">
                  <div class="Company_Rank_chart_content">
                    <img src="../../public/dashboard-assets/images/M7/Company_Rank_chart_icon.png" width="30" alt="" />
                    <p>NA</p>
                  </div>
                </div>
              </div> -->

                <!-- <div class="col-xl-2 col-lg-4 col-md-6 col-12 my-3 text-center chart_data_NA_css">
                <label class="Score_Rank_label_color">Industry Rank
                  <img src="../../public/dashboard-assets/images/M7/Actions.png" width="20" class="cursor-pointer ms-0"
                    alt="" /></label>
                <div class="Industry_Rank_chart">
                  <div class="Industry_Rank_chart_content">
                    <img src="../../public/dashboard-assets/images/M7/Industry_Rank_chart_icon.png" width="30" alt="" />
                    <p>NA</p>
                  </div>
                </div>
              </div> -->
              </div>
            </div>

            <div class="Report_basic_info_area my-4" >
              <div class="Report_basic_info_heading">
                <h4>
                  Overall Test Result Summary
                  <img src="../../public/dashboard-assets/images/M7/Actions.png" width="25" class="cursor-pointer ms-2"
                    alt="" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Summary candidate's score on all sections. " />
                </h4>
              </div>

              <div class="row Score_Rank_chart_area justify-content-around">
                <template v-for="(summary, index) in testSummary" :key="index">
                  <div class="col-xl-4 col-lg-4 col-md-6 col-12 my-3 text-center"
                    :class="[(index < testSummary?.length - 1 && index % 3 != 2) ? 'border-end' : '']">
                    <div>
                      <label style="font-weight: bold;">
                        Overall {{ summary.sectionName }} Score
                        <img src="../../public/dashboard-assets/images/M7/Actions.png" width="20"
                          class="cursor-pointer ms-0" alt="" data-bs-toggle="tooltip" data-bs-placement="top"
                          :title="summary.text" ref="tooltipElement" /></label>
                      <CustomChart :isPdf="false" :score="this.summaryScore[index]" :main="summary.sectionName">
                      </CustomChart>
                    </div>
                  </div>
                  <hr v-if="(index + 1) % 3 === 0 && index !== testSummary.length - 1" class="my-3" />
                </template>

              </div>
            </div>


            <!-- {{ Behaviouralrecommend.aoiRecommendations }} -->
            <div class="Report_basic_info_area my-4"
              v-if="(Behaviouralrecommend?.aoiRecommendations?.length != 0 || Behaviouralrecommend?.strengthRecommendations.length != 0) && (Behaviouralrecommend?.aoiRecommendations != null || Behaviouralrecommend?.strengthRecommendations != null)">
              <div class=" Report_basic_info_heading">
                <h4>
                  Recommendation based on behavioural competencies
                  <img src="../../public/dashboard-assets/images/M7/Actions.png" data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Suggestions given to the candidate on the basis of their  scores on the behavioral competencies. "
                    width="25" class="cursor-pointer ms-2" alt="" />
                  <span :class="[ Behaviouralrecommend.fitment === 'Recommended' ? 'Recommended_heading_text ms-3' : Behaviouralrecommend.fitment === 'Not Recommended' ? 'Not_recommended_heading_text ms-3' : 'Cautiously_recommended_heading_text ms-3' ]"> {{ Behaviouralrecommend.fitment }} </span>
                </h4>
              </div>
              <div class="row Score_Rank_chart_area justify-content-around">
                <div class="col-lg-6 col-md-12 mb-3">
                  <div class="Recommendation_Strengths_area">
                    <h3>Strengths:</h3>
                    <div v-if="Behaviouralrecommend?.strengthRecommendations.length == 0">
                      <img src="../../public/dashboard-assets/images/M7/Improvement_none_icon.png" width="150" alt=""
                        class="mt-3 me-4" />
                      <span style="font-weight: bold"> No discernible Strength</span>
                    </div>

                    <ul class="nav Recommendation_Strengths_points">
                      <li class="my-2 d-flex" v-for="strength of Behaviouralrecommend?.strengthRecommendations"
                        :key="strength">
                        <i class="fa-regular fa-lightbulb mt-2 me-4"></i>
                        <div>
                          <b>{{ strength.title }}:</b> {{ strength.description }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-6 col-md-12 mb-3">
                  <div class="Recommendation_Improvements_area" style="background-color: #fff7fb">
                    <h3>Area of Improvements:</h3>

                    <div v-if="Behaviouralrecommend?.aoiRecommendations?.length == 0">
                      <img src="../../public/dashboard-assets/images/M7/Improvement_none_icon.png" width="150" alt=""
                        class="mt-3 me-4" />

                      <span style="font-weight: bold"> No discernible Area Of Improvement</span>
                    </div>

                    <ul class="nav Recommendation_Improvements_points">
                      <li class="mb-3 d-flex align-items-start" v-for="aoi of Behaviouralrecommend?.aoiRecommendations"
                        :key="aoi">
                        <img v-if="aoi.length > 0" src="../../public/dashboard-assets/images/M7/Improvement_none_icon.png"
                          width="150" alt="" class="mt-2 me-4" />
                        <img src="../../public/dashboard-assets/images/M7/Improvements_icon.png" width="20" height="20"
                          alt="" class="cursor-pointer mt-2 me-2" />
                        <div>
                          <b>{{ aoi.title }}:</b> {{ aoi.description }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="Report_basic_info_area my-4"
              v-if="(recommendations?.strengthRecommendations?.length != 0 || recommendations?.aoiRecommendations?.length != 0) && (recommendations?.strengthRecommendations?.length != null || recommendations?.aoiRecommendations?.length != null)">
              <div class="Report_basic_info_heading">
                <h4>
                  Recommendation based on Cognitive competencies
                  <img src="../../public/dashboard-assets/images/M7/Actions.png" width="25" class="cursor-pointer ms-2"
                    alt="" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Suggestions given to the candidate on the basis of their  scores on the cognitive competencies. " />
                    <span :class="[ recommendations.fitment === 'Recommended' ? 'Recommended_heading_text ms-3' : recommendations.fitment === 'Not Recommended' ? 'Not_recommended_heading_text ms-3' : 'Cautiously_recommended_heading_text ms-3' ]"> {{ recommendations.fitment }} </span>
                </h4>
              </div>
              <div class="row Score_Rank_chart_area justify-content-around">
                <div class="col-lg-6 col-md-12 mb-3">
                  <div class="Recommendation_Strengths_area">
                    <h3>Strengths:</h3>
                    <!-- {{ recommendations?.strengthRecommendations }} -->
                    <div v-if="recommendations?.strengthRecommendations?.length == 0">
                      <img src="../../public/dashboard-assets/images/M7/Improvement_none_icon.png" width="150" alt=""
                        class="mt-3 me-4" />
                      <span style="font-weight: bold"> No discernible Strength</span>
                    </div>

                    <ul class="nav Recommendation_Strengths_points">
                      <li class="my-2 d-flex" v-for="strength of recommendations?.strengthRecommendations"
                        :key="strength">
                        <i class="fa-regular fa-lightbulb mt-2 me-4"></i>
                        <div>
                          <b>{{ strength.title }}:</b> {{ strength.description }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-6 col-md-12 mb-3">
                  <div class="Recommendation_Improvements_area" style="background-color: #fff7fb">
                    <h3>Area of Improvements:</h3>
                    <div v-if="recommendations?.aoiRecommendations?.length == 0">
                      <img src="../../public/dashboard-assets/images/M7/Improvement_none_icon.png" width="150" alt=""
                        class="mt-3 me-4" />

                      <span style="font-weight: bold"> No discernible Area Of Improvement</span>
                    </div>

                    <ul class="nav Recommendation_Improvements_points">
                      <li class="mb-3 d-flex align-items-start" v-for="aoi of recommendations?.aoiRecommendations"
                        :key="aoi">
                        <img v-if="aoi.length > 0" src="../../public/dashboard-assets/images/M7/Improvement_none_icon.png"
                          width="150" alt="" class="mt-2 me-4" />
                        <img src="../../public/dashboard-assets/images/M7/Improvements_icon.png" width="20" height="20"
                          alt="" class="cursor-pointer mt-2 me-2" />
                        <div>
                          <b>{{ aoi.title }}:</b> {{ aoi.description }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="Report_basic_info_area my-4">
              <div class="Report_basic_info_heading">
                <h4>
                  Attempt Details
                  <img src="../../public/dashboard-assets/images/M7/Actions.png" width="25" class="cursor-pointer ms-2"
                    alt="" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Includes information about the candidate's name; number of questions attempted out of the given questions, the amount of time spent on each section, pauses and resume time of the candidate." />
                </h4>
              </div>
              <div class="table-responsive mt-3 Attempt_Details_table_area">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" style="font-size: 15px">Section</th>
                      <th scope="col" style="font-size: 15px">Attempt log</th>
                      <th scope="col" style="font-size: 15px">Time Spent</th>
                      <!-- <th scope="col">Pause Time</th>
                    <th scope="col">Resume Time</th> -->
                    </tr>
                  </thead>
                  <tbody> 
                    <tr class="text-muted" v-for="item in Attempt" :key="item">
                      <td scope="row">{{ item.sectionName }}</td>
                      <td>{{ item.attemptedQuestionCount }} / {{ item.totalQuestionCount }}</td>
                      <td>{{ item.timeSpent }}</td>
                      <!-- <td>{{ item.pauseTime }}</td>
                    <td>{{ item.resumeTime }}</td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>


          </div>
          <div class="Report_basic_info_area my-4">
            <div class="Report_basic_info_heading">
              <h4>DISCLAIMER</h4>
            </div>
            <div class="mt-3">
              <!-- <p> -->
                <!-- PMaps is involved in psychometric assessments and is capable of,
                on a best effort basis designing a test as per its client’s
                requirements and making a suggestion or giving an opinion on the
                suitability of a candidate for a particular job role based on the
                evaluation and interpretation of the candidate’s test results. The
                same applies to the report on the candidate’s psychometric
                profile, the report is an opinion on the candidate’s personality.
                PMaps makes no warranty or representation with respect to the
                accuracy of its opinion, suggestion or the profile report. Client
                will use PMaps opinion and suggestion as an input in its
                recruitment process, but PMaps will not be held liable for any
                decision that client takes based on the suggestions, opinions or
                the profile report given by PMaps. Client indemnifies PMaps from
                any liability that can arise on account of utilizing PMaps
                services and holds PMaps harmless without limitation, against any
                losses, claims, costs, damages or liabilities suffered by PMaps
                arising out of or in connection with providing its services to
                client. This indemnification extends to PMaps, its affiliates,
                respective directors, officers and employees. -->
                <!-- {{ basicInfo.disclaimer }}
              </p> -->
              <span v-html="basicInfo.disclaimer"></span>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>

  <div v-if="this.showpdf">
    <ActiveTestReportOverviewPDFVue :testId="this.testId" :candidateId="this.candidateId" :name="this.name"
      :pdfData="this.overviewPdf" :isPdf="true" />
    <!-- v-show="false" ref="DownloadComp" -->
  </div>
</template>

<script>
import ActiveTestHeaderVue from "./ActiveTestHeader.vue";
// import ActiveTestReportOverviewPDF from "./ActiveTestReportOverviewPDF.vue";
import CustomChart from "./CustomChart.vue";
import ProctorChart from "./ProctorChart.vue";
import moment from "moment";
import { mapActions } from "vuex";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";
import { downloadPDF } from "../utils/helper.js";
import store from "../store";
import ActiveTestReportOverviewPDFVue from "./ActiveTestReportOverviewPDF.vue";
import Base64Images from "../utils/base64Image.js";
// import * as html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
// const xyz = (event) =>{
//   console.log("ndbvj",event);
// }
// console.log("kdsjn",xyz());

export default {
  components: {
    ActiveTestHeaderVue,
    CustomChart,
    ProctorChart,
    ActiveTestReportOverviewPDFVue,
  },
  name: "ActiveTestReportOverview",

  data() {
    return {
      isShowing: false,
      actived: "Overview",
      basicInfo: "",
      testSummary: "",
      recommendations: "",
      // candidateId: 10104,
      candidateId: window.atob(this.$route.params.candidateId), //10098
      testId: window.atob(this.$route.params.testId), //3532,
      clientId: window.atob(this.$route.query.clientId),
      isParent: window.atob(this.$route.query.isParent),
      routesearch: window.atob(this.$route.query.id),
      routeoffset: window.atob(this.$route.query.offset),
      routecurrent: window.atob(this.$route.query.current),
      score: [],
      proctorScore: [],
      summaryScore: [],
      AttpData: [],
      Rank: "",
      name: window.atob(this.$route.query.name),
      showpdf: false,
      imageUrl: "",
      clientCompany: "",
      namePdf: "",
      testNamePDF: "",
      Companylogo: "",
      Attempt: [],
      Behaviouralrecommend: {},
      reportUrl: "",
      compeleteReportUrl : "",
      overviewPdf: {
        OverviewbasicInfo: "",
        AttpDataPDF: "",
        RankPDF: "",
        testSummary: "",
        recommendations: "",
      },
    };
  },
  unmounted() {
    store.state.loader.isLoading = false;
    this.showpdf = false;
  },
  computed: {},
  async created() {
    this.reportUrl = window.app_base_url + "/managecandidates/GetReport?CandidateId=" + this.candidateId + "&TestId=" + this.testId + "&Report=Overview";
    this.compeleteReportUrl= window.app_base_url + "/managecandidates/GetReport?CandidateId=" + this.candidateId + "&TestId=" + this.testId + "&Report=consolidated";

    this.moment = moment;
    var reportsList = await this.getReportData(this.testId);
    var isCognitiveReport = reportsList?.object?.data?.details.filter(x => x.name === 'Cognitive');
    if(isCognitiveReport.length === 1) {
      await this.getCognitiveRecommendations();
    }
    var isBehaviouralReport = reportsList?.object?.data?.details.filter(x => x.name === 'Behavioural');
    if(isBehaviouralReport.length === 1) {
      await this.getBehaviouralRecommendations(); 
    }
  },

  async mounted() {

    store.state.loader.isLoading = true;
    await this.getInfo();
    await this.GetScoreRank();
    await this.getTestSummary();
    await this.getAttpdata();

    Array.from(
      document.querySelectorAll('img[data-bs-toggle="tooltip"]')
    ).forEach((tooltipNode) => new Tooltip(tooltipNode));


  },
  methods: {
    downloadPDF,
    ...mapActions([
      "getBasicInfo",
      "getTestResultSummary",
      "getRecommendations",
      "GetAttemptData",
      "GetScoreRankData",
      "getReportData"
    ]),
    encode(item) {
      return window.btoa(item);
    },


    async onSearch(val){
      const emitdata = val;
      if(emitdata.find(x=> x.name == 'Cognitive')!= null){
        await this.getCognitiveRecommendations();
      }

      if(emitdata.find(x=> x.name == 'Behavioural')!= null){
        await this.getBehaviouralRecommendations();
      }
    },
    
    async getInfo() {
      var info = await this.getBasicInfo(this.candidateId);
      if (info?.status) {
        store.state.loader.isLoading = false;
        if (info?.status) {
          this.basicInfo = info.object.data;
          this.clientCompany = this.basicInfo.clientCompanyLogo;
          this.Companylogo = this.basicInfo.companyLogo;
          this.namePdf = this.basicInfo.name;
          this.testNamePDF = this.basicInfo.testName;
        } else {
          this.basicInfo = null;
        }
      }
    },

    async getAttpdata() {
      var AttpData = await this.GetAttemptData(this.candidateId);
      if (AttpData?.status) {
        store.state.loader.isLoading = false;
        this.Attempt = AttpData.object.data;
        this.overviewPdf.AttpDataPDF = this.Attempt;
      } else {
        this.Attempt = null;
      }
    },

    async GetScoreRank() {
      var Rank = await this.GetScoreRankData(this.candidateId);
      if (Rank?.status) {
        this.Rank = Rank?.object?.data?.scoreAndRanks;
        this.overviewPdf.RankPDF = Rank?.object?.data?.scoreAndRanks;

        this.Rank.forEach((elm, index) => {
          if (elm.name == "Overall Score")
            elm.text =
              "Refers to the combined score obtained by the candidate across all competencies.";
          if (elm.name == "Proctoring Rank")
            elm.text =
              "Refers to the candidates score on the invigilation parameter. ";
          if (elm.name == "Genuinity")
            elm.text =
              "Refers to the authenticity of the responses given by the candidate. ";
          if (elm.name == "Company Rank")
            elm.text =
              "Candidate's scores rank when compared with other candidate's scores rank. ";
          if (elm.name == "Industry Rank")
            elm.text =
              "Candidate's scores rank when compared with other company's scores rank. ";

          this.score[index] = {
            marksScored: elm.score != null ? +elm.score : null,
            outOfMarks: elm.ranges
              ? elm.ranges[elm.ranges.length - 1]?.maxVal
              : null,
            result: elm.fitment ? elm.fitment : null,
            ranges: elm.ranges ? elm.ranges : null,
          };
          if (elm.name == 'Proctoring Rank') {
            this.proctorScore = {
              marksScored: elm.score != null ? +elm.score : null,
              outOfMarks: elm.ranges
                ? elm.ranges[elm.ranges.length - 1]?.maxVal
                : null,
              result: elm.fitment ? elm.fitment : null,
              ranges: elm.colorCode ? elm.colorCode : null,
              rangeArr: elm.ranges
            };
          }
        });
      } else {
        this.Rank = null;
      }
    },

    async getTestSummary() {
      var testSum = await this.getTestResultSummary(this.candidateId);

      if (testSum?.status) {
        this.testSummary = testSum?.object?.data?.summary;

        this.overviewPdf.testSummary = this.testSummary;

        this.testSummary.forEach((element, index) => {
          if (element.sectionName == "Cognitive")
            element.text =
              "Refers to the combined score obtained by the candidate on all cognitive competencies. ";
          if (element.sectionName == "Behavioural")
            element.text =
              "Refers to the combined score obtained by the candidate on all behavioral competencies.";
          if (element.sectionName == "Skill")
            element.text =
              "Refers to the combined score obtained by the candidate on skills such as language, grammar, vocabulary and typing.  ";
          this.summaryScore[index] = {
            marksScored: element.score,
            outOfMarks: element?.ranges[element?.ranges.length - 1].maxVal,
            result: element.fitment,
            ranges: element.ranges,
          };
        });
      } else {
        this.testSummary = null;
      }
    },

    async getCognitiveRecommendations() {
      var model = {
        candidateId: this.candidateId,
        type: "Cognitive",
      };
      var recommend = await this.getRecommendations(model);
      if (recommend?.status) {
        this.recommendations = recommend?.object?.data;
        this.overviewPdf.recommendations = this.recommendations;
      } else {
        this.recommendations = null;
      }
    },
    async getBehaviouralRecommendations() {
      var model = {
        candidateId: this.candidateId,
        type: "Behavioural",
      };
      var recommend = await this.getRecommendations(model);
      if (recommend?.status) {
        this.Behaviouralrecommend = recommend?.object?.data;
        // this.overviewPdf.Behaviouralrecommend = this.Behaviouralrecommend;
      } else {
        this.Behaviouralrecommend = null;
      }
    },

    async savePdf() {
      store.state.loader.isLoading = true;
      this.showpdf = true;
      // const doc = new jsPDF("p", "mm", "a4" ); 
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",

        // autoPaging: "text"
      });
      const namePdf = this.namePdf;
      const testName = this.testNamePDF;
      const Companylogo = this.Companylogo;
      const clientCompany = this.clientCompany;
      setTimeout(() => {
        const element = document.getElementById("overviewpdf");
        const container = document.createElement("div");
        // Append the container to the element
        element.appendChild(container);

        doc.html(element, {
          margin: [25, 0, 25, 0],
          x: 0,
          y: 0,
          autoPaging: true,

          callback: function (pdf) {
            // footer start
            const footerContainer = document.createElement("div");
            // Append the footer container to the element
            element.appendChild(footerContainer);
            const pageCount = doc.internal.getNumberOfPages(); // Get the total number of pages in the PDF document
            for (let i = 1; i <= pageCount; i++) {

              doc.setPage(i);
              // header start 
              if (i === 1) {
                var imageUrl = Base64Images.Background;
                // Add the background image
                doc.addImage(imageUrl, 'PNG', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight());
                // logo 1
                const img1 = document.createElement("img");
                img1.setAttribute(
                  "src", Companylogo);

                const imageWidth = 40; // Width of each image
                const imageHeight = 10; // Height of each image
                const imageX1 = 10; // X position of the first image
                const imageY1 = 5; // Y position of the first image
                doc.addImage(
                  img1.src,
                  "JPEG",
                  imageX1,
                  imageY1,
                  imageWidth,
                  imageHeight
                );
                // logo-2
                const img2 = document.createElement("img");
                img2.setAttribute("src", clientCompany);
                const image2Width = 40; // Width of each image
                const image2Height = 10; // Height of each image
                const imageX2 = doc.internal.pageSize.width - image2Width - 10;
                const imageY2 = 5; // Y position of the image
                doc.addImage(
                  img2.src, "JPEG", imageX2, imageY2, image2Width, image2Height
                );
              } else {

                // header 2
                doc.setFillColor(232, 247, 250);
                doc.rect(0, 0, doc.internal.pageSize.getWidth(), 20, "F");

                // logo 1
                const img1 = document.createElement("img");
                img1.setAttribute(
                  "src", Companylogo);

                const imageWidth = 40; // Width of each image
                const imageHeight = 10; // Height of each image

                const imageX1 = 10; // X position of the first image
                const imageY1 = 5; // Y position of the first image
                doc.addImage(
                  img1.src,
                  "JPEG",
                  imageX1,
                  imageY1,
                  imageWidth,
                  imageHeight
                );
                doc.setFontSize(10);
                // Specify the content of the header text
                const headerText = `${testName}`;
                // Calculate the x-position to center the header text
                const textWidth =
                  (doc.getStringUnitWidth(headerText) *
                    doc.internal.getFontSize()) /
                  doc.internal.scaleFactor;
                const x = (doc.internal.pageSize.getWidth() - textWidth) / 2;
                // Add the header text at the centered position
                doc.text(headerText, x, 10);
                // logo-2
                const img2 = document.createElement("img");
                img2.setAttribute(
                  "src", clientCompany);
                const image2Width = 40; // Width of each image
                const image2Height = 10; // Height of each image
                const imageX2 = doc.internal.pageSize.width - image2Width - 10; // X position of the image on the right
                const imageY2 = 5; // Y position of the image
                doc.addImage(img2.src, "JPEG", imageX2, imageY2, image2Width, image2Height);
              }
              // footer start
              doc.setFontSize(15);
              doc.setTextColor(41, 46, 52, 1);
              const img3 = document.createElement("img");
              img3.setAttribute(
                "src",
                Base64Images.Footer
              );
              const image3Width = doc.internal.pageSize.width; // Width of the image (equal to the page width)
              const image3Height = 20; // Height of the image
              const imageX3 = 0; // X position of the image (start from the left)
              const imageY3 = doc.internal.pageSize.height - image3Height;
              doc.addImage(img3, "JPEG", imageX3, imageY3, image3Width, image3Height);
              const footerText = "";
              const pageNumber = "Overview " + " | " + i;
              const textWidth = doc.getTextWidth(footerText); // Calculate the width of the footer text
              const xPos = doc.internal.pageSize.width - textWidth - 40; // 10 is the margin from the right side
              // Add footer text aligned to the right
              doc.text(footerText, xPos, doc.internal.pageSize.height - 10);
              // Add page number aligned to the right
              doc.text(pageNumber, xPos, doc.internal.pageSize.height - 5);
            }
            // end footer
            const pdfDataUri = pdf.output("datauristring");
            const link = document.createElement("a");
            link.href = pdfDataUri;
            link.download = `Overview_${namePdf}.pdf`;
            store.state.loader.isLoading = false;
            link.click();
          },
        });
        this.showpdf = false;
      }, 10000);

    },


  },
};
</script>
<style>
.active-card {
  background-color: #ff000000 !important;
  box-shadow: none !important;
  border: 0 !important;
  padding: 0 !important;
}

.top_heading_CreatePartner_btn .Group_top_three_dot .Group_top_three_dot_dropdown-active {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  border: 1px solid #e6e6eb;
  box-shadow: none;
  background: none;
  line-height: 1.1;
}

.top_heading_CreatePartner_btn .Group_top_three_dot .Group_top_three_dot_dropdown-active:hover {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  border: 1px solid #e6e6eb;
  box-shadow: 0 0 10px #e6e6eb;
  background-color: #fff;
  line-height: 1.1;
}

.fw-600 {
  font-weight: 600;
}

.Report_basic_info_area .Score_Rank_chart_area .Recommendation_Strengths_area .Recommendation_Strengths_points {
  flex-direction: column;
}
</style>