<template>
  <div class="page-content">
    <!-- mobile view Searching -->
    <div class="Searching_input_box_mobile_view mb-3 position-relative">
      <div class="w-100" v-if="this.show">
        <input
          type="text"
          v-model="searchinput"
          :disabled="searchDisable"
          placeholder="Search..."
          @keyup.enter="searchpartnerlst(searchinput)"
        />
        <!--update @keyup="filteredList"-->
        <!-- <div class="search_detail_list" v-if="this.searchresult.length > 0">
          <div
            v-for="(item, index) in this.searchresult"
            :key="index"
            class="search_detail_list_item"
            @click="searchpartnerlst(item)"
          >
            <span :id="index">
              {{ stringTOHtml(item, index) }}
            </span>
          </div>
        </div> -->
        <!--update-->
        <img
          src="../../public/dashboard-assets/images/M2/Close_icon.png"
          @click="closemobilesearch"
          alt=""
          class="close-icon"
        />
      </div>
    </div>

    <div
      class="d-flex justify-content-between align-items-center flex-wrap mb-2"
    >
      <div class="my-2">
        <h4 class="mb-2 mb-md-0">Partners</h4>
      </div>
      <div class="d-flex">
        <div class="top_heading_CreatePartner_btn position-relative me-2">
          <div class="Group_top_three_dot">
            <div class="Searching_input-box">
              <input
                type="text"
                v-model="searchinput"
                :disabled="searchDisable"
                placeholder="Search..."
                @keyup.enter="searchpartnerlst(searchinput)"
              />
              <!--update @keyup="filteredList"-->
              <!-- <div
                class="search_detail_list"
                v-if="this.searchresult.length > 0"
              >
                <div
                  v-for="(item, index) in this.searchresult"
                  :key="index"
                  class="search_detail_list_item"
                >
                  <span :id="index" @click="searchpartnerlst(item)">
                    {{ stringTOHtml(item, index) }}
                  </span>
                </div>
              </div> -->
              <!--update-->
              <span
                class="icon Group_top_three_dot_dropdown"
                @click="opensearch"
              >
                <img
                  src="../../public/dashboard-assets/images/M2/Icon-Set.png"
                  alt=""
                  class="Searching_search_icon"
                />
              </span>
              <img
                src="../../public/dashboard-assets/images/M2/Close_icon.png"
                @click="closesearch"
                alt=""
                class="close-icon"
              />
            </div>
          </div>
        </div>
        <div
          class="top_heading_CreatePartner_btn position-relative Searching_button_mobileView"
        >
          <div class="Group_top_three_dot">
            <span
              class="icon Group_top_three_dot_dropdown"
              :style="{ display: this.show == true ? 'none' : 'block' }"
              @click="openmobilesearch"
            >
              <img
                src="../../public/dashboard-assets/images/M2/Icon-Set.png"
                alt=""
                class="Searching_search_icon"
              />
            </span>
          </div>
        </div>
        <!--Assign--RM Akash-->
        <div
          class="top_heading_CreatePartner_btn mx-2"
          v-if="
            partnerList.length != 0 &&
            superadmin() &&
            getUserPermissionData({
              parent: 'Partner Management',
              child: 'Partner Account',
              permission: 'Can_Create_Partner',
            })
          "
        >
          <button
            type="button"
            class="btn Assign_RM_buttons"
            data-bs-toggle="modal"
            data-bs-target="#CreateMapClientsWithUsers"
            @click="GetRMUserList()"
          >
            Assign RM
          </button>
        </div>

        <div
          class="top_heading_CreatePartner_btn"
          v-if="partnerList.length != 0"
        >
          <button
            v-if=" superadmin() &&
              getUserPermissionData({
                parent: 'Partner Management',
                child: 'Partner Account',
                permission: 'Can_Create_Partner',
              })
            "
            type="button"
            class="btn btn-primary btn-icon-text"
            data-bs-toggle="modal"
            data-bs-target="#CreatePartnerModal"
          >
            <i class="fa-solid fa-plus"></i> Create Partner
          </button>
        </div>
      </div>

        <!-- Moblie View icon Create Group / Assign Group-->
     
        <div
          class="mobile_Create_Partner_button"
          v-if="
            partnerList.length != 0 && superadmin() &&
            getUserPermissionData({
              parent: 'Partner Management',
              child: 'Partner Account',
              permission: 'Can_Create_Partner',
            })
          "
        >
          <button
            type="button"
            class="btn"
            data-bs-toggle="modal"
            data-bs-target="#CreatePartnerModal"
            style=" margin-bottom: 100%;"
          >
            <i class="fa-solid fa-plus"></i>
          </button>

             <div
          class="mobile_Create_Partner_button-kj"
          v-if="
            getUserPermissionData({
              parent: 'Partner Management',
              child: 'Partner Account',
              permission: 'Can_Create_Partner',
            })
          "
        >
          <button
            type="button"
            class="btn"
            data-bs-toggle="modal"
            data-bs-target="#CreateMapClientsWithUsers"
            style="background-color: white !important"
          >
            <img
              src="../../public/dashboard-assets/images/M2/Icon-dashboard.png"
              alt=""
            />
          </button>
        </div>

        </div>
      </div>
      <div class="row">
        <div class="col-xl-5 col-lg-8 partners_top_menu_section_hp">
          <div class="row">
            <div class="col-6">
              <router-link
                :to="{ name: 'ManagementPartners' }"
                class="w-100 btn active btn-outline-primary btn-icon-text"
              >
                Partners <span>({{ this.totalPartnerCount }})</span>
              </router-link>
            </div>
            <div class="col-6">
              <router-link
                :to="{ name: 'ManagementPartnersAssignTeam' }"
                class="w-100 btn"
                >Assigned Groups
                <span>({{ this.totalGroupCount }})</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- empty section -->

      <section
        v-if="partnerList.length == 0"
        class="Partner_empty_main_section_hp"
      >
        <div class="row">
          <div class="Partner_empty_img_hp m-auto text-center">
            <img
              src="../../public/dashboard-assets/images/M2/Partner_blank.png"
              alt=""
            />
          </div>
          <div class="Partner_empty_content_hp m-auto text-center">
            <div class="mb-4">
              <h2>Hey, you still alone?</h2>
              <p class="text-muted mt-2">
                This is the best time to create partners and start working
              </p>
            </div>
            <button
              type="button"
              v-if="
                superadmin() &&
                getUserPermissionData({
                  parent: 'Partner Management',
                  child: 'Partner Account',
                  permission: 'Can_Create_Partner',
                })
              "
              class="btn btn-primary btn-icon-text"
              data-bs-toggle="modal"
              data-bs-target="#CreatePartnerModal"
            >
              <i class="fa-solid fa-plus"></i> Create Partner
            </button>
          </div>
        </div>
      </section>

      <!-- After Data -->
      <section class="partners_data_section mt-5" v-else>
        <div class="row">
          <div
            class="col-xl-3 col-lg-4 col-md-4 col-6 mb-3"
            v-for="item in partnerList"
            :key="item"
          >
            <div class="partner_item_area">
              <div class="partner_item_action">
                <div
                  class="dropdown"
                  :style="{ visibility: item.isActve ? 'visible' : 'hidden' }"
                >
                  <button
                    class="btn p-0"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fa-solid fa-ellipsis"></i>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li
                      v-if="
                        getUserPermissionData({
                          parent: 'Partner Management',
                          child: 'Partner Account',
                          permission: 'Can_Update_Partner_Detail',
                        }) && superadmin()
                      "
                    >
                      <button
                        @click="editPartner(item.clientId)"
                        type="button"
                        class="btn w-100 text-start"
                        data-bs-toggle="modal"
                        data-bs-target="#CreatePartnerModal"
                      >
                        Edit partner
                      </button>
                    </li>
                    <li
                      v-if="
                        getUserPermissionData({
                          parent: 'Partner Management',
                          child: 'Partner Account',
                          permission: 'Can_Deactivate_Partner',
                        }) && superadmin()
                      "
                    >
                      <button
                        type="button"
                        class="btn w-100 text-start"
                        data-bs-toggle="modal"
                        data-bs-target="#DeactivatePartnerModal"
                        @click="clientPartner(item.clientId, item.company)"
                      >
                        Deactivate partner
                      </button>
                    </li>
                    <!--  v-if="getUserPermissionData({
                        parent: 'Partner Management',
                        child: 'Partner Account',
                        permission: 'Can_Update_Partner_Detail',
                      })
                        " 
                       <li class="nav-item"
                v-if="getUserPermissionData({ parent: 'Role Management', child: 'Role Account', permission: 'Can_Access_Role' })">
                <router-link :to="{ name: 'ManagementRoles' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Roles</span>
                </router-link>
              </li>
                      
                      -->
                    <li v-if="superadmin()">
                      <router-link
                        :to="{
                          name: 'ManagementPartnersUserDetail',
                          params: {
                            id: item.clientId,
                            offset: this.offset,
                            current: this.currentPage,
                          },
                        }"
                      >
                        <button type="button" class="btn w-100 text-start">
                          Manage User
                        </button>
                      </router-link>
                    </li>

                    <li v-if="superadmin()">
                      <router-link
                        :to="{
                          name: 'ManagementPartnersGroupDetail',
                          params: {
                            id: item.clientId,
                            offset: this.offset,
                            current: this.currentPage,
                          },
                        }"
                      >
                        <button type="button" class="btn w-100 text-start">
                          Manage Group
                        </button>
                      </router-link>
                    </li>
                    <!-- --For update CRM  -->
                    <li v-if="partnerList.length != 0 && superadmin() && getUserPermissionData({ parent: 'Partner Management', child: 'Partner Account', permission: 'Can_Update_Partner_Detail' })">
                      <button type="button" class="btn w-100 text-start" data-bs-toggle="modal"
                        data-bs-target="#GetMapClientsWithUsersModal" @click="EditCRM(item.clientId)" >
                        Update CRM
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <router-link
                :to="{
                  name: 'ManagementPartnersDetail',
                  params: {
                    id: encode(item.clientId),
                    offset: encode(this.offset),
                    current: encode(this.currentPage),
                    search: encode(this.searchinput),
                  },
                }"
              >
                <div class="p-3">
                  <div class="parnter_iten_content">
                    <div
                      class="partner_item_img"
                      v-if="item.clientLogo != null && item.clientLogo != ''"
                    >
                      <img :src="item.clientLogo" />
                    </div>
                    <div
                      class="partner_item_img"
                      v-if="item.clientLogo == null || item.clientLogo == ''"
                    >
                      <div class="partner_FirstWord">
                        {{ item.company.charAt(0) }}
                      </div>
                    </div>
                    <h4
                      class="my-2 text-dark"
                      style="overflow-wrap: break-word"
                    >
                      {{ item.company }}
                    </h4>
                    <p class="text-muted font-size-12">
                      Tokens: {{ item.remainingTokens }}
                    </p>
                  </div>
                </div>
              </router-link>

              <div class="parnter_item_date" v-if="item.isActve">
                <router-link
                  class="text-muted"
                  :to="{
                    name: 'ManagementPartnersDetail',
                    params: {
                      id: encode(item.clientId),
                      offset: encode(this.offset),
                      current: encode(this.currentPage),
                      search: encode(this.searchinput),

                    },
                  }"
                >
                  <i class="fa-solid fa-calendar-days"></i>&nbsp;Created
                  {{ dateConvert(item.createdOn) }}
                </router-link>
              </div>
              <div class="parnter_item_Reactivate" v-else>
                <button
                  v-if="
                    getUserPermissionData({
                      parent: 'Partner Management',
                      child: 'Partner Account',
                      permission: 'Can_Activate_Partner',
                    })
                  "
                  type="button"
                  class="btn w-100 text-center parnter_item_date border-0 p-0"
                  data-bs-toggle="modal"
                  data-bs-target="#ReactivatePartnerModal"
                  @click="clientPartner(item.clientId, item.company)"
                >
                  Reactivate Partner
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- <paginate v-model="currentPage" :page-count="Page_count" :margin-pages="1" :page-range="1"
        :click-handler="clickCallback" :prev-text="'< Prev'" :next-text="'Next >'" :container-class="'pagination'"
        :page-class="'page-item'">
      </paginate> -->
        <paginate
          v-model="currentPage"
          :page-count="Page_count"
          :margin-pages="1"
          :page-range="1"
          :click-handler="clickCallback"
          :prev-text="'< Prev'"
          :next-text="'Next >'"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate>
      </section>

      <div
        class="modal fade CreatePartnerModal_hp"
        id="CreatePartnerModal"
        tabindex="-1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="CreatePartnerModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h4
                class="modal-title text-centerm-auto"
                id="CreatePartnerModalLabel"
              >
                {{ Create_Partner == 1 ? "Edit Partner" : "Create Partner" }}
              </h4>
              <button
                type="button"
                @click="resetForm"
                id="closeEditPartner"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="btn-close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row" id="CreateForm">
                <div class="CreatePartnerModal-img-area">
                  <div class="CreatePartnerModal-img mb-2">
                    <img
                      v-bind:src="url"
                      alt=""
                      class="CreatePartnerModal-pic"
                      v-if="url"
                    />
                    <!-- <img
                    src="../../public/dashboard-assets/images/M2/Create_partner_profile_img.png"
                    alt=""
                    class="CreatePartnerModal-pic"
                    v-else
                  /> -->
                    <span class="partner_FirstWord" v-else>
                      {{ partner_company.charAt(0) }}
                    </span>

                    <div class="CreatePartnerModal-img-edit">
                      <input
                        type="file"
                        name=""
                        id="file-input_icon1"
                        class="file-upload"
                        accept="image/*"
                        @change="onSelectFile($event)"
                      />
                      <label
                        style="cursor: pointer"
                        class="upload-button"
                        for="file-input_icon1"
                      >
                        <i class="fa-solid fa-pen"></i
                      ></label>
                    </div>
                  </div>
                  <p>Company Logo</p>
                </div>

                <div class="form-group mb-4">
                  <label class="input-hero" for="PartnerCompany">
                    <input
                      class="input_area"
                      type="text"
                      v-model="partner_company"
                      required=""
                    /><span class="input-name"
                      >Partner Company <span class="text-danger">*</span></span
                    >
                  </label>
                </div>

                <div class="form-group mb-4">
                  <label class="input-hero" for="AdminEmail">
                    <input
                      class="input_area"
                      type="email"
                      v-model="email"
                      :style="[
                        Create_Partner == 1
                          ? { color: '#9c9c9e!important' }
                          : {},
                      ]"
                      :readonly="Create_Partner == 1"
                      required
                    /><span
                      class="input-name"
                      :style="[
                        Create_Partner == 1
                          ? { color: '#9c9c9e!important' }
                          : {},
                      ]"
                      >Admin Email <span class="text-danger">*</span></span
                    >
                  </label>
                </div>

                <div class="form-group mb-4">
                  <label class="input-hero" for="FirstName">
                    <input
                      class="input_area"
                      type="text"
                      v-model="first_name"
                      :style="[
                        Create_Partner == 1
                          ? { color: '#9c9c9e!important' }
                          : {},
                      ]"
                      :readonly="Create_Partner == 1"
                      required
                    /><span
                      class="input-name"
                      :style="[
                        Create_Partner == 1
                          ? { color: '#9c9c9e!important' }
                          : {},
                      ]"
                      >First Name <span class="text-danger">*</span></span
                    >
                  </label>
                </div>

                <div class="form-group mb-4">
                  <label class="input-hero" for="LastName">
                    <input
                      class="input_area"
                      type="text"
                      v-model="last_name"
                      :style="[
                        Create_Partner == 1
                          ? { color: '#9c9c9e!important' }
                          : {},
                      ]"
                      :readonly="Create_Partner == 1"
                      required
                    /><span
                      class="input-name"
                      :style="[
                        Create_Partner == 1
                          ? { color: '#9c9c9e!important' }
                          : {},
                      ]"
                      >Last Name <span class="text-danger">*</span></span
                    >
                  </label>
                </div>
                <div v-if="Create_Partner === 0" class="form-group mb-4">
                  <label class="input-hero" for="Tokens">
                    <input
                      class="input_area"
                      :class="{ input_error_border: token > 500000 }"
                      type="number"
                      v-model="token"
                      required=""
                      :style="[
                        Create_Partner == 1
                          ? { color: '#9c9c9e!important' }
                          : {},
                      ]"
                      :readonly="Create_Partner == 1"
                    />
                    <span
                      :style="[
                        token > 500000 ? { color: '#f60202 !important' } : {},
                      ]"
                      class="input-name text"
                      >Tokens (In Numbers)
                      <span class="text-danger">*</span></span
                    >
                    <!-- <span class="toggle-password tooltip_area_hover_area">
                      <img
                        src="../../public/dashboard-assets/images/M2/Actions.png"
                        alt=""
                      />
                      <div class="tooltip_area_hover_line">
                        {{
                          Create_Partner == 1
                            ? "Uh-oh seems like you have only 20000 token left"
                            : "You can use 5 lac token max"
                        }}
                        <span class="tooltip_area_line_arrow"></span>
                      </div>
                    </span> -->
                  </label>
                </div>

                <div class="">
                  <button
                    @click="savepartner(0)"
                    class="modail_submit_button"
                    :disabled="isDisabled()"
                    :class="{ active_form_button: !isDisabled() }"
                    v-if="Create_Partner == 0"
                  >
                    Create
                  </button>
                  <button
                    @click="savepartner(1)"
                    class="modail_submit_button"
                    v-if="Create_Partner == 1"
                    :disabled="isDisabled()"
                    :class="{ active_form_button: !isDisabled() }"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- DeactivatePartnerModal -->
    <div
      class="modal fade"
      id="DeactivatePartnerModal"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="DeactivatePartnerModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-body p-4">
            <div class="">
              <h3 class="mb-3">Deactivate partner?</h3>
              <p class="text-muted">
                Are you sure you want to Deactivate
                <strong style="color: #000">{{ personalCompanyName }}</strong>
                Partners.
              </p>
            </div>
            <div class="Deactivate_buttons row mt-5 justify-content-center">
              <button
                class="col-5 Deactivate_Cancel_buttons"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="col-5 btn Deactivate_Deactivate_buttons"
                data-bs-dismiss="modal"
                @click="toggleActivate('deactivate')"
              >
                Deactivate
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- akash Assign Relationship Managers -->
    <div
      class="modal fade CreatePartnerModal_hp"
      id="CreateMapClientsWithUsers"
      tabindex="-1"
      aria-labelledby="CreatePartnerModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title text-centerm-auto"
              id="CreateMapClientsWithUsers "
            >
              Assign Relationship Managers
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="btn-close"
              id="closeassignRM"
              @click="ResetForm"
            ></button>
          </div>
          <!-- {{ partnerList }} -->
          <div class="modal-body">
            <div class="row mt-4" id="CreateForm">
              <div class="form-group mb-4">
                <label class="input-hero">
                  <Multiselect
                    v-model="selectedclient"
                    placeholder="Choose Client"
                    mode="tags"
                    :close-on-select="false"
                    :options="options"
                    :searchable="true"
                    :group-select="true"
                  >
                    <!-- {{ partnerList }} -->
                  </Multiselect>
                  <span class="input-name input-group-kj"
                    >Client <span class="text-danger">*</span></span
                  >
                </label>
              </div>
              <div class="form-group mb-4">
                <label class="input-hero">
                  <Multiselect
                    v-model="selecteduser"
                    placeholder="Choose Usernames"
                    mode="tags"
                    :close-on-select="false"
                    :options="users"
                    :searchable="true"
                    :group-select="true"
                    @select="GetSelectedUser(selecteduser)"
                 @deselect="GetSelectedUser(selecteduser)">
                  </Multiselect>
                  <span class="input-name input-group-kj"
                    >Username<span class="text-danger">*</span></span
                  >
                </label>
              </div>

              <div class="form-group mb-4">
                <label class="input-hero">
                  <Multiselect
                    v-model="primaryUser"
                    placeholder="Choose Primary User"
                    mode="tags"
                    :close-on-select="false"
                    :options="selectedPrimaryUser"
                    :searchable="true"
                    :group-select="true"
                  >
                  </Multiselect>
                  <span class="input-name input-group-kj"
                    >Primary User<span class="text-danger">*</span></span
                  >
                </label>
              </div>

              <div class="">
                <button
                  :disabled="
                    this.selectedclient.length == 0 ||
                    this.selecteduser.length == 0 ||
                    this.primaryUser.length == 0
                  "
                  @click="AssignRMtoUser"
                  class="modail_submit_button"
                  :class="{
                    active_form_button:
                      this.selectedclient.length > 0 &&
                      this.selecteduser.length > 0 &&
                      this.primaryUser.length > 0,
                  }"
                >
                  Assign
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- update CRM module -->
    <div class="modal fade CreatePartnerModal_hp" id="GetMapClientsWithUsersModal" tabindex="-1"
      aria-labelledby="CreatePartnerModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-centerm-auto" id="GetMapClientsWithUsersModal">
            Update Client Relationship Managers
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"  id="closeViewRM"
            @click="ResetForm"></button>
        </div>
        <div class="modal-body">
          <div class="row" id="CreateForm">
            <div class="form-group mb-4">
              <label class="input-hero" for="ClientName">
                <input class="input_area" type="text" v-model="client_name" required=""  :readonly="client_name!= ''"/>
                <span class="input-name">Client Name<span class="text-danger">*</span></span>\
                <!-- {{ this.partnerList }} -->
              </label>
            </div>

            <div class="form-group mb-4">
              <label class="input-hero" for="PartnerCompany">
                  <Multiselect
                    v-model="group"
                    placeholder="Choose Users"
                    mode="tags"
                    :close-on-select="false"
                    :options="option"
                    :searchable="true"
                    :group-select="true"
                    @select="GetSelectedPrimaryRM(group)"
                    @deselect="GetSelectedPrimaryRM(group)"
                  >
                  </Multiselect>

                <span class="input-name input-group-kj">Usernames<span class="text-danger">*</span></span>
              </label>
            </div>
            <div class="form-group mb-4">
              <label class="input-hero">
                <Multiselect
                 v-model="EditPrimaryUser" 
                 placeholder="Choose Primary User" 
                 mode="tags" 
                 :close-on-select="false"
                 :options="primaryRMs" 
                 :searchable="true" 
                 :group-select="true">
                </Multiselect>
                <span class="input-name input-group-kj">Primary User<span class="text-danger">*</span></span>
              </label>
            </div>

            <div class="">
              <button :disabled="
                this.group.length == 0 ||
                this.EditPrimaryUser.length == 0 
                " @click="ViewUserOfClient" class="modail_submit_button" :class="{
      active_form_button:
        this.group.length > 0 &&
        this.EditPrimaryUser.length > 0 ,
    }">
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



    <!-- ReactivatePartnerModal -->
    <div
      class="modal fade"
      id="ReactivatePartnerModal"
      tabindex="-1"
      data-bs-backdrop="static"
      aria-labelledby="ReactivatePartnerModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-body p-4">
            <div class="">
              <h3 class="mb-3">Reactivate partner?</h3>
              <p class="text-muted">
                Are you sure you want to Reactivate
                <strong style="color: #00000">{{ personalCompanyName }}</strong>
                Partners.
              </p>
            </div>
            <div class="Deactivate_buttons row mt-5 justify-content-center">
              <button
                class="col-5 Deactivate_Cancel_buttons"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="col-5 btn Deactivate_Deactivate_buttons"
                data-bs-dismiss="modal"
                @click="toggleActivate('activate')"
              >
                Reactivate
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { superadmin } from "../utils/helper";
import { mapState, mapActions, mapMutations } from "vuex";
import moment from "moment";
import { getUserPermissionData } from "../utils/helper";
import Multiselect from "@vueform/multiselect";
import Paginate from "vuejs-paginate-next";
import { validateName } from "../utils";
import { validateEmail } from "@/utils";
import { validateWhenExpiryDateIsNull } from '../platformFeatures/dateUtils';



export default {
  name: "Management-Partner",
  data() {
    return {
      Page_count: 1,
      partner_company: "",
      partnerList: [],
      email: "",
      company_logo: "",
      successMsg: "",
      useroptions: [],
      name: "",
      logo_url: "",
      url: "",
      first_name: "",
      last_name: "",
      token: "",
      CountryCode: "",
      allowedAssessment: "",
      partner_data: "",
      Create_Partner: 0,
      //create_crm: [],
      client_name: [],
      user_client: [],
      userofclient: [],
      userclientname: [],
      offset: 1,
      limit: 8,
      imgs: [],
      personalClientId: 0,
      totalPartnerCount: 0,
      personalCompanyName: "",
      assignedgrpsList: [],
      totalGroupCount: 0,
      grpsList: [],
      currentPage: 1,
      searchresult: [],
      searchinput: "",
      show: false,
      partnersearch: [],
      searchDisable: false,
      selectedclient: [],
      selecteduser: [],
      //PrimaryUser: [],
      primaryUser: [],
      primaryRMs: [],
      options: [],
      users: [],
      mappedRM: [],
      group:[],
      client:"",
      userlist: [],
      option: [],
      EditPrimaryUser: [],
      selectedPrimaryUser: [],
      filters: {
        partnerList: "",
      },
      photo: "",
      clientName: "",
      mappedData: [],
      routesearch:this.$route.query.search
        ? window.atob(this.$route.query.search)
        : null,
      routeoffset :this.$route.query.offset
        ? window.atob(this.$route.query.offset)
        : null,
      routecurrent :this.$route.query.current
        ? window.atob(this.$route.query.current)
        : null,
        billing:JSON.parse(localStorage.getItem('billingData')),


    };
  },
  components: {
    paginate: Paginate, Multiselect
  },


  // async Edituser(id) {
  //       this.Userid = id;
  //       var res = await this.UserEditData({
  //         userId: this.Userid,
  //       });
  //       if (res.status) {
  //         var data = res.object.data;
  //         (this.role = data.roleId), (this.firstname = data.firstName);
  //         this.lastname = data.lastName;
  //         this.email = data.email;
  //         this.group = data?.regions.map((i) => i.regionId);
  //       }
  //     },
async created(){
  validateWhenExpiryDateIsNull(this.billing.object.data.subscription.expiryDate );
},
  async mounted() {
    this.mappedData.push(this.mappedRM[1]);
    if (this.$route.query.offset && this.$route.query.current) {
      this.offset = parseInt(window.atob(this.$route.query.offset));
      this.currentPage = parseInt(window.atob(this.$route.query.current));
      this.searchinput = (window.atob(this.$route.query.search));


      if (this.searchinput) {
      this.searchinput = this.routesearch;
      this.opensearch();
      this.routesearchpartnerlist(this.searchinput);
    }
    }

    var allUsers = await this.GetAllUsers({
      clientId: this.clientId,
          page: 1,
          limit: 100,
          status: 4,
    });
    this.userlist = await allUsers.object.data;

    this.option = await this.userlist.users.map((i) => ({
      value: i.id,
      label: i.name,
     }));

    await this.getPartnerList();
    // await this.GetRMUserList();
    // await this.GetUserOfClient();
    // await this.getAllUser();
    // await this.GetSelectedUser();
    // await this.GetAllUsers();

    await this.getAssignedgroupList();
    // var location = await this.getlocation();
    // if (location.status) {
    //   this.location = location.object.data;
    // }
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      clientId: (state) => state.auth.user.clientId,
      PartnerId: (state) => state.auth.user.PartnerId,
    }),
  },

  methods: {
    getUserPermissionData,
    superadmin,
    ...mapActions([
      "AllPartnerList",
      "activatepartner",
      "deactivatepartner",
      "CreatePartner",
      "ShowWarningMessage",
      "accountProfileImage",
      "companylogo",
      "updatepartner",
      "getpartner",
      "Getpartners",
      "AllAssignedGroups",
      "companylogo",
      "partnerLogo",
      "createcrm",
      "updatecrm",
      "AssignCRM",
      "GetAllUsers",
      "getRMWithPagination",
      "CreateMapClientsWithUsers",
      "GetMapClientsWithUsers",
      "Getusersearchlist",
      "Getuserlist",
      "GetMappedPrimaryUser",
      "UpdateMappedClientsWithUsers"

      // "GetLimitedUser",


      //"ClientRMMapping"
    ]),
    ...mapMutations(["setLoading"]),
    dateConvert: (value) => {
      return moment(String(value)).format("DD-MMM-YYYY");
    },
    encode(item) {
      return window.btoa(item);
    },
      async routesearchpartnerlist(val) {
      this.searchinput = val.trim();
      this.offset = this.routeoffset;
      this.filters.partnerList = this.searchinput;
      this.currentPage = this.routecurrent;
      this.searchresult = [];
      await this.getPartnerList();
    },
    // Searching box
    opensearch() {
      document.querySelector(".Searching_input-box").classList.add("open");
    },
    async closesearch() {
      document.querySelector(".Searching_input-box").classList.remove("open");
      this.searchresult = [];
      this.searchinput = "";
      this.routesearch ="",
      this.currentPage= 1;
      this.offset=1;
      this.filters.partnerList = "";
      await this.getPartnerList();
    },
    async closemobilesearch() {
      this.show = false;
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.remove("open");
      this.searchresult = [];
      this.filters.partnerList = "";
      this.searchinput = "";
      await this.getPartnerList();
    },
    openmobilesearch() {
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.add("open");
      this.searchresult = [];
      this.input = "";
      this.show = true;
    },

    async filteredList() {
      if (this.searchinput != "") {
        this.partnersearch = [];
        this.searchresult = [];
        var partners = await this.AllPartnerList({
          clientId: this.clientId,
          offset: 1,
          limit: 100,
          Search: this.searchinput,
        });
        // if (partners != undefined) {
        //   this.partnerList = partners.object.data.partnerData;
        //   var total_partner = partners.object.data.totalPartners;
        //   this.Page_count = parseInt(total_partner / this.limit) + 1;
        // }
        var x = partners.object.data.partnerData;
        x.forEach((element) => {
          if (this.partnersearch.indexOf(element.company) < 0) {
            this.partnersearch.push(element.company);
          }
        });
        this.searchresult = this.partnersearch.filter((element) =>
          element.toLowerCase().startsWith(this.searchinput.toLowerCase())
        );
        this.searchresult = Object.freeze(
          JSON.parse(JSON.stringify(this.searchresult))
        );
      } else {
        this.searchresult = [];
        this.filters.partnerList = "";
        await this.getPartnerList();
      }
    },

    async EditCRM(id) {
      const names = await this.getpartner({
        clientId: id,
      });
      this.client = id;

      this.client_name = names.object.data.companyName;

      var res = await this.GetMapClientsWithUsers({
        clientId: id,
      });
      
     var data = res.object.data;
     this.group = data?.map((i) => i.userId);
     this.primaryRMs = data?.map((i) => ({value : i.userId, label: i.firstName + ' ' + i.lastName}));

      
      const primUser = await this.GetMappedPrimaryUser({
        clientId : id,
      })
      this.EditPrimaryUser = primUser.map((i) => i.userId);
    },

    stringTOHtml(item, idx) {
      setTimeout(() => {
        if (this.searchinput != "") {
          let re = new RegExp(this.searchinput.toLowerCase(), "g");
          var html = document.getElementById(idx);
          var ex = item
            .toLowerCase()
            .replace(
              re,
              `<span style="color: #7b68ee !important">${this.searchinput}</span>`
            );
          if (html) html.innerHTML = ex;
        }
      }, 100);
    },
    async searchpartnerlst(val) {
      this.searchinput = val.trim();
      this.offset = 1;
      this.filters.partnerList = this.searchinput;
      this.currentPage = 1;
      this.searchresult = [];
      await this.getPartnerList();
    },

    isDisabled() {      
      return (
        this.email == "" ||
        this.last_name == "" ||
        this.first_name == "" ||
        this.partner_company == "" ||
        this.token == ""
      );
    },

    clickCallback(pageNum) {
      this.offset = (pageNum - 1) * this.limit + 1;
      this.getPartnerList();
    },
    async savepartner(event) {
      this.isLoading = true;

      this.partner_company = this.partner_company.trim();
      if (this.partner_company.trim() === "" || this.partner_company == null) {
        return this.ShowWarningMessage(
          "Please enter your Partner Company Name."
        );
      }

      if (this.partner_company.length > 100) {
        return this.ShowWarningMessage(
          "Please enter partner_company upto 100 character."
        );
      }

      if (this.email.trim() === "" || this.email == null) {
        return this.ShowWarningMessage("Please enter your Email.");
      }

      if (!validateEmail(this.email)) {
        return this.ShowWarningMessage("Please enter valid Email Address.");
      }
      this.first_name = this.first_name.trim();
      if (this.first_name.trim() === "" || this.first_name == null) {
        return this.ShowWarningMessage("Please enter your First Name.");
      }

      if (this.first_name.length > 20) {
        return this.ShowWarningMessage(
          "Please enter First Name upto 20 character."
        );
      }
      if (!validateName(this.first_name)) {
        return this.ShowWarningMessage("First Name must be alphabetic .");
      }
      if (this.last_name.length > 20) {
        return this.ShowWarningMessage(
          "Please enter Last Name upto 20 character."
        );
      }
      this.last_name = this.last_name.trim();
      if (this.last_name.trim() === "" || this.last_name == null) {
        return this.ShowWarningMessage("Please enter your Last Name.");
      }

      if (!validateName(this.last_name)) {
        return this.ShowWarningMessage("Last Name must be alphabetic .");
      }

      if (this.token == null || this.token == "") {
        return this.ShowWarningMessage("please enter the tokens");
      }
      if (this.token < 1) {
        return this.ShowWarningMessage("Please enter valid token.");
      }
      if (this.token > 500000) {
        return this.ShowWarningMessage("You can use 5 lac tokens at max");
      }

      if (event == 0) {
        if (this.clientId) {
          const model = {
            method: "POST",
            params: {
              clientId: this.clientId,
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.user.token,
            },
            body: JSON.stringify({
              name: this.partner_company,
              domain: "ABC",
              countryCode: "IN",
              logo: this.logo_url,
              firstName: this.first_name,
              lastName: this.last_name,
              email: this.email,
              allowedAssessment: parseInt(this.token),
              // profilePic: this.profilePic,
            }),
          };
          await this.CreatePartner(model);
          this.name = "";
          (this.CountryCode = ""), (this.allowedAssessment = "");
         
        }
        this.Create_Partner = 0;
      }

      if (event == 1) {
        const model = {
          method: "PATCH",
          params: {
            clientId: this.partner_data,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.user.token,
          },
          body: {
            Name: this.partner_company,
            ClientLogo: this.profileImage,
            AllowedAssessment: parseInt(this.token),
            firstName: this.first_name,
            lastName: this.last_name,
          },
        };
        this.offset = 1;

        await this.updatepartner(model);
      }

      this.resetForm();
      document.getElementById("closeEditPartner").click();
      this.offset = 1;
      this.currentPage = 1;
      this.getPartnerList();
      window.$("#CreatePartnerModal").modal("hide");
      // this.resetForm();
    },

    async resetForm() {
      this.Create_Partner = 0;
      this.partner_company = "";
      this.url = "";
      this.first_name = "";
      this.last_name = "";
      (this.email = ""), (this.profilePic = "");
      this.token = "";
      this.logo_url = "";
      this.profileImage = "";
    },

    onSelectFile(event) {
      this.url = "";
      if (event.target.files && event.target.files[0]) {
        this.userProfileImage = event.target.files[0];
        var fileSize = this.userProfileImage.size;
        var extension = this.userProfileImage.name.split(".")[1].toLowerCase();
        if (
          extension == "jpeg" ||
          extension == "jpg" ||
          extension == "png" ||
          extension == "svg" ||
          extension == "webp"
        ) {
          if (fileSize >= 2097152) {
            this.ShowWarningMessage("please select image below 2 mb");
            return false;
          } else {
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = async (event) => {
              this.url = event.target.result;
              var a = this.url.split(",").pop();
              if (this.partner_data) {
                const model = {
                  method: "PATCH",
                  params: {
                    clientId: this.partner_data,
                  },
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.user.token,
                  },

                  body: {
                    fileExtension: extension,
                    fileBytes: a,
                  },
                };
                var res = await this.accountProfileImage(model);
                this.logo_url = res.object.data.url;
              } else {
                var rs = await this.partnerLogo({
                  fileBytes: a,
                  fileExtension: extension,
                });
                this.logo_url = rs.object.data;
              }
            };
          }
        } else {
          this.ShowWarningMessage("please select only image Format");
        }
      }
    },
    async toggleActivate(type) {
      this.isLoading = true;
      const modal = {
        method: "PATCH",
        params: {
          clientId: this.personalClientId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.user.token,
        },
      };
      if (type == "activate") {
        await this.activatepartner(modal);
      } else {
        await this.deactivatepartner(modal);
      }

      this.isLoading = false;
      await this.getPartnerList();
    },
    async getPartnerList() {
      this.searchDisable = true;
      var partners;

      partners = await this.AllPartnerList({
        clientId: this.clientId,
        offset: this.offset,
        limit: this.limit,
        Search: this.filters.partnerList,
      });
      this.searchDisable = false;

      if (partners.status) {
        this.partnerList = partners.object.data.partnerData;
        this.partnerList.forEach(async (element) => {
          if (element.clientLogo) {
            var x = await this.companylogo({
              fileName: element.clientLogo,
            });
            element.clientLogo = x.object.data;
          }
        });
        this.totalPartnerCount = partners.object.data.totalPartners;
        this.Page_count = partners
          ? Math.ceil(partners.object.data.filteredPartners / this.limit)
          : 0;
      } else {
        this.partnerList = [];
      }
      if (this.input !== undefined && this.input.trim() != "") {
        partners.Search = this.input.trim();
      }
      // if (partners != undefined) {
      // }
    },

    // async GetUserOfClient(id) {
    //    const names = await this.getpartner({
    //      clientId: id,
    //   });
    //   this.client_name = names.object.data.companyName;

    //   var mappeduser = await this.GetMapClientsWithUsers({
    //      ClientId: id,
    //   });

    //   this.useroptions =  await mappeduser.object.data; 
    //   this.mappedRM = await this.useroptions.map((i) => ({
    //     value: i.userId,
    //     label: i.firstName + ' ' + i.lastName
    // }));

    // },

    async editPartner(id) {
      this.Create_Partner = 1;
      this.partner_data = id;
      var parnter = await this.getpartner({
        clientId: id,
      });

      this.email = parnter.object.data.adminEmail;
      this.partner_company = parnter.object.data.companyName;
      this.first_name = parnter.object.data.firstName;
      this.last_name = parnter.object.data.lastName;
      this.token = parnter.object.data.tokens;
      this.token = this.token.toString();
      var x = parnter.object.data.profileImage;
      if (x) {
        var resp = await this.companylogo({
          fileName: x,
        });
        this.url = resp.object.data;
      }
    },

    async getAllUser(){
      var model = {
        status:this.userStatus,
        clientId: this.clientId,
        limit:this.limit,
        page:this.page,
      };
      var partners = await this.GetAllUsers(model);
      this.AllPartnerList = await partners.object.data.partners;
      this.totalPartnerCount = partners.object.data.totalPartners;

      // this.Page_count = await partners.object.data.totalPages;
      //this.AllPartnerList = await partners.object.data.partners;
      if(this.AllPartnerList.length > 0)
      {
        this.AllPartnerList.forEach(async(partner) => {
          var model={
            clientId:partner.id,

          };
          var clientuser = await this.Getuserlist(model);
          if(clientuser.status){
            partner.userdata = await clientuser.object.data;
          }
          else{
            partner.userdata=[]
          }
        })
      }
    },
    async clientPartner(id, companyName) {
      this.personalClientId = id;
      this.personalCompanyName = companyName;
    },
    //

    //   const group = await this.getGroupsWithPagination({
    //     clientId: this.user.clientId,
    //     offset: 1,
    //     limit: 100,
    //     status: "active",
    //   });
    //   this.options = group.object.data.regions.map((i) => ({
    //     value: i.regionId,
    //     label: i.regionName,
    //   }));

    async GetSelectedUser(selecteduser) {
      this.primaryUser = [];
      this.selectedPrimaryUser = [];

      const filteredUser = this.users.filter(item => selecteduser.includes(item.value));
      
      this.selectedPrimaryUser = filteredUser.map((i) => ({
        value: i.value,
        label: i.label
      }))
    },

    async GetSelectedPrimaryRM(group) {
      this.primaryRMs = [];
      this.EditPrimaryUser = [];

      const filteredUser = this.option.filter(item => group.includes(item.value));

      this.primaryRMs = filteredUser.map((i) => ({
        value: i.value,
        label: i.label
      }))
    },


    handleSelected(event) {
      if (parseInt(event) === 0) {
        // select all
        for (let item of this.options) {
          if (parseInt(item.value) > 0) {
            this.client_name.push(item);
            this.$emit("handle-select", item.value);
          } else {
            this.client_name.pop(1);
          }
        }
      } else {
        this.$emit("handle-select", event);
      }
    },
    async ViewUserOfClient() {
      if (this.group.length == 0) {
        return this.ShowWarningMessage("Please select Username of Client");
      }
      // if (this.client_name.length == 0) {
      //   return this.ShowWarningMessage("Please select Client");
      // }

      await this.UpdateMappedClientsWithUsers({
        userIds: this.group,
        clientId: this.client,
        primaryRMs: this.EditPrimaryUser
      }); 

      
      if (this.EditPrimaryUser.length > 0 && this.group.length > 0) {
        document.getElementById("closeViewRM").click();
      }
    },

    // For Assign RM button
    async GetRMUserList() {
      const user = await this.GetAllUsers({
        clientId: this.user.clientId,
        page: 1,
        limit: 100,
        status: 4,
      });

      this.users = user.object.data.users.map((i) => ({
        value: i.id,
        label: i.name,
      }));

      const client = await this.getRMWithPagination({
        clientId: this.clientId,
        offset: 1,
        limit: 100,
        status: "active",
      });

      this.options = client.object.data.partnerData.map((i) => ({
        value: i.clientId,
        label: i.company,
      }));
    },

    handleSelect(event) {
      if (parseInt(event) === 0) {
        // select all
        for (let item of this.options) {
          if (parseInt(item.value) > 0) {
            this.selectedclient.push(item);
            this.$emit("handle-select", item.value);
          } else {
            this.selectedclient.pop(1);
          }
        }
      } else {
        this.$emit("handle-select", event);
      }
    },
    async AssignRMtoUser() {
      if (this.selectedclient.length == 0) {
        return this.ShowWarningMessage("Please select Client");
      }
      if (this.selecteduser.length == 0) {
        return this.ShowWarningMessage("Please select Username");
      }
      if (this.primaryUser.length == 0) {
        return this.ShowWarningMessage("Please select Primary User");
      }

      await this.CreateMapClientsWithUsers({
        userIds: this.selecteduser,
        clientIds: this.selectedclient,
        primaryRMs: this.primaryUser
      });

      if (
        this.selecteduser.length > 0 &&
        this.selectedclient.length > 0 &&
        this.primaryUser.length > 0
      ) {
        document.getElementById("closeassignRM").click();
      }
    },

    ResetForm() {
      this.selectedclient = [];
      this.selecteduser = [];
      this.primaryUser = [];
      this.selectedPrimaryUser = [];
      this.clientusername = [];
      this.userclientname = [];
      this.clientuser=[];
      this.EditPrimaryUser=[],
      this.group=[],
      this.user_client = [];
      this.client_name = [];
    },

    //testing
    async getAssignedgroupList() {
      var assignedgrps = await this.AllAssignedGroups({
        clientId: this.clientId,
        offset: this.offset,
        limit: this.limit,
      });
      if (assignedgrps.status) {
        this.assignedgrpsList = assignedgrps.object.data.partnerRegion;
        this.totalGroupCount = assignedgrps.object.data.totalRegions;
        this.grpsList = Object.freeze(
          JSON.parse(JSON.stringify(this.assignedgrpsList))
        );
      }
    },
  },
};
</script>

<style>
.input_number_arrow_remove::-webkit-outer-spin-button,
.input_number_arrow_remove::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

.input_error_border:focus-visible {
  border: 2px solid red !important;
  outline: none !important;
}

.input_error_border:focus {
  border: 1px solid red !important;
}

.page-item.disabled .page-link {
  background-color: #fff !important;
  border-color: #fff !important;
}

.pagination .page-item .page-link {
  width: 30px;
  height: 32px;
  background: transparent;
  display: flex;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  margin: 5px;
  color: #000 !important;
  border: none;
  cursor: pointer;
}

.pagination {
  justify-content: flex-end;
}

.pagination .page-item.active .page-link {
  font-weight: 600;
  background: #ffc801;
}

.pagination .page-item:last-child .page-link {
  background-color: transparent;
  border: none;
  width: 100% !important;
}

.pagination .page-item:first-child .page-link {
  background-color: transparent;
  border: none;
  width: 100% !important;
}

.pagination .page-item.disabled .page-link {
  color: rgb(130, 130, 130) !important;
  cursor: no-drop !important;
  background: #7fffd400 !important;
}
.Assign_RM_buttons {
  border-radius: 50px !important;
  border: 2px solid #7c68ee !important;
  background: #fff !important;
  color: #7c68ee !important;
  margin: 0px 6px 0 0;
}
</style>
