<template>
    <div class="accordion-item mt-0" v-if="qType == 1003">
        <!-- <h2>new code</h2> -->
        <h4 class="accordion-header" id="headingOne">
            <button class="accordion-button fw-bolder align-items-sm-start " type="button" data-bs-toggle="collapse"
                aria-expanded="true" aria-controls="Q1"
                :class="{ collapsed: this.question.show == false }" @click=onTabPress(question.qid)>
                <span class="me-2">Q.{{ this.index + 1 }}</span>
                <div style="display: flex;flex-wrap: wrap; align-items: flex-start;">
                    <span v-html="this.question?.question"></span>
                    <span v-if="question.responses == null && qType!=1005" class="Unanswered_text">Unanswered</span>
                 
                </div>
            </button>
        </h4>
        <div :class="{ show: this.question.show }" class="accordion-collapse collapse"
            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body p-0">
                <div class="ms-3" v-if="this.question?.imageUrl">
                    <img v-bind:src="this.question?.imageUrl" class="w-50" alt="no image">
                </div>
                <!-- <hr> -->
                <div class="Report_Responses_Answer_option_list_area">
                    <div v-for="data in this.question?.questionOptions" :key="data"
                        class="Report_Responses_Answer_option_item" :class="[
                                data.isPsychometryOption
                                    ? data.isChecked
                                        ? 'Report_Responses_right_option_section'
                                        : ''
                                    : data.optionWeightage > 0.0
                                        ? 'Report_Responses_right_option_section'
                                        : data.isChecked
                                            ? 'Report_Responses_wrong_option_section'
                                            : '',
                            ]">
                        {{ data.optionName }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="accordion-item mt-0" v-if="qType == 1005">
       
        <h4 class="accordion-header" id="headingOne">
          
            <button class="accordion-button fw-bolder align-items-sm-start" type="button" data-bs-toggle="collapse"
                 aria-expanded="true" aria-controls="Q1"
                :class="{ collapsed: this.question.show == false }" @click=onTabPress(this.question.qid)>
                <span class="me-2">Q.{{ this.index + 1 }}</span>
                <div  style="display: flex;flex-wrap: wrap; align-items: flex-start;">
                    <span v-html="this.question?.question"></span>
                    <span v-if="question.responses == null && qType!=1005" class="Unanswered_text">Unanswered</span>
                                 </div>
            </button>
        </h4>
        <div  :class="{ show: this.question.show }" class="accordion-collapse collapse"
            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body p-0">
                <div class="ms-3" v-if="this.question?.imageUrl">
                    <img v-bind:src="this.question?.imageUrl" class="w-50" alt="no image">
                </div>
                <!-- <hr class="w-98 my-4 m-auto"> -->
                <SubQuestionType  v-for="(subQ, i) in this.question?.dependentQuestions" :subQuestion="subQ" :key="i"
                    :quesType="subQ?.questionType" :pIndex="this.index" :index="i"></SubQuestionType>
                
            </div>
        </div>
    </div>

    <div class="accordion-item mt-0" v-if="qType == 1037">
        <h4 class="accordion-header" id="headingOne">
            <button class="accordion-button fw-bolder align-items-sm-start" type="button" data-bs-toggle="collapse"
                 aria-expanded="true" aria-controls="Q1"
                :class="{ collapsed: this.question.show == false }" @click=onTabPress(this.question.qid)><span
                    class="me-2"></span>
                <div class="row">
                    <div class="col-md-2">Question 1:</div>
                    <div class="col-md-10">
                        <div class="row">
                            <div  style="display: flex;flex-wrap: wrap; align-items: flex-start;">

                                <span class="col-12" v-html="this.question.question"></span>
                                <span v-if="question.responses == null && qType!=1005" class="Unanswered_text">Unanswered</span>
                            </div>
                            <audio class="col-4" controls v-if="this.question?.mediaAudio">
                                <source :src="this.question?.mediaAudio" type="audio/mpeg" />
                            </audio>
                        </div>
                    </div>
                </div>
            </button>
        </h4>
        <div  :class="{ show: this.question.show }" class="accordion-collapse collapse"
            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body p-0">
                <div class="row">
                    <div class="col-md-12">
                        <div class="Report_basic_info_area active-report-repsponses my-4">
                            <div class="table-responsive-sm table-border-M7-responsive mt-3">
                                <table class="table table-border-M7-kj">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5  style="font-size: 10px !important ;">Response Confidence</h5>
                                                <P>{{ resp?.scoringResults?.outputConfidence }}</P>
                                            </td>
                                            <td>
                                                <h5 style="font-size: 10px !important ;">Pronunciation(%)</h5>
                                                <P>{{ resp?.scoringResults?.pronunciationAbs }}</P>
                                            </td>
                                            <td>
                                                <h5 style="font-size: 10px !important ;">Fluency(%)</h5>
                                                <P>{{ resp?.scoringResults?.fluencyAbs }}</P>
                                            </td>
                                            <td>
                                                <h5 style="font-size: 10px !important ;">Grammar(%)</h5>
                                                <P>{{ resp?.scoringResults?.grammerAbs }}</P>
                                            </td>
                                            <td>
                                                <h5 style="font-size: 10px !important ;">Comprehension(%)</h5>
                                                <P>{{ resp?.scoringResults?.comprehensionAbs }}</P>
                                            </td>
                                            <td>
                                                <h5 style="font-size: 10px !important ;">Vocabulary(%)</h5>
                                                <P>{{ resp?.scoringResults?.vocabularyAbs }}</P>
                                            </td>
                                            <td>
                                                <h5 style="font-size: 10px !important ;">Transcribe(%)</h5>
                                                <P>{{ resp?.scoringResults?.transcribe }}</P>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <div class="accordion-item mt-0" v-if="qType == 1036">
        <h4 class="accordion-header" id="headingOne">
            <button class="accordion-button fw-bolder align-items-sm-start" type="button" data-bs-toggle="collapse"
                 aria-expanded="true" aria-controls="Q1"
                :class="{ collapsed: this.question.show == false }" @click="onTabPress(this.question.qid)"><span
                    class="me-2"></span>
                <div class="row">
                    <div class="col-md-2">Question {{ this.index + 1 }}:</div>
                    <div class="col-md-10">
                        <div class="row">
                            <div  style="display: flex;flex-wrap: wrap; align-items: flex-start;">

                                <span class="col-12" v-html="this.question.question"></span>
                                <span v-if="question.responses == null && qType!=1005" class="Unanswered_text">Unanswered</span>
                            </div>
                            <audio class="col-4" controls v-if="this.question?.mediaAudio">
                                <source :src="this.question?.mediaAudio" type="audio/mpeg" />
                            </audio>
                        </div>
                    </div>
                </div>
            </button>
        </h4>
        <div  :class="{ show: this.question.show }" class="accordion-collapse collapse"
            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body p-0">
                <div class="row">
                    <div class="col-md-12">
                        <div class="Report_basic_info_area active-report-repsponses my-4"
                            v-for="resp in this.question?.responses" :key="resp">
                            <p class="my-3 border-top border-bottom p-3">
                                <b>Candidate Inputs:</b> <br>
                                {{ resp.candidateInputs }}
                            </p>
                            <div class="table-responsive-sm table-border-M7-responsive mt-3">
                                <table class="table table-border-M7-kj">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5 style="font-size: 10px !important ;">Response Confidence</h5>
                                                <P>{{ resp?.scoringResults?.outputConfidence }}</P>
                                            </td>
                                            <td>
                                                <h5 style="font-size: 10px !important ;">Pronunciation(%)</h5>
                                                <P>{{ resp?.scoringResults?.pronunciationAbs }}</P>
                                            </td>
                                            <td>
                                                <h5 style="font-size: 10px !important ;">Fluency(%)</h5>
                                                <P>{{ resp?.scoringResults?.fluencyAbs }}</P>
                                            </td>
                                            <td>
                                                <h5 style="font-size: 10px !important ;">Grammar(%)</h5>
                                                <P>{{ resp?.scoringResults?.grammerAbs }}</P>
                                            </td>
                                            <td>
                                                <h5 style="font-size: 10px !important ;">Comprehension(%)</h5>
                                                <P>{{ resp?.scoringResults?.comprehensionAbs }}</P>
                                            </td>
                                            <td>
                                                <h5 style="font-size: 10px !important ;">Vocabulary(%)</h5>
                                                <P>{{ resp?.scoringResults?.vocabularyAbs }}</P>
                                            </td>
                                            <td>
                                                <h5 style="font-size: 10px !important ;">Transcribe(%)</h5>
                                                <P>{{ resp?.scoringResults?.transcribe }}</P>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <div class="accordion-item mt-0" v-if="qType == 1027">
        <h2 class="accordion-header" id="headingThree">
            <button class="accordion-button fw-bolder align-items-sm-start" type="button" data-bs-toggle="collapse"
                data-bs-target="#Q6" aria-expanded="false" aria-controls="Q6"   :class="{ collapsed: this.question.show == false }" @click="onTabPress(this.question.qid)">
                <span class="me-2">Q.{{ this.index + 1 }}</span>
                <div  style="display: flex;flex-wrap: wrap; align-items: flex-start;">
                    <span v-html="this.question.question"></span>
                    <span v-if="question.responses == null && qType!=1005" class="Unanswered_text">Unanswered
                    
                    </span>
                </div>
            </button>
        </h2>
        <div :class="{ show: this.question.show }" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div class="accordion-body">
                <div class="">
                    <p class="ms-2">
                        <!-- <span v-html="item.question"></span> -->
                    </p>
                    <div class="row">
                        <div class="col-md-4">
                            <button name="cars" class="Report_Responses_Answer_selectBox my-4 form-select"
                                @click="isShow = !isShow">
                                Options Weightage
                            </button>
                        </div>
                    </div>

                    <div class="col-xl-6 col-xl-7 col-md-12" v-show="isShow">
                        <div class="table-border-M7">
                            <div class="row border">
                                <div class="col-md-6 p-0">
                                    <div class="border p-2 bg-secondary-option border-end-0">
                                        Option
                                    </div>
                                    <div v-for="data in questionOptions(true)" :key="data">
                                        <div class="border-top border-bottom p-2"
                                            :class="{ 'Report_Responses_right_option_section border': data.isChecked }">
                                            <span>
                                                {{ data.optionName }}
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-6 p-0">
                                    <div class="border p-2 bg-secondary-option border-start-0">
                                        Option
                                    </div>
                                    <div class="" v-for="data in questionOptions(false)" :key="data">
                                        <div class="border-top border-bottom p-2"
                                            :class="{ 'Report_Responses_wrong_option_section border': data.isChecked }">
                                            <span>
                                                {{ data.optionName }}
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>





                        </div>
                    </div>
                </div>
                <hr />
                <div>
                    <p class="fw-bolder">Candidate Input</p>
                </div>
            </div>
        </div>
    </div>

    <div class="accordion-item mt-0" v-if="qType == 1039">
        <h4 class="accordion-header" id="headingOne">
            <button class="accordion-button fw-bolder align-items-sm-start" type="button" data-bs-toggle="collapse"
                 aria-expanded="true" aria-controls="Q1"
                :class="{ collapsed: this.question.show == false }" @click=onTabPress(this.question.qid)>
                <span class="me-2">Q.{{ this.index + 1 }}</span>
                <div  style="display: flex;flex-wrap: wrap; align-items: flex-start;">
                    <span v-html="this.question?.question"></span>
                    <span v-if="question.responses == null && qType!=1005" class="Unanswered_text">Unanswered</span>
                 
                </div>
            </button>
        </h4>
        <div  :class="{ show: this.question.show }" class="accordion-collapse collapse"
            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body p-0">
                <SubQuestionType  v-for="(subQ, i) in this.question?.dependentQuestions" :subQuestion="subQ" :key="i"
                    :quesType="subQ?.questionType" :pIndex="this.index" :index="i"></SubQuestionType>
            </div>
        </div>
    </div>
    <div class="accordion-item mt-0" v-if="qType == 1020">
        <h4 class="accordion-header" id="headingOne">
            <button class="accordion-button fw-bolder align-items-sm-start" type="button" data-bs-toggle="collapse"
                 aria-expanded="true" aria-controls="Q1"
                :class="{ collapsed: this.question.show == false }" @click=onTabPress(this.question.qid)>
                <span class="me-2">Q.{{ this.index + 1 }}</span>
                <div  style="display: flex;flex-wrap: wrap; align-items: flex-start;">
                    <span v-html="this.question?.question"></span>
                    <span v-if="question.responses == null && qType!=1005" class="Unanswered_text">Unanswered</span>
                 
                </div>
            </button>
        </h4>
        <div  :class="{ show: this.question.show }" class="accordion-collapse collapse"
            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body p-0">
                <div class="row">
                    <div class="col-md-12">
                        <div class="Report_basic_info_area active-report-repsponses my-4"
                            v-for="resp in this.question?.responses" :key="resp">
                            <p class="my-3 border-top p-3">
                                <b>Candidate Input:</b> <br>
                                {{ resp.candidateInputs }}
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="accordion-item mt-0" v-if="qType == 1008">
        <h4 class="accordion-header" id="headingOne">
            <button class="accordion-button fw-bolder align-items-sm-start" type="button" data-bs-toggle="collapse"
                 aria-expanded="true" aria-controls="Q1"
                :class="{ collapsed: this.question.show == false }" @click=onTabPress(this.question.qid)>
                <span class="me-2">Q.{{ this.index + 1 }}</span>
                <div  style="display: flex;flex-wrap: wrap; align-items: flex-start;">
                    <span v-html="this.question?.question"></span>
                    <span v-if="question.responses == null && qType!=1005" class="Unanswered_text">Unanswered</span>
                 
                </div>
            </button>
        </h4>
        <div :class="{ 'show': this.question.show }" class="accordion-collapse collapse" aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">
            <p class="p-3">{{ this.question?.passage }}</p>
            <div class="accordion-body" v-for="sectionRes in this.question.responses" :key="sectionRes">
                <p class="my-3 border-top py-3">
                    <b>Candidate Inputs:</b> <br>
                    {{ sectionRes.candidateInputs }}
                </p>

            </div>
        </div>
    </div>
</template>
<script>
import SubQuestionType from '../question-type-components/Sub-Question-Type.vue';
export default {
    name: "QuestionType",
    props: ["qType", "question", "index", "mainques"],
    components: {
        SubQuestionType
    },
    data() {
        return {
            isShow: false,
            questiontype: false,
            questionlist: [],
        };
    },
    methods: {
        questionOptions(type) {
            if (type == true) {
                var x = this.question?.questionOptions.filter((x) => x.optionWeightage > 0)
                return x;
                //    console.log(x);
            } else {
                var y = this.question?.questionOptions.filter((x) => x.optionWeightage <= 0);
                return y;
                // console.log(y);
            }

        },
        onTabPress(id) {
            this.mainques?.questions.forEach((elm)=>{
                if(elm.qid==id && elm.show != true) elm.show = true;
               
                else elm.show = false;
            })
        },
    }
};
</script>
<style>
.bg-secondary-option {
    background: #d2d2d2;
}
</style>