<template>
    <div class="page-content">
        <div class="Searching_input_box_mobile_view mb-3 position-relative">
            <div class="w-100" v-if="this.show">
                <input type="text" v-model="input" @keyup="filteredList" placeholder="Search..." />
                <!-- <div v-for="(item, index) in this.result" :key="index">
                        <span :id="index" @click="searchregionname(item)">{{
                                stringTOHtml(item, index)
                        }}</span>
                    </div> -->
                <img src="../../public/dashboard-assets/images/M2/Close_icon.png" @click="closemobilesearch" alt=""
                    class="close-icon" />
            </div>
        </div>
        <div class="
                      d-flex
                      justify-content-between
                      align-items-center
                      flex-wrap
                      mb-2
                      header-MV-kj
                      d-none d-md-flex
                    ">
            <!-- header top -->
            <div class="my-2 d-flex">
                <div class="top_heading_CreatePartner_btn d-block me-2">
                    <div class="Group_top_three_dot">
                        <div class="dropdown">
                            <router-link
                                :to="{ name: 'ManagementTeams', query: { offset: encode(this.routeroffset), current: encode(this.routercurrent,),filter: true } }"
                                class="btn p-0 Group_top_three_dot_dropdown" type="button" style="line-height: 2.3">
                                <i class="fa-solid fa-chevron-left"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div>
                    <h4 class="mb-2 mb-md-0 ">{{ this.groupname }}</h4>
                    <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item text-muted">
                                {{ this.grouplocation }}
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">
                                Total Members ({{ this.totalmember }})
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="d-flex">
                <div class="top_heading_CreatePartner_btn position-relative">
                    <div class="Group_top_three_dot">
                        <div class="Searching_input-box">
                            <input type="text" v-model="input" @keyup="filteredList" placeholder="Search..."
                                @keyup.enter="filteredList" />
                            <!-- <div v-for="(item, index) in this.result" :key="index">
                            <span :id="index" @click="searchregionname(item)">
                                {{ stringTOHtml(item, index) }}
                            </span>
                        </div> -->
                            <span class="icon Group_top_three_dot_dropdown" @click="opensearch">
                                <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt=""
                                    class="Searching_search_icon" />
                            </span>
                            <img src="../../public/dashboard-assets/images/M2/Close_icon.png" @click="closesearch" alt=""
                                class="close-icon" />
                        </div>
                    </div>
                </div>

                <!-- <div class="top_heading_CreatePartner_btn d-block">
                        <div class="Group_top_three_dot">
                            <div class="dropdown">
                                <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    style="line-height: 0.3">
                                    <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" />
                                </button>
                            </div>
                        </div>
                    </div> -->
                <div class="top_heading_CreatePartner_btn mx-2">
                    <button type="button" class="btn btn-primary btn-icon-text" @click="addmemberpopup">
                        <i class="fa-solid fa-plus"></i> Add Member
                    </button>
                </div>
            </div>
        </div>
        <!-- </div>
            </div> -->
        <div class="
                      header-2-MV-kj
                      justify-content-between
                      align-items-center
                      flex-wrap
                      mb-2
                    ">
            <div class="d-flex justify-content-between align-items-center my-2">
                <div class="d-flex">
                    <div class="top_heading_CreatePartner_btn d-block me-2">
                        <div class="Group_top_three_dot">
                            <div class="dropdown">
                                <!-- <router-link :to="{ name: 'ManagementTeams', query: { offset: this.routeroffset, current: this.routercurrent } }" class="btn p-0 Group_top_three_dot_dropdown" type="button">
                                <i class="fa-solid fa-chevron-left"></i>
                            </router-link> -->
                                <router-link
                                    :to="{ name: 'ManagementTeams', query: { offset: encode(this.routeroffset), current: encode(this.routercurrent) } }"
                                    class="btn p-0 Group_top_three_dot_dropdown" type="button" style="line-height: 2.3">
                                    <i class="fa-solid fa-chevron-left"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h4 class="mb-2 mb-md-0">Group > {{ this.groupname }}</h4>
                        <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb :">
                            <ol class="breadcrumb">
                                <!-- <li class="breadcrumb-item text-muted">
                      {{ this.grouplocation }}
                    </li> -->
                                <li class="breadcrumb-item active" aria-current="page">
                                    Total Group Members ({{ this.totalmember }})
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <!-- <h6 class="text-muted">Total Partners (07)</h6> -->

                <!-- Mobile View Searching Button -->
                <div class="
                          top_heading_CreatePartner_btn
                          position-relative
                          Searching_button_mobileView
                        ">
                    <div class="Group_top_three_dot">
                        <span class="icon Group_top_three_dot_dropdown"
                            :style="{ display: this.show == true ? 'none' : 'block' }" @click="openmobilesearch">
                            <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt=""
                                class="Searching_search_icon" />
                        </span>
                    </div>
                </div>
            </div>

            <!-- Moblie View icon Create Group -->
            <div class="mobile_Create_Partner_button">
                <button type="button" class="btn" @click="addmemberpopup">
                    <i class="fa-solid fa-plus"></i>
                </button>
            </div>
        </div>

        <!-- empty section -->
        <section class="Partner_empty_main_section_hp mt-4 mb-2" v-if="this.memberlist.length == null">
            <div class="row">
                <div class="Partner_empty_img_hp m-auto text-center">
                    <img src="../../public/dashboard-assets/images/M2/Partner_blank.png" alt="" />
                </div>
                <div class="Partner_empty_content_hp m-auto text-center">
                    <div class="mb-4">
                        <h2>Don’t have members yet?</h2>
                        <p class="text-muted mt-2">
                            let’s start adding members and start working
                        </p>
                    </div>
                    <button type="button" class="btn btn-primary btn-icon-text" data-bs-toggle="modal"
                        data-bs-target="#Addmember">
                        <i class="fa-solid fa-plus"></i> Add Members
                    </button>
                </div>
            </div>
        </section>

        <div class="row">
            <div class="col-md-12">
                <div class="accordion" id="FaqAccordion">
                    <div class="managementPartner-none">
                        <div class="card managementPartner-header-kj mb-2">
                            <table class="table ActiveTestTable teble_heading_section_hp">
                                <thead>
                                    <tr>
                                        <th class="w-25 teble_heading_item_hp">Name</th>
                                        <th class="w-25 teble_heading_item_hp">Email</th>
                                        <th class="w-25 teble_heading_item_hp">Role</th>
                                        <th class="w-25 teble_heading_item_hp">Action</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                    <div class="managementPartner-D-M-V">
                        <div class="card managementPartner-header-kj mb-2">
                            <div class="card-body p-2">
                                <div class="row">
                                    <div class="col-6"><span class=""> Partner </span></div>
                                    <div class="col-3 p-0"><span class=""> Role</span></div>
                                    <div class="col-3 p-0"><span class=""> Action </span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card p-0 managementPartner-Acc-p-kj">
                        <!-- web UI -->
                        <div class="managementPartner-none">
                            <table class="table ActiveTestTable">
                                <tbody>
                                    <!-- 1 -->
                                    <tr v-for="item in this.memberlist" :key="item">
                                        <td class="w-25">
                                            <div class="d-flex fw-bold align-items-center">
                                                <div class="PartnerTeamMembersProfile-area-kj">
                                                    <div class="PartnerTeamMembersProfile-kj name-logo-1">
                                                        {{ item.firstName.charAt(0) }}
                                                    </div>
                                                </div>
                                                {{ item.firstName }} &nbsp;{{ item.lastName }}
                                            </div>
                                        </td>
                                        <td class="w-25 text-muted">
                                            <a href="mailto:ajayshah@gmail.com" mailto:class="text-dark">
                                                <span class="web-a-color-kj">
                                                    {{ item.email }}
                                                </span>
                                            </a>
                                        </td>
                                        <td class="w-25 text-muted">
                                            <span class="web-a-color-kj">{{ item.roleName }}</span>
                                        </td>
                                        <td class="w-25 text-muted">
                                            <a data-bs-toggle="modal" data-bs-target="#DeactivatePartnerModal"
                                                class="mx-2 text-color-kj" @click="
                                                    removemember(
                                                        item.userId,
                                                        item.firstName,
                                                        item.lastName
                                                    )
                                                    "><i class="fa-solid fa-xmark"></i> Remove</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- Moblie Ui -->
                        <div class="managementPartner-D-M-V">
                            <div class="card mb-2" v-for="item in this.memberlist" :key="item">
                                <div class="card-body p-2">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="PartnerTeamMembersProfile-area-kj">
                                                <div class="PartnerTeamMembersProfile-kj name-logo-1">
                                                    {{ item.firstName.charAt(0) }}
                                                </div>
                                            </div>

                                            <div>
                                                <span class="font-size-12-MV-kj">
                                                    {{ item.firstName }} &nbsp;{{ item.lastName }}</span>
                                            </div>
                                            <div>
                                                <a href="mailto:ajayshah@gmail.com" mailto:class="text-dark"><span
                                                        class="font-size-12-MV-kj">
                                                        {{ item.email.substring(0, 10) + "..." }}</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-3 p-0">
                                            <span class="font-size-12-MV-kj">{{
                                                item.roleName
                                            }}</span>
                                        </div>
                                        <div class="col-3 p-0">
                                            <a data-bs-toggle="modal" data-bs-target="#DeactivatePartnerModal"
                                                class="mx-2 text-color-kj font-size-12-MV-kj" @click="
                                                removemember(
                                                    item.userId,
                                                    item.firstName,
                                                    item.lastName
                                                )
                                                    "><i class="fa-solid fa-xmark"></i> Remove</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row justify-content-end mt-3">
                <div class="col-lg-4">
                    <div class="Partner_management_top-kj pagination-kj">
                        <a href=""><i class="fa-solid fa-chevron-left"></i> <span>Prev</span></a>
                        <a href="" class="pagination-kj-number">1</a>
                        <a href="" class="pagination-kj-number">2</a>
                        <a href="" class="pagination-kj-number">3</a>
                        <a href=""><span>Next</span> <i class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div> -->
    </div>

    <Addnewgroupmember v-if="this.addnew == true" :gid="this.groupid" :gname="this.groupname" @clicked="showpop" />
    <!-- remove -->
    <div class="modal fade" id="DeactivatePartnerModal" data-bs-backdrop="static" tabindex="-1"
        aria-labelledby="DeactivatePartnerModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-body p-4">
                    <div class="">
                        <h3 class="mb-3">Remove</h3>
                        <p class="text-muted">
                            Are you sure you want to remove
                            <strong class="text-dark">{{ this.firstname }}&nbsp;{{ this.lastname }}</strong>
                            from {{ this.groupname }}.
                        </p>
                    </div>
                    <div class="Deactivate_buttons row mt-5 justify-content-center">
                        <button class="col-5 Deactivate_Cancel_buttons" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" class="col-5 btn Deactivate_Deactivate_buttons" data-bs-dismiss="modal"
                            @click="confirmremove()">
                            Remove
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapActions,
    mapState
} from "vuex";
import Addnewgroupmember from "./Addnewgroupmember.vue";
export default {
    name: "AddMemberlist",
    data() {
        return {
            memberlist: [],
            grpmemid: "",
            firstname: "",
            lasttname: "",
            input: "",
            show: false,
            memberlistfilter: [],
            result: [],
            groupname: "",
            totalmember: "",
            addnew: false,
            grouplocation: "",
            status: '',
            groupid: window.atob(this.$route.params.id),
            routerstatus: window.atob(this.$route.params.status),
            routeroffset: window.atob(this.$route.query.offset),
            routercurrent: window.atob(this.$route.query.current)
        };
    },
    components: {
        Addnewgroupmember,
    },
    async mounted() {
        await this.getgroups();
        await this.getgroupmembers(this.groupid);
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),
    },
    methods: {
        ...mapActions(["Getregionmembers", "deleteregionmember", "GetTeams", "getGroupsWithPagination"]),
        opensearch() {
            document.querySelector(".Searching_input-box").classList.add("open");
        },
        encode(item) {
            return window.btoa(item);
        },
        async closesearch() {
            document.querySelector(".Searching_input-box").classList.remove("open");
            this.input = "";
            this.memberlist = this.memberlistfilter;
        },
        async closemobilesearch() {
            this.show = false;
            document
                .querySelector(".Searching_input_box_mobile_view")
                .classList.remove("open");
            this.input = "";
            this.memberlist = this.memberlistfilter;
        },
        openmobilesearch() {
            document
                .querySelector(".Searching_input_box_mobile_view")
                .classList.add("open");
            this.input = "";
            this.show = true;
        },

        async filteredList() {
            if (this.input != "") {
                this.memberlist = this.memberlistfilter.filter((element) => {
                    return (
                        (element.firstName + " " + element.lastName)
                            .toLowerCase()
                            .startsWith(this.input.trim().toLowerCase()) ||
                        element.email.toLowerCase().startsWith(this.input.trim().toLowerCase()) ||
                        element.roleName.toLowerCase().startsWith(this.input.trim().toLowerCase())
                    );
                });
            } else this.memberlist = this.memberlistfilter;
        },

        async getgroups() {
            if (this.routerstatus == "true") {
                this.status = "active"
            } else {
                this.status = "inactive"
            }
            const group = await this.getGroupsWithPagination({
                clientId: this.user.clientId,
                offset: 1,
                limit: 100,
                status: this.status,
            })
            group.object.data.regions.forEach((element) => {
                if (element.regionId == this.groupid) {
                    this.groupname = element.regionName;
                    this.grouplocation = element.locationName;

                }
            });
        },
        async getgroupmembers(regionid) {
            const members = await this.Getregionmembers({
                regionId: regionid,
            });
            if (members.status) {
                this.memberlist = members.object.data;
                this.memberlistfilter = members.object.data;
                this.totalmember = this.memberlist.length;
            }
        },
        async removemember(id, fname, lname) {
            this.grpmemid = id;
            this.firstname = fname;
            this.lastname = lname;
        },
        async confirmremove() {
            await this.deleteregionmember({
                userId: this.grpmemid,
                regionId: this.groupid,
            });
            await this.getgroupmembers(this.groupid);
        },
        async showpop(val) {
            this.addnew = val;
            await this.getgroupmembers(this.groupid);
        },
        async addmemberpopup() {
            this.addnew = true;
        },
    },
};
</script>
