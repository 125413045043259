<template>
  <div>
    <div
      class="modal fade"
      id="error"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref="errorModal"
      style="z-index:99999999 ;"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-body">
          <div class="termsPop-section">
            <p>{{ message }}</p>
            <a
              href=""
              class="text-dark text-decoration-underline ms-4 font-size-13"
              data-bs-dismiss="modal"
              aria-label="Close" id="errCloseModal"
              >DISMISS</a
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="success"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref="successModal"
      style="z-index:99999999 ;"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-body">
          <div class="termsPop-section">
            <p>{{ message }}</p>
            <a
              href=""
              class="text-dark text-decoration-underline ms-4 font-size-13"
              data-bs-dismiss="modal"
              aria-label="Close" id="sucCloseModal"
              >DISMISS</a
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="warning"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref="WarningModal"
      style="z-index:99999999 ;"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-body">
          <div class="termsPop-section">
            <p>{{ message }}</p>
            <a
              href=""
              class="text-dark text-decoration-underline ms-4 font-size-13"
              data-bs-dismiss="modal"
              aria-label="Close" id="warnCloseModal"
              >DISMISS</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Popup",
  computed: {
    ...mapState({
      warningPopup: (state) => state.popup.warningPopup,
      successPopup: (state) => state.popup.successPopup,
      errorPopup: (state) => state.popup.errorPopup,
      message: (state) => state.popup.message,
      isOnline: (state) => state.popup.isOnline,
    }),
  },
  methods: {
    ...mapMutations(["setSuccessPopup", "setErrorPopup", "setWarningPopup"]),
  },
  watch: {
    successPopup() {
      if (this.message !== "") {
        window.$("#success").modal("show");
        setTimeout(() => {
          document.getElementById('sucCloseModal').click();
        }, 2000);
      }
    },
    errorPopup() {
      if (this.message !== "") {
        window.$("#error").modal("show");
        setTimeout(()=>{
          document.getElementById('errCloseModal').click();
        },2000);
      }
    },
    warningPopup() {
      if (this.message !== "") {
        window.$("#warning").modal("show");
        setTimeout(()=>{
          document.getElementById('warnCloseModal').click();
        },2000);
      }
    },
  },
  mounted() {
    this.$refs.WarningModal.addEventListener("hidden.bs.modal", () => {
      window.$("#warning").modal("hide");
      this.setWarningPopup({ bool: false, message: "" });
    });

    this.$refs.errorModal.addEventListener("hidden.bs.modal", () => {
      window.$("#error").modal("hide");
      this.setErrorPopup({ bool: false, message: "" });
    });

    this.$refs.successModal.addEventListener("hidden.bs.modal", () => {
      window.$("#success").modal("hide");
      this.setSuccessPopup({ bool: false, message: "" });
    });
  },
};
</script>
<style lang="css">
/* @import url("/assets/css/style.css");
@import url("/assets/css/responsive.css"); */

.mx-clone-btn {
  margin-right: 3rem !important;
  margin-left: 2.5rem !important;
}

.font-size-14 {
  font-size: 14px;
}

.text-justify {
  text-align: justify;
  word-break: break-all;
}
</style>
