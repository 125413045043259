<template>
  <section class="container-fluid login_page_section login-img">
    <div class="row align-items-center">
      <div class="col-md-6 login_left_section">
        <div class="text-center">
          <img src="../../public/assets/Images/Signup.png" alt="" />
        </div>
      </div>
      <div class="col-md-6 login_right_section ">
        <div class="logo_section mx-3">
          <img src="../assets/Images/logo.png" alt="" width="" />
        </div>
        <div style="clear: both"></div>
        <div class="row justify-content-center responsive"
          style="padding-right: var(--bs-gutter-x, 0.75rem); padding-left: var(--bs-gutter-x, 0.75rem);">
          <div class="col-xl-8 col-lg-10 col-md-11">
            <div class="login_page_heading">
              <h3 class="font-weight-800">Glad to see you again!</h3>
              <p>Hire the right personality and skilled candidate</p>
            </div>
            <form action="">
              <div class="form-group mt-3">
                <div class="form-group mb-3">
                  <label class="input-hero">
                    <input class="input_area" type="text" required v-model="companyName" />
                    <span class="input-name">Company Name</span>
                  </label>
                </div>
              </div>
              <div class="row my-4 mb-3">
                <div class="col-12 col-xl-6 col-sm-12 space">
                  <label class="input-hero">
                    <input class="input_area" required v-model="firstName" />
                    <span class="input-name"> First Name </span>
                  </label>
                </div>
                <div class="col-12 col-xl-6 col-sm-12 space">
                  <label class="input-hero">
                    <input class="input_area" required v-model="lastName" />
                    <span class="input-name"> Last Name </span>
                  </label>
                </div>
              </div>
              <div class="form-group">
                <div class="form-group mb-3">
                  <label class="input-hero">
                    <select class="form-select input_area select_ca" v-model="countryName" required>
                      <option disabled selected>Select Preferred Country</option>
                      <option v-for="item in GetLocationData.object.data" :key="item" :value="item.iso2">
                        {{ item.name }}
                      </option>
                    </select>
                    <span class="input-name">Country</span>
                  </label>
                </div>
              </div>
              <div class="form-group mt-4">
                <div class="form-group mb-3">
                  <label class="input-hero">
                    <input class="input_area" type="tel" required v-model="Phone"  />
                    <span class="input-name">Phone Number</span>
                  </label>
                </div>
              </div>
              <div class="form-group mt-4">
                <div class="form-group mb-3">
                  <label class="input-hero">
                    <input class="input_area" type="email" required v-model="Email" />
                    <span class="input-name">Work Email</span>
                  </label>
                </div>
              </div>
              <div style="font-size: 15px; display: flex; align-items: center; margin-top: 5%;">
                <input type="checkbox" name="" id="" style="margin-right: 2%; " v-model="privacy">
                <span style="color: rgb(92, 92, 92);">By signing up you agree to PMaps's Terms and <a
                    href="https://pmapstest.com/privacy-policy/" target="_blank">Privacy Policy </a></span>
              </div>

              <div class="mt-5 login_button_area mb-6">
                <div class="row">
                  <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                  <router-link :to="{ name: 'Login' }" class="btn btn-primary2 me-3 mb-3" type="submit" style="width: 100%;">
                  Back to Login
                </router-link>
                 </div>  -->
                  <div class="col-12 ">
                    <!-- Separate function for disabled  -->
                    <button class="btn btn-primary" @click="createAccount" type="button" style="width: 100%;"
                      
                      :disabled="ValidateData()">
                      Create Account
                    </button>
                  </div>
                </div>
                <div class="float-end mt-3 mb-5">Already have an account?
                <router-link :to="{ name: 'Login' }" type="submit">
                  Sign in
                </router-link>
              </div>
              </div>
             
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState, mapActions } from "vuex";
import {  validatePhoneNumber } from "@/utils";

export default {
  name: "Signup",

  data() {
    return {
      emailError: "",
      GetLocationData: {
        object: {
          data: [],
        },
      },
      companyName: "",
      firstName: "",
      lastName: "",
      countryName: "",
      Email: "",
      lat: "",
      lng: "",
      error: "",    
      country: "",
      privacy: false,
      Phone: null,
      plancode:"",
      PhoneError:""
    };
  },
  async created() {
    this.GetLocationData = await this.getlocation();
  },
  async mounted() {
    await this.fetchLocation();

  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

  },
  methods: {
    ...mapActions(["ShowWarningMessage", "billingSignup", "getlocation"]),


    ValidateData(){
      return this.privacy == false || this.Email == '' || this.Phone == null || this.countryName == '' || this.companyName == '' || this.firstName == '' || this.lastName == '';
    },
async preventNumericData() {
  
  if(!validatePhoneNumber(this.Phone)) {
        this.PhoneError= "Enter valid phone number";
      } else {
        this.PhoneError= ''
      }

},

   
    async createAccount() {
      await this.validateEmail();
      await this.preventNumericData();


if (this.emailError != '') {
        return this.ShowWarningMessage(this.emailError);
      }
    else if(this.PhoneError !=''){
  return this.ShowWarningMessage(this.PhoneError);
}
else if(this.emailError=='' && this.PhoneError=='') {
        await this.billingSignup({
          name: this.companyName,
          domain: "string",
          isPartner: true,
          countryCode: this.countryName,
          logo: "string",
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.Email,
          mobileNo: this.Phone,
          plan: 'FreeTrial'
        });
      }
    },

    validateEmail() {
  const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,3})+$/;
  const disallowedDomains = ["outlook.com", "hotmail.com", "gmail.com", "yahoo.com", "aol.com", "icloud.com"];
  const email = this.Email.toLowerCase();
  const domain = email.split("@")[1];

  if (!emailRegex.test(email)) {
    this.emailError = "Please enter a valid email addresses";
  } else if (disallowedDomains.includes(domain)) {
    this.emailError = "Emails from " + domain + " are not allowed";
  } else {
    this.emailError = '';
  }
},


    fetchLocation() {
      fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then((data) => {
          const country = this.GetLocationData.object.data.find(item => item.name === data.country_name);
          this.countryName = country ? country.iso2 : '';
        })
        // .catch((error) => {
        //   console.error(error);
        // });
    }
  },
};
</script>
<style scoped>
.btn-primary2 {
  border-radius: 50px !important;
  border: 2px solid #7c68ee !important;
  background: #fff !important;
  color: #7c68ee !important;
  margin: 0px 6px 0 0;
}

.btn-primary2:hover {
  background: #7c68ee !important;
  color: #ffffff !important;
  margin: 0px 6px 0 0;
}



.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 0rem !important;
  padding-left: 0rem !important;

  margin-right: auto;
  margin-left: auto;
}

.responsive {
  margin-top: 10%;
}

@media (max-width: 500px) {
  .responsive {
    margin-top: 75% !important;
  }

  .space {
    margin-top: 4%;
  }
}

@media (max-width: 900px) {
  .responsive {
    margin-top: 50%;
  }
}
</style>
  
