<template>
    <div style="padding: 2px 3px;margin: 3px 0;" v-if="this.quesType == 1003 || this.quesType == 1005">
        <div style="font-weight: 700; display: flex; align-items: start; margin-bottom: 3px;" type="button">
            <p style="padding-right: 2px;">Q.{{ this.pIndex + 1 }}.{{ this.index + 1 }}</p>
            <p v-html="this.subQuestion?.question"></p>
            <!-- v-if="question.responses == null && qType!=1005" -->
            <p
                v-if="(quesType != 1003) ? (!question?.responses) : (question?.questionOptions.filter(x => x.isChecked == true).length == 0)"
                class="Unanswered_text">Unanswered</p>
        </div>
        <div class="accordion-body p-0 ps-1">
            <div class="Report_Responses_Answer_option_list_area">
                <div v-for="item in this.subQuestion?.questionOptions" :key="item"
                    class="Report_Responses_Answer_option_item" :class="[
                        item.isPsychometryOption
                            ? item.isChecked
                                ? 'Report_Responses_right_option_section'
                                : ''
                            : item.optionWeightage > 0.0
                                ? 'Report_Responses_right_option_section'
                                : item.isChecked
                                    ? 'Report_Responses_wrong_option_section'
                                    : '',
                    ]">
                    {{ item.optionName }}
                </div>
            </div>
        </div>
    </div>
    <div class="" v-if="this.quesType == 1037 || this.quesType == 1038">
        <div style="display: flex; align-items: start; font-weight: 600;margin-top: 8px; ">
            <div style="width: 70px;font-size: 3.5px;">Question {{ this.pIndex + 1 }}.{{ this.index + 1 }}:</div>
            <div>
                <div class="row">
                    <p class="col-12" v-html="this.subQuestion.question"></p>

                    <div v-if="this.type == 'pdf'">
                        <p> {{ this.subQuestion?.mediaAudio }}</p>
                    </div>
                    <div v-else>
                        <audio class="col-4" controls v-if="this.subQuestion?.mediaAudio">
                            <source :src="this.subQuestion?.mediaAudio" type="audio/mpeg" />
                        </audio>

                    </div>
                </div>
            </div>
        </div>

        <div class="">
            <div class="border-top border-bottom " style="margin: 3px 0; padding: 3px; border-color: #e9ecef;">
                <b>Candidate Inputs:</b>
                <div v-if="this.type == 'pdf'">
                    <p> {{ this.subQuestion?.responses?.candidateInputs }}</p>
                </div>
                <div v-else>
                    <audio class="col-4" controls v-if="this.subQuestion?.responses?.candidateInputs">
                        <source :src="this.subQuestion?.responses?.candidateInputs" type="audio/mpeg" />
                    </audio>

                </div>

            </div>
            <div style="font-size: 8px !important">
                <table class="table">
                    <tbody>
                        <tr>
                            <td style="padding: 1px 2px 2px 2px;">
                                <h5 class="table-title" style="font-size: 2.6px !important">
                                    Response Confidence</h5>
                                <P>{{ this.subQuestion?.responses?.scoringResults?.outputConfidence }}</P>
                            </td>
                            <td style="padding: 1px 2px 2px 2px;">
                                <h5 class="table-title" style="font-size: 2.6px !important">
                                    Pronunciation(%)</h5>
                                <P>{{ this.subQuestion?.responses?.scoringResults?.pronunciationAbs }}</P>
                            </td>
                            <td style="padding: 1px 2px 2px 2px;">
                                <h5 class="table-title" style="font-size: 2.6px !important">
                                    Fluency(%)</h5>
                                <P>{{ this.subQuestion?.responses?.scoringResults?.fluencyAbs }}</P>
                            </td>
                            <td style="padding: 1px 2px 2px 2px;">
                                <h5 class="table-title" style="font-size: 2.6px !important">
                                    Grammar(%)</h5>
                                <P>{{ this.subQuestion?.responses?.scoringResults?.grammerAbs }}</P>
                            </td>
                            <td style="padding: 1px 2px 2px 2px;">
                                <h5 class="table-title" style="font-size: 2.6px !important">
                                    Comprehension(%)</h5>
                                <P>{{ this.subQuestion?.responses?.scoringResults?.comprehensionAbs }}</P>
                            </td>
                            <td style="padding: 1px 2px 2px 2px;">
                                <h5 class="table-title" style="font-size: 2.6px !important">
                                    Vocabulary(%)</h5>
                                <P>{{ this.subQuestion?.responses?.scoringResults?.vocabularyAbs }}</P>
                            </td>
                            <td style="padding: 1px 2px 2px 2px;">
                                <h5 class="table-title" style="font-size: 2.6px !important">
                                    Transcribe(%)</h5>
                                <P>{{ this.subQuestion?.responses?.scoringResults?.transcribe }}</P>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <!-- new code  -->

    <div class="mt-0" v-if="quesType == 1036">
        <!-- <div class="mt-0"> -->
        <div class="">
            <div class="fw-bolder align-items-sm-start"><p class="me-2"></p>
                <div class="row">
                    <div class="col-md-2">Question {{ this.index + 1 }}:</div>
                    <div class="col-md-10">
                        <div class="row">
                            <p class="col-12" v-html="this.subQuestion.question"></p>
                            <audio class="col-4" controls v-if="this.subQuestion?.mediaAudio">
                                <source :src="this.subQuestion?.mediaAudio" type="audio/mpeg" />
                            </audio>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="accordion-body p-0">
                <div class="row">
                    <div class="col-md-12">
                        <div class="Report_basic_info_area active-report-repsponses my-4"
                            v-for="resp in this.subQuestion?.responses" :key="resp">
                            <p class="my-1 border-top border-bottom p-1" style="border-color: #e9ecef;">
                                <b>Candidate Inputs:</b> <br>
                                {{ resp.candidateInputs }}
                            </p>
                            <div class="table-responsive-sm table-border-M7-responsive mt-3"
                                style="font-size: 8px !important">
                                <table class="table table-border-M7-kj">
                                    <tbody>
                                        <tr>
                                            <td style="padding: 1px 2px 2px 2px;">
                                                <h5 class="table-title"
                                                    style="font-size: 8px !important">
                                                    Response
                                                    Confidence</h5>
                                                <P>{{ resp?.scoringResults?.outputConfidence }}</P>
                                            </td>
                                            <td style="padding: 1px 2px 2px 2px;">
                                                <h5 class="table-title"
                                                    style="font-size: 8px !important">
                                                    Pronunciation(%)</h5>
                                                <P>{{ resp?.scoringResults?.pronunciationAbs }}</P>
                                            </td>
                                            <td style="padding: 1px 2px 2px 2px;">
                                                <h5 class="table-title"
                                                    style="font-size: 8px !important">
                                                    Fluency(%)
                                                </h5>
                                                <P>{{ resp?.scoringResults?.fluencyAbs }}</P>
                                            </td>
                                            <td style="padding: 1px 2px 2px 2px;">
                                                <h5 class="table-title"
                                                    style="font-size: 8px !important">
                                                    Grammar(%)
                                                </h5>
                                                <P>{{ resp?.scoringResults?.grammerAbs }}</P>
                                            </td>
                                            <td style="padding: 1px 2px 2px 2px;">
                                                <h5 class="table-title"
                                                    style="font-size: 8px !important">
                                                    Comprehension(%)</h5>
                                                <P>{{ resp?.scoringResults?.comprehensionAbs }}</P>
                                            </td>
                                            <td style="padding: 1px 2px 2px 2px;">
                                                <h5 class="table-title"
                                                    style="font-size: 8px !important">
                                                    Vocabulary(%)
                                                </h5>
                                                <P>{{ resp?.scoringResults?.vocabularyAbs }}</P>
                                            </td>
                                            <td style="padding: 1px 2px 2px 2px;">
                                                <h5 class="table-title"
                                                    style="font-size: 8px !important">
                                                    Transcribe(%)
                                                </h5>
                                                <P>{{ resp?.scoringResults?.transcribe }}</P>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
    <div class="mt-0" v-if="quesType == 1027 || this.quesType == 1039">
        <!-- <div class="mt-0"> -->
        <div>
            <div>
                <p class="me-2">Q.{{ this.index + 1 }}</p>
                <div>
                    <p v-html="this.subQuestion.question"></p>
                </div>
            </div>
        </div>
        <div>
            <div class="accordion-body">
                <div class="">
                    <div class="col-xl-6 col-xl-7 col-md-12">
                        <div class="table-border-M7">
                            <div class="row border">
                                <div class="col-md-6 p-0">
                                    <div class="border p-2 bg-secondary-option">
                                        Option
                                    </div>
                                    <div v-for="data in questionOptions(true)" :key="data">
                                        <div class="border-top border-bottom p-1" style="border-color: #e9ecef;"
                                            :class="{ 'Report_Responses_right_option_section border': data.isChecked }">
                                            <p>
                                                {{ data.optionName }}
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-6 p-0">
                                    <div class="border p-2 bg-secondary-option">
                                        Option
                                    </div>
                                    <div class="" v-for="data in questionOptions(false)" :key="data">
                                        <div class="border-top border-bottom p-2" style="border-color: #e9ecef;"
                                            :class="{ 'Report_Responses_wrong_option_section border': data.isChecked }">
                                            <p>
                                                {{ data.optionName }}
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>





                        </div>
                    </div>
                </div>
                <hr style="border-color: #e9ecef;" />
                <div>
                    <p class="fw-bolder">Candidate Input</p>
                </div>
            </div>
        </div>
    </div>

    <div class=" mt-0" v-if="quesType == 1020">
        <div>
            <div>
                <p class="me-2">Q.{{ this.index + 1 }}</p>
                <div>
                    <p v-html="this.subQuestion?.question"></p>
                    <p v-if="!question.responses" class="Unanswered_text">Unanswered</p>
                    <div class="fw-normal mt-1"></div>
                </div>
            </div>
        </div>
        <div>
            <div class="accordion-body p-0">
                <div class="row">
                    <div class="col-md-12">
                        <div class="Report_basic_info_area active-report-repsponses my-4"
                            v-for="resp in this.subQuestion?.responses" :key="resp">
                            <p class="my-3 border-top p-3">
                                <b>Candidate Input:</b> <br>
                                {{ resp.candidateInputs }}
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-0" v-if="quesType == 1008">
        <div>
            <div>
                <p class="me-2">Q.{{ this.index + 1 }}</p>
                <div>
                    <p v-html="this.subQuestion?.question"></p>
                    <p v-if="!question.responses" class="Unanswered_text">Unanswered</p>
                    <div class="fw-normal mt-1"></div>
                </div>
            </div>
        </div>
        <div>
            <p style="padding: 2px 3px;">{{ this.subQuestion?.passage }}</p>
            <div class="accordion-body" v-for="sectionRes in this.subQuestion.responses" :key="sectionRes">
                <p class="my-3 border-top py-3">
                    <b>Candidate Inputs:</b> <br>
                    {{ sectionRes.candidateInputs }}
                </p>

            </div>
        </div>
    </div>





    <!-- end  -->
</template>

<script>
export default {
    name: "SubQuestionTypepdf",
    props: ["quesType", "subQuestion", "pIndex", "index", "type"],
    data() {
        return {
        };
    },
    methods: {
        questionOptions(type) {
            if (type == true) {
                var x = this.question?.questionOptions.filter((x) => x.optionWeightage > 0)
                return x;
                //    console.log(x);
            } else {
                var y = this.question?.questionOptions.filter((x) => x.optionWeightage <= 0);
                return y;
                // console.log(y);
            }

        }
    }


};
</script>