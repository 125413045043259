<template>
  <section class="container-fuild">
    <div class="container">
      <div class="resetPasswordLinks-section">
        <span>Reset your password</span>
        <p>
          PMaps has just sent a reset password link to your email ID:
          {{ email }}
        </p>

        <div class="resetPasswordLinks-bottomLinks">
          <router-link :to="{ name: 'Login' }" class="resetPassword-backLoing-Link">Return to login page</router-link>
          <!-- <router-link
            :to="{ name: 'Login' }"
            class="resetPassword-backLoing-Link"
            >Resend Recovery link</router-link
          ><br /> -->
          <a class="resetPassword-backLoing-Link"  @click="reset">Resend Recovery link</a><br />
          <a href="#" class="resetPassword-backLoing-Link">Need help? Contact PMaps team</a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "ResetPasswordLink",
  data() {
    return {
      email: localStorage.getItem("Email"),
      Email: "",
    };
  },
  async mounted() {
    if (this.email == null)
      this.$router.push({
        name: "ErrorPage"
      });
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    ...mapActions(["ResetPassword", "forgetPassword", "ShowSucessMessage"]),

    async reset() {
        localStorage.setItem("Email", this.email);
        await this.forgetPassword({ email: this.email });
        return this.ShowSucessMessage("Email Send Successfully")
    
    },
  },
};
</script>
<style></style>
