import * as getters from "./getters";
import * as actions from "./actions";
import * as mutations from "./mutations";
import {
  getAuthUserData,
  // userInfo,
  // checkTokenExpire,
} from "../../../utils/auth";

const user = getAuthUserData();

const state = {
  authenticate: user ? (user.token == undefined ? false : true) : false,
  //authenticate: user ? true: false,
  user: user ? user : {}, //userInfo(user.token),
  subscription: {},
  IsNewAccount: false,
  UserId: 0,
  permission: localStorage.getItem("Userpermission")
    ? JSON.parse(localStorage.getItem("Userpermission"))
    : [],
  parent: false,
  // color:[]  //StackedBarchart  
  userLogin:user
};

export default {
  state,
  getters,
  actions,
  mutations,
};
