<template>
    <div class="modal fade CreatePartnerModal_hp" id="AddNewUserModal" tabindex="-1" data-bs-backdrop="static"
        data-bs-keyboard="false" aria-labelledby="AddNewUserModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title text-centerm-auto" id="AddNewUserModalLabel">
                        Add User
                    </h4>
                    <button type="button" class="btn-close" id="close" data-bs-dismiss="modal" aria-label="btn-close"
                        @click="ResetForm"></button>
                </div>
                <div class="modal-body mt-4">
                    <div class="row" id="CreateForm">
                        <div class="form-group mb-4">
                            <label class="input-hero" for="Category">
                                <select name="" v-model="role" class=" form-select input_area select_ca" id="">
                                    <option value="" selected disabled>Choose Role</option>
                                    <option v-for="region in roleList" :key="region" :value="region.value">
                                        {{ region.label }}</option>
                                    <!-- <option value="">Department</option>
                    <option value="">Location</option>
                    <option value="">Region</option> -->
                                </select>
                                <span class="input-name">Role <span class="text-danger">*</span></span>
                            </label>
                        </div>

                        <div class="form-group mb-4">
                            <!-- <label class="input-hero" for="Category">
                  <Multiselect v-model="group" placeholder="Choose Groups" mode="tags" :close-on-select="false"
                    :options="options" :searchable="true" />

                  <span class="input-name">Groups <span class="text-danger">*</span></span>
                </label> -->

                            <!-- <div class="form-group mb-4"> -->
                            <label class="input-hero" for="PartnerCompany">
                                <input class="input_area" type="text" :value="this.gname" :disabled="true"
                                    required="" />
                                <!-- {{this.gname}} -->
                                <!-- <Multiselect v-model="group" placeholder="Choose Groups" mode="tags"
                                    :close-on-select="false" :options="options" :searchable="true"></multiselect> -->
                                <span class="input-name input-group-kj">Group <span class="text-danger">*</span></span>
                            </label>
                            <!-- </div> -->
                        </div>

                        <div class="form-group mb-4">
                            <label class="input-hero" for="FirstName">
                                <input class="input_area" v-model="firstname" type="text" required="" /><span
                                    class="input-name">First
                                    Name <span class="text-danger">*</span></span>
                            </label>
                        </div>

                        <div class="form-group mb-4">
                            <label class="input-hero" for="LastName">
                                <input class="input_area" v-model="lastname" type="text" required="" /><span
                                    class="input-name">Last
                                    Name <span class="text-danger">*</span></span>
                            </label>
                        </div>

                        <div class="form-group mb-4">
                            <label class="input-hero" for="Tokens">
                                <input class="input_area" type="email" v-model="email" required="" /><span
                                    class="input-name">Work
                                    Email <span class="text-danger">*</span></span>
                            </label>
                        </div>

                        <div class="">
                            <button @click="savepartner" class="modail_submit_button" :disabled="isDisabled()"
                                 :class="{ active_form_button: !isDisabled() }">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapState,
    mapActions,
    mapMutations
} from "vuex";
// import Multiselect from "@vueform/multiselect";
import {
    validateEmail
} from "@/utils";
import { validateName } from "../utils";
export default {
    name: 'Addnewuser',
    props: ["gid", "gname"],
    data() {
        return {
            group: [],
            firstname: "",
            email: "",
            lastname: "",
            role: '',
            roleList: [],
            // options: [],
        }
    },
    computed: {
        ...mapState({
            authenticated: (state) => state.auth.authenticate,
            user: (state) => state.auth.user,
            clientId: (state) => state.auth.user.clientId,
        }),
    },
    components: {
        // Multiselect
    },
    methods: {
        ...mapActions([
            "GetAllRolesnoPag",
            "AllRolesList",
            "ShowWarningMessage",
            "Getuserlist",
            "AddUser",
            "inviteUser",
            "GetTeams",
        ]),
        ...mapMutations(["setLoading"]),

        isDisabled() {
            return (
                this.role == "" ||
                this.firstname.trim() === "" || this.lastname.trim() === "" || this.email.trim()===""
            );
        },

        async savepartner() {
            if (this.firstname.length > 20 || !validateName(this.firstname) || this.lastname.length > 20 || !validateName(this.lastname) || !validateEmail(this.email)) {
                if (this.firstname.length > 20) {
                    return this.ShowWarningMessage(
                        "Please enter First Name upto 20 character."
                    );
                }
                if (!validateName(this.firstname)) {
                    return this.ShowWarningMessage("First Name must be alphabetic .");
                }
                if (this.lastname.length > 20) {
                    return this.ShowWarningMessage(
                        "Please enter Last Name upto 20 character."
                    );
                }
                if (!validateName(this.lastname)) {
                    return this.ShowWarningMessage("Last Name must be alphabetic .");
                }
                if (!validateEmail(this.email)) {
                    return this.ShowWarningMessage("enter correct e-mail");
                }
            }
            else {
                
                const model = {
                    Email: this.email,
                    Role: this.role,
                    FirstName: this.firstname,
                    LastName: this.lastname,
                    Regions: [+this.gid],
                };
                const createdUser = await this.AddUser({
                    model,
                    clientId: this.clientId,
                });
                await this.inviteUser({
                    clientId: this.clientId,
                    userId: createdUser.object.data.id,
                });
                if (createdUser.status) {
                    document.getElementById("close").click();
                    // window.$("#AddNewUserModal").modal('hide');
                }

            }
        },
        ResetForm() {
            this.$emit('clicked', 'false')
        },
    },
    async mounted() {
        window.$("#AddNewUserModal").modal('show');
        var a = await this.GetTeams({
            clientId: this.clientId
        })
        this.TeamList = await a.object.data;

        this.options = this.TeamList.regions.map((i) => ({
            value: i.regionId,
            label: i.regionName
        }));
        var b = await this.AllRolesList({
            clientId: this.clientId,
            PageNo: 1,
            Limit: 100,
            Search: ""
        });
        this.roleList = b.object.data.items.map((i) => ({
            value: i.roleId,
            label: i.roleName,
        }));
    }
}
</script>

<style>

</style>
