<template>
  <div class="page-content">
    <!-- mobile view Searching -->
    <div class="Searching_input_box_mobile_view mb-3 position-relative">
      <div class="w-100" v-if="this.show">
        <input type="text" v-model="input" :disabled="searchDisable" @keyup.enter="searchregionname(input)" placeholder="Search..." />
        <!--update @keyup="filteredList"-->
        <!-- <div class="search_detail_list">
          <div v-for="(item, index) in this.result" :key="index" class="search_detail_list_item"
            @click="searchregionname(item)" style="cursor: pointer">
            <span :id="index">
              {{ stringTOHtml(item, index) }}
            </span>
          </div>
        </div> -->
        <!--update-->
        <!-- <span onclick="closemobilesearch()"> -->
        <img src="../../public/dashboard-assets/images/M2/Close_icon.png" @click="closemobilesearch" alt=""
          class="close-icon" />
        <!-- </span> -->
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
      <div class="my-2 d-flex align-items-center">
        <div class="
                top_heading_CreatePartner_btn
                d-block d-sm-block d-md-none
                me-2
              ">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <router-link :to="{ name: 'DashboardPartner' }" class="btn p-0 Group_top_three_dot_dropdown" type="button">
                <i class="fa-solid fa-chevron-left"></i>
              </router-link>
            </div>
          </div>
        </div>
        <h4 class="mb-2 mb-md-0">Roles</h4>
      </div>
      <div class="d-flex">
        <!-- Search  web view-->
        <div class="top_heading_CreatePartner_btn position-relative" v-if="RoleList.length != 0">
          <div class="Group_top_three_dot">
            <div class="Searching_input-box">
              <input type="text" v-model="input" :disabled="searchDisable" @keyup.enter="searchregionname(input)" placeholder="Search..." />
              <!--update @keyup="filteredList"-->
              <!-- <div class="search_detail_list" v-if="this.result.length > 0">
                <div v-for="(item, index) in this.result" :key="index" class="search_detail_list_item"
                  @click="searchregionname(item)" style="cursor: pointer">
                  <span :id="index">
                    {{ stringTOHtml(item, index) }}
                  </span>
                </div>
              </div> -->
              <!--update-->
              <span class="icon Group_top_three_dot_dropdown" @click="opensearch">
                <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" class="Searching_search_icon" />
              </span>
              <img src="../../public/dashboard-assets/images/M2/Close_icon.png" @click="closesearch" alt=""
                class="close-icon" />
            </div>
          </div>
        </div>

        <!-- Mobile View Searching Button -->
        <div class="
                top_heading_CreatePartner_btn
                position-relative
                Searching_button_mobileView
               ">
          <div class="Group_top_three_dot">
            <span class="icon Group_top_three_dot_dropdown" :style="{ display: this.show == true ? 'none' : 'block' }"
              @click="openmobilesearch">
              <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" class="Searching_search_icon" />
            </span>
          </div>
        </div>
        <!--update-->
        <div class="ms-2 top_heading_CreatePartner_btn"
          v-if="/*getUserPermissionData({parent:'Role Management',child:'Role Account',permission:'Can_Create_Role'})*/superadmin()">
          <!--update-->
          <router-link v-if="RoleList.length != 0" :to="{ name: 'ManagementCreateRoles' }"
            class="btn btn-primary btn-icon-text mb-2 mb-md-0">
            <i class="fa-solid fa-plus"></i> Create New Role
          </router-link>
        </div>
      </div>
      <div class="mobile_Create_Partner_button">
        <!--update-->
        <router-link :to="{ name: 'ManagementCreateRoles' }" class="btn btn-primary btn-icon-text mb-2 mb-md-0"
          v-if="/*getUserPermissionData({parent:'Role Management',child:'Role Account',permission:'Can_Create_Role'})*/superadmin()">
          <!--update-->
          <i class="fa-solid fa-plus"></i>
        </router-link>
      </div>
    </div>

    <!-- empty section -->
    <section class="Partner_empty_main_section_hp" v-if="RoleList.length == 0">
      <div class="row">
        <div class="Partner_empty_img_hp m-auto text-center">
          <img src="../../public/dashboard-assets/images/M2/Partner_blank.png" alt="" />
        </div>
        <div class="Partner_empty_content_hp m-auto text-center">
          <div class="mb-4">
            <h2>Hey, you still alone?</h2>
            <p class="text-muted mt-2">
              This is the best time to create Roles and start working
            </p>
          </div>
          <router-link :to="{ name: 'ManagementCreateRoles' }" class="btn btn-primary btn-icon-text mb-2 mb-md-0">
            <i class="fa-solid fa-plus"></i> Create New Role
          </router-link>
        </div>
      </div>
    </section>

    <!-- After Data -->
    <div v-else>
      <section class="row role_item_list_section mt-4">
        <div class="col-xl-3 col-lg-3 col-md-4 col-6 mb-4" v-for="item in RoleList" :key="item">
          <div class="role_item_box">
            <div class="
                    d-flex
                    justify-content-between
                    role_item_box_new_item
                    align-items-center
                  ">
              <span v-if="this.maxRoleId == item.roleId && this.offset == 1">new</span>
              <div v-else>
                <img src="../../public/dashboard-assets/images/M2/Role_logo.png" alt="" class="w-100" />
              </div>

              <div class="partner_item_action" v-if="item.isDefault != true">
                <div class="dropdown">
                  <button class="btn p-0" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <i class="fa-solid fa-ellipsis"></i>
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li
                      v-if="getUserPermissionData({ parent: 'Role Management', child: 'Role Account', permission: 'Can_Update_Role' })">
                      <router-link :to="{
                        name: 'ManagementEditRoles',
                        params: { id: encode(item.roleId) },
                      }" class="btn w-100 text-start">
                        Edit Role
                      </router-link>
                    </li>
                    <li
                      v-if="getUserPermissionData({ parent: 'Role Management', child: 'Role Account', permission: 'Can_Delete_Role' })">
                      <button type="button" class="btn w-100 text-start" data-bs-toggle="modal"
                        data-bs-target="#DeleteRoleModal" @click="deleteRole(item.roleId, item.roleName)">
                        Delete Role
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <p class="text-muted font-size-12" v-else>Defined Role</p>
            </div>
            <div data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
              @click="cardopen(item.roleId)">
              <div class="role_item_box_heading">{{ item.roleName }}</div>
              <div class="role_item_box_des">
                <span>Description</span>
                <p>
                  {{ item.description }}
                  <!-- {{ item.description.substring(0, item.description.lastIndexOf(item.description.substring(20))) + "..." }} -->

                  <!-- {{ item.description.substr(0, 45).replace(/\s\S*$/, "...") }} -->
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <paginate v-model="currentPage" :page-count="Page_count" :margin-pages="1" :page-range="1"
        :click-handler="clickCallback" :prev-text="'< Prev'" :next-text="'Next >'" :container-class="'pagination'"
        :page-class="'page-item'">
      </paginate>
    </div>

    <!-- Role Item view section -->
    <div class="offcanvas offcanvas-end role_item_view_detail_sidebar" data-bs-backdrop="static"  tabindex="-1" id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel">
      <div class="offcanvas-header">
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>

        <h4 id="offcanvasRightLabel">{{ Roledata.roleName }}</h4>

        <div class="partner_item_action">
          <div class="dropdown" v-if="Roledata.isDefault != true">
            <button class="btn p-0" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <i class="fa-solid fa-ellipsis"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <router-link :to="{
                  name: 'ManagementEditRoles',
                  params: { id: activeRoleId },
                }" class="btn w-100 text-start">
                  Edit Role
                </router-link>
              </li>
              <li>
                <button type="button" class="btn w-100 text-start" data-bs-toggle="modal"
                  data-bs-target="#DeleteRoleModal" @click="deleteRole(Roledata.roleId)">
                  Delete Role
                </button>
                <button type="button" id="offcanvasmks" class="btn-close text-reset d-none" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
              </li>
            </ul>
          </div>
          <div class="text-muted" v-else>Defined Role</div>
        </div>
      </div>
      <div class="offcanvas-body">
        <div class="card p-0 role_item_view_detail_item border-0 mb-3">
          <div class="card-body p-3">
            <h4 class="mb-3">Description</h4>
            <p class="font-size-12 text-muted">
              {{ Roledata.description }}
            </p>
          </div>
        </div>

        <div v-for="item in this.permissionsList" :key="item">
          <div class="card p-0 role_item_view_detail_item mb-3 border-0">
            <div v-for="m in this.x" :key="m">
              <div class="card-body p-3" v-if="m.name == item.name && m.select == 'true'">
                <h4 class="mb-3">{{ item.name }}</h4>
                <div v-for="x in item.groups" :key="x">
                  <span v-for="z in x.permissions" :key="z">
                    <span v-if="z.isSelected == true">
                      <button class="select_role_item_button btn text-muted">
                        {{ z.name }}
                      </button>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- DeleteRoleModal -->
    <div class="modal fade" id="DeleteRoleModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="DeleteRoleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-body p-4">
            <div class="">
              <h3 class="mb-3">Delete Role?</h3>
              <p class="text-muted">
                Are you sure you want to delete
                <strong style="color: #000000">{{ roleName }}</strong> Role.
              </p>
            </div>
            <div class="Deactivate_buttons row mt-5 justify-content-center">
              <button class="col-5 Deactivate_Cancel_buttons cancel-btn-mobileView" @click="CancelPopup">
                No
              </button>
              <button class="col-5 Deactivate_Cancel_buttons No-keep-btn" @click="CancelPopup">
                No, Keep
              </button>
              <button type="button" class="
                      col-5
                      btn
                      Deactivate_Deactivate_buttons
                      Delete_buttons_mobileView
                    " @click="confirmdelete()">
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
        
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Paginate from "vuejs-paginate-next";
import { getUserPermissionData } from '../utils/helper';
import { superadmin } from "../utils/helper"; //update
import { validateWhenExpiryDateIsNull } from '../platformFeatures/dateUtils';


export default {
  name: "Management-Roles",
  components: {
    paginate: Paginate,
  },
  data() {
    return {
      RoleList: [],
      roleID: 0,
      maxRoleId: 0,
      Roledata: [],
      permissionsList: [],
      offset: 1,
      limit: 12,
      Page_count: 0,
      activeRoleId: 0,
      show: false,
      Search: "",
      fruits: ["apple", "banana", "orange", "hllo oranges"],
      input: "",
      rolenamelist: [],
      result: [],
      x: [],
      searchDisable:false,
      currentPage: 1
    };
  },

  async created(){
    validateWhenExpiryDateIsNull(this.billing.object.data.subscription.expiryDate );
  },
  async mounted() {
    await this.getrolelist();
  },

  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      clientId: (state) => state.auth.user.clientId,
    }),
  },

  methods: {
    superadmin, //update
    ...mapActions(["AllRolesList", "getrole", "deleteroles"]),
    ...mapMutations(["setLoading"]),
    getUserPermissionData,

    encode(item) {
      return window.btoa(item);
    },
    // Searching box
    opensearch() {
      document.querySelector(".Searching_input-box").classList.add("open");
    },
    async closesearch() {
      document.querySelector(".Searching_input-box").classList.remove("open");
      this.result = [];
      this.input = "";
      this.Search = "";
      await this.getrolelist();
    },
    async closemobilesearch() {
      this.show = false;
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.remove("open");
      this.result = [];
      this.input = "";
      this.Search = "";
      await this.getrolelist();
    },
    openmobilesearch() {
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.add("open");
      this.result = [];
      this.input = "";
      this.show = true;
    },
    async filteredList() {
      if (this.input != "") {
        this.rolenamelist = [];
        var roles = await this.AllRolesList({
          clientId: this.user.clientId,
          PageNo: 1,
          Limit: 100,
          Search: this.input,
        });
        var x = roles.object.data.items;
        x.forEach((element) => {
          if (this.rolenamelist.indexOf(element.roleName) < 0) {
            this.rolenamelist.push(element.roleName);
          }
        });
        this.result = this.rolenamelist.filter((element) =>
          element.toLowerCase().startsWith(this.input.toLowerCase())
        );
        this.result = Object.freeze(JSON.parse(JSON.stringify(this.result)));
      } else {
        this.result = [];
        this.getrolelist();
      }
    },
    stringTOHtml(item, idx) {
      setTimeout(() => {
        if (this.input != "") {
          let re = new RegExp(this.input.toLowerCase(), "g");
          var html = document.getElementById(idx);
          var ex = item
            .toLowerCase()
            .replace(
              re,
              `<span style="color:#7b68ee !important">${this.input}</span>`
            );
          if (html) html.innerHTML = ex;
        }
      }, 100);
    },
    //*********search end*********

    clickCallback(pageNum) {
      this.offset = pageNum;
      // this.offset = (pageNum - 1) * this.limit + 1;
      this.getrolelist();
    },
    CancelPopup() {
      window.$("#DeleteRoleModal").modal("hide");
    },
    async cardopen(roleId) {
      this.x = [];
      this.permissionsList = [];
      var rolebyid = await this.getrole({
        roleid: roleId,
        clientId: this.clientId,
      });
      this.Roledata = rolebyid.object.data;
      this.activeRoleId = this.Roledata.roleId;

      this.Roledata.permissions.forEach((element) => {
        this.permissionsList.push(element);
        var Length = [];
        element.groups.forEach((el) => {
          el.permissions.forEach((elm) => {
            if (elm.isSelected == true) {
              Length.push(elm.id);
            }
          });
        });
        if (Length.length > 0) {
          this.x.push({
            name: element.name,
            select: "true",
          });
        } else {
          this.x.push({ name: element.name, select: "false" });
        }
      });
    },

    async deleteRole(id, name) {
      document.getElementById("offcanvasmks").click();
      this.roleID = id;
      this.roleName = name;
    },
    async confirmdelete() {
      await this.deleteroles({
        roleId: this.roleID,
        clientId: this.clientId,
      });
      await this.getrolelist();
      window.$("#DeleteRoleModal").modal("hide");
    },

    async getrolelist() {
      this.searchDisable = true;
      var roles = await this.AllRolesList({
        clientId: this.clientId,
        PageNo: this.offset,
        Limit: this.limit,
        Search: this.Search,
      });
      this.searchDisable = false;
      var ar = [];
      if (roles != undefined) {
        this.RoleList = roles?.object?.data?.items;
        this.RoleList.forEach((element) => {
          ar.push(element.roleId);
        });
        this.maxRoleId = Math.max(...ar);
        let sorted = this.RoleList.sort((p1, p2) =>
          p1.roleId < p2.roleId ? 1 : p1.roleId > p2.roleId ? -1 : 0
        );
        this.RoleList = sorted;
        this.Page_count = roles.object.data.totalPages;
      }
    },
    async searchregionname(val) {
      this.input = val.trim();
      this.offset = 1
      this.Search = this.input;
      this.result = [];
      this.currentPage = 1;
      await this.getrolelist();
    },
  },
};
</script>
    
        
        
<style>
.page-item.disabled .page-link {
  background-color: #fff !important;
  border-color: #fff !important;
}

.pagination .page-item .page-link {
  width: 30px;
  height: 32px;
  background: transparent;
  display: flex;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  margin: 5px;
  color: #000 !important;
  border: none;
  cursor: pointer;
}

.pagination {
  justify-content: flex-end;
}

.pagination .page-item.active .page-link {
  font-weight: 600;
  background: #ffc801;
}

.pagination .page-item:last-child .page-link {
  background-color: transparent;
  border: none;
  width: 100% !important;
}

.pagination .page-item:first-child .page-link {
  background-color: transparent;
  border: none;
  width: 100% !important;
}

.pagination .page-item.disabled .page-link {
  color: rgb(130, 130, 130) !important;
  cursor: no-drop !important;
  background: #7fffd400 !important;
}

.No-keep-btn {
  display: none;
}

.cancel-btn-mobileView {
  display: block;
}

@media (max-width: 767px) {
  .No-keep-btn {
    display: block;
  }

  .cancel-btn-mobileView {
    display: none;
  }
}
</style>
